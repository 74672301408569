import React, { useState } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { notification, Tooltip } from 'antd';
import HeroAxios from '../../helpers/HeroAxios';

export function RefreshDgiFE({ clientId, onRefreshComplete }) {
    const [loading, setLoading] = useState(false);

    const handleRefresh = async () => {
        if (loading) return;
        setLoading(true);

        notification.info({
            message: 'Sincronización Iniciada',
            description: (
                <>
                    Actualizando facturas desde DGI.
                    <br />
                    Este proceso puede tardar varios minutos...
                </>
            ),
            placement: 'topRight',
        });

        try {
            await HeroAxios.get(
                `/hero-runner/dgi/get-facturas/${clientId}`
            );
            // 200 response just means the async process was initiated successfully
            onRefreshComplete && onRefreshComplete();
        } catch (error) {
            console.error('Error iniciando sincronización:', error);
            notification.error({
                message: 'Error de Sincronización',
                description: 'No se pudo iniciar la sincronización con DGI. Por favor intente nuevamente.',
                placement: 'topRight',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Tooltip title="Buscar Facturas">
            <SyncOutlined
                spin={loading}
                onClick={handleRefresh}
                style={{
                    cursor: loading ? 'not-allowed' : 'pointer',
                    marginRight: 8,
                    color: '#00bcd4' // Light blue/celeste color
                }}
            />
        </Tooltip>
    );
} 