// CommitDoubleFilterDropdown.js
import React, { useState } from 'react';
import { Button, Radio, Space } from 'antd';

/**
 * We'll store something like:
 *   selectedKeys = [{
 *     commitStatus: null,  // or "committed" or "not_committed"
 *     agentFilter: "all"
 *   }]
 */
export function CommitDoubleFilterDropdown({
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
}) {
  const initialFilterValue = selectedKeys[0] || {
    commitStatus: null, // or "committed"/"not_committed"
    agentFilter: 'all',
  };

  const [localFilter, setLocalFilter] = useState(initialFilterValue);

  const onChangeCommitStatus = (e) => {
    setLocalFilter((prev) => ({
      ...prev,
      commitStatus: e.target.value,
    }));
  };

  const onChangeAgentFilter = (e) => {
    setLocalFilter((prev) => ({
      ...prev,
      agentFilter: e.target.value,
    }));
  };

  const handleConfirm = () => {
    setSelectedKeys([localFilter]);
    confirm();
  };

  const handleClear = () => {
    setLocalFilter({ commitStatus: null, agentFilter: 'all' });
    setSelectedKeys([]);
    clearFilters();
    confirm({ closeDropdown: true });
  };

  return (
    <div style={{ padding: 12 }}>
      <div style={{ marginBottom: 8, fontWeight: 500 }}>Estado de Commit:</div>
      <Radio.Group
        onChange={onChangeCommitStatus}
        value={localFilter.commitStatus}
        style={{ display: 'flex', flexDirection: 'column', marginBottom: 12 }}
      >
        <Radio value='committed'>Committed</Radio>
        <Radio value='not_committed'>Not Committed</Radio>
      </Radio.Group>

      <div style={{ marginBottom: 8, fontWeight: 500 }}>Creado por:</div>
      <Radio.Group
        onChange={onChangeAgentFilter}
        value={localFilter.agentFilter}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Radio value='all'>Todas</Radio>
        <Radio value='AUTONOMOUS_AGENT'>Autónomas</Radio>
        <Radio value='human'>Humanos</Radio>
      </Radio.Group>

      <Space style={{ marginTop: 12 }}>
        <Button type='primary' onClick={handleConfirm}>
          Filtrar
        </Button>
        <Button onClick={handleClear}>Reset</Button>
      </Space>
    </div>
  );
}
