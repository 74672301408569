/********************************
 * AutoClassifyEngineButton.jsx
 ********************************/
import React, { useState } from 'react';
import { Modal, Checkbox, message } from 'antd';
import HeroAxios from '../../helpers/HeroAxios.js';
import EngineButton from './EngineButton.js';

function AutoClassifyEngineButton(props) {
  const [autoClassifyLoading, setAutoClassifyLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // State for each classification type
  const [classifyBotExpenses, setClassifyBotExpenses] = useState(true);
  const [classifyFes, setClassifyFes] = useState(true);
  const [classifySotTxns, setClassifySotTxns] = useState(true);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleOk = () => {
    setShowModal(false);
    setAutoClassifyLoading(true);

    HeroAxios.post('autoClassifyAll', {
      accounting_clients_id: props.accountingClientsId,
      classify_bot_expenses: classifyBotExpenses,
      classify_fes: classifyFes,
      classify_sot_txns: classifySotTxns,
    })
      .then((response) => {
        setAutoClassifyLoading(false);
        if (response.status === 200) {
          message.info(
            'Autoclasificación en proceso. Puede demorar unos minutos.'
          );
        }
      })
      .catch((error) => {
        setAutoClassifyLoading(false);
        console.error('Error auto-classifying:', error);

        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          message.error('Error al clasificar: ' + error.response.data.error);
        } else {
          message.error('Error al clasificar: ' + error.message);
        }
      });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <EngineButton
        text='Auto clasificar 🪄 🦸‍♂️'
        disabled={autoClassifyLoading}
        loading={autoClassifyLoading}
        onClick={handleOpenModal}
        // Popover content no longer relevant in this flow:
        popOverContent={null}
      />

      <Modal
        visible={showModal}
        title='🪄 Auto Clasificar'
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={autoClassifyLoading}
        cancelText='Cancelar'
        okText='Auto clasificar'
      >
        <div>
          <p>Seleccione las que quiere que se autoclasifiquen:</p>
        </div>
        <div>
          <Checkbox
            checked={classifyBotExpenses}
            onChange={(e) => setClassifyBotExpenses(e.target.checked)}
          >
            Gastos Bot
          </Checkbox>
        </div>

        <div style={{ marginTop: 10 }}>
          <Checkbox
            checked={classifyFes}
            onChange={(e) => setClassifyFes(e.target.checked)}
          >
            FEs
          </Checkbox>
        </div>

        <div style={{ marginTop: 10 }}>
          <Checkbox
            checked={classifySotTxns}
            onChange={(e) => setClassifySotTxns(e.target.checked)}
          >
            Transacciones SOT
          </Checkbox>
        </div>
      </Modal>
    </>
  );
}

export { AutoClassifyEngineButton };
