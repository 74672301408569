// AccountingTransactionsUploader.js

import React, { useState, useContext } from 'react';
import { Modal, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { read, utils } from 'xlsx';
import HeroAxios from '../../helpers/HeroAxios.js'; // Import your custom axios instance
import { authContext } from '../../ProvideAuth.js';

function AccountingTransactionsUploader({
  API_domain,
  accountingClientCompany,
  fetchTransactions,
}) {
  const auth = useContext(authContext);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [validationPassed, setValidationPassed] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [transactionsWithErrors, setTransactionsWithErrors] = useState(0);
  const [transactionsWithoutErrors, setTransactionsWithoutErrors] = useState(0);

  // Handle CSV File Upload
  const handleFileUpload = (file) => {
    setUploading(true);
    setValidationPassed(false);
    setUploadErrors([]);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;

      const workbook = read(data, {
        type: 'string',
        cellDates: false, // We will handle dates manually
        raw: true,
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      let jsonData = utils.sheet_to_json(worksheet, {
        header: 1,
        raw: true, // Keep raw values so we can identify serial dates
      });

      // Helper function to convert Excel serial date to YYYY-MM-DD
      const excelToJSDate = (serial) => {
        // Excel's date system starts at 1899-12-30
        const baseDate = new Date(Date.UTC(1899, 11, 30));
        // Convert serial (days) to milliseconds and add to base date
        const ms = serial * 24 * 60 * 60 * 1000;
        const date = new Date(baseDate.getTime() + ms);

        // Format to YYYY-MM-DD
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      // If the first column is a date serial, convert it.
      jsonData = jsonData.map((row, index) => {
        if (index === 0) return row; // Skip header row
        if (typeof row[0] === 'number') {
          row[0] = excelToJSDate(row[0]);
        } else if (typeof row[0] === 'string') {
          const [m, d, y] = row[0].split('/');
          const year = y.length === 2 ? '20' + y : y;
          const month = String(m).padStart(2, '0');
          const day = String(d).padStart(2, '0');
          row[0] = `${year}-${month}-${day}`;
        }
        return row;
      });

      validateCsvData(jsonData);
    };

    reader.readAsText(file);
    return false;
  };

  // Validate CSV Data
  const validateCsvData = (jsonData) => {
    const headers = jsonData[0];
    const rows = jsonData.slice(1);

    // Map rows to objects
    const data = rows.map((row, index) => {
      const rowData = {};
      headers.forEach((header, i) => {
        rowData[header] = row[i];
      });
      return { rowNumber: index + 2, ...rowData }; // rowNumber starts at 2 because of headers
    });

    // Send data to backend for validation
    HeroAxios.post('validateCsvData', {
      data,
      accounting_client_id: accountingClientCompany.accounting_client_id,
    })
      .then((response) => {
        const {
          errors,
          total_transactions,
          transactions_with_errors,
          transactions_without_errors,
        } = response.data;
        if (errors && errors.length > 0) {
          // Show errors to user
          setUploadErrors(errors);
        } else {
          // No errors, proceed to create transactions
          setValidationPassed(true);
          setParsedData(data);
          message.success(
            `Validación exitosa. ${transactions_without_errors} transacciones pueden ser creadas.`
          );
        }
        setTotalTransactions(total_transactions);
        setTransactionsWithErrors(transactions_with_errors);
        setTransactionsWithoutErrors(transactions_without_errors);
      })
      .catch((error) => {
        console.error('Error validating CSV data:', error);
        message.error('Error al validar los datos del CSV');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  // Create Transactions from CSV Data
  const handleCreateTransactions = () => {
    setUploading(true);
    HeroAxios.post('createTransactionsFromCsv', {
      data: parsedData,
      accounting_client_id: accountingClientCompany.accounting_client_id,
    })
      .then((response) => {
        message.success('Transacciones creadas exitosamente');
        fetchTransactions();
        setIsUploadModalVisible(false);
      })
      .catch((error) => {
        console.error('Error creating transactions:', error);
        message.error('Error al crear las transacciones');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  return (
    <div>
      <Button
        type='secondary'
        onClick={() => setIsUploadModalVisible(true)}
        style={{ marginLeft: 8 }}
      >
        Subir CSV
      </Button>

      {/* Modal for uploading CSV */}
      <Modal
        title='Subir CSV'
        visible={isUploadModalVisible}
        onCancel={() => setIsUploadModalVisible(false)}
        footer={null}
      >
        <Upload
          name='file'
          accept='.csv'
          showUploadList={false}
          beforeUpload={handleFileUpload}
        >
          <Button icon={<UploadOutlined />}>Seleccionar Archivo CSV</Button>
        </Upload>

        {uploadErrors.length > 0 && (
          <div style={{ marginTop: 16 }}>
            <h3>Errores:</h3>
            <ul>
              {uploadErrors.map((error, index) => (
                <li key={index}>
                  Fila {error.rowNumber}: {error.message}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Display transaction counts */}
        <div style={{ marginTop: 16 }}>
          <p>Total de transacciones: {totalTransactions}</p>
          <p>Transacciones con errores: {transactionsWithErrors}</p>
          <p>Transacciones sin errores: {transactionsWithoutErrors}</p>
        </div>

        {validationPassed && (
          <Button
            type='primary'
            onClick={handleCreateTransactions}
            loading={uploading}
            style={{ marginTop: 16 }}
          >
            Crear Transacciones
          </Button>
        )}
      </Modal>
    </div>
  );
}

export default AccountingTransactionsUploader;
