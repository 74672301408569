import React, { createContext, useState } from 'react';
import Cookies from 'universal-cookie';

const authContext = createContext();

const accountingEmails = [
  // this should not be editted going forward. it exists for users who might have old cookies
  'noreplyherofacturas@gmail.com',
  'jarol@herofacturas.com',
  'jorge@herofacturas.com',
  'ernestolyons@gmail.com',
  'salomon.cohen.08@gmail.com',
  'gtesta@gutpanama.com',
  'alex@fonduee.com',
  'javier@fonseca.com',
  'joseralemanc@gmail.com',
  'kalabazapa@gmail.com',
  'maxalfredoga@gmail.com',
  'rocioroldan5@gmail.com',
  'gretelsucre@gmail.com',
  'felix.weng@dalifex.com',
  'milagros.esquivel@dalifex.com',
  'nathalie.rodriguez@dalifex.com',
  'elisayanes@gmail.com',
  'antonio@lagunazo.com',
  'aheilbron@ptmpanama.com',
  'jorge4arias@gmail.com',
  'vale@rpfoodgroup.com',
  'rafael@colrizado.com',
  'a.saenz.u@gmail.com',
  'fguillen@herofacturas.com',
  'invernaderocomplex@gmail.com',
  'contabilidad@celtecgroup.com',
  'd.torres@myenergylatam.com',
  'admin@celtecgroup.com',
  'recepcion@celtecgroup.com',
  'cookingmammapty@gmail.com',
  'guillemarria@gmail.com',
  'miguel@herofacturas.com',
  'gtesta+invernadero@gutpanama.com',
  'rdmm1893@gmail.com',
  'micaelalmanza@gmail.com',
  'autoparteshk24@gmail.com',
  'ivangonzaleza23@gmail.com',
  'gary.martin@quantil.com.co',
  'asistencia@flashcocinas.com',
  'mark@flashcocinas.com',
  'jdguerrac04@gmail.com',
  'jbrawerman@hotmail.com',
  'nutricion@malegiraldo.com',
  'nutricion@dioteiza.com',
  'nutrition@helenrd.com',
  'milaymimopty2@gmail.com',
  'milaymimopty@gmail.com',
  'alvarososa123+smmqbv@gmail.com',
  'alvarososa123+smmfsa@gmail.com',
  'mileidy+smmsfsa@salvamimaquina.com',
  'quentin+smmsfsa@salvamimaquina.com',
  'vromero+smmsfsa@salvamimaquina.com',
  'aalvarez+smmsfsa@alpersa.com',
  'victor+smmsfsa@salvamimaquina.com',
  'aalvarez@alpersa.com',
  'mileidy@salvamimaquina.com',
  'quentin@salvamimaquina.com',
  'vromero@salvamimaquina.com',
  'victor@salvamimaquina.com',
  'alvarososa123+salvamimaquina@gmail.com',
  'alvarososa123+salvamimaquinasmmfsa@gmail.com',
  'alvarososa123+alpersa@gmail.com',
  'alvarososa123+alpersasmmsfsa@gmail.com',
];

function useProvideAuth() {
  const cookies = new Cookies();

  // React State
  const [email, setEmail] = useState(cookies.get('email'));
  const [token, setToken] = useState(cookies.get('token'));
  const [accounts, setAccounts] = useState(
    cookies.get('accounts') ? cookies.get('accounts') : []
  );
  const [adminEmail, setAdminEmail] = useState(cookies.get('adminEmail'));
  const [adminToken, setAdminToken] = useState(cookies.get('adminToken'));
  const [showAccountingFeatures, setShowAccountingFeatures] = useState(
    cookies.get('showAccountingFeatures') === 'true' ||
      accountingEmails.includes(email)
  );
  const [accountingClientsId, setAccountingClientsId] = useState(
    cookies.get('accountingClientsId')
  );

  // Clean up weird cookie values from the past
  if (
    cookies.get('showAccountingFeatures') &&
    cookies.get('showAccountingFeatures') !== 'true'
  ) {
    cookies.remove('showAccountingFeatures', { path: '/' });
  }

  // Helper to set cookies with common options
  const cookieSetter = (cookieName, cookieValue) => {
    if (cookieValue) {
      cookies.set(cookieName, cookieValue, {
        maxAge: 60 * 60 * 24 * 365, // 1 year
        path: '/',
      });
    }
  };

  // Sets adminEmail/adminToken if user is one of the known admin emails
  const setAdminStuff = (email, token) => {
    if (
      email === 'noreplyherofacturas@gmail.com' ||
      email === 'jarol@herofacturas.com' ||
      email === 'jorge@herofacturas.com' ||
      email.includes('alpersa')
    ) {
      cookieSetter('adminEmail', email);
      cookieSetter('adminToken', token);
      setAdminEmail(email);
      setAdminToken(token);
    }
  };

  // Signin handles reading params from URL, setting cookies, and updating state
  const signin = (cb) => {
    const cookies = new Cookies();
    let search = window.location.search;
    let params = new URLSearchParams(search);

    // Get the new credentials from URL
    let newEmail = params.get('email');
    let newToken = params.get('token');
    let showAccountingFeatures =
      params.get('showAccountingFeatures') === 'True';
    let newAccountingClientsId = params.get('accountingClientsId');

    // We only proceed if we have a valid email & token
    if (newEmail && newToken) {
      cookieSetter('email', newEmail);
      setEmail(newEmail);

      cookieSetter('token', newToken);
      setToken(newToken);

      cookieSetter('showAccountingFeatures', showAccountingFeatures);
      setShowAccountingFeatures(showAccountingFeatures);

      // If there's an accountingClientsId, set it; otherwise remove the cookie
      if (newAccountingClientsId) {
        cookieSetter('accountingClientsId', newAccountingClientsId);
        setAccountingClientsId(newAccountingClientsId);
      } else {
        cookies.remove('accountingClientsId', { path: '/' });
        setAccountingClientsId(null);
      }

      // If user is an admin, set admin stuff
      setAdminStuff(newEmail, newToken);

      // Check whether the new email is already in the stored accounts
      let accountExists = accounts.some(
        (account) => account.email === newEmail
      );
      // If not in stored accounts, add it
      if (!accountExists) {
        let newAccounts = [
          ...accounts,
          {
            email: newEmail,
            token: newToken,
            showAccountingFeatures,
            accountingClientsId: newAccountingClientsId,
          },
        ];
        setAccounts(newAccounts);
        cookieSetter('accounts', JSON.stringify(newAccounts));
      }
    }
    cb();
  };

  // Signout removes all cookies and resets state
  const signout = () => {
    cookies.remove('adminEmail', { path: '/' });
    cookies.remove('adminToken', { path: '/' });
    cookies.remove('email', { path: '/' });
    cookies.remove('token', { path: '/' });
    cookies.remove('showAccountingFeatures', { path: '/' });
    cookies.remove('accountingClientsId', { path: '/' });
    cookies.remove('accounts', { path: '/' });
    setAdminEmail(null);
    setAdminToken(null);
    setEmail(null);
    setToken(null);
    setShowAccountingFeatures(false);
    setAccountingClientsId(null);
    setAccounts([]);
  };

  // Switch to another stored account
  const switchAccount = (emailToSwitch) => {
    const accountToSwitch = accounts.find(
      (account) => account.email === emailToSwitch
    );
    if (accountToSwitch) {
      setEmail(accountToSwitch.email);
      setToken(accountToSwitch.token);
      setShowAccountingFeatures(
        Boolean(accountToSwitch.showAccountingFeatures)
      );

      // If there's an accountingClientsId on the account, set it; otherwise remove
      if (accountToSwitch.accountingClientsId) {
        setAccountingClientsId(accountToSwitch.accountingClientsId);
      } else {
        cookies.remove('accountingClientsId', { path: '/' });
        setAccountingClientsId(null);
      }

      // Remove old showAccountingFeatures cookie before setting new
      cookies.remove('showAccountingFeatures', { path: '/' });

      // Re-set cookies for the new account
      cookieSetter('email', accountToSwitch.email);
      cookieSetter('token', accountToSwitch.token);
      cookieSetter(
        'showAccountingFeatures',
        accountToSwitch.showAccountingFeatures
      );
      if (accountToSwitch.accountingClientsId) {
        cookieSetter(
          'accountingClientsId',
          accountToSwitch.accountingClientsId
        );
      }

      // If user is an admin, set admin stuff
      setAdminStuff(accountToSwitch.email, accountToSwitch.token);
    }
    window.location.reload();
  };

  // Moves adminEmail to/from a "temp" cookie, toggled by `checked`
  const moveAdminEmail = (checked) => {
    if (checked) {
      const adminEmailValue = cookies.get('tempAdminEmail');
      cookieSetter('adminEmail', adminEmailValue);
      setAdminEmail(adminEmailValue);
      cookies.remove('tempAdminEmail', { path: '/' });
    } else {
      cookieSetter('tempAdminEmail', adminEmail);
      cookies.remove('adminEmail', { path: '/' });
      setAdminEmail(false);
    }
  };

  return {
    email,
    token,
    accounts,
    adminEmail,
    adminToken,
    accountingClientsId,
    showAccountingFeatures,
    signin,
    signout,
    switchAccount,
    moveAdminEmail,
  };
}

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export { authContext, ProvideAuth };
