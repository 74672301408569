/********************************
 * AccountantView.js
 ********************************/
import React, { useContext } from 'react';
import { Layout, Card } from 'antd';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
import { BookkeepingProgressDashboard } from './BookkeepingProgressDashboard.js';

const { Content } = Layout;

function AccountantView(props) {
  const auth = useContext(authContext);

  return (
    <Content
      style={{
        padding: '24px',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      <BookkeepingProgressDashboard API_domain={props.API_domain} />
    </Content>
  );
}

export { AccountantView };
