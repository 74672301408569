import React, { useState, useEffect } from 'react';
import { Table, Tag, Space, InputNumber, Typography, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import HeroAxios from '../../helpers/HeroAxios';

const { Text } = Typography;

const ObligationsRunsAdminViewer = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [runsPerSource, setRunsPerSource] = useState(2);

    const fetchData = async () => {
        setLoading(true);
        try {
            const params = new URLSearchParams();
            params.append('runs_per_source', runsPerSource);

            const response = await HeroAxios.get(`obligations/get-runs?${params.toString()}`);
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching all obligation runs:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [runsPerSource]);

    const getStatusTag = (status) => {
        const statusColors = {
            completed: 'success',
            processing: 'processing',
            failed: 'error',
            pending: 'warning'
        };
        const statusText = {
            completed: 'Completado',
            processing: 'Procesando',
            failed: 'Error',
            pending: 'Pendiente'
        };
        return <Tag color={statusColors[status] || 'default'}>{statusText[status] || status}</Tag>;
    };

    const getCredentialTag = (credential) => {
        const statusColors = {
            success: 'success',
            failed: 'error',
            pending: 'warning'
        };
        const statusText = {
            success: 'OK',
            failed: 'Error',
            pending: 'Pendiente'
        };
        return <Tag color={statusColors[credential] || 'default'}>{statusText[credential] || credential}</Tag>;
    };

    const columns = [
        {
            title: 'Cliente',
            dataIndex: 'client_name',
            key: 'client_name',
            sorter: (a, b) => a.client_name.localeCompare(b.client_name),
            filters: [...new Set(data.map(item => item.client_name))].map(name => ({
                text: name,
                value: name,
            })),
            onFilter: (value, record) => record.client_name === value,
        },
        {
            title: 'Fecha',
            dataIndex: 'run_date',
            key: 'run_date',
            render: (text) => new Date(text).toLocaleString(),
            sorter: (a, b) => new Date(a.run_date) - new Date(b.run_date),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Fuente',
            dataIndex: 'source',
            key: 'source',
            filters: [...new Set(data.map(item => item.source))].map(source => ({
                text: source,
                value: source,
            })),
            onFilter: (value, record) => record.source === value,
        },
        {
            title: 'Estado',
            key: 'status',
            render: (_, record) => {
                const status = record.additional_info?.run_status;
                return getStatusTag(status);
            },
            filters: [
                { text: 'Completado', value: 'completed' },
                { text: 'Procesando', value: 'processing' },
                { text: 'Error', value: 'failed' },
                { text: 'Pendiente', value: 'pending' }
            ],
            onFilter: (value, record) => record.additional_info?.run_status === value,
        },
        {
            title: 'Credenciales',
            key: 'credentials',
            render: (_, record) => {
                if (record.source === 'CSS') {
                    const credentials = record.additional_info?.credentials_status || {};
                    return (
                        <Space direction="vertical" size="small">
                            <Text>
                                Elaborador: {getCredentialTag(credentials.elaborador || 'pending')}
                            </Text>
                            <Text>
                                Replegal: {getCredentialTag(credentials.replegal || 'pending')}
                                {credentials.replegal === 'failed' && record.additional_info?.alerts?.length > 0 && (
                                    <Tooltip title={record.additional_info.alerts.join('\n')}>
                                        <InfoCircleOutlined style={{ marginLeft: 8, color: '#ff4d4f' }} />
                                    </Tooltip>
                                )}
                            </Text>
                        </Space>
                    );
                }
                return getCredentialTag(record.additional_info?.credential_status || 'pending');
            },
            filters: [
                { text: 'OK', value: 'success' },
                { text: 'Error', value: 'failed' },
                { text: 'Pendiente', value: 'pending' }
            ],
            onFilter: (value, record) => {
                if (record.source === 'CSS') {
                    const credentials = record.additional_info?.credentials_status || {};
                    return credentials.elaborador === value || credentials.replegal === value;
                }
                return record.additional_info?.credential_status === value;
            },
        },
        {
            title: 'Registros',
            key: 'records',
            render: (_, record) => {
                const summary = record.additional_info?.summary || {};
                return (
                    <Space direction="vertical" size="small">
                        <Text>Procesados: {record.records_processed}</Text>
                        {summary.total_active_obligations !== undefined && (
                            <Text>Total activos: {summary.total_active_obligations}</Text>
                        )}
                    </Space>
                );
            },
        },
        {
            title: 'Alertas',
            key: 'alerts',
            width: '30%',
            render: (_, record) => {
                const alerts = record.additional_info?.alerts || [];
                const error = record.additional_info?.error;
                const allMessages = [...alerts];
                if (error) allMessages.push(error);

                if (allMessages.length === 0) return null;

                return (
                    <Text style={{
                        display: 'block',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        color: '#000000',
                        fontSize: '12px',
                        lineHeight: '1.4'
                    }}>
                        {allMessages.join('\n')}
                    </Text>
                );
            },
            filters: [
                { text: 'Con alertas', value: 'has_alerts' },
                { text: 'Sin alertas', value: 'no_alerts' }
            ],
            onFilter: (value, record) => {
                const hasAlerts = (record.additional_info?.alerts?.length > 0 || record.additional_info?.error);
                return value === 'has_alerts' ? hasAlerts : !hasAlerts;
            },
        },
    ];

    return (
        <div>
            <div style={{ marginBottom: '16px' }}>
                <Space>
                    <Text>Últimas ejecuciones por fuente y cliente:</Text>
                    <InputNumber
                        min={1}
                        max={20}
                        value={runsPerSource}
                        onChange={setRunsPerSource}
                    />
                </Space>
            </div>

            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                rowKey="id"
                pagination={false}
                size="small"
            />
        </div>
    );
};

export default ObligationsRunsAdminViewer; 