import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import { Table, Affix, Switch, Button, message } from 'antd';
import {
  DownloadOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  closestCenter,
} from '@dnd-kit/core';
import {
  useSortable,
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import * as XLSX from 'xlsx';
import axios from 'axios';

import { FormattedUSD } from '../FormattedUSD.js';
import { AccountingFinancialStatementsModal } from './AccountingFinancialStatementsModal.js';
import { authContext } from '../../ProvideAuth.js';
import { DefaultVisibleTooltip } from '../DefaultVisibleTooltip.js';
import { formatDateSpanishMed } from '../../utils';
import HeroAxios from '../../helpers/HeroAxios';

export function IncomeStatementTable2(props) {
  const auth = useContext(authContext);

  // For expansions
  const [expandedKeys, setExpandedKeys] = useState([]);
  // For detail modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});

  // For toggling drag
  const [dragEnabled, setDragEnabled] = useState(false);

  // The local array of nodes, each with children
  const [tableData, setTableData] = useState([]);

  // Keep track of which node is currently being dragged
  const [draggingNode, setDraggingNode] = useState(null);

  // Keep track if the *current potential drop* is invalid
  // so we can color the dragged row red.
  const [invalidDrop, setInvalidDrop] = useState(false);

  // Pagination
  const itemsPerPage = 5;
  const sortedTimePeriods = useMemo(
    () => [...(props.incomeStatementRecentTimePeriods || [])],
    [props.incomeStatementRecentTimePeriods]
  );
  const totalMonths = sortedTimePeriods.length;
  const maxStartIndex = Math.max(totalMonths - itemsPerPage, 0);
  const [startIndex, setStartIndex] = useState(
    totalMonths > itemsPerPage ? totalMonths - itemsPerPage : 0
  );

  useEffect(() => {
    setStartIndex(totalMonths > itemsPerPage ? totalMonths - itemsPerPage : 0);
  }, [totalMonths, itemsPerPage]);

  const displayedTimePeriods = useMemo(() => {
    return sortedTimePeriods.slice(startIndex, startIndex + itemsPerPage);
  }, [startIndex, sortedTimePeriods, itemsPerPage]);

  // On mount, clone data
  useEffect(() => {
    if (!props.incomeStatementData || !Array.isArray(props.incomeStatementData)) {
      setTableData([]);
      return;
    }
    setTableData(JSON.parse(JSON.stringify(props.incomeStatementData)));
  }, [props.incomeStatementData]);

  // Build nested data
  const createNestedData = useCallback(
    (nodes, parentKey = '') => {
      return nodes.map((node) => {
        // 1) Decide on a stable key
        let stableKey;
        if (node.account_id) {
          // Use the backend ID for child nodes
          stableKey = String(node.account_id);
        } else {
          // e.g. top-level or summary nodes
          // Use something stable like node.subcategory or node.category
          // or a hard-coded string if you know exactly what it is
          // e.g. "INGRESOS", "GASTOS", "UTILIDAD_NETA", etc.
          stableKey = node.subcategory;
        }
  
        // 2) Slice values (same as before)
        const slicedValues = Array.isArray(node.values)
          ? node.values.slice(startIndex, startIndex + itemsPerPage)
          : [];
  
        // 3) Build the row object
        const fieldMap = {};
        slicedValues.forEach((val, i) => {
          fieldMap[`value${i + 1}`] = val;
        });
  
        const newNode = {
          ...node,
          key: stableKey,
          parentKey,
          values: slicedValues,
          ...fieldMap,
        };
        console.log('Final newNode =', newNode);
  
        // 4) Recurse
        if (node.children?.length) {
          newNode.children = createNestedData(node.children, stableKey);
        } else {
          delete newNode.children;
        }
  
        return newNode;
      });
    },
    [startIndex, itemsPerPage]
  );

  // This is what we pass to <Table dataSource={...} />
  const dataSource = useMemo(() => {
    if (!tableData.length) return [];
    return createNestedData(tableData);
  }, [tableData, createNestedData]);

  // BFS to find a node by key
  const findNodeInDataSource = useCallback((arr, key) => {
    for (const item of arr) {
      if (item.key === key) return item;
      if (item.children?.length) {
        const found = findNodeInDataSource(item.children, key);
        if (found) return found;
      }
    }
    return null;
  }, []);

  // Flatten the *currently visible* rows
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  useEffect(() => setExpandedRowKeys(expandedKeys), [expandedKeys]);

  const flattenData = useCallback(
    (arr) => {
      const result = [];
      function dfs(list) {
        list.forEach((item) => {
          result.push(item.key);
          if (item.children && item.children.length && expandedRowKeys.includes(item.key)) {
            dfs(item.children);
          }
        });
      }
      dfs(arr);
      return result;
    },
    [expandedRowKeys]
  );

  // The array of *visible* row keys
  const visibleRowKeys = useMemo(() => flattenData(dataSource), [dataSource, flattenData]);

  // Expand/collapse
  const handleExpand = (expanded, record) => {
    if (!record.key) return;
    if (expanded) {
      setExpandedKeys((prev) => [...prev, record.key]);
    } else {
      setExpandedKeys((prev) => prev.filter((k) => k !== record.key));
    }
  };
  const handleCollapseAll = () => setExpandedKeys([]);
  const handleExpandAll = useCallback(() => {
    const gatherKeys = (arr) => {
      let results = [];
      arr.forEach((n) => {
        results.push(n.key);
        if (n.children?.length) results = results.concat(gatherKeys(n.children));
      });
      return results;
    };
    setExpandedKeys(gatherKeys(dataSource));
  }, [dataSource]);

  // Modal
  const showModal = (record, month) => {
    setModalContent({ ...record, month });
    setIsModalVisible(true);
  };

  // Columns
  const createColumns = useCallback(() => {
    const baseColumns = [
      {
        title: 'Cuenta',
        dataIndex: 'subcategory',
        key: 'subcategory',
        width: 400,
        render: (text, record) => {
          const isExpanded = expandedKeys.includes(record.key);
          if (record.children?.length && isExpanded) {
            return <strong>{text}</strong>;
          }
          return text;
        },
      },
    ];

    const timePeriodsWithMostRecentTransaction =
      props.timePeriodsWithMostRecentTransaction || {};

    const dynamicValueColumns = displayedTimePeriods.map((period, idx) => {
      const dataIndex = `value${idx + 1}`;
      const mostRecentTransactionTime = timePeriodsWithMostRecentTransaction[period] || null;
      const formattedTransactionTime = mostRecentTransactionTime
        ? formatDateSpanishMed(mostRecentTransactionTime)
        : 'No hay datos';
      return {
        title: (
          <DefaultVisibleTooltip
            title={<>Actualizado el {formattedTransactionTime}</>}
            defaultVisible={period === sortedTimePeriods[sortedTimePeriods.length - 1]}
          >
            <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
              {period || `Value ${idx + 1}`}
            </div>
          </DefaultVisibleTooltip>
        ),
        dataIndex,
        key: dataIndex,
        align: 'right',
        render: (value, record) => {
          if (value == null) return '';
          const isExpandableCategory =
            record.category === 'Income' ||
            record.category === 'Expenses' ||
            record.category === 'Net Income' ||
            record.subcategory === 'UTILIDAD NETA';
          if (!isExpandableCategory) {
            return (
              <Button
                type="link"
                style={{ paddingRight: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  showModal(record, period);
                }}
              >
                <FormattedUSD total={value} />
              </Button>
            );
          }
          const isExpanded = expandedKeys.includes(record.key);
          const fontWeight = isExpanded ? 'bold' : 'normal';
          return (
            <span style={{ display: 'block', textAlign: 'right', fontWeight }}>
              <FormattedUSD total={value} />
            </span>
          );
        },
      };
    });

    return [...baseColumns, ...dynamicValueColumns];
  }, [
    expandedKeys,
    displayedTimePeriods,
    props.timePeriodsWithMostRecentTransaction,
    sortedTimePeriods,
  ]);

  const columns = useMemo(() => createColumns(), [createColumns]);

  // DND SENSORS
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 },
    })
  );

  // onDragStart
  const handleDragStart = ({ active }) => {
    if (!active) return;
    const node = findNodeInDataSource(dataSource, active.id);
    if (node) {
      setDraggingNode(node);
    }
    // On a new drag, reset invalidDrop
    setInvalidDrop(false);
  };

  // onDragOver: check if drop is invalid
  const handleDragOver = ({ active, over }) => {
    if (!active || !over) {
      setInvalidDrop(false);
      return;
    }
    const dragKey = active.id;
    const hoverKey = over.id;
    const dragNode = findNodeInDataSource(dataSource, dragKey);
    const hoverNode = findNodeInDataSource(dataSource, hoverKey);

    if (!dragNode || !hoverNode) {
      setInvalidDrop(false);
      return;
    }
    // If they have different parent keys => invalid
    if (dragNode.parentKey !== hoverNode.parentKey) {
      setInvalidDrop(true);
    } else {
      setInvalidDrop(false);
    }
  };

  // onDragEnd
  const handleDragEnd = ({ active, over }) => {
    setDraggingNode(null);
    setInvalidDrop(false);

    if (!over || active.id === over.id) {
      console.log('[onDragEnd] No valid drop or same item');
      return;
    }

    const dragKey = active.id;
    const hoverKey = over.id;

    console.log(`[onDragEnd] dragKey=${dragKey}, hoverKey=${hoverKey}`);

    const dragNode = findNodeInDataSource(dataSource, dragKey);
    const hoverNode = findNodeInDataSource(dataSource, hoverKey);

    console.log('[onDragEnd] dragNode:', dragNode, 'hoverNode:', hoverNode);

    if (!dragNode || !hoverNode) {
      console.warn('[onDragEnd] Could not find dragNode or hoverNode in dataSource');
      return;
    }
    if (dragNode.parentKey !== hoverNode.parentKey) {
      message.error('Solo puedes reordenar dentro de la misma cuenta madre.');
      return;
    }

    const parentKey = dragNode.parentKey;
    const newData = JSON.parse(JSON.stringify(dataSource));

    function findParentNode(arr, pk) {
      console.log('findParentNode', arr, pk);
      if (!pk) {
        return { children: arr }; // top-level
      }
      for (const node of arr) {
        if (node.key === pk) return node;
        // if ((node.key == '2' && pk == 'GASTOS') || (node.key == '1' && pk == 'INGRESOS')) return node;
        if (node.children?.length) {
          const found = findParentNode(node.children, pk);
          if (found) return found;
        }
      }
      return null;
    }

    const parentNode = findParentNode(newData, parentKey);
    console.log('[onDragEnd] parentNode:', parentNode);

    if (!parentNode) {
      console.warn('[onDragEnd] Could not find parent node for key=', parentKey);
      return;
    }

    const siblings = parentNode.children || [];
    console.log('[onDragEnd] siblings BEFORE reorder:', siblings.map((x, i) => `${i}:${x.subcategory} (key=${x.key})`));

    const dragIndex = siblings.findIndex((s) => s.key === dragKey);
    const hoverIndex = siblings.findIndex((s) => s.key === hoverKey);

    console.log('[onDragEnd] dragIndex=', dragIndex, ' hoverIndex=', hoverIndex);

    if (dragIndex < 0 || hoverIndex < 0) {
      console.warn('[onDragEnd] invalid dragIndex or hoverIndex');
      return;
    }

    const updatedSiblings = arrayMove(siblings, dragIndex, hoverIndex);

    console.log('[onDragEnd] siblings AFTER reorder:', updatedSiblings.map((x, i) => `${i}:${x.subcategory} (key=${x.key})`));

    parentNode.children = updatedSiblings;

    setTableData(newData);

    // Save to backend
    HeroAxios({
      method: 'post',
      url: `${props.API_domain}/saveNewOrder`,
      data: { data: newData, client_id: props.accountingClientCompany},
    })
      .then(() => {
      message.success('Nuevo orden guardado');
      console.log('[onDragEnd] reorder successful, newData ->', newData);
      })
      .catch((err) => {
      console.error('[onDragEnd] error saving:', err);
      console.log('[onDragEnd] newData ->', newData);
      message.error('Error guardando el nuevo orden');
      });
  };

  /**
   * SortableRow: The custom row that uses dnd-kit’s useSortable.
   * We'll color the dragged row red if "invalidDrop" is true.
   * This ensures that the row is always re-rendered whenever "invalidDrop" changes.
   */
  const SortableRow = (props) => {
    const record = props.record || {};

    // Disable drag for specific rows
    const disableDrag = ['INGRESOS', 'GASTOS', 'UTILIDAD NETA'].includes(record.subcategory);

    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props['data-row-key'],
      disabled: !dragEnabled || disableDrag, // Disable drag based on custom logic
    });

    const style = {
      ...props.style,
      transition,
      transform: CSS.Translate.toString(transform),
      cursor: dragEnabled && !disableDrag ? 'move' : 'default',
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999,
            opacity: 0.9,
            // If this row is being dragged, color it red if invalidDrop is true
            ...(invalidDrop && {
              backgroundColor: 'rgba(255,0,0,0.1)',
            }),
          }
        : {}),
    };

    return (
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {props.children}
      </tr>
    );
  };

  // example export to Excel
  const exportToExcel = useCallback(() => {
    // 1) Flatten the "tableData" hierarchy
    function traverseAllNodes(nodes, level = 0, output = []) {
      nodes.forEach((node) => {
        // Indent the subcategory name by 4 spaces per nesting level
        const row = {
          Subcategory: ' '.repeat(level * 4) + (node.subcategory || ''),
        };

        // For each full time period, pick the corresponding value
        // (node.values should have a value for each index if the data is consistent)
        sortedTimePeriods.forEach((period, idx) => {
          const val = Array.isArray(node.values) ? node.values[idx] : null;
          row[period] = val ?? 0; // default 0 if undefined
        });

        output.push(row);

        // Recurse if children exist
        if (Array.isArray(node.children) && node.children.length > 0) {
          traverseAllNodes(node.children, level + 1, output);
        }
      });
      return output;
    }

    const flattenedRows = traverseAllNodes(tableData);

    // 2) Convert that flat array to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(flattenedRows);

    // 3) Create a new workbook and append the sheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Income Statement');

    // 4) Trigger download to the user
    XLSX.writeFile(workbook, 'income_statement.xlsx');

  }, [dataSource, displayedTimePeriods, props.API_domain, auth]);

  // Render
  return (
    <div style={{ textAlign: 'left', width: '100%' }}>
      <Affix offsetTop={0}>
        <div
          style={{
            marginBottom: '10px',
            backgroundColor: 'white',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Button style={{ margin: 4 }} onClick={handleCollapseAll}>
              <FullscreenExitOutlined />
              Colapsar
            </Button>
            <Button style={{ margin: 4 }} onClick={handleExpandAll}>
              <FullscreenOutlined />
              Expandir
            </Button>
            <div style={{ marginLeft: 8, display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 4 }}>Mover Cuentas:</span>
              <Switch checked={dragEnabled} onChange={setDragEnabled} />
            </div>
          </div>
          <div>
            <Button style={{ marginLeft: 12, textAlign: 'right' }} onClick={exportToExcel}>
              <DownloadOutlined /> Excel
            </Button>
          </div>
        </div>
      </Affix>

      {/* Pagination Controls */}
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Button
          icon={<LeftOutlined />}
          onClick={() => setStartIndex((prev) => Math.max(prev - 1, 0))}
          disabled={startIndex === 0}
          style={{ marginRight: '8px' }}
        >
          Más viejo
        </Button>
        <Button
          icon={<RightOutlined />}
          onClick={() => setStartIndex((prev) => Math.min(prev + 1, maxStartIndex))}
          disabled={startIndex >= maxStartIndex}
          style={{ marginLeft: '8px' }}
        >
          Más reciente
        </Button>
      </div>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={visibleRowKeys} strategy={verticalListSortingStrategy}>
          <Table
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            // We pass the "record" to the row so that SortableRow can see subcategory/parentKey
            onRow={(record) => ({
              record,
            })}
            components={{
              body: {
                row: SortableRow,
              },
            }}
            expandable={{
              expandedRowKeys: expandedKeys,
              onExpand: handleExpand,
              expandRowByClick: true,
              rowExpandable: (record) => !!record.children?.length,
            }}
            style={{ width: '1200px', minWidth: '800px' }}
          />
        </SortableContext>
      </DndContext>

      <AccountingFinancialStatementsModal
        API_domain={props.API_domain}
        accountingClientCompany={props.accountingClientCompany}
        modalContent={modalContent}
        isModalVisible={isModalVisible}
        title="Additional Information"
        onOk={() => setIsModalVisible(false)}
        onCancel={() => {
          setIsModalVisible(false);
          setModalContent({});
        }}
      />
    </div>
  );
}
