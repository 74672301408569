import React, { useState, useEffect } from 'react';
import { InboxOutlined, BankOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, Table, Upload, message, Collapse, Descriptions } from 'antd';
import HeroAxios from '../helpers/HeroAxios.js';

const { Dragger } = Upload;

const LOADING_MESSAGES = [
  "Inicializando analizador financiero cuántico...",
  "Escaneando estructura del documento...",
  "Extrayendo patrones de transacciones...",
  "Calculando anomalías numéricas...",
  "Balanceando libros digitales...",
  "Verificando consistencia temporal...",
  "Aplicando algoritmos de aprendizaje automático...",
  "Ejecutando análisis OCR avanzado...",
  "Normalizando formatos de moneda...",
  "Validando secuencias de transacciones...",
  "Detectando patrones de comerciantes...",
  "Optimizando estructuras de datos...",
  "Aplicando verificación blockchain...",
  "Calculando sumas de verificación...",
  "Sincronizando matrices financieras...",
  "Ejecutando análisis de redes neuronales...",
  "Calibrando algoritmos financieros...",
  "Procesando vectores de transacciones...",
  "Analizando patrones de gastos...",
  "Ejecutando verificaciones finales...",
  "Preparando salida estructurada...",
  "¡Casi listo! Solo contando centavos...",
  "Verificando integridad de datos...",
  "Aplicando reglas contables...",
  "Consolidando información bancaria...",
  "Generando resumen de transacciones..."
];

const SIMPLIFIED_COLUMNS = [
  {
    title: 'Fecha',
    dataIndex: 'transaction_date',
    width: 100,
    sorter: (a, b) => new Date(a.transaction_date) - new Date(b.transaction_date)
  },
  {
    title: 'Fecha Proceso',
    dataIndex: 'processed_date',
    width: 100,
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    width: 300
  },
  {
    title: 'Referencia',
    dataIndex: 'reference',
    width: 120,
  },
  {
    title: 'Crédito',
    dataIndex: 'credit',
    width: 120,
    render: (val) => val > 0 ? <span style={{ color: '#3f8600' }}>${val.toFixed(2)}</span> : null
  },
  {
    title: 'Débito',
    dataIndex: 'debit',
    width: 120,
    render: (val) => val > 0 ? <span style={{ color: '#cf1322' }}>${val.toFixed(2)}</span> : null
  },
  {
    title: 'Saldo',
    dataIndex: 'running_balance',
    width: 120,
    render: (val) => val ? `$${val.toFixed(2)}` : '-'
  }
];

// Add status constants
const STATUS = {
  CREATED: 'created',
  PROCESSING: 'processing',
  PARSED: 'parsed',
  ERROR: 'error'
};

const POLL_INTERVAL = 2000; // Poll every 2 seconds

function SOTUploaderV2() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [pastRuns, setPastRuns] = useState([]);
  const [selectedRun, setSelectedRun] = useState(null);
  const [currentRunId, setCurrentRunId] = useState(null);
  const [pollInterval, setPollInterval] = useState(null);
  const [isRunModalOpen, setIsRunModalOpen] = useState(false);
  const [runDetails, setRunDetails] = useState(null);
  const [showRawResponse, setShowRawResponse] = useState(false);

  // Loading message rotation effect
  useEffect(() => {
    let currentIndex = 0;
    let interval;

    if (isProcessing) {
      setLoadingMessage(LOADING_MESSAGES[0]);

      interval = setInterval(() => {
        currentIndex = (currentIndex + 1) % LOADING_MESSAGES.length;
        setLoadingMessage(LOADING_MESSAGES[currentIndex]);
      }, 2500);
    }

    return () => clearInterval(interval);
  }, [isProcessing]);

  const fetchPastRuns = async () => {
    try {
      const resp = await HeroAxios.get('past-parsing-runs', {
        params: {
          accounting_client_id: '12345'
        }
      });
      if (resp.data.success) {
        setPastRuns(resp.data.data);
      }
    } catch (err) {
      console.error('Error fetching past runs:', err);
      message.error('Failed to load past runs');
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchPastRuns();
    }
  }, [isModalOpen]);

  const processRunForUI = (parsingResult) => ({
    success: true,
    timestamp: parsingResult.timestamp,
    document_info: {
      statement_period: {
        start_date: parsingResult.start_date,
        end_date: parsingResult.end_date
      },
      balances: {
        opening_balance: parsingResult.opening_balance,
        closing_balance: parsingResult.closing_balance
      }
    },
    summary: {
      total_debits: parsingResult.transactions?.reduce((a, t) => a + t.debit, 0),
      total_credits: parsingResult.transactions?.reduce((a, t) => a + t.credit, 0),
      transaction_count: parsingResult.transactions?.length || 0
    },
    data: parsingResult.transactions || []
  });

  // Function to check run status
  const checkRunStatus = async (runId) => {
    try {
      console.log('Checking status for run:', runId);
      const response = await HeroAxios.get(`parsing-run-status/${runId}`);
      const run = response.data.data;

      if (run.status === STATUS.ERROR) {
        clearInterval(pollInterval);
        setPollInterval(null);
        setIsProcessing(false);
        message.error(run.error_message || 'Processing failed');
        fetchPastRuns();
        return;
      }

      if (run.status === STATUS.PARSED) {
        console.log('Run completed. Refreshing list now...');
        clearInterval(pollInterval);
        setPollInterval(null);
        setIsProcessing(false);
        fetchPastRuns();
      }

      // If still processing, just fetch the runs so the table gets updated status:
      else {
        fetchPastRuns();
      }
    } catch (error) {
      console.error('Error checking run status:', error);
      message.error('Error checking processing status');
      clearInterval(pollInterval);
      setPollInterval(null);
      setIsProcessing(false);
    }
  };

  // Cleanup polling on unmount or modal close
  useEffect(() => {
    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
        setPollInterval(null);
      }
    };
  }, [pollInterval]);

  const handleUpload = async (file) => {
    setIsProcessing(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('accounting_client_id', '12345');

    try {
      const response = await HeroAxios.post('parse-document', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (!response.data.success) {
        message.error(response.data.error || 'Processing failed');
        setIsProcessing(false);
        return false;
      }

      // Start polling if we got a run ID
      if (response.data.run_id) {
        setCurrentRunId(response.data.run_id);
        if (pollInterval) {
          clearInterval(pollInterval);
        }
        const interval = setInterval(() => checkRunStatus(response.data.run_id), POLL_INTERVAL);
        setPollInterval(interval);
      }

      // Wait 5 seconds before allowing another upload
      setTimeout(() => {
        setIsProcessing(false);
      }, 5000);

    } catch (error) {
      console.error('Upload error:', error);
      message.error(error.response?.data?.error || 'Upload failed');
      setIsProcessing(false);
    }

    // Prevent default upload behavior
    return false;
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setIsProcessing(false);
    if (pollInterval) {
      clearInterval(pollInterval);
      setPollInterval(null);
    }
  };

  const openRunDetails = (run) => {
    setSelectedRun(run);
    setShowRawResponse(false);
    if (run.parsing_result) {
      const processed = processRunForUI(run.parsing_result);
      setRunDetails(processed);
    } else {
      setRunDetails(null);
    }
    setIsRunModalOpen(true);
  };

  const closeRunDetails = () => {
    setIsRunModalOpen(false);
    setRunDetails(null);
    setSelectedRun(null);
  };

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)} icon={<BankOutlined />}>
        Upload Transactions
      </Button>

      <Modal
        title="Transaction Upload"
        open={isModalOpen}
        onCancel={handleClose}
        footer={null}
        width={1000}
        centered
      >
        {/* Upload area & Past Runs table only. No results displayed here. */}
        <div style={{ padding: '20px 0' }}>
          {!isProcessing && (
            <Dragger
              accept=".xls,.xlsx,.csv,.pdf"
              multiple={false}
              beforeUpload={handleUpload}
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ fontSize: 48, color: '#40a9ff' }} />
              </p>
              <p style={{ fontSize: 16, fontWeight: 500, margin: '16px 0' }}>
                Click or drag file to upload
              </p>
              <p style={{ color: '#666' }}>
                Supports PDF, Excel (.xlsx, .xls), and CSV files
              </p>
            </Dragger>
          )}

          {isProcessing && (
            <div style={{ textAlign: 'center', marginTop: 24 }}>
              <Spin size="large" />
              <p style={{ marginTop: 16, color: '#666' }}>{loadingMessage}</p>
            </div>
          )}
        </div>

        <Collapse style={{ marginTop: 24 }} defaultActiveKey={[]}>
          <Collapse.Panel header="Past Runs" key="1">
            <Table
              dataSource={pastRuns}
              rowKey="id"
              size="small"
              pagination={{ pageSize: 6 }}
              expandable={{
                expandedRowRender: record => {
                  return (
                    <div style={{ padding: '6px 12px', fontSize: '13px' }}>
                      {record.error_message ? (
                        <div style={{ color: 'red', whiteSpace: 'pre-wrap' }}>
                          <strong>Error:</strong> {record.error_message}
                        </div>
                      ) : (
                        <div>
                          <strong>Transactions:</strong> {record.total_transactions || 0} <br />
                          <strong>Document URL:</strong> {record.document_url || 'N/A'}
                        </div>
                      )}
                    </div>
                  );
                },
              }}
              columns={[
                { title: 'Run ID', dataIndex: 'id', width: 70 },
                { title: 'File', dataIndex: 'original_filename' },
                {
                  title: 'Time',
                  dataIndex: 'time_taken',
                  width: 60,
                  render: val => <>{val ? `${val}s` : '0s'}</>
                },
                {
                  title: 'Status',
                  dataIndex: 'status',
                  width: 100,
                  render: val => <span>{val}</span>
                },
                {
                  title: 'Date',
                  dataIndex: 'run_date',
                  width: 160,
                  defaultSortOrder: 'descend',
                  sorter: (a, b) => new Date(a.run_date) - new Date(b.run_date),
                  render: val => <span>{new Date(val).toLocaleString()}</span>
                },
                {
                  title: 'Actions',
                  width: 100,
                  render: (_, record) => {
                    if (record.status === STATUS.PROCESSING) {
                      return <Spin size="small" tip="Processing..." />;
                    }
                    if (record.status === STATUS.PARSED) {
                      return (
                        <Button onClick={() => openRunDetails(record)}>
                          View
                        </Button>
                      );
                    }
                    return null;
                  }
                }
              ]}
            />
          </Collapse.Panel>
        </Collapse>
      </Modal>

      <Modal
        title={selectedRun ? `Parsing Run #${selectedRun.id}` : "Run Details"}
        open={isRunModalOpen}
        onCancel={closeRunDetails}
        footer={null}
        width={1000}
        centered
      >
        {selectedRun && runDetails ? (
          <div>
            {/* Info Section */}
            <Descriptions bordered column={2} size="small" style={{ marginBottom: 24 }}>
              <Descriptions.Item label="File">
                {selectedRun.original_filename || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Run Date">
                {new Date(selectedRun.run_date).toLocaleString()}
              </Descriptions.Item>
              <Descriptions.Item label="Statement Period">
                {runDetails.document_info.statement_period.start_date} – {runDetails.document_info.statement_period.end_date}
              </Descriptions.Item>
              <Descriptions.Item label="Time Taken">
                {selectedRun.time_taken ? `${selectedRun.time_taken}s` : '0s'}
              </Descriptions.Item>
              <Descriptions.Item label="Opening Balance">
                ${runDetails.document_info.balances.opening_balance.toFixed(2)}
              </Descriptions.Item>
              <Descriptions.Item label="Closing Balance">
                ${runDetails.document_info.balances.closing_balance.toFixed(2)}
              </Descriptions.Item>
              <Descriptions.Item label="Timestamp">
                {runDetails.timestamp}
              </Descriptions.Item>
              {selectedRun.error_message && (
                <Descriptions.Item label="Error" span={2} style={{ color: 'red' }}>
                  {selectedRun.error_message}
                </Descriptions.Item>
              )}
            </Descriptions>

            {/* Transactions Section */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
              <h4>Transactions ({runDetails.summary.transaction_count})</h4>
              <Button
                type="text"
                icon={showRawResponse ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                onClick={() => setShowRawResponse(!showRawResponse)}
              >
                {showRawResponse ? 'Hide Raw JSON' : 'Show Raw JSON'}
              </Button>
            </div>
            {showRawResponse ? (
              <div style={{ marginBottom: 24, backgroundColor: '#f5f5f5', padding: 16 }}>
                <pre style={{ maxHeight: '500px', overflow: 'auto' }}>
                  {JSON.stringify(runDetails, null, 2)}
                </pre>
              </div>
            ) : (
              <Table
                columns={SIMPLIFIED_COLUMNS}
                dataSource={runDetails.data}
                pagination={{ pageSize: 10 }}
                size="small"
                scroll={{ x: 'max-content' }}
                rowKey="key"
              />
            )}
          </div>
        ) : (
          <p>No parsing results found.</p>
        )}
      </Modal>
    </>
  );
}

export { SOTUploaderV2 };