import {
  Button,
  Form,
  Input,
  Modal,
  Spin,
  Select,
  DatePicker,
  Radio,
  Checkbox,
} from 'antd';
import axios from 'axios';
import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminCreateCopyOfAccountingClient(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);

  const getAccountingClientOptions = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: props.API_domain + 'getAccountingClients',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      const options = response.data.map((client) => (
        <Option key={client.id} value={client.id}>
          {client.name}
        </Option>
      ));
      setClientOptions(options);
    } catch (error) {
      console.error(
        'There was an error fetching the accounting clients',
        error
      );
    }
  };

  useEffect(() => {
    if (visible) {
      getAccountingClientOptions();
    }
  }, [visible]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      client_id: values.client_id,
      cutoff_date: values.cutoff_date
        ? values.cutoff_date.format('YYYY-MM-DD')
        : undefined,
      copy_bot_expenses: values.copy_bot_expenses || false,
      copy_fes: values.copy_fes || false,
      copy_sots: values.copy_sots || false,
      copy_relationships: values.copy_relationships || false,
      copy_commits: values.copy_commits || false,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'createCopyOfAccountingClient',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert('An Error occurred hehee');
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Crear Copia de Accounting Client</ButtonSpan>
      </Button>
      <Modal
        title='Crear Copia de Accounting Client'
        visible={visible}
        onOk={form.submit} // Changed to onOk to submit form on modal confirmation
        onCancel={handleCancel}
        centered
        confirmLoading={isSubmitting}
        width={800}
      >
        <Form
          {...layout}
          form={form}
          name='adminCreateAccountingObligationEnum'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name='client_id'
            label='Cliente a copiar'
            rules={[{ required: true, message: 'Please select the client' }]}
          >
            <Select
              showSearch
              placeholder='Select a client'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {clientOptions}
            </Select>
          </Form.Item>
          <Form.Item
            name='cutoff_date'
            label='Fecha'
            rules={[{ required: true, message: 'Please select the due date' }]}
          >
            <DatePicker
              format='YYYY-MM-DD'
              placeholder='Select date'
              style={{ width: '100%' }} // Ensures the date picker takes the full width of the form item
            />
          </Form.Item>
          <Form.Item
            name='copy_bot_expenses'
            valuePropName='checked'
            initialValue={true}
          >
            <Checkbox>Copiar Gastos de Bot</Checkbox>
          </Form.Item>
          <Form.Item
            name='copy_fes'
            valuePropName='checked'
            initialValue={true}
          >
            <Checkbox>Copiar Facturas Electrónicas</Checkbox>
          </Form.Item>
          <Form.Item
            name='copy_sots'
            valuePropName='checked'
            initialValue={true}
          >
            <Checkbox>Copiar Banco y Tarjetas</Checkbox>
          </Form.Item>
          <Form.Item
            name='copy_relationships'
            valuePropName='checked'
            initialValue={true}
          >
            <Checkbox>
              Copiar Relaciones (solo si los de arriba están seleccionados)
            </Checkbox>
          </Form.Item>
          <Form.Item
            name='copy_commits'
            valuePropName='checked'
            initialValue={true}
          >
            <Checkbox>
              Copiar Commits (solo si los de arriba están seleccionados)
            </Checkbox>
          </Form.Item>
          <div>👆Fecha desde la que se ignorará clasificación de gastos</div>
          <div style={{ marginBottom: 8, marginTop: 8 }}>
            <b>Esta acción demora! No la interrumpas.</b>
          </div>
          <div>
            <div>
              Se llevarán a cabo las siguientes acciones POR DEFAULT con data de
              los 12 meses más recientes:
            </div>
            <ol>
              <li>
                Se crearé un random user nuevo que será el admin del nuevo
                accouting client
              </li>
              <li>
                Se creará un nuevo Accounting Client (db: accounting_clients)
                con nombre "[accounting_clients name] (Copy)"
              </li>
              <li>
                Se creará una nueva company (db: companies) con nombre
                "[companies name] - testing"
              </li>
              <li>
                Se creará una nueva relación entre el nuevo accounting client y
                nuevo company (db: company_accounting_clients)
              </li>
              <li>
                Se crearán nuevos accounts (db: accounts) con exactamente los
                mismos nombres y tipos de cuenta
              </li>
              <li>
                Se creará una nueva relación entre el nuevo accounting client y
                nuevo company (db: company_accounting_clients)
              </li>
            </ol>
            <div>
              Adicionalmente se llevarán a cabo las acciones seleccionadas.
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}
const ButtonSpan = styled.span`
  font-weight: 500;
`;

export { AdminCreateCopyOfAccountingClient };
