import React, { useState } from 'react';
import { Form, Input, message, Button, Row, Col, Tag, Tooltip } from 'antd';
import {
  CopyOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
  SyncOutlined
} from '@ant-design/icons';
import HeroAxios from '../../helpers/HeroAxios.js';

const MunicipioIntegration = (props) => {
  // pull existing credential data
  const [usuario, setUsuario] = useState(
    props.municipioCredentials.login_info.usuario || ''
  );
  const [contrasena, setContrasena] = useState(
    props.municipioCredentials.login_info.contrasena || ''
  );

  // Local states
  const [loading, setLoading] = useState(false);
  const [runningIntegration, setRunningIntegration] = useState(false);
  const [pendingProcess, setPendingProcess] = useState(false);
  const [countdown, setCountdown] = useState(null);

  // Check if last_run < updated_at => show 'Pendiente'
  const isCredentialTimePending = () => {
    const lastRun = props?.lastRun ? new Date(props.lastRun) : null;
    const updatedAt = props?.municipioCredentials?.updated_at
      ? new Date(props.municipioCredentials.updated_at)
      : null;

    if (!lastRun || !updatedAt) return false;
    return lastRun < updatedAt;
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleString('es-ES', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  const handleRunIntegration = async () => {
    if (!props.accountingClientId) {
      message.error('ID de cliente no válido');
      return;
    }

    setRunningIntegration(true);

    try {
      await HeroAxios.post(
        `obligations/update-obligations-by-source/${props.accountingClientId}`,
        { source: 'mupa' }
      );
      message.success('Las obligaciones de Municipio están siendo procesadas.');
    } catch (error) {
      console.error('Error corriendo integración municipio:', error);
      message.error(
        'No se pudo iniciar la actualización de obligaciones de Municipio'
      );
    } finally {
      // Re-enable button after 30 seconds, keep status 'pendiente'
      setTimeout(() => {
        setRunningIntegration(false);
      }, 30000);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const updatedCredentials = {
        accounting_client_id: props.municipioCredentials.accounting_client_id,
        id: props.municipioCredentials.id,
        site: 'municipio',
        login_info: {
          usuario: usuario,
          contrasena: contrasena,
        },
      };

      await props.updateAccountingClientConfig(updatedCredentials);
      message.success('Credenciales de Municipio actualizadas correctamente');
      setPendingProcess(true);

      // After saving, run integration
      await handleRunIntegration();

      // Start countdown from 10
      setCountdown(10);
      const countdownInterval = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            // Wait 2 additional seconds before reloading
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

    } catch (error) {
      console.error('Error actualizando credenciales Municipio:', error);
      message.error('Error al actualizar credenciales de Municipio');
    } finally {
      setLoading(false);
    }
  };

  // Determine if we need to show "Pendiente"
  const credentialTimePending = isCredentialTimePending();
  const showPending = pendingProcess || credentialTimePending;

  let tagColor = 'default';
  let tagIcon = pendingProcess ? <SyncOutlined spin /> : null;
  let tagText = 'Estado de Credenciales pendiente...';
  let tooltipText = showPending
    ? 'La actualización puede tardar unos minutos. Puede regresar más tarde para ver el estado actualizado.'
    : props.credentialStatusDetail;

  if (!showPending) {
    if (props.credentialStatus === 'OK') {
      tagColor = 'success';
      tagIcon = <CheckCircleOutlined />;
      tagText = 'Credenciales OK';
    } else if (props.credentialStatus === 'Sin Datos') {
      tagColor = 'default';
      tagIcon = <InfoCircleOutlined />;
      tagText = 'Estado de Credenciales pendiente...';
      tooltipText = 'Por favor guarde las credenciales para iniciar la validación y actualización de las obligaciones';
    } else {
      tagColor = 'error';
      tagIcon = <WarningOutlined />;
      tagText = 'Error de Credenciales';
      tooltipText = props.credentialStatusDetail;
    }
  }

  return (
    <div style={{ marginBottom: 32, marginTop: 32 }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
        <h3 style={{ marginLeft: 4, textAlign: 'left', marginBottom: 0 }}>
          MUNICIPIO
        </h3>
        <div style={{ color: '#999999', fontSize: '12px', marginLeft: 16, alignSelf: 'flex-end' }}>
          última actualización: {formatDateTime(props.municipioCredentials.updated_at)}
        </div>
      </div>
      <div style={{ display: 'flex', marginLeft: 4 }}>
        <Tooltip title={tooltipText}>
          <Tag color={tagColor} icon={tagIcon} style={{ marginLeft: 4 }}>
            {tagText}
          </Tag>
        </Tooltip>
      </div>
      <Form.Item label="Usuario" style={{ marginLeft: 96, marginTop: 16 }}>
        <Row>
          <Col span={22}>
            <Input
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type="text"
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(usuario)}
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label="Contraseña" style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={contrasena}
              onChange={(e) => setContrasena(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type="text"
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(contrasena)}
            />
          </Col>
        </Row>
      </Form.Item>
      <div style={{ textAlign: 'left', marginLeft: 96 }}>
        <Button
          type="primary"
          onClick={!runningIntegration ? handleSave : null}
          loading={loading || runningIntegration}
          style={{ marginRight: 16 }}
        >
          Guardar y ejecutar
        </Button>
        {showPending && (
          <div
            style={{
              marginLeft: 8,
              color: '#999',
              textAlign: 'left',
              fontSize: '12px',
              marginTop: 8,
            }}
          >
            Hemos guardado las credenciales y estamos buscando las obligaciones.
            <br />
            {countdown !== null && (
              <div style={{ marginTop: 4 }}>
                Actualizaremos el estado de credenciales en {countdown} segundos...
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MunicipioIntegration;
