import React from 'react';
import { Row, Col } from 'antd';
import { SalesLineChart } from './SalesLineChart';
import { ProfitBarChart } from './ProfitBarChart';
import { AvailableCash } from './AvailableCash';
import './../styles.css';

function AccountingInfographicsContainer(props) {
  return (
    <Row gutter={16} style={{ paddingLeft: 16, paddingRight: 16 }}>

      {/* 
      // ~~~~~~~~~~~~~~~~~~~~~~
      // Commented out SalesLineChart:
      // ~~~~~~~~~~~~~~~~~~~~~~
      <Col xs={24} sm={24} md={8} xxl={8}>
        <SalesLineChart
          accountingClientId={
            props.accountingClientCompany.accounting_client_id
          }
        />
      </Col>
      */}

      <Col
        xs={24}
        sm={24}
        md={{ span: 8, offset: 4 }} 
        xxl={{ span: 8, offset: 4 }}
      >
        <ProfitBarChart
          incomeStatementData={props.incomeStatementData}
          incomeStatementRecentTimePeriods={
            props.incomeStatementRecentTimePeriods
          }
        />
      </Col>

      <Col
        xs={24}
        sm={24}
        md={{ span: 8, offset: 0 }}
        xxl={{ span: 8, offset: 0 }}
      >
        <AvailableCash
          balanceSheetData={props.balanceSheetData}
          balanceSheetRecentTimePeriods={props.balanceSheetRecentTimePeriods}
        />
      </Col>
    </Row>
  );
}

export { AccountingInfographicsContainer };
