import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Spin } from 'antd';
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './../styles.css';

ChartJS.register(
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  ChartDataLabels
);

function ProfitBarChart(props) {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // If no data yet, just keep showing spinner
    if (!props.incomeStatementData || props.incomeStatementData.length === 0) {
      setLoading(true);
      return;
    }

    setLoading(false);

    // Safe to use the data here
    const incomeCategory = props.incomeStatementData.find(
      (item) => item.category === 'Income'
    );
    const expensesCategory = props.incomeStatementData.find(
      (item) => item.category === 'Expenses'
    );
    const netIncomeCategory = props.incomeStatementData.find(
      (item) => item.category === 'Net Income'
    );

    const incomeData = incomeCategory ? incomeCategory.values : [];
    const expensesData = expensesCategory
      ? expensesCategory.values.map((val) => val * -1)
      : [];
    const netData = netIncomeCategory ? netIncomeCategory.values : [];

    // Example colors (use your own CSS variables or remove if not needed)
    const getCSSVariable = (variable) => {
      return getComputedStyle(document.documentElement)
        .getPropertyValue(variable)
        .trim();
    };
    const backgroundColorSales = getCSSVariable('--green-light');
    const backgroundColorExpenses = getCSSVariable('--red-light');
    const backgroundColorProfit = getCSSVariable('--purple-normal');

    setChartData({
      labels: props.incomeStatementRecentTimePeriods,
      datasets: [
        {
          label: 'Ingresos',
          data: incomeData,
          backgroundColor: backgroundColorSales,
          stack: 'stack1',
          borderRadius: 2,
          datalabels: { display: false },
        },
        {
          label: 'Gastos',
          data: expensesData,
          backgroundColor: backgroundColorExpenses,
          stack: 'stack1',
          borderRadius: 2,
          datalabels: { display: false },
        },
        {
          label: 'Ganancia Neta',
          data: netData,
          backgroundColor: backgroundColorProfit,
          stack: 'stack2',
          borderRadius: 2,
          datalabels: {
            display: false, // or set true if you want to show in chart
          },
        },
      ],
    });
  }, [
    props.incomeStatementData,
    props.incomeStatementRecentTimePeriods,
  ]);

  if (loading) {
    return (
      <div
        className="infographic-card"
        style={{ minHeight: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Spin tip="Loading chart..." />
      </div>
    );
  }
  console.log(loading)
  return (
    <div className="infographic-card">
      <div>
        <h2 style={{ color: 'var(--purple-darkest)' }}>Ganancias Mensuales</h2>
        <Bar
          data={chartData}
          options={{
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function (value) {
                    // Format y-axis labels as USD with commas
                    return `$${Number(value).toLocaleString()}`;
                  },
                },
              },
              x: { stacked: true },
            },
            plugins: {
              datalabels: { display: false },
            },
          }}
        />
      </div>
      {/* <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'rgba(128, 128, 128, 0.9)',
          fontSize: '18px',
          fontWeight: 'bold',
          pointerEvents: 'none',
        }}
      >
        Coming soon ⚡🙂
      </div> */}
    </div>
  );
}

export { ProfitBarChart };
