import React, { useState } from 'react';
import { Form, Input, message, Button, Row, Col, Tag, Tooltip } from 'antd';
import {
  CopyOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
  SyncOutlined
} from '@ant-design/icons';
import HeroAxios from '../../helpers/HeroAxios.js';

const CSSIntegration = (props) => {
  // local states for credential sub-fields
  const [elaboradorMap, setElaboradorMap] = useState(
    props.cssCredentials.login_info.elaborador_map || {}
  );
  const [representanteLegalMap, setRepresentanteLegalMap] = useState(
    props.cssCredentials.login_info.representante_legal_map || {}
  );

  // states for controlling UI
  const [loading, setLoading] = useState(false);
  const [runningIntegration, setRunningIntegration] = useState(false);
  const [pendingProcess, setPendingProcess] = useState(false);

  // Check if last_run < updated_at => show 'Pendiente'
  const isCredentialTimePending = () => {
    const lastRun = props?.lastRun ? new Date(props.lastRun) : null;
    const updatedAt = props?.cssCredentials?.updated_at
      ? new Date(props.cssCredentials.updated_at)
      : null;

    if (!lastRun || !updatedAt) return false;
    return lastRun < updatedAt;
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleString('es-ES', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  const handleRepresentanteLegalChange = (key, value) => {
    setRepresentanteLegalMap((prev) => ({ ...prev, [key]: value }));
  };

  const handleElaboradorChange = (key, value) => {
    setElaboradorMap((prev) => ({ ...prev, [key]: value }));
  };

  const handleRunIntegration = async () => {
    if (!props.accountingClientId) {
      message.error('ID de cliente no válido');
      return;
    }

    setRunningIntegration(true);

    try {
      await HeroAxios.post(
        `obligations/update-obligations-by-source/${props.accountingClientId}`,
        { source: 'css' }
      );
      message.success('Las obligaciones de CSS están siendo procesadas.');
    } catch (error) {
      console.error('Error iniciando integración para CSS:', error);
      message.error('No se pudo iniciar la actualización de obligaciones de CSS');
    } finally {
      // Re-enable button after 30 seconds, keep status 'pendiente'
      setTimeout(() => {
        setRunningIntegration(false);
      }, 30000);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const updatedCredentials = {
        accounting_client_id: props.cssCredentials.accounting_client_id,
        id: props.cssCredentials.id,
        site: 'css',
        login_info: {
          elaborador_map: elaboradorMap,
          representante_legal_map: representanteLegalMap,
        },
      };
      await props.updateAccountingClientConfig(updatedCredentials);
      message.success('Credenciales de CSS actualizadas correctamente');
      setPendingProcess(true);

      // after saving, run integration
      handleRunIntegration();
    } catch (error) {
      console.error('Error durante la actualización de CSS:', error);
      message.error('Error al actualizar credenciales de CSS');
    } finally {
      setLoading(false);
    }
  };

  // test if credentials are pending
  const credentialTimePending = isCredentialTimePending();
  const showPending = pendingProcess || credentialTimePending;

  let tagColor = 'default';
  let tagIcon = pendingProcess ? <SyncOutlined spin /> : null;
  let tagText = 'Estado de Credenciales: pendiente...';
  let tooltipText = showPending
    ? 'La actualización puede tardar unos minutos. Puede regresar más tarde para ver el estado actualizado.'
    : 'Por favor guarde las credenciales para iniciar la validación y actualización de las obligaciones';

  if (!showPending) {
    if (props.credentialStatus === 'OK') {
      tagColor = 'success';
      tagIcon = <CheckCircleOutlined />;
      tagText = 'Credenciales OK';
    } else if (props.credentialStatus === 'Sin Datos') {
      tagColor = 'default';
      tagIcon = <InfoCircleOutlined />;
      tagText = 'Estado de Credenciales pendiente...';
      tooltipText = 'Por favor guarde las credenciales para iniciar la validación y actualización de las obligaciones';
    } else {
      tagColor = 'error';
      tagIcon = <WarningOutlined />;
      tagText = 'Error de Credenciales';
      tooltipText = props.credentialStatusDetail;
    }
  }

  const renderFormItems = (map, handleChange) => {
    const order = ['usuario', 'contrasena', 'correo', 'firma']; // example fields
    return order.map((key) => (
      <Form.Item label={key} style={{ marginLeft: 96 }} key={key}>
        <Row>
          <Col span={22}>
            <Input
              value={map[key] || ''}
              onChange={(e) => handleChange(key, e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type="text"
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(map[key])}
            />
          </Col>
        </Row>
      </Form.Item>
    ));
  };

  return (
    <div style={{ marginBottom: 32, marginTop: 32 }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
        <h3 style={{ marginLeft: 4, textAlign: 'left', marginBottom: 0 }}>
          CAJA DE SEGURO SOCIAL
        </h3>
        <div style={{
          color: '#999999', fontSize: '12px', marginLeft: 16,
          alignSelf: 'flex-end'
        }}>
          última actualización: {formatDateTime(props.cssCredentials.updated_at)}
        </div>
      </div>
      <div style={{ display: 'flex', marginLeft: 4 }}>
        <Tooltip title={tooltipText}>
          <Tag color={tagColor} icon={tagIcon} style={{ marginLeft: 4 }}>
            {tagText}
          </Tag>
        </Tooltip>
      </div>
      <div
        style={{
          textAlign: 'left',
          marginLeft: 72,
          marginTop: 24,
          marginBottom: 8,
        }}
      >
        Representante Legal
      </div>
      {renderFormItems(representanteLegalMap, handleRepresentanteLegalChange)}

      <div
        style={{
          textAlign: 'left',
          marginLeft: 72,
          marginTop: 24,
          marginBottom: 8,
        }}
      >
        Elaborador
      </div>
      {renderFormItems(elaboradorMap, handleElaboradorChange)}

      <div style={{ textAlign: 'left', marginLeft: 96, marginTop: 24 }}>
        <Button
          type="primary"
          onClick={!runningIntegration ? handleSave : null}
          loading={loading || runningIntegration}
          style={{ marginRight: 16 }}
        >
          Guardar y ejecutar
        </Button>
        {showPending && (
          <div
            style={{
              marginLeft: 8,
              color: '#999',
              textAlign: 'left',
              fontSize: '12px',
              marginTop: 8,
            }}
          >
            Hemos guardado las credenciales y estamos buscando las obligaciones.
            <br /> Actualizaremos el estado de credenciales en unos momentos.
          </div>
        )}
      </div>
    </div>
  );
};

export default CSSIntegration;
