import React, { useEffect, useState } from 'react';
import { Card, Table, Button, Modal, Spin, Tag } from 'antd';
import { FileTextOutlined, CalendarOutlined } from '@ant-design/icons';
import HeroAxios from '../../helpers/HeroAxios';

function EstadosDeCuentaCard({ accountingClientId, API_domain, auth, source, style }) {
    const [docs, setDocs] = useState([]);
    const [loadingDocs, setLoadingDocs] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [iframeLoading, setIframeLoading] = useState(false);
    const [activeFilter, setActiveFilter] = useState(null);

    const fetchEstadosDeCuenta = async () => {
        setLoadingDocs(true);
        try {
            const response = await HeroAxios.get('get_obligations_documents', {
                params: {
                    client_id: accountingClientId
                }
            });

            if (response.data?.documents) {
                setDocs(response.data.documents);
            }
        } catch (error) {
            console.error('Error fetching estados de cuenta:', error);
        } finally {
            setLoadingDocs(false);
        }
    };

    useEffect(() => {
        fetchEstadosDeCuenta();
    }, [accountingClientId]);

    useEffect(() => {
        if (source) {
            setActiveFilter(source);
        } else {
            setActiveFilter(null);
        }
    }, [source]);

    const getFilteredDocs = () => {
        if (!source) return docs;
        return docs.filter(doc => doc.source === source);
    };

    const handleOpenModal = (doc) => {
        setSelectedDoc(doc);
        setModalVisible(true);
        setIframeLoading(true);
    };

    const handleIframeLoad = () => {
        setIframeLoading(false);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedDoc(null);
    };

    const formatDate = (dateString) => {
        // Parse the UTC date string
        const utcDate = new Date(dateString);

        // Convert to local time
        const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'document_date',
            key: 'document_date',
            width: '180px',
            render: (date) => formatDate(date),
            sorter: (a, b) => new Date(b.document_date) - new Date(a.document_date),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Fuente',
            dataIndex: 'source',
            key: 'source',
            width: '100px',
            render: (source) => (
                <Tag color={source === 'DGI' ? 'geekblue' : source === 'CSS' ? 'purple' : 'blue'}>
                    {source}
                </Tag>
            )
        },
        {
            title: 'Tipo de Documento',
            dataIndex: 'document_type',
            key: 'document_type',
            render: (type) => type.replace(/_/g, ' ').toLowerCase(),
        },
        {
            title: 'Acciones',
            key: 'actions',
            width: '80px',
            render: (_, record) => (
                <Button
                    type="link"
                    icon={<FileTextOutlined />}
                    onClick={() => handleOpenModal(record)}
                    style={{ padding: '4px 8px' }}
                >
                    Ver
                </Button>
            ),
        },
    ];

    return (
        <Card
            headStyle={{
                display: 'none'
            }}
            size="small"
            style={{
                marginBottom: '16px',
                ...(style || {})
            }}
            bodyStyle={{
                padding: '8px',
                backgroundColor: '#fff'
            }}
        >
            <Table
                className="small-table nested-table"
                columns={columns}
                dataSource={getFilteredDocs()}
                rowKey="id"
                loading={loadingDocs}
                size="small"
                pagination={{
                    pageSize: 5,
                    size: 'small',
                    simple: true,
                    hideOnSinglePage: true
                }}
            />

            <Modal
                title={selectedDoc?.filename || 'Documento'}
                visible={modalVisible}
                onCancel={handleCloseModal}
                footer={null}
                width="90vw"
                bodyStyle={{ height: '80vh', padding: 0 }}
                style={{ top: 20 }}
                destroyOnClose
            >
                {iframeLoading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 99,
                        }}
                    >
                        <Spin tip="Cargando documento..." />
                    </div>
                )}

                {selectedDoc && (
                    <iframe
                        src={selectedDoc.url}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            display: 'block',
                        }}
                        title="Estado de Cuenta"
                        onLoad={handleIframeLoad}
                    />
                )}
            </Modal>

            <style>
                {`
                    .nested-table .ant-table-cell {
                        padding: 4px 8px !important;
                        font-size: 11px !important;
                    }
                    .nested-table .ant-table-thead > tr > th {
                        padding: 4px 8px !important;
                        font-size: 11px !important;
                        background: #fafafa !important;
                    }
                    .nested-table .ant-pagination-item {
                        min-width: 24px !important;
                        height: 24px !important;
                        line-height: 22px !important;
                    }
                    .nested-table .ant-pagination-item a {
                        font-size: 11px !important;
                    }
                `}
            </style>
        </Card>
    );
}

export default EstadosDeCuentaCard;     