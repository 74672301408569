import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import {
  Button,
  Card,
  Timeline,
  Table,
  Tag,
  Typography,
  Skeleton,
  notification,
  Modal,
  Tooltip
} from 'antd';
import { Link } from 'react-router-dom';
import {
  FileTextOutlined,
  CloseOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  InfoCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { FormattedUSD } from '../FormattedUSD';
import { authContext } from '../../ProvideAuth.js';
import InfoCard from '../ui/InfoCard';
import './../styles.css';
import HeroAxios from '../../helpers/HeroAxios.js';
import EstadosDeCuentaCard from './EstadosDeCuentaCard';
import { SocketContext } from '../../SocketContext';  // <-- import context

const { Text, Paragraph } = Typography;

export function AccountingObligationsTimeline(props) {
  const auth = useContext(authContext);

  // MAIN STATE
  const [loading, setLoading] = useState(props.loading);
  const [fullData, setFullData] = useState(props.data);
  const [data, setData] = useState([]);
  const [selectedItemDetails, setSelectedItemDetails] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [selectedItemDueDate, setSelectedItemDueDate] = useState(null);
  const [selectedObligation, setSelectedObligation] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);

  // UI/UX STATE
  const [showInfoCard, setShowInfoCard] = useState(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);

  // Credential status (like in FETable.js)
  const [credentialStatus, setCredentialStatus] = useState(null);
  const [credentialStatusDetail, setCredentialStatusDetail] = useState(null);

  // Add state for all credential statuses
  const [dgiCredentialStatus, setDgiCredentialStatus] = useState(null);
  const [cssCredentialStatus, setCssCredentialStatus] = useState(null);
  const [mupaCredentialStatus, setMupaCredentialStatus] = useState(null);

  // Add state for credential status details
  const [credentialStatuses, setCredentialStatuses] = useState({
    dgi: { status: null, detail: null },
    css: { status: null, detail: null },
    mupa: { status: null, detail: null }
  });

  // REFS
  const detailsRef = useRef(null);
  const timelineContainerRef = useRef(null);

  // DESTRUCTURE PROPS
  const { pendingCount, dueThisMonthCount } = props;
  const clientId = props.clientId;
  const clientName = props.data.names[clientId];

  // Socket
  const { socket, startSocket, stopSocket } = useContext(SocketContext);

  // HELPERS
  const convertToSpanishDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  // For displaying which automated obligations the client has
  const getUniqueSources = (dataArray) => {
    const sources = new Set();
    dataArray.forEach((item) => {
      if (item.source) sources.add(item.source);
      if (item.details && item.details.length > 0) {
        item.details.forEach((detail) => {
          if (detail.source) sources.add(detail.source);
        });
      }
    });
    return Array.from(sources);
  };

  const memoizedUniqueSources = useMemo(() => getUniqueSources(data), [data]);

  // ORGANIZE DATA
  const organizeData = (responseData) => {
    const clientIdStr = String(props.clientId);
    const obligationsData = responseData && responseData.obligations;
    const clientObligations =
      (obligationsData && obligationsData[clientIdStr]) || [];

    return clientObligations.map((obligation) => ({
      groupKey: obligation.group_key,
      dueDate: obligation.due_date,
      status: obligation.status,
      totalAmount: obligation.total_amount,
      source: obligation.source,
      details: obligation.details,
      last_updated: obligation.last_updated,
    }));
  };

  // LOAD DATA
  useEffect(() => {
    if (fullData) {
      const organizedData = organizeData(fullData);
      setData(organizedData);
      setLoading(false);
    }
  }, [fullData]);

  // Fetch all credential statuses
  useEffect(() => {
    const fetchCredentialStatuses = async () => {
      try {
        const [dgiResponse, cssResponse, mupaResponse] = await Promise.all([
          HeroAxios.get(`obligations/get-credential-status/${clientId}/dgi`),
          HeroAxios.get(`obligations/get-credential-status/${clientId}/css`),
          HeroAxios.get(`obligations/get-credential-status/${clientId}/mupa`)
        ]);

        setCredentialStatuses({
          dgi: {
            status: dgiResponse.data.credential_status,
            detail: dgiResponse.data.credential_status_detail
          },
          css: {
            status: cssResponse.data.credential_status,
            detail: cssResponse.data.credential_status_detail
          },
          mupa: {
            status: mupaResponse.data.credential_status,
            detail: mupaResponse.data.credential_status_detail
          }
        });
      } catch (error) {
        console.error('Error fetching credential statuses:', error);
      }
    };

    fetchCredentialStatuses();
  }, [clientId]);

  // SCROLL TIMELINE to the first relevant item
  useEffect(() => {
    if (timelineContainerRef.current && !loading) {
      const currentDate = new Date();
      const sortedData = [...data].sort(
        (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
      );
      const targetIndex = sortedData.findIndex(
        (item) => item.status === 'pendiente' || new Date(item.dueDate) >= currentDate
      );

      if (targetIndex !== -1) {
        const itemHeight =
          timelineContainerRef.current.scrollHeight / sortedData.length;
        const scrollPosition = itemHeight * targetIndex;
        timelineContainerRef.current.scrollTop = scrollPosition;
      }
    }
  }, [loading, data]);

  // DEFAULT SELECT one of this month's obligations (or next pending)
  useEffect(() => {
    if (!loading && data.length > 0) {
      const currentDate = new Date();
      const sortedData = [...data].sort(
        (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
      );

      // 1) Try to find an obligation due this month
      let selectedIndex = sortedData.findIndex((item) => {
        const dueDateObj = new Date(item.dueDate);
        return (
          dueDateObj.getMonth() === currentDate.getMonth() &&
          dueDateObj.getFullYear() === currentDate.getFullYear()
        );
      });

      // 2) If none is found, find the next pending item
      if (selectedIndex === -1) {
        selectedIndex = sortedData.findIndex((item) => item.status === 'pendiente');
      }

      // 3) If we found something, set it as selected
      if (selectedIndex !== -1) {
        const selected = sortedData[selectedIndex];
        const originalIndex = data.indexOf(selected); // find the item in the original "data" array
        showDetails(selected.details, originalIndex, selected.dueDate);
        setSelectedObligation(selected);
        setSelectedItemIndex(originalIndex);
      }
    }
    // eslint-disable-next-line
  }, [data, loading]);

  // CLOSE details when user clicks outside
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDetails, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideDetails, true);
    };
    // eslint-disable-next-line
  }, []);

  const handleClickOutsideDetails = (event) => {
    if (detailsRef.current && !detailsRef.current.contains(event.target)) {
      closeDetails();
    }
  };

  // SHOW / HIDE DETAILS
  const showDetails = (details, index, dueDate) => {
    setSelectedItemDetails(details);
    setSelectedItemIndex(index);
    setSelectedItemDueDate(dueDate);
    setSelectedSource(details[0]?.source || null);
  };

  const closeDetails = () => {
    setSelectedItemDetails([]);
    setSelectedItemIndex(null);
    setSelectedItemDueDate(null);
  };

  // TIMELINE RENDER
  const renderTimelineItems = () => {
    const sortedData = [...data].sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
    const now = new Date();

    return sortedData.map((item, index) => {
      const dueDateObj = new Date(item.dueDate);
      const isPastDue = now > dueDateObj && item.status !== 'completado';
      const isDueThisMonth =
        dueDateObj.getMonth() === now.getMonth() &&
        dueDateObj.getFullYear() === now.getFullYear();

      let color;
      if (item.status === 'completado') {
        color = '#22ce07'; // Green for completed
      } else if (isPastDue) {
        color = 'red'; // Red for overdue
      } else if (isDueThisMonth) {
        color = 'var(--yellow-normal)'; // Yellow/Orange for due this month
      } else {
        color = '#696969'; // Dark gray for future
      }

      const isSelected = selectedItemIndex === index;
      const isOtherSelected = selectedItemIndex !== null && !isSelected;

      const itemStyle = {
        color: isSelected
          ? 'var(--purple-darkest)'
          : isOtherSelected
            ? '#a6a6a6'
            : '#4f4f4f',
        cursor: 'pointer',
        fontSize: '12px',
        position: 'relative',
        paddingRight: '2px',
      };

      const selectedIndicatorStyle = {
        position: 'absolute',
        right: '50px',
        top: 0,
        bottom: 0,
        width: '2px',
        backgroundColor: 'var(--purple-normal)',
        borderRadius: '1px',
        display: isSelected ? 'block' : 'none'
      };

      const amountStyle = {
        fontSize: '10px',
        color: isSelected ? 'var(--purple-darkest)' : '#a6a6a6',
      };

      const handleMouseEnter = (e) => {
        e.currentTarget.style.color = '#000';
      };

      const handleMouseLeave = (e) => {
        e.currentTarget.style.color = isSelected
          ? 'var(--purple-darkest)'
          : isOtherSelected
            ? '#a6a6a6'
            : '#4f4f4f';
      };

      const formattedDate = dueDateObj.toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });

      return (
        <Timeline.Item color={color} key={index} label={formattedDate}>
          <div style={{ position: 'relative' }}>
            <div
              style={itemStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => {
                showDetails(item.details, index, item.dueDate);
                setSelectedObligation(item);
              }}
            >
              {item.groupKey}
              <Button
                type="text"
                icon={<FileTextOutlined />}
                onClick={() => {
                  showDetails(item.details, index, item.dueDate);
                  setSelectedObligation(item);
                }}
                style={{ ...itemStyle, minWidth: '20px', height: '20px' }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
              <div style={selectedIndicatorStyle} />
            </div>
            {item.source && <div style={{ ...amountStyle }}>{item.source}</div>}
            {item.totalAmount && item.totalAmount !== '0.00' && (
              <div style={{ ...amountStyle }}>
                <FormattedUSD total={item.totalAmount} />
              </div>
            )}
          </div>
        </Timeline.Item>
      );
    });
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      render: (text) => (
        <div style={{ fontSize: '12px', textAlign: 'left' }}>{text}</div>
      ),
      sorter: (a, b) => a.description.localeCompare(b.description),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text) =>
        text !== '0.00' && text !== '0.0' ? (
          <div style={{ textAlign: 'right' }}>
            <FormattedUSD total={text} />
          </div>
        ) : null,
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) => (
        <Tag
          color={status === 'completado' ? 'green' : 'orange'}
          icon={status === 'completado' ? <CheckOutlined /> : <ClockCircleOutlined />}
          style={{ fontSize: '0.85em' }}
        >
          {status === 'completado' ? 'completado' : 'pendiente'}
        </Tag>
      ),
    },
    {
      title: 'Fecha de Vencimiento',
      key: 'dueDateColumn',
      align: 'right',
      render: () => (
        <Text style={{ fontSize: '12px' }}>
          {selectedItemDueDate
            ? new Date(selectedItemDueDate).toLocaleDateString('es-ES', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            : '-'}
        </Text>
      ),
    },
  ];

  // INFO CARD
  const infoCardContent = (
    <>
      <Paragraph style={{ fontSize: '12px', textAlign: 'left' }}>
        En esta sección puedes ver las diferentes obligaciones legales que
        tienes pendientes o completadas.
      </Paragraph>
      {memoizedUniqueSources.length > 0 ? (
        <Paragraph style={{ fontSize: '12px', textAlign: 'left' }}>
          Tienes habilitadas las siguientes obligaciones automáticas:{' '}
          <Text strong>{memoizedUniqueSources.join(', ')}</Text>.
        </Paragraph>
      ) : (
        <Paragraph style={{ fontSize: '12px', textAlign: 'left' }}>
          No tienes obligaciones automáticas actualmente.
        </Paragraph>
      )}
      <Paragraph style={{ fontSize: '12px', textAlign: 'left' }}>
        Esta información se carga automáticamente con las credenciales cargadas
        en <Link to="/config">Configuración</Link>.
      </Paragraph>
    </>
  );

  const handleInfoCardClose = () => {
    setShowInfoCard(false);
  };

  // TRIGGER OBLIGATIONS
  const TriggerObligationRunner = async (accounting_client_id, source) => {
    try {
      await HeroAxios.post(
        `obligations/update-obligations-by-source/${accounting_client_id}`,
        {
          source: source,
        }
      );

      if (source === 'MUPA') {
        setTimeout(async () => {
          try {
            const response = await HeroAxios.post('getAccountingObligations', {
              group_by: 'source',
            });
            setFullData(response.data);

            if (selectedItemIndex !== null) {
              const organizedData = organizeData(response.data);
              const updatedDetails =
                organizedData[selectedItemIndex]?.details || [];
              setSelectedItemDetails(updatedDetails);
            }
          } catch (error) {
            console.error('Error refreshing obligations:', error);
            notification.error({
              message: 'Error',
              description: 'No se pudieron actualizar las obligaciones.',
              placement: 'topRight',
            });
          }
        }, 5000);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleUpdateClick = async (source) => {
    setIsUpdateDisabled(true);
    notification.info({
      message: 'Sincronización Iniciada',
      description: `Actualizando obligaciones de ${clientName} para ${source}`,
      placement: 'topRight',
    });
    // startSocket(auth.email);  // <-- start socket connection
    try {
      await TriggerObligationRunner(props.clientId, source);

      if (source === 'MUPA') {
        setTimeout(async () => {
          try {
            const response = await HeroAxios.post('getAccountingObligations', {
              group_by: 'source',
            });
            setFullData(response.data);

            if (selectedItemIndex !== null) {
              const organizedData = organizeData(response.data);
              const updatedDetails =
                organizedData[selectedItemIndex]?.details || [];
              setSelectedItemDetails(updatedDetails);
            }
          } catch (error) {
            console.error('Error refreshing obligations:', error);
            notification.error({
              message: 'Error',
              description: `No se pudieron actualizar las obligaciones de ${clientName} para ${source}`,
              placement: 'topRight',
            });
          }
        }, 5000);
      }
    } catch (error) {
      console.error('Error triggering obligation runner:', error);
      notification.error({
        message: 'Error',
        description: `No se pudo iniciar la actualización de obligaciones de ${clientName} para ${source}`,
        placement: 'topRight',
      });
    } finally {
      setTimeout(() => {
        setIsUpdateDisabled(false);
      }, 5000);
    }
  };

  // HANDLE PAYMENT
  const handlePayClick = async ({
    cardId,
    totalAmount,
    obligationType,
    selectedItemDetails,
  }) => {
    try {
      const response = await HeroAxios.post('/getPaycaddyCardBalance', {
        card_id: cardId,
      });
      const { balance } = response.data;

      Modal.confirm({
        title: 'Confirmar Pago',
        content: (
          <div>
            <p>
              <strong>Balance:</strong> {balance}
            </p>
            <p>
              <strong>Tipo de obligación:</strong> {obligationType}
            </p>
            <p>
              <strong>Total a pagar:</strong> {totalAmount}
            </p>
          </div>
        ),
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        okButtonProps: { disabled: balance < totalAmount },
        onOk: async () => {
          if (balance >= totalAmount) {
            try {
              await HeroAxios.post('/obligations/payObligation', {
                card_id: cardId,
                client_id: props.clientId,
                amount: totalAmount,
                selectedItemDetails: selectedItemDetails,
              });
              notification.success({
                message: 'Pago Completado',
                description: `El pago de ${totalAmount} ha sido enviado.`,
              });
            } catch (error) {
              console.error('Error processing payment', error);
              notification.error({
                message: 'Error de Pago',
                description: 'Ocurrió un error al procesar el pago.',
              });
            }
          } else {
            notification.warning({
              message: 'Saldo Insuficiente',
              description: 'Tu saldo es insuficiente para completar este pago.',
            });
          }
        },
      });
    } catch (error) {
      console.error('Error fetching balance', error);
      notification.error({
        message: 'Error',
        description: 'Ocurrió un error obteniendo el saldo de la tarjeta.',
      });
    }
  };

  // Helper function to get status color
  const getStatusColor = (status) => {
    switch (status) {
      case 'OK':
        return 'green';
      case 'Error de Credenciales':
        return 'red';
      case 'Sin Datos':
        return 'gray';
      default:
        return 'gray';
    }
  };

  // RENDER
  return (
    <>
      <Card
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Text style={{ fontWeight: 'bold', fontSize: '14px', textTransform: 'capitalize' }}>
              Obligaciones de {clientName}
            </Text>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              {pendingCount > 0 && (
                <Tag
                  color="red"
                  style={{
                    margin: 0,
                    display: 'inline-flex',
                    alignItems: 'center',
                    height: '22px',
                    lineHeight: '22px',
                  }}
                >
                  {pendingCount === 1 ? '1 Vencida' : `${pendingCount} Vencidas`}
                </Tag>
              )}
              {dueThisMonthCount > 0 && (
                <Tag
                  color="orange"
                  style={{
                    margin: 0,
                    display: 'inline-flex',
                    alignItems: 'center',
                    height: '22px',
                    lineHeight: '22px',
                  }}
                >
                  {dueThisMonthCount === 1
                    ? '1 Vence Pronto'
                    : `${dueThisMonthCount} Vencen Pronto`}
                </Tag>
              )}
              <Button
                type="text"
                icon={<InfoCircleOutlined />}
                onClick={() => setShowInfoCard(!showInfoCard)}
              />
            </div>
          </div>
        }
        headStyle={{ textAlign: 'left' }}
        size="small"
        bodyStyle={{ padding: '12px' }}
      >
        <div style={{ display: 'flex' }}>
          {/* TIMELINE - 1/3 */}
          <div
            ref={timelineContainerRef}
            style={{
              flex: '1 1 30%',
              maxHeight: 500,
              overflowY: 'auto',
              paddingRight: '2%',
              paddingTop: '8px',
            }}
          >
            {loading ? (
              <Skeleton active paragraph={{ rows: 6 }} />
            ) : (
              <Timeline mode="left" style={{ marginLeft: 0 }}>
                {renderTimelineItems()}
              </Timeline>
            )}
          </div>

          {/* DETAILS - 2/3 */}
          <div style={{ flex: '1 1 70%', paddingLeft: '2%' }}>
            {selectedItemDetails.length > 0 && (
              <Card size="small">
                {/* Details Section */}
                <div style={{ marginBottom: '24px' }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '16px',
                    position: 'relative'
                  }}>
                    <Text strong style={{ fontSize: '16px', textAlign: 'center' }}>
                      Obligaciones de {selectedItemDetails[0]?.source}
                    </Text>
                    <div style={{ fontSize: '12px', marginTop: '4px', color: '#8c8c8c' }}>
                      Vencen el: {selectedItemDueDate ? convertToSpanishDate(selectedItemDueDate) : ' - '}
                    </div>
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={closeDetails}
                      style={{
                        fontSize: '10px',
                        height: 'auto',
                        padding: '2px 4px',
                        position: 'absolute',
                        right: 0,
                        top: 0
                      }}
                    />
                  </div>

                  {/* Credential Status and Update Info */}
                  <div style={{
                    fontSize: '10px',
                    color: '#8c8c8c',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    marginBottom: '16px'
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <SyncOutlined
                        style={{
                          cursor: isUpdateDisabled ? 'not-allowed' : 'pointer',
                          opacity: isUpdateDisabled ? 0.5 : 1,
                          animation: isUpdateDisabled ? 'spin 1s linear infinite' : 'none',
                        }}
                        onClick={(e) => {
                          if (!isUpdateDisabled) {
                            handleUpdateClick(selectedItemDetails[0]?.source);
                          }
                        }}
                      />
                      {selectedObligation?.last_updated && (
                        <span>
                          Actualizado el{' '}
                          {new Date(selectedObligation.last_updated).toLocaleString('es-ES', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true,
                          })}
                        </span>
                      )}
                      {credentialStatuses[selectedItemDetails[0]?.source?.toLowerCase()]?.status && (
                        <Tooltip
                          title={
                            <div style={{ maxWidth: '480px', whiteSpace: 'normal' }}>
                              {credentialStatuses[selectedItemDetails[0]?.source?.toLowerCase()]?.detail}
                              {credentialStatuses[selectedItemDetails[0]?.source?.toLowerCase()]?.status === 'Error de Credenciales' && (
                                <div>
                                  Luego de corregir el error puede <Link to="/config" style={{ marginLeft: '4px', color: '#1890ff', textDecoration: 'underline' }} onClick={(e) => e.stopPropagation()}>actualizarlas acá</Link>
                                </div>
                              )}
                            </div>
                          }
                          placement="topLeft"
                          overlayStyle={{ maxWidth: '480px' }}
                        >
                          <Tag
                            color={getStatusColor(credentialStatuses[selectedItemDetails[0]?.source?.toLowerCase()]?.status)}
                            icon={
                              credentialStatuses[selectedItemDetails[0]?.source?.toLowerCase()]?.status === 'OK' ? (
                                <CheckCircleOutlined />
                              ) : credentialStatuses[selectedItemDetails[0]?.source?.toLowerCase()]?.status === 'Error de Credenciales' ? (
                                <WarningOutlined />
                              ) : (
                                <InfoCircleOutlined />
                              )
                            }
                            style={{
                              margin: 0,
                              marginLeft: '4px',
                              display: 'inline-flex',
                              alignItems: 'center',
                              height: '18px',
                              lineHeight: '18px',
                              fontSize: '9px'
                            }}
                          >
                            {credentialStatuses[selectedItemDetails[0]?.source?.toLowerCase()]?.status}
                          </Tag>
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  {/* Obligations Table First */}
                  <div style={{ marginBottom: '24px' }}>
                    <Table
                      className="small-table subtle-table"
                      dataSource={selectedItemDetails}
                      columns={columns}
                      pagination={false}
                      size="small"
                      rowKey="id"
                      showHeader={true}
                      summary={(pageData) => {
                        const totalAmount = pageData.reduce(
                          (sum, record) =>
                            sum +
                            (record.status !== 'completado'
                              ? parseFloat(record.amount) || 0
                              : 0),
                          0
                        );
                        return (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <strong>Total Pendiente</strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={1} align="right">
                              <strong>
                                <FormattedUSD total={totalAmount} />
                              </strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              {totalAmount > 0 &&
                                props.defaultPaycaddyCardId &&
                                (selectedItemDetails[0]?.source === 'MUPA' ||
                                  selectedItemDetails[0]?.source === 'ITBMS') && (
                                  <Button
                                    type="primary"
                                    size="small"
                                    onClick={() =>
                                      handlePayClick({
                                        cardId: props.defaultPaycaddyCardId,
                                        totalAmount,
                                        obligationType: selectedItemDetails[0]?.source,
                                        selectedItemDetails,
                                      })
                                    }
                                  >
                                    Pagar
                                  </Button>
                                )}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        );
                      }}
                    />
                  </div>

                  {/* Estados de Cuenta Card Second */}
                  {selectedSource && (
                    <div>
                      <Text type="secondary" style={{
                        fontSize: '12px',
                        marginBottom: '8px',
                        display: 'block',
                        textAlign: 'left'
                      }}>
                        Documentos
                      </Text>
                      <EstadosDeCuentaCard
                        accountingClientId={props.clientId}
                        API_domain={props.API_domain}
                        auth={props.auth}
                        source={selectedSource}
                        style={{
                          marginBottom: 0,
                          boxShadow: 'none',
                          border: '1px solid #f0f0f0',
                          borderRadius: '4px',
                        }}
                      />
                    </div>
                  )}
                </div>
              </Card>
            )}
          </div>

          {/* INFO CARD (OPTIONAL, TO THE RIGHT) */}
          {showInfoCard && (
            <div style={{ flex: '1 1 30%', paddingLeft: '16px' }}>
              <InfoCard
                title="¿Qué es la vista de obligaciones?"
                onClose={handleInfoCardClose}
              >
                {infoCardContent}
              </InfoCard>
            </div>
          )}
        </div>
      </Card>

      <style>
        {`
          .small-table .ant-table-cell {
            padding: 4px 8px;
            font-size: 12px;
            text-align: left;
          }
          .small-table .ant-table-thead > tr > th {
            padding: 4px 8px;
            font-size: 12px;
            text-align: left;
          }
          @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
          .credential-status-container {
            display: flex;
            align-items: center;
            gap: 8px;
          }

          .credential-status {
            font-weight: 500;
          }

          .info-icon {
            cursor: pointer;
            opacity: 0.7;
            &:hover {
              opacity: 1;
            }
          }

          .subtle-table .ant-table {
            background: transparent;
          }
          .subtle-table .ant-table-thead > tr > th {
            background: #fafafa;
            font-weight: normal;
            color: #8c8c8c;
            padding: 8px;
            font-size: 11px;
          }
          .subtle-table .ant-table-tbody > tr > td {
            background: transparent;
            border-bottom: 1px solid #f5f5f5;
            padding: 8px;
            font-size: 11px;
          }
          .subtle-table .ant-table-tbody > tr:hover > td {
            background: #fafafa;
          }
          .subtle-table .ant-table-summary {
            background: #fafafa;
            font-size: 11px;
          }
        `}
      </style>
    </>
  );
}
