/********************************
 * AutoCommitEngineButton.jsx
 ********************************/
import React, { useContext, useState } from 'react';
import HeroAxios from '../../helpers/HeroAxios.js';
import { Button, Modal, message } from 'antd';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import AccountingAccountSelect from '../Accounting/AccountingAccountSelect';
import EngineButton from './EngineButton.js';

function AutoCommitEngineButton(props) {
  const auth = useContext(authContext);

  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Optional account states
  const [itbmsAccountId, setItbmsAccountId] = useState(null);
  const [feEmitidasAccountId, setFeEmitidasAccountId] = useState(null);
  const [feRecibidasAccountId, setFeRecibidasAccountId] = useState(null);

  const selectedClientId = props.accountingClientsId;

  const [autoCommitLoading, setAutoCommitLoading] = useState(false);

  /*
   * Handle opening the modal:
   *  1. Fetch default accounts from the backend.
   *  2. Set local states for the three accounts.
   *  3. Show the modal.
   */
  const handleOpenModal = () => {
    if (!selectedClientId) {
      // If no client selected, just open the modal with no defaults
      setVisible(true);
      return;
    }

    HeroAxios.post('getDefaultAccountsForAutoCommit', {
      client_id: selectedClientId,
    })
      .then((res) => {
        // Destructure the returned data
        const {
          itbms_account_id,
          fe_emitidas_account_id,
          fe_recibidas_account_id,
        } = res.data;

        // Update local states
        setItbmsAccountId(Number(itbms_account_id) || null);
        setFeEmitidasAccountId(Number(fe_emitidas_account_id) || null);
        setFeRecibidasAccountId(Number(fe_recibidas_account_id) || null);

        // Show the modal
        setVisible(true);
      })
      .catch((error) => {
        console.error('Error fetching default accounts:', error);
        message.error('No se pudieron cargar las cuentas por defecto.');
        setVisible(true);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  /*
   * Handle confirming (OK button):
   *  1. POST to autoCommit route with the chosen accounts.
   *  2. Reset states if desired.
   */
  const handleOk = () => {
    setIsSubmitting(true);
    setAutoCommitLoading(true);

    const data = {
      client_id: selectedClientId,
      itbms_account_id: itbmsAccountId || null,
      fe_emitidas_account_id: feEmitidasAccountId || null,
      fe_recibidas_account_id: feRecibidasAccountId || null,
    };

    HeroAxios.post('autoCommit', data)
      .then(() => {
        message.success('Se inició exitosamente la auto creación de diarios.');
        setVisible(false);
        setIsSubmitting(false);
        setAutoCommitLoading(false);

        // Reset local states if needed
        setItbmsAccountId(null);
        setFeEmitidasAccountId(null);
        setFeRecibidasAccountId(null);
      })
      .catch((error) => {
        console.error('autoCommit error:', error);
        message.error('Ocurrió un error durante el proceso de auto commit.');
        setIsSubmitting(false);
        setAutoCommitLoading(false);
      });
  };

  return (
    <>
      <EngineButton
        text='Auto crear Diarios 🪄 🦸‍♂️'
        disabled={autoCommitLoading}
        loading={autoCommitLoading}
        onClick={handleOpenModal}
        popOverContent={null}
      />

      <Modal
        title='🪄 Auto Creación de Diarios'
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={isSubmitting}
        centered
        okText='Auto crear Diarios'
        cancelText='Cancelar'
        width={800}
      >
        <Label>Cuenta ITBMS (Opcional)</Label>
        {selectedClientId ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AccountingAccountSelect
              clientId={selectedClientId}
              API_domain={props.API_domain}
              auth={auth}
              placeholder='Seleccionar cuenta ITBMS (ITBMS por pagar posiblemente)'
              value={itbmsAccountId}
              onChange={(val) => setItbmsAccountId(val)}
              allowClear
            />
            <Button
              onClick={() => setItbmsAccountId(null)}
              style={{ marginLeft: 8 }}
            >
              Reset
            </Button>
          </div>
        ) : (
          <p>Seleccione primero un cliente</p>
        )}

        <Label style={{ marginTop: 16 }}>
          Cuenta para FE Emitidas No Relacionadas (Opcional)
        </Label>
        {selectedClientId ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AccountingAccountSelect
              clientId={selectedClientId}
              API_domain={props.API_domain}
              auth={auth}
              placeholder='Seleccionar cuenta FE emitidas (CxC posiblemente)'
              value={feEmitidasAccountId}
              onChange={(val) => setFeEmitidasAccountId(val)}
              allowClear
            />
            <Button
              onClick={() => setFeEmitidasAccountId(null)}
              style={{ marginLeft: 8 }}
            >
              Reset
            </Button>
          </div>
        ) : (
          <p>Seleccione primero un cliente</p>
        )}

        <Label style={{ marginTop: 16 }}>
          Cuenta para FE Recibidas No Relacionadas (Opcional)
        </Label>
        {selectedClientId ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AccountingAccountSelect
              clientId={selectedClientId}
              API_domain={props.API_domain}
              auth={auth}
              placeholder='Seleccionar cuenta FE recibidas (CxP posiblemente)'
              value={feRecibidasAccountId}
              onChange={(val) => setFeRecibidasAccountId(val)}
              allowClear
            />
            <Button
              onClick={() => setFeRecibidasAccountId(null)}
              style={{ marginLeft: 8 }}
            >
              Reset
            </Button>
          </div>
        ) : (
          <p>Seleccione primero un cliente</p>
        )}
        <ExplanationContainer>
          <strong>Cuentas Opcionales:</strong>
          <br />
          1. Separar el ITBMS de los gastos.
          <br />
          2. La cuenta de contrapartida para las FE emitidas no relacionadas
          (Ejemplo: Cuentas por Cobrar).
          <br />
          3. La cuenta de contrapartida para las FE recibidas no relacionadas.
        </ExplanationContainer>
      </Modal>
    </>
  );
}

const Label = styled.div`
  margin-bottom: 8px;
  font-weight: 500;
`;

const ExplanationContainer = styled.div`
  margin-top: 16px;
  font-size: 12px;
  color: #555;
`;

export { AutoCommitEngineButton };
