import React, { useContext, useEffect, useRef, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { Button, message, Select, Tooltip } from 'antd';
import axios from 'axios';

const AccountingAccountSelectBank = (props) => {
  // THIS COMPONENT IS ONLY USED IN BANK TABLE and AccountingInboxTable. USE THE EQUIVALENT ONE FROM /accounting/AccountingAccountSelect.js
  const auth = useContext(authContext);

  const [value, setValue] = useState(props.record.account_id);

  const [confidence, setConfidence] = useState(
    props.account_id_confidence || 0
  );

  const selectRef = useRef(null);

  const selectClassName =
    'accounting-account-select-' + Math.random().toString(36).substr(2, 9);

  /* 
     Build dynamic styling from `confidence` state. Any time
     `setConfidence(...)` changes it, we re-render and thus
     regenerate this CSS string.
  */
  const styles = `
    .${selectClassName} .ant-select-selector {
      ${
        confidence == 1
          ? 'border-image: linear-gradient(to right, var(--green-dark), var(--green-light)) 1 !important; border-width: 2.2px !important;'
          : ''
      }
      ${
        confidence > 0 && confidence < 1
          ? `
            border-image: linear-gradient(
              to left,
              var(--yellow-dark) ${((1 - confidence) * 100).toFixed(0)}%,
              var(--green-light) ${((1 - confidence) * 100).toFixed(0)}%
            ) 1 !important;
            border-style: dashed !important;
            border-width: 1.8px !important;
          `
          : ''
      }
    }
  `;

  // Recompute dropdown width
  const calculateDropdownWidth = () => {
    if (selectRef.current) {
      const selectWidth = selectRef.current.offsetWidth;
      return selectWidth * 1.5; // example: 1.5x the select width
    }
    return 300; // fallback
  };

  // If the record changes from the parent, update value/confidence in state
  useEffect(() => {
    setValue(props.record.account_id);
    setConfidence(props.account_id_confidence || 0);
  }, [props.record, props.account_id_confidence]);

  const updateSotTxnAccount = (newValue, newConfidence) => {
    console.log('updateSotTxnAccount', newValue, newConfidence);
    axios({
      method: 'post',
      url: `${props.API_domain}updateSotTxnAccount`,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        sot_txn_id: props.record.id,
        new_account: newValue,
        create_flagged_comment: !!props.createFlaggedComment,
        admin: !!auth.adminToken,
        account_id_confidence: newConfidence,
      },
    })
      .then((response) => {
        console.log('updateSotTxnAccount response:', response);
        message.success('Cuenta contable actualizada');
        const relatedMovements =
          response.data.amount_of_related_movements_reclassified;
        if (relatedMovements > 0) {
          message.info(
            `Se sincronizó${
              relatedMovements > 1 ? 'n' : ''
            } ${relatedMovements} movimiento${
              relatedMovements > 1 ? 's' : ''
            } relacionado${relatedMovements > 1 ? 's.' : '.'}`
          );
        }
        const warning = response.data.warning;
        if (warning) {
          message.warning(warning);
        }
        // Call parent's onChange if provided
        if (props.onChange) {
          props.onChange(newValue, props.record, newConfidence);
        }
      })
      .catch((error) => {
        console.error('Error updating account:', error);
        message.error('Error al actualizar la cuenta contable');
      });
  };

  const handleChange = (newValue) => {
    setValue(newValue);

    if (newValue === null || newValue === undefined) {
      // If the user clears, confidence -> 0
      console.log('Clearing account');
      setConfidence(0);
      updateSotTxnAccount(null, 0);
    } else {
      setConfidence(1);
      updateSotTxnAccount(newValue, 1);
    }
  };

  // If this component is purely text display
  if (props.displayMode === 'text') {
    let accountName = '';
    if (Array.isArray(props.accountOptions)) {
      const account = props.accountOptions.find((acc) => acc.id === value);
      if (account) {
        accountName = account.name;
      }
    }
    return <span style={{ fontWeight: 'bold' }}>{accountName}</span>;
  }

  // Tooltip content
  const tooltipContent = (() => {
    if (confidence == 1) return 'Confianza 100%';
    if (confidence > 0 && confidence < 1)
      return '🪄 Confianza ' + (confidence * 100).toFixed(0) + '%';
    return 'No se ha seleccionado una cuenta.';
  })();

  return (
    <>
      {/* Inject dynamic styles */}
      <style>{styles}</style>
      <div style={{ display: 'flex', width: '100%' }}>
        <Tooltip title={tooltipContent} placement='left'>
          <Select
            ref={selectRef}
            className={selectClassName}
            // Spread other props, but override value & onChange
            {...props}
            value={value}
            onChange={handleChange}
            showSearch
            placeholder='Elegir cuenta contable'
            style={{ flex: props.showThumbUp ? '1' : '1 1 100%' }}
            optionFilterProp='children'
            allowClear={props.allowClear}
            dropdownStyle={{ width: `${calculateDropdownWidth()}px` }}
            dropdownMatchSelectWidth={false}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {props.accountOptions.map((account) => (
              <Select.Option key={account.id} value={account.id}>
                {account.name}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>

        {props.showThumbUp && (
          <Tooltip title='Subir confianza a 100%'>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                // If user clicks 💯, set confidence=1 and call backend
                setConfidence(1);
                updateSotTxnAccount(value, 1);
              }}
              style={{ marginLeft: 8 }}
            >
              💯
            </Button>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export { AccountingAccountSelectBank };
