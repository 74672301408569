// ConciliationTable.js

import React, { useState, useRef } from 'react';
import { Empty, Table, Row, Col, Input, Button } from 'antd';
import styled from 'styled-components/macro';
import { FormattedUSD } from './../FormattedUSD.js';
import {
  CreditCardOutlined,
  InboxOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { DateFilterDropdown } from '../DateFilterDropdown.js';
import { get_moments_from_month_name } from '../../utils.js';
import { ImgOrPdf } from '../ImgOrPdf.js';
import RelationshipModalV2 from './../RelationshipModalV2';
import { sourceMapping, convertToIntDateFormat } from '../../utils.js';

const StyledTable = styled(Table)`
  .highlighted-row {
    background-color: #e6f7ff; /* Light blue for highlighted movements */
  }
  .leaving-row {
    transition: opacity 0.3s ease-out;
    opacity: 0;
  }
`;

function ConciliationTable({
  data,
  loading,
  selectedItems,
  setSelectedItems,
  highlightedMovements,
  rowsToFade = [],
  type,
  API_domain,
}) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  // Add state for date filter value
  const [dateFilterValue, setDateFilterValue] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMovement, setSelectedMovement] = useState(null);

  const [relationshipModalVisible, setRelationshipModalVisible] =
    useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState(null);
  const [selectedRecordForRelationship, setSelectedRecordForRelationship] =
    useState(null);

  const formatDate = (date) => {
    if (!date) return '';
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return date;

    return new Intl.DateTimeFormat('es-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(parsedDate);
  };

  const openRelationshipModal = (record, relationship) => {
    setSelectedRecordForRelationship(record);
    setSelectedRelationship(relationship);
    setRelationshipModalVisible(true);
  };

  // Function to enable search functionality for each column
  const getColumnSearchProps = (dataIndex, displayName) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${displayName}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex];
      return recordValue
        ? recordValue.toString().toLowerCase().includes(value.toLowerCase())
        : '';
    },
    render: (text) => {
      const value = text ? text.toString() : '';
      return searchedColumn === dataIndex && searchText ? (
        <span>
          {value.split(new RegExp(`(${searchText})`, 'gi')).map((fragment, i) =>
            fragment.toLowerCase() === searchText.toLowerCase() ? (
              <span key={i} style={{ backgroundColor: '#ffc069' }}>
                {fragment}
              </span>
            ) : (
              fragment
            )
          )}
        </span>
      ) : (
        value
      );
    },
  });

  // Handlers for search functionality
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0] || '');
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleLinkClick = (record) => {
    setSelectedMovement(record);
    setIsModalVisible(true);
  };

  // Helper function to highlight search text
  const highlightText = (text, searchText) => {
    const regex = new RegExp(`(${searchText})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === searchText.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: '#ffc069' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Table columns with search and conditional styling
  const columns = [
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      ...getColumnSearchProps('descripcion', 'Descripción'),
      render: (descripcion, record) => {
        const provider =
          type === 'expense'
            ? record.provider || ''
            : record.provider_or_consumer_name || '';
        const descriptionText = descripcion || record.description || '';
        return (
          <div>
            <div>
              {searchedColumn === 'descripcion' && searchText
                ? highlightText(descriptionText, searchText)
                : descriptionText}
            </div>
            <div style={{ color: 'grey' }}>{provider}</div>
          </div>
        );
      },
      onFilter: (value, record) => {
        const descriptionText = record.descripcion || record.description || '';
        const provider =
          type === 'expense'
            ? record.provider || ''
            : record.provider_or_consumer_name || '';
        const combinedText = `${descriptionText} ${provider}`.toLowerCase();
        return combinedText.includes(value.toLowerCase());
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'right', // Right-align the amounts
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['descend', 'ascend'],
      render: (text) => {
        const amount = parseFloat(text);
        const amountColor = amount < 0 ? 'red' : 'green';
        return (
          <span style={{ color: amountColor }}>
            <FormattedUSD total={text} />
          </span>
        );
      },
    },
    {
      title: 'Fecha',
      dataIndex: type === 'expense' ? 'fecha' : 'movement_date',
      key: 'fecha',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        const dateField =
          type === 'expense'
            ? record.fecha || record.date
            : record.movement_date;
        const current_date = new Date(dateField);
        current_date.setHours(current_date.getHours() + 5);
        if (typeof value === 'string') {
          const filter_dates = get_moments_from_month_name(value);
          return (
            current_date >= filter_dates[0] && current_date <= filter_dates[1]
          );
        }
        // value is expected to be an array of two dates [startDate, endDate]
        return current_date >= value[0] && current_date <= value[1];
      },
      render: (text, record) => {
        const dateField =
          type === 'expense'
            ? record.fecha || record.date
            : record.movement_date;
        const formattedDate = convertToIntDateFormat(dateField);
        return <div>{formattedDate}</div>;
      },
      sorter: (a, b) => {
        const dateA = new Date(
          type === 'expense' ? a.fecha || a.date : a.movement_date
        );
        const dateB = new Date(
          type === 'expense' ? b.fecha || b.date : b.movement_date
        );
        return dateB - dateA; // Descending order
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: '',
      dataIndex: 'expand',
      key: 'expand',
      render: () => null,
    },
    {
      title: 'Relación',
      dataIndex: 'relationship',
      key: 'relationship',
      render: (relationship, record) => {
        if (relationship && relationship.length > 0) {
          return relationship
            .filter(
              (rel) =>
                rel.type_of_mm !== 'entries' &&
                rel.movement_table_name !== 'entries'
            )
            .map((rel, index) => {
              const relatedMovementSource =
                rel.type_of_mm || rel.movement_table_name;
              const beautifulName =
                sourceMapping[relatedMovementSource] || relatedMovementSource;
              return (
                <span
                  key={index}
                  style={{
                    color: 'var(--purple-dark)',
                    cursor: 'pointer',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    transition: 'background-color 0.3s, color 0.3s',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openRelationshipModal(record, rel);
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = 'var(--purple-light)';
                    e.target.style.color = 'white';
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = 'transparent';
                    e.target.style.color = 'var(--purple-dark)';
                  }}
                >
                  {beautifulName}
                </span>
              );
            });
        } else {
          return '';
        }
      },
      filters: [
        ...Array.from(
          new Set(
            data
              .filter(
                (record) =>
                  record.expense &&
                  record.expense.length > 0 &&
                  record.expense.some(
                    (rel) =>
                      rel.type_of_mm !== 'entries' &&
                      rel.movement_table_name !== 'entries'
                  )
              )
              .flatMap((record) =>
                record.expense
                  .filter(
                    (rel) =>
                      rel.type_of_mm !== 'entries' &&
                      rel.movement_table_name !== 'entries'
                  )
                  .map((rel) => rel.type_of_mm || rel.movement_table_name)
              )
          )
        ).map((type) => {
          const beautifulName = sourceMapping[type] || type;
          return { text: beautifulName, value: type };
        }),
        { text: ' ', value: 'no_relationship' },
      ],
      onFilter: (value, record) => {
        if (value === 'no_relationship') {
          return !record.relationship || record.relationship.length === 0;
        }
        const relationship = record.relationship;
        if (relationship && relationship.length > 0) {
          return relationship.some(
            (rel) =>
              rel.type_of_mm === value || rel.movement_table_name === value
          );
        } else {
          return false;
        }
      },
    },
    {
      title: '✅',
      dataIndex: 'committed',
      key: 'committed',
      filters: [
        { text: 'Comprometido', value: 'committed' },
        { text: 'No comprometido', value: 'not_committed' },
      ],
      onFilter: (value, record) => {
        const hasEntriesRelationship =
          record.relationship &&
          record.relationship.some(
            (rel) => rel.movement_table_name === 'entries'
          );
        if (value === 'committed') {
          return hasEntriesRelationship;
        } else {
          return !hasEntriesRelationship;
        }
      },
      render: (text, record) => {
        const hasEntries =
          record.relationship &&
          record.relationship.some(
            (rel) => rel.movement_table_name === 'entries'
          );
        return hasEntries ? '✅' : '';
      },
    },
  ];

  // Function to sort data based on highlights
  const sortDataWithHighlights = (data) => {
    return [...data].sort((a, b) => {
      const isHighlightedA = highlightedMovements.some((m) => m.id === a.id);
      const isHighlightedB = highlightedMovements.some((m) => m.id === b.id);

      if (isHighlightedA && !isHighlightedB) return -1;
      if (!isHighlightedA && isHighlightedB) return 1;

      // If both are highlighted or both are not highlighted, sort by date
      const dateA = new Date(
        type === 'expense' ? a.fecha || a.date : a.movement_date
      );
      const dateB = new Date(
        type === 'expense' ? b.fecha || b.date : b.movement_date
      );
      return dateB - dateA; // Descending order
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedItems.map((item) => item.id),
    onChange: (_, selectedRows) => setSelectedItems(selectedRows),
    columnTitle: '',
  };

  const handleRowClick = (record) => {
    const isSelected = selectedItems.some((item) => item.id === record.id);

    if (isSelected) {
      // If row is already selected, deselect it
      setSelectedItems(selectedItems.filter((item) => item.id !== record.id));
    } else {
      // Otherwise, select the row
      setSelectedItems([...selectedItems, record]);
    }
  };

  const customExpandIcon = (props) => {
    if (props.expanded) {
      return (
        <EyeOutlined
          onClick={(e) => props.onExpand(props.record, e)}
          style={{ transform: 'rotate(180deg)' }}
        />
      );
    }
    return <EyeOutlined onClick={(e) => props.onExpand(props.record, e)} />;
  };

  const expandedRowRender = (record) => (
    <Row>
      <Col span={12}>
        {type === 'expense' ? (
          <>
            <p>
              <strong>Categoría:</strong> {record.category}
            </p>
            <p>
              <strong>Proveedor:</strong> {record.provider}
            </p>
            <p>
              <strong>RUC:</strong> {record.ruc}
            </p>
            <p>
              <strong>Gastante:</strong> {record.gastante}
            </p>
            <p>
              <strong>Fecha de creación:</strong>{' '}
              {formatDate(record.created_at)}
            </p>
          </>
        ) : (
          <>
            <p>
              <strong>Categoría:</strong> {record.categoria}
            </p>
            <p>
              <strong>Proveedor/Consumidor:</strong>{' '}
              {record.provider_or_consumer_name}
            </p>
            <p>
              <strong>ID Proveedor/Consumidor:</strong>{' '}
              {record.provider_or_consumer_id}
            </p>
            <p>
              <strong>Fecha de movimiento:</strong>{' '}
              {formatDate(record.movement_date)}
            </p>
            <Button
              type='secondary'
              href={record.factura ? record.factura : record.image_url}
              target='_blank'
              style={{ marginTop: 0, textAlign: 'right' }}
            >
              Abrir
            </Button>
          </>
        )}
      </Col>
      <Col span={12}>
        {type === 'expense' && record.factura && (
          <ImgOrPdf src={record.factura} API_domain={API_domain} />
        )}
        {type !== 'expense' && record.image_url && (
          <>
            <ImgOrPdf src={{ imageSource: record.image_url }} />
          </>
        )}
      </Col>
    </Row>
  );

  return (
    <>
      <StyledTable
        columns={columns}
        dataSource={sortDataWithHighlights(data)}
        loading={loading}
        rowKey={(record) => record.id}
        pagination={{ defaultPageSize: 100 }} // Set default page size to 100
        rowSelection={rowSelection}
        style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}
        rowClassName={(record) => {
          let className = '';
          if (highlightedMovements.some((m) => m.id === record.id)) {
            className += ' highlighted-row';
          }
          if (rowsToFade && rowsToFade.includes(record.id)) {
            className += ' leaving-row';
          }
          return className;
        }}
        expandable={{
          expandedRowRender,
          expandIcon: customExpandIcon,
        }}
        onRow={(record) => ({
          onClick: (e) => {
            if (e.target.closest('.anticon-link')) {
              // Clicked on the link icon, do nothing
              return;
            }
            handleRowClick(record);
          },
        })}
        locale={{
          emptyText: (
            <Empty
              style={{ paddingTop: '20vh', paddingBottom: '20vh' }}
              image={
                type === 'expense' ? (
                  <InboxOutlined style={{ fontSize: '64px' }} />
                ) : (
                  <CreditCardOutlined style={{ fontSize: '64px' }} />
                )
              }
              description={
                type === 'expense' ? (
                  <>
                    Selecciona un <b>equipo para ver los gastos</b> pendientes
                    por conciliar
                  </>
                ) : (
                  <>
                    Selecciona una{' '}
                    <b>fuente de movimiento para ver los movimientos</b>{' '}
                    pendientes por conciliar
                  </>
                )
              }
            />
          ),
        }}
      />
      {/* <RelationshipModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        movement={selectedMovement}
        API_domain={API_domain}
      /> */}
      {relationshipModalVisible && (
        <RelationshipModalV2
          visible={relationshipModalVisible}
          onCancel={() => setRelationshipModalVisible(false)}
          relationship={selectedRelationship}
          // setRecords={setExpenses}
          selectedRecordForRelationship={selectedRecordForRelationship}
          setRelationshipModalVisible={setRelationshipModalVisible}
          API_domain={API_domain}
          accounting_clients_id={
            selectedRecordForRelationship.accounting_clients_id
          }
        />
      )}
    </>
  );
}

export { ConciliationTable };
