import React from 'react';
import moment from 'moment';
import { Card, Statistic, Progress, Row, Col, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { formatDateSpanishMed } from '../utils';

export const ProgressOverview = ({
  totalUncommitted,
  categorizedCount,
  highConfidenceCount,
  relatedCount,
  cutoffDate = moment('2024-10-31'),
}) => {
  // Calculate percentages safely, handling the case where totalUncommitted could be 0.
  const categorizedPercentage =
    totalUncommitted > 0 ? (categorizedCount / totalUncommitted) * 100 : 0;
  const highConfidencePercentage =
    totalUncommitted > 0 ? (highConfidenceCount / totalUncommitted) * 100 : 0;
  const relatedPercentage =
    totalUncommitted > 0 ? (relatedCount / totalUncommitted) * 100 : 0;

  return (
    <div
      style={{
        position:
          'relative' /* Position the parent to allow absolutely positioned child */,
        background: 'var(--grey-light)',
        padding: '16px',
        borderRadius: '8px',
        marginBottom: '16px',
      }}
    >
      {/* Interrogation sign in the corner that shows cutoff date on hover */}
      <div
        style={{
          position: 'absolute',
          bottom: '8px',
          left: '8px',
          cursor: 'pointer',
        }}
      >
        <Tooltip title={`Contado desde el ${formatDateSpanishMed(cutoffDate)}`}>
          <QuestionCircleOutlined style={{ color: 'var(--grey-normal)' }} />
        </Tooltip>
      </div>

      {/* Displaying progress metrics for uncommitted items */}
      <Row gutter={[16, 16]} justify='space-between' align='middle'>
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card style={{ borderRadius: '12px' }}>
            {/* Number of uncommitted items */}
            <Tooltip title='Pendientes por incluir en contabilidad'>
              <Statistic title='Pendientes' value={totalUncommitted} />
            </Tooltip>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card style={{ borderRadius: '12px' }}>
            {/* Related count out of uncommitted */}
            <Statistic
              title='Relacionados'
              value={relatedCount}
              suffix={`/ ${totalUncommitted}`}
            />
            <Progress
              strokeColor={{
                '0%': 'var(--purple-light)',
                '100%': 'var(--purple-dark)',
              }}
              percent={Math.round(relatedPercentage)}
              status='active'
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card style={{ borderRadius: '12px' }}>
            {/* Categorized count out of uncommitted */}
            <Statistic
              title='Con cuenta contable'
              value={categorizedCount}
              suffix={`/ ${totalUncommitted}`}
            />
            <Progress
              strokeColor={{
                '0%': 'var(--purple-light)',
                '100%': 'var(--purple-dark)',
              }}
              percent={Math.round(categorizedPercentage)}
              status='active'
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card style={{ borderRadius: '12px' }}>
            {/* High confidence count out of uncommitted */}
            <Statistic
              title='Cuenta contable 100% confianza'
              value={highConfidenceCount}
              suffix={`/ ${totalUncommitted}`}
            />
            <Progress
              strokeColor={{
                '0%': 'var(--purple-light)',
                '100%': 'var(--purple-dark)',
              }}
              percent={Math.round(highConfidencePercentage)}
              status='active'
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
