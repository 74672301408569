/********************************
 * EngineView.jsx
 ********************************/
import React, { useContext, useState, useEffect } from 'react';
import {
  Layout,
  Card,
  Input,
  message,
  Modal,
  Button,
  List,
  Row,
  Col,
} from 'antd';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { AutoRelateEngineButton } from './AutoRelateEngineButton.js';
import { AutoClassifyEngineButton } from './AutoClassifyEngineButton.js';
import { AutoCommitEngineButton } from './AutoCommitEngineButton.js';
import HeroAxios from '../../helpers/HeroAxios';

const { Content } = Layout;
const { TextArea } = Input;

function EngineView(props) {
  const auth = useContext(authContext);

  // State for classification rules
  const [classificationRules, setClassificationRules] = useState('');

  // State for “Consultar cuentas contables”
  const [accountsModalVisible, setAccountsModalVisible] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(false);

  // Logs state
  const [relacionarLogs, setRelacionarLogs] = useState([]);
  const [clasificarLogs, setClasificarLogs] = useState([]);
  const [commitLogs, setCommitLogs] = useState([]);

  // ===========================
  // Fetch classification rules
  // ===========================
  useEffect(() => {
    fetchClassificationRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClassificationRules = async () => {
    try {
      const response = await HeroAxios({
        method: 'post',
        url: 'getClassificationRules',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      if (response.status === 200) {
        if (response.data && response.data.rules) {
          setClassificationRules(
            typeof response.data.rules === 'string'
              ? response.data.rules
              : JSON.stringify(response.data.rules, null, 2)
          );
        } else {
          setClassificationRules(''); // no rules
        }
      } else {
        message.error('Error al traer las reglas de clasificación.');
      }
    } catch (error) {
      console.error('Error fetching classification rules:', error);
      message.error('Error consiguiendo las reglas de clasificación.');
    }
  };

  const handleSaveClassificationRules = async () => {
    try {
      const response = await HeroAxios({
        method: 'post',
        url: 'saveClassificationRules',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {
          rules: classificationRules,
        },
      });

      if (response.status === 200) {
        message.success('Reglas de clasificación guardadas exitosamente.');
      } else {
        message.error('Error al guardar las reglas de clasificación.');
      }
    } catch (error) {
      console.error('Error saving classification rules:', error);
      message.error('Error guardando las reglas de clasificación.');
    }
  };

  // ===========================
  // Fetch Accounts (Modal)
  // ===========================
  const handleShowAccounts = async () => {
    setAccountsModalVisible(true);
    setLoadingAccounts(true);
    try {
      const response = await HeroAxios({
        method: 'post',
        url: 'getAccountsOptions',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {
          client_id: auth.accountingClientsId,
        },
      });
      if (response.status === 200 && Array.isArray(response.data)) {
        setAccounts(response.data);
      } else {
        message.error('Error al obtener cuentas contables.');
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
      message.error('Error al obtener cuentas contables.');
    } finally {
      setLoadingAccounts(false);
    }
  };

  const handleCloseModal = () => {
    setAccountsModalVisible(false);
  };

  // ===========================
  // Fetch logs for each "paso"
  // ===========================
  useEffect(() => {
    fetchRelacionarLogs();
    fetchClasificarLogs();
    fetchCommitLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRelacionarLogs = async () => {
    try {
      const response = await HeroAxios({
        method: 'post',
        url: 'getBotonEngineRunLogs',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {
          // Show last 100 logs for auto_create_relationships_core
          client_id: auth.accountingClientsId,
          step_name: ['auto_create_relationships_core'],
        },
      });
      console.log('response:', response);
      if (response.status === 200 && Array.isArray(response.data)) {
        setRelacionarLogs(response.data);
      } else {
        message.error('Error al obtener logs de Auto Relacionar.');
      }
    } catch (error) {
      console.error('Error fetching relacionar logs:', error);
    }
  };

  const fetchClasificarLogs = async () => {
    try {
      // For auto_classify_fes_core, auto_classify_bot_expenses_core, auto_classify_sot_transactions_core
      const response = await HeroAxios({
        method: 'post',
        url: 'getBotonEngineRunLogs',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {
          client_id: auth.accountingClientsId,
          step_name: [
            'auto_classify_fes_core',
            'auto_classify_bot_expenses_core',
            'auto_classify_sot_transactions_core',
          ],
        },
      });
      if (response.status === 200 && Array.isArray(response.data)) {
        setClasificarLogs(response.data);
      } else {
        message.error('Error al obtener logs de Auto Clasificar.');
      }
    } catch (error) {
      console.error('Error fetching clasificar logs:', error);
    }
  };

  const fetchCommitLogs = async () => {
    try {
      const response = await HeroAxios({
        method: 'post',
        url: 'getBotonEngineRunLogs',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {
          client_id: auth.accountingClientsId,
          step_name: ['auto_commit_core'],
        },
      });
      if (response.status === 200 && Array.isArray(response.data)) {
        setCommitLogs(response.data);
      } else {
        message.error('Error al obtener logs de Auto Commit.');
      }
    } catch (error) {
      console.error('Error fetching commit logs:', error);
    }
  };

  function parseAndRenderLogs(logs) {
    let parsedLogs = logs;

    // If logs is a string, try to parse as JSON
    if (typeof logs === 'string') {
      try {
        parsedLogs = JSON.parse(logs);
      } catch (error) {
        // if it's not valid JSON, we just show the string
        return <p style={{ color: '#555' }}>{logs}</p>;
      }
    }

    // If after parsing we have an object/array, render recursively
    if (parsedLogs && typeof parsedLogs === 'object') {
      return <RenderLogObject obj={parsedLogs} level={0} />;
    } else {
      // If it's just a string, display it plainly
      return <p style={{ color: '#555' }}>{String(parsedLogs)}</p>;
    }
  }

  function RenderLogObject({ obj, level }) {
    if (Array.isArray(obj)) {
      return (
        <ul style={{ paddingLeft: 20 }}>
          {obj.map((item, index) => (
            <li key={index} style={{ marginBottom: 4 }}>
              {typeof item === 'object' ? (
                <RenderLogObject obj={item} level={level + 1} />
              ) : (
                <span style={{ color: '#555' }}>{String(item)}</span>
              )}
            </li>
          ))}
        </ul>
      );
    } else {
      // 'obj' is a plain object
      return (
        <ul style={{ paddingLeft: 20 }}>
          {Object.entries(obj).map(([key, value]) => (
            <li key={key} style={{ marginBottom: 4 }}>
              <strong style={{ color: '#333' }}>{key}:</strong>{' '}
              {typeof value === 'object' ? (
                <RenderLogObject obj={value} level={level + 1} />
              ) : (
                <span style={{ color: '#555' }}>{String(value)}</span>
              )}
            </li>
          ))}
        </ul>
      );
    }
  }

  // Helper to nicely render logs in a List
  const renderLogsList = (logsData) => (
    <List
      itemLayout='vertical'
      dataSource={logsData}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={
              <div
                style={{ color: item.status === 'success' ? 'green' : 'red' }}
              >
                {item.step_name} - {item.status || 'Unknown'}
              </div>
            }
            description={
              <div>
                <div>
                  <strong>Run ID:</strong> {item.run_id}
                </div>
                <div>
                  <strong>Inicio:</strong> {item.start_time}
                </div>
                <div>
                  <strong>Fin:</strong> {item.end_time}
                </div>
                {item.logs && (
                  <div
                    style={{
                      background: '#f5f5f5',
                      padding: '8px',
                      marginTop: '8px',
                      borderRadius: '4px',
                    }}
                  >
                    {parseAndRenderLogs(item.logs)}
                  </div>
                )}
              </div>
            }
          />
        </List.Item>
      )}
    />
  );

  return (
    <Content
      style={{
        padding: '24px',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      <HeaderSection>
        <h2 style={{ marginBottom: '12px' }}>Automatización Contable</h2>
        <p style={{ marginBottom: '0', fontSize: '15px', lineHeight: 1.6 }}>
          A continuación se muestran tres pasos importantes para automatizar
          tareas tediosas de contabilidad. Sigue el orden indicado para obtener
          los mejores resultados.
        </p>
      </HeaderSection>

      <CardContainer>
        {/* ========================================= */}
        {/* PASO 1: AUTO RELACIONAR                  */}
        {/* ========================================= */}
        <Card
          title='Paso 1: Auto Relacionar'
          bordered
          style={{ marginBottom: '24px' }}
        >
          <Row gutter={16}>
            {/* LEFT COLUMN */}
            <Col xs={24} md={12}>
              <CardContent>
                <p style={{ marginBottom: '8px', lineHeight: 1.6 }}>
                  Vincula automáticamente movimientos de bancos y tarjetas con
                  las facturas recibidas y/o gastos del bot.
                </p>
                <p style={{ marginBottom: '8px', lineHeight: 1.6 }}>
                  El proceso se realiza en el siguiente orden:
                </p>
                <ol style={{ marginLeft: '24px', marginBottom: '12px' }}>
                  <li>Banco(s) y Tarjeta(s)</li>
                  <li>FE Recibidas y Bot de Gastos</li>
                  <li>
                    Banco(s) y Tarjeta(s) con FE Recibidas y Bot de Gastos
                  </li>
                </ol>
                <p style={{ marginBottom: '16px', lineHeight: 1.6 }}>
                  <strong>Nota:</strong> Puede tardar algunos segundos. Se te
                  enviará una notificación al terminar de Auto Relacionar.
                </p>
              </CardContent>
            </Col>

            {/* RIGHT COLUMN - LOGS */}
            <Col xs={24} md={12}>
              <LogsContainer>
                <h4>Historial de Auto Relacionar</h4>
                {renderLogsList(relacionarLogs)}
              </LogsContainer>
            </Col>
          </Row>

          {/* BUTTON AT THE BOTTOM */}
          <Row>
            <Col span={24} style={{ textAlign: 'center', marginTop: '16px' }}>
              <AutoRelateEngineButton
                accountingClientsId={auth.accountingClientsId}
                API_domain={props.API_domain}
              />
            </Col>
          </Row>
        </Card>

        {/* ========================================= */}
        {/* PASO 2: AUTO CLASIFICAR                  */}
        {/* ========================================= */}
        <Card
          title='Paso 2: Auto Clasificar'
          bordered
          style={{ marginBottom: '24px' }}
        >
          <Row gutter={16}>
            {/* LEFT COLUMN */}
            <Col xs={24} md={12}>
              <CardContent>
                <p style={{ marginBottom: '8px', lineHeight: 1.6 }}>
                  Asigna automáticamente cuentas contables a los movimientos. El
                  sistema usa clasificaciones anteriores para clasificar
                  consistentemente. Es necesario contar con al menos 10 gastos
                  con una cuenta asignada previamente para usar esta función.
                </p>
                <p style={{ marginBottom: '8px', lineHeight: 1.6 }}>
                  Podrás elegir clasificar:
                </p>
                <ul style={{ marginLeft: '24px', marginBottom: '12px' }}>
                  <li>Gastos del Bot</li>
                  <li>Facturas Electrónicas (FEs recibidas y emitidas)</li>
                  <li>Transacciones SOT</li>
                </ul>
                <p style={{ marginBottom: '8px', lineHeight: 1.6 }}>
                  Podrás crear relaciones adicionales en{' '}
                  <a href='/conciliationv2'>Relaciones</a>
                </p>
                <p style={{ marginBottom: '16px', lineHeight: 1.6 }}>
                  <strong>Nota:</strong> Puede tardar unos segundos. Se te
                  enviará una notificación al terminar de Auto Relacionar.
                </p>

                {/* ========================== */}
                {/* Classification Rules UI */}
                {/* ========================== */}
                <p style={{ fontWeight: 'bold', marginTop: '32px' }}>
                  Reglas de Clasificación:
                </p>
                <TextArea
                  rows={4}
                  placeholder='Ejemplo: Clasifica todas las facturas electrónicas emitidas como Cuentas por cobrar clientes...'
                  value={classificationRules}
                  onChange={(e) => setClassificationRules(e.target.value)}
                />
                <Button
                  type='primary'
                  onClick={handleSaveClassificationRules}
                  style={{ marginRight: 8, marginTop: 8 }}
                >
                  Guardar
                </Button>

                <Button
                  onClick={handleShowAccounts}
                  style={{ marginRight: 8, marginTop: 8 }}
                >
                  Consultar cuentas contables
                </Button>
              </CardContent>
            </Col>

            {/* RIGHT COLUMN - LOGS */}
            <Col xs={24} md={12}>
              <LogsContainer>
                <h4>Historial de Auto Clasificar</h4>
                {renderLogsList(clasificarLogs)}
              </LogsContainer>
            </Col>
          </Row>

          {/* BUTTON AT THE BOTTOM */}
          <Row>
            <Col span={24} style={{ textAlign: 'center', marginTop: '16px' }}>
              <AutoClassifyEngineButton
                accountingClientsId={auth.accountingClientsId}
                API_domain={props.API_domain}
              />
            </Col>
          </Row>
        </Card>

        {/* ========================================= */}
        {/* PASO 3: AUTO CREAR DIARIOS (Auto Commit)  */}
        {/* ========================================= */}
        <Card title='Paso 3: Auto Crear Diarios' bordered>
          <Row gutter={16}>
            {/* LEFT COLUMN */}
            <Col xs={24} md={12}>
              <CardContent>
                <p style={{ marginBottom: '8px', lineHeight: 1.6 }}>
                  Crea diarios manuales de los movimientos clasificados. Los
                  diarios popularán los reportes contables que el usuario puede
                  ver en
                  <a href='/accounting'> Contabilidad</a>.
                </p>
                <p>
                  Podrás asignar cuentas para separar el ITBMS de los gastos
                  (ITBMS por pagar), o especificar cuentas de contrapartida para
                  FE emitidas/recibidas no relacionadas.
                </p>
                <p style={{ marginBottom: '16px', lineHeight: 1.6 }}>
                  <strong>Nota:</strong> Puede tardar unos segundos. Se te
                  enviará una notificación al terminar de Auto Relacionar.
                </p>
              </CardContent>
            </Col>

            {/* RIGHT COLUMN - LOGS */}
            <Col xs={24} md={12}>
              <LogsContainer>
                <h4>Historial de Auto Commit</h4>
                {renderLogsList(commitLogs)}
              </LogsContainer>
            </Col>
          </Row>

          {/* BUTTON AT THE BOTTOM */}
          <Row>
            <Col span={24} style={{ textAlign: 'center', marginTop: '16px' }}>
              <AutoCommitEngineButton
                accountingClientsId={auth.accountingClientsId}
                API_domain={props.API_domain}
              />
            </Col>
          </Row>
        </Card>
      </CardContainer>

      {/* Modal for displaying Accounts */}
      <Modal
        title='Cuentas Contables'
        visible={accountsModalVisible}
        onCancel={handleCloseModal}
        footer={[
          <Button key='close' onClick={handleCloseModal}>
            Cerrar
          </Button>,
        ]}
      >
        {loadingAccounts ? (
          <p>Cargando cuentas...</p>
        ) : (
          <List
            bordered
            dataSource={accounts}
            renderItem={(item) => <List.Item>{item.name}</List.Item>}
          />
        )}
      </Modal>
    </Content>
  );
}

/* Styled-components for layout and styling */
const HeaderSection = styled.div`
  text-align: left;
  margin-bottom: 24px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContent = styled.div`
  text-align: left;
`;

const LogsContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #eee;
  padding: 8px;
  border-radius: 4px;
  background: #fafafa;
  display: none;
`;

export { EngineView };
