// AccountingFinancialStatementsModal.js

import { Button, Empty, Table, Modal, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';
import { FormattedUSD } from '../FormattedUSD.js';
import * as XLSX from 'xlsx';
import moment from 'moment';
import TransactionCreatorModal from './TransactionCreatorModal.js';
import MovementSpecificDetails from './../MovementSpecificDetails';
import { sourceMapping } from './../../utils.js';
import { RelationshipButton } from '../RelationshipButton.js';
import { CommitViewButton } from '../CommitViewButton.js';

function AccountingFinancialStatementsModal(props) {
  const auth = useContext(authContext);
  const [accountEntries, setAccountEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTransactionModalVisible, setIsTransactionModalVisible] =
    useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  // States for MovementSpecificDetails modal
  const [isMovementDetailsVisible, setIsMovementDetailsVisible] =
    useState(false);
  const [selectedRelationshipData, setSelectedRelationshipData] =
    useState(null);
  const [selectedMovement, setSelectedMovement] = useState(null);

  const handleAmountClick = (record) => {
    setSelectedTransactionId(record.movement?.transaction_id || null);
    setIsTransactionModalVisible(true);
  };

  const handleRelacionClick = (relationshipData, movement) => {
    setSelectedRelationshipData(relationshipData);
    setSelectedMovement(movement);
    setIsMovementDetailsVisible(true);
  };

  const getAccountEntries = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: `${props.API_domain}getAccountEntries`,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        account_id: props.modalContent.account_id,
      },
    })
      .then((response) => {
        setLoading(false);

        let filteredEntries = response.data || [];

        if (props.modalContent.month) {
          const month = props.modalContent.month;
          const startDate = moment(month, 'YYYY-MM')
            .startOf('month')
            .format('YYYY-MM-DD');
          const endDate = moment(month, 'YYYY-MM')
            .endOf('month')
            .format('YYYY-MM-DD');

          filteredEntries = filteredEntries.filter(
            (entry) =>
              entry?.movement?.date >= startDate &&
              entry?.movement?.date <= endDate
          );
        }

        filteredEntries = filteredEntries.sort(
          (a, b) => new Date(a?.movement?.date) - new Date(b?.movement?.date)
        );

        setAccountEntries(filteredEntries);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching account entries:', error);
        message.error('Error al obtener las entradas de la cuenta.');
      });
  };

  useEffect(() => {
    if (props.modalContent.account_id && !isTransactionModalVisible) {
      getAccountEntries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.modalContent.account_id,
    props.modalContent.month,
    isTransactionModalVisible,
  ]);

  const exportToExcel = (data) => {
    if (!Array.isArray(data)) {
      console.error('Data is not an array:', data);
      return;
    }

    // Flatten data for Excel export
    const formattedData = data.map((entry) => ({
      Fecha: entry?.movement?.date || 'N/A',
      Monto: entry?.movement?.amount || 0,
      Comentarios: entry?.movement?.comments || '',
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Account Entries');
    XLSX.writeFile(workbook, `${props.modalContent.subcategory}.xlsx`);

    const trackingData = {
      event: 'exportAccountingEntriesToExcel',
      properties: {},
    };
    axios({
      method: 'post',
      url: `${props.API_domain}trackFrontEndEvent`,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: trackingData,
    })
      .then((response) => {
        console.log('Event tracked:', response);
      })
      .catch((error) => {
        console.error('Error tracking event:', error);
      });
  };

  const columns = [
    {
      title: 'Fecha',
      dataIndex: ['movement', 'date'],
      key: 'date',
      width: 150,
      render: (text) => (text ? moment(text).format('YYYY-MM-DD') : 'N/A'), // Format date or show 'N/A'
    },
    {
      title: 'Monto',
      dataIndex: ['movement', 'amount'],
      key: 'amount',
      width: 150,
      render: (amount, record) => (
        <>
          <CommitViewButton
            record={record}
            onClick={() => handleAmountClick(record)}
            displayText={
              props.accountingClientCompany === 4
                ? (amount || 0) * 0.62
                : amount || 0
            }
          />
        </>
      ),
    },
    {
      title: 'Comentarios',
      dataIndex: ['movement', 'comments'],
      key: 'comments',
      render: (comments) => (
        <span
          title={
            props.accountingClientCompany === 4 &&
            comments &&
            comments.length > 6
              ? comments
              : null
          }
        >
          {props.accountingClientCompany === 4 &&
          comments &&
          comments.length > 6
            ? `${comments.slice(0, 6)}...`
            : comments || 'N/A'}
        </span>
      ),
    },
    {
      title: 'Relación',
      key: 'relacion',
      width: 150,
      render: (record) => {
        let relationship = record.relationshipData;

        // Normalize `relationship` to an array if it's not already
        if (!Array.isArray(relationship)) {
          relationship = relationship ? [relationship] : [];
        }

        if (relationship.length > 0) {
          return relationship
            .filter(
              (rel) =>
                rel &&
                rel.type_of_mm !== 'entries' &&
                rel.movement_table_name !== 'entries'
            )
            .map((rel, index) => {
              if (!rel) {
                return null; // Skip null relationships
              }

              const relatedMovementSource =
                rel.type_of_mm || rel.movement_table_name;
              const beautifulName =
                sourceMapping[relatedMovementSource] || 'Ver';

              return (
                <RelationshipButton
                  record={record}
                  rel={rel}
                  beautifulName={beautifulName}
                  onClick={() => handleRelacionClick(rel, record.movement)}
                  index={index}
                />
              );
            })
            .filter(Boolean); // Remove any nulls resulting from skipped relationships
        } else {
          return null; // Return nothing if no valid relationships exist
        }
      },
    },
  ];

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              {props.modalContent.subcategory}{' '}
              {props.modalContent.month && (
                <span style={{ color: 'grey' }}>
                  {props.modalContent.month}
                </span>
              )}
            </div>
            <Button
              key='export'
              style={{ margin: 4, marginRight: 30, textAlign: 'right' }}
              onClick={() => exportToExcel(accountEntries)}
              disabled={props.accountingClientCompany === 4}
            >
              <DownloadOutlined /> Excel
            </Button>
          </div>
        }
        visible={props.isModalVisible}
        onOk={props.onOk}
        onCancel={() => {
          props.onCancel();
          setAccountEntries([]);
        }}
        width={1200}
        footer={null}
      >
        <Table
          columns={columns}
          dataSource={accountEntries}
          rowKey={(record) => record.movement?.id || `${record.key}`} // Use unique ID from movement or a fallback
          pagination={false}
          loading={loading}
          onRow={(record) => ({
            onClick: () => console.log('record', record),
          })}
          locale={{
            emptyText: (
              <Empty
                style={{ paddingTop: '8vh', paddingBottom: '8vh' }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  props.modalContent.month
                    ? 'No hay entradas para este mes'
                    : 'No hay entradas para esta cuenta'
                }
              />
            ),
          }}
          summary={(pageData) => {
            // Example summary (adjust as needed)
            const totalAmount = pageData.reduce(
              (sum, entry) => sum + (entry?.movement?.amount || 0),
              0
            );
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={1}>
                  <strong>Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>
                    <FormattedUSD total={totalAmount} />
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={4} />
              </Table.Summary.Row>
            );
          }}
        />
        <TransactionCreatorModal
          isVisible={isTransactionModalVisible}
          onCancel={() => setIsTransactionModalVisible(false)}
          prepareTransactionEndpoint={'getTransactionData'}
          transactionCreationEndpoint={'editTransaction'}
          initialPayload={{ transaction_id: selectedTransactionId }}
          API_domain={props.API_domain}
          auth={auth}
          clientId={props.accountingClientCompany}
          onTransactionCreated={() => {
            message.success('Transacción editada exitosamente');
            // Optionally refresh entries
            getAccountEntries();
          }}
        />
      </Modal>

      {/* MovementSpecificDetails Modal */}
      <Modal
        title='Detalles de Movimiento'
        visible={isMovementDetailsVisible}
        onCancel={() => setIsMovementDetailsVisible(false)}
        footer={null}
        width={800}
      >
        {selectedRelationshipData && selectedMovement ? (
          <MovementSpecificDetails
            visible={isMovementDetailsVisible}
            relationshipData={selectedRelationshipData}
            movement={selectedMovement}
            auth={auth}
            API_domain={props.API_domain}
          />
        ) : (
          <Empty description='No hay detalles disponibles.' />
        )}
      </Modal>
    </>
  );
}

export { AccountingFinancialStatementsModal };
