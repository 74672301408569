import {
  BookFilled,
  FolderFilled,
  PieChartFilled,
  SettingFilled,
  InteractionOutlined,
  WalletFilled,
  CreditCardOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu, Select, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';

const { Sider } = Layout;

const HomeSiderExpMan = (props) => {
  console.log('HomeSiderExpMan');
  const auth = useContext(authContext);
  const location = useLocation();
  const siderDefaultKey_map = {
    '/expenses': ['1'],
    '/reports': ['2'],
    '/cards': ['3'],
    '/visualizations': ['4'],
    '/config': ['5'],
    '/admin': ['7'],
    '/help': ['8'],
    '/dashboard': ['9'],
    '/reconciliation': ['14'],
    '/accounting': ['15'],
    '/bank': ['16'],
    '/transactions': ['18'],
    '/conciliation': ['19'],
    '/hr': ['20'],
  };
  var siderDefaultKey = siderDefaultKey_map[location.pathname];

  let reloadIfSameUrl = (path) => {
    if (path == window.location.pathname) {
      window.location.reload();
    }
  };

  return (
    <Sider
      style={{
        overflow: 'auto',
        overflowX: 'hidden',
        height: '100vh',
        position: 'fixed',
        left: 0,
        borderRight: '1px solid #f0f0f0',
      }}
      collapsedWidth='60'
      breakpoint='sm'
    >
      <Link
        to='/expenses'
        onClick={() => {
          reloadIfSameUrl('/expenses');
          siderDefaultKey = ['1'];
        }}
      >
        <>
          {props.mobile ? (
            <Logo
              src='heroLogoTransparent.png'
              style={{ width: '32px', height: '32px' }}
            />
          ) : (
            <Logo
              src='heroLogoTransparent.png'
              style={{ width: '80px', height: '80px' }}
            />
          )}
        </>
      </Link>
      <Menu
        mode='inline'
        selectedKeys={siderDefaultKey}
        style={{ backgroundColor: 'rgb(247,249,252)', borderRight: '0px' }}
      >
        <Menu.Item
          key='1'
          icon={
            <BookFilled
              style={{
                fontSize: 'max(1.05em,14px)',
                marginRight: 15,
                color: 'rgb(100,100,100)',
              }}
            />
          }
          style={{
            marginTop: 8,
            marginBottom: 5,
            fontSize: 'max(1.16em,14px)',
          }}
        >
          <span className='firstStep'>Gastos</span>
          <Link to='/expenses' onClick={() => reloadIfSameUrl('/expenses')} />
        </Menu.Item>
        <Menu.Item
          key='3'
          icon={
            <WalletFilled
              style={{
                fontSize: 'max(1.05em,14px)',
                marginRight: 15,
                color: 'rgb(100,100,100)',
              }}
            />
          }
          style={{
            marginTop: 8,
            marginBottom: 5,
            fontSize: 'max(1.16em,14px)',
          }}
        >
          <span>Tarjetas</span>
          <Link to='/cards' />
        </Menu.Item>
        {location.pathname === '/cards' ||
        location.pathname === '/transactions' ||
        location.pathname === '/conciliation' ? (
          <>
            <Menu.Item
              key='18'
              icon={
                <CreditCardOutlined
                  style={{
                    fontSize: 'max(1.05em,14px)',
                    marginRight: 10,
                    color: 'rgb(100,100,100)',
                  }}
                />
              }
              style={{
                marginTop: 8,
                marginBottom: 5,
                fontSize: 'max(1.16em,14px)',
                paddingLeft: '40px', // Indent to show as a child
              }}
            >
              <span className='firstStep'>Transacciones</span>
              <Link
                to='/transactions'
                onClick={() => reloadIfSameUrl('/transactions')}
              />
            </Menu.Item>
            <Menu.Item
              key='19'
              icon={
                <InteractionOutlined
                  style={{
                    fontSize: 'max(1.05em,14px)',
                    marginRight: 10,
                    color: 'rgb(100,100,100)',
                  }}
                />
              }
              style={{
                marginTop: 8,
                marginBottom: 5,
                fontSize: 'max(1.16em,14px)',
                paddingLeft: '40px', // Indent to show as a child
              }}
            >
              <span className='firstStep'>Conciliación</span>
              <Link
                to='/conciliation'
                onClick={() => reloadIfSameUrl('/conciliation')}
              />
            </Menu.Item>
          </>
        ) : (
          ''
        )}
        <Menu.Item
          key='2'
          icon={
            <FolderFilled
              style={{
                fontSize: 'max(1.05em,14px)',
                marginRight: 15,
                color: 'rgb(100,100,100)',
              }}
            />
          }
          style={{
            marginTop: 8,
            marginBottom: 5,
            fontSize: 'max(1.16em,14px)',
          }}
        >
          <span className='thirdStep'>Reportes</span>
          <Link to='/reports' onClick={() => reloadIfSameUrl('/reports')} />
        </Menu.Item>
        {props.mobile ? (
          <></>
        ) : (
          <>
            <Menu.Item
              key='4'
              icon={
                <PieChartFilled
                  style={{
                    fontSize: 'max(1.05em,14px)',
                    marginRight: 15,
                    color: 'rgb(100,100,100)',
                  }}
                />
              }
              style={{
                marginTop: 8,
                marginBottom: 5,
                fontSize: 'max(1.16em,14px)',
              }}
            >
              <span>Visualizaciones</span>
              <Link to='/visualizations' />
            </Menu.Item>
          </>
        )}
        {props.mobile ? (
          <></>
        ) : (
          <Menu.Item
            key='5'
            icon={
              <SettingFilled
                style={{
                  fontSize: 'max(1.05em,14px)',
                  marginRight: 15,
                  color: 'rgb(100,100,100)',
                }}
              />
            }
            style={{
              marginTop: 30,
              marginBottom: 30,
              fontSize: 'max(1.16em,14px)',
            }}
          >
            <span className='fourthStep'>Configuración</span>
            <Link to='/config' />
          </Menu.Item>
        )}
        <Menu.Item
          style={{
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            marginBottom: 0,
            bottom: 4,
            backgroundColor: 'rgb(247,249,252)',
            borderTop: '1px solid #f0f0f0',
          }}
        >
          <Tooltip title={`${auth.email}`}>
            <Button
              style={{ width: '100%' }}
              type='secondary'
              onClick={() => auth.signout(auth.email)}
            >
              Logout
              <LogoutOutlined />
            </Button>
          </Tooltip>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

const Logo = styled.img`
  margin-top: 16px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export { HomeSiderExpMan };
