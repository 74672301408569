import React, { useContext, useState, useEffect } from 'react';
import {
  PlusOutlined,
  UploadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Modal, Input, Button, Upload, Row, Col, Typography } from 'antd';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import { useAnalytics } from 'use-analytics';
import { ImgOrPdf } from './ImgOrPdf'; // Your custom preview component

const { Text } = Typography;

// Easily change this to allow a different max
const MAX_FILES = 5;

function CreateBotExpenseButton(props) {
  const auth = useContext(authContext);
  const { track } = useAnalytics();
  const [visible, setVisible] = useState(false);

  const { API_domain } = props;

  return (
    <div>
      <Button onClick={() => setVisible(true)} type='primary'>
        <PlusOutlined />
        Crear gastos
      </Button>

      <CreateMultipleBotExpenseModal
        API_domain={API_domain}
        visible={visible}
        onCancel={() => setVisible(false)}
        onSubmit={props.onSubmit} // e.g. fetchExpenses
        onError={props.onError}
      />
    </div>
  );
}

function CreateMultipleBotExpenseModal(props) {
  const { visible, onCancel, onSubmit, onError, API_domain } = props;
  const auth = useContext(authContext);

  // Each item in this array = { uid, file, previewUrl, description }
  const [expenseItems, setExpenseItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Clear out items on close
  useEffect(() => {
    if (!visible) {
      // Revoke any object URLs to avoid memory leaks
      expenseItems.forEach((item) => {
        if (item.previewUrl) {
          URL.revokeObjectURL(item.previewUrl);
        }
      });
      setExpenseItems([]);
    }
  }, [visible]);

  // Handle user selecting/removing files in <Upload />
  const handleFileChange = ({ fileList }) => {
    // Only allow up to MAX_FILES
    const limitedFiles = fileList.slice(0, MAX_FILES);

    // Build our new array of items
    const newExpenseItems = limitedFiles.map((fileWrapper) => {
      const existing = expenseItems.find((e) => e.uid === fileWrapper.uid);
      if (existing) {
        // If it's already in the array, keep it (retains description)
        return existing;
      } else {
        // New file => create a local preview URL
        const previewUrl = URL.createObjectURL(fileWrapper.originFileObj);
        return {
          uid: fileWrapper.uid,
          file: fileWrapper.originFileObj,
          previewUrl,
          description: '',
        };
      }
    });

    // Revoke old URLs that are no longer in the new list
    expenseItems.forEach((oldItem) => {
      const stillExists = newExpenseItems.some((n) => n.uid === oldItem.uid);
      if (!stillExists && oldItem.previewUrl) {
        URL.revokeObjectURL(oldItem.previewUrl);
      }
    });

    setExpenseItems(newExpenseItems);
  };

  // Update the description for a particular file
  const handleDescriptionChange = (uid, newDescription) => {
    setExpenseItems((prev) =>
      prev.map((item) =>
        item.uid === uid ? { ...item, description: newDescription } : item
      )
    );
  };

  // Remove a file from the list
  const handleRemoveItem = (uid) => {
    setExpenseItems((prev) => {
      return prev.filter((item) => {
        if (item.uid === uid && item.previewUrl) {
          // Clean up the object URL
          URL.revokeObjectURL(item.previewUrl);
        }
        return item.uid !== uid;
      });
    });
  };

  // Create all expenses in one request
  const handleCreateExpenses = () => {
    if (expenseItems.length === 0) {
      return; // no files
    }
    setIsLoading(true);

    // Build a FormData that pairs each 'images' with 'descriptions'
    const formData = new FormData();
    expenseItems.forEach((item) => {
      formData.append('images', item.file);
      formData.append('descriptions', item.description || '');
    });

    axios
      .post(`${API_domain}/uploadBotExpense`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Basic ' + btoa(auth.email + ':' + auth.token),
        },
      })
      .then((response) => {
        console.log('Expenses created successfully:', response);
        setIsLoading(false);
        // Refresh parent data, close modal
        onSubmit && onSubmit();
        onCancel();
      })
      .catch((error) => {
        console.error('Error creating expenses:', error);
        setIsLoading(false);
        onError && onError(error);
      });
  };

  return (
    <Modal
      visible={visible}
      title='Crear Gastos'
      okText={`Crear gastos (${expenseItems.length})`}
      confirmLoading={isLoading}
      cancelText='Cancelar'
      onCancel={onCancel}
      onOk={handleCreateExpenses}
    >
      <div style={{ marginBottom: 16 }}>
        <Upload
          multiple
          // Prevent auto-upload; we do manual submission
          beforeUpload={() => false}
          // Hide the default file list so we can make our own
          showUploadList={false}
          onChange={handleFileChange}
        >
          {expenseItems.length < MAX_FILES && (
            <Button icon={<UploadOutlined />}>
              Seleccionar Archivos (máx {MAX_FILES})
            </Button>
          )}
        </Upload>
      </div>

      {expenseItems.map((item) => (
        <Row
          key={item.uid}
          gutter={[8, 8]}
          style={{
            marginBottom: 12,
            border: '1px solid #f0f0f0',
            padding: 8,
            borderRadius: 4,
            alignItems: 'center',
          }}
        >
          {/* Preview on the left */}
          <Col span={8} style={{ maxHeight: 200, overflow: 'auto' }}>
            {item.previewUrl ? (
              <ImgOrPdf
                src={{ imageSource: item.previewUrl }}
                // You can pass any other props needed by ImgOrPdf
              />
            ) : (
              <Text>Sin vista previa</Text>
            )}
          </Col>
          {/* Description in the middle */}
          <Col span={12}>
            <Input
              placeholder='Descripción'
              value={item.description}
              onChange={(e) =>
                handleDescriptionChange(item.uid, e.target.value)
              }
            />
          </Col>
          {/* Remove button on the right */}
          <Col span={4} style={{ textAlign: 'right' }}>
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={() => handleRemoveItem(item.uid)}
            />
          </Col>
        </Row>
      ))}
    </Modal>
  );
}

export { CreateBotExpenseButton };
