// AccountDoubleFilterDropdown.js

import React, { useState } from 'react';
import { Button, Select, Radio, Space } from 'antd';

/**
 * props.accounts: list of { account_id, zoho_account_name } to display
 * We'll store something like:
 *   selectedKeys = [{
 *     accountIds: [],       // e.g. [12345, 9999]
 *     agentFilter: "all"
 *   }]
 */
export function AccountDoubleFilterDropdown({
  accounts,
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
}) {
  const initialFilterValue = selectedKeys[0] || {
    accountIds: [],
    agentFilter: 'all',
  };

  const [localFilter, setLocalFilter] = useState(initialFilterValue);

  const onChangeAccounts = (value) => {
    setLocalFilter((prev) => ({
      ...prev,
      accountIds: value,
    }));
  };

  const onChangeAgentFilter = (e) => {
    setLocalFilter((prev) => ({
      ...prev,
      agentFilter: e.target.value,
    }));
  };

  const handleConfirm = () => {
    setSelectedKeys([localFilter]);
    confirm();
  };

  const handleClear = () => {
    setLocalFilter({ accountIds: [], agentFilter: 'all' });
    setSelectedKeys([]);
    clearFilters();
    confirm({ closeDropdown: true });
  };

  return (
    <div style={{ padding: 12, width: 220 }}>
      <div style={{ marginBottom: 8, fontWeight: 500 }}>Cuentas:</div>
      <Select
        mode='multiple'
        showSearch
        optionFilterProp='label'
        style={{ width: '100%', marginBottom: 12 }}
        placeholder='Seleccionar cuenta(s)'
        value={localFilter.accountIds}
        onChange={onChangeAccounts}
        optionLabelProp='label'
        maxTagCount='responsive'
      >
        {accounts.map((acc) => (
          <Select.Option
            key={acc.account_id}
            value={acc.account_id}
            label={acc.zoho_account_name}
          >
            {acc.zoho_account_name}
          </Select.Option>
        ))}
      </Select>

      <div style={{ marginBottom: 8, fontWeight: 500 }}>Asignado por:</div>
      <Radio.Group
        onChange={onChangeAgentFilter}
        value={localFilter.agentFilter}
        style={{ display: 'flex', flexDirection: 'column', marginBottom: 12 }}
      >
        <Radio value='all'>Todas</Radio>
        <Radio value='AUTONOMOUS_AGENT'>Autónomas</Radio>
        <Radio value='human'>Humanos</Radio>
      </Radio.Group>

      <Space>
        <Button type='primary' onClick={handleConfirm}>
          Filtrar
        </Button>
        <Button onClick={handleClear}>Reset</Button>
      </Space>
    </div>
  );
}
