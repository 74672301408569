import React, { useState, useEffect } from 'react';
import { Modal, Input, DatePicker, Table, Button, Select, message, InputNumber, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import AccountingAccountSelect from './AccountingAccountSelect.js';
import HeroAxios from '../../helpers/HeroAxios.js';

function TransactionCreatorModal({
  isVisible,
  onCancel,
  prepareTransactionEndpoint,
  transactionCreationEndpoint,
  initialPayload,
  API_domain,
  auth,
  clientId,
  onTransactionCreated,
}) {
  const [transactionData, setTransactionData] = useState(null);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [clientAccounts, setClientAccounts] = useState([]);

  const fetchClientAccounts = async () => {
    try {
      const response = await HeroAxios.post(`${API_domain}/getClientAccounts`, {
        client_id: clientId,
      });
      setClientAccounts(response.data.accounts);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      message.error('Error al obtener las cuentas');
    }
  };

  useEffect(() => {
    if (isVisible) {
      const fetchData = async () => {
        try {
          await fetchClientAccounts();
          await fetchTransactionData();
        } catch (error) {
          console.error('Error fetching data:', error);
          message.error('Error al obtener los datos');
        }
      };
      fetchData();
    } else {
      setTransactionData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const fetchTransactionData = async () => {
    try {
      setLoadingLocal(true);
      const response = await HeroAxios.post(prepareTransactionEndpoint, initialPayload);
      setTransactionData(response.data.transaction);
      setLoadingLocal(false);
    } catch (error) {
      console.log(error.response.data?.message);
      alert(error.response.data?.message);
      setLoadingLocal(false);
      onCancel();
    }
  };

  const handleTransactionChange = (field, value) => {
    setTransactionData({ ...transactionData, [field]: value });
  };

  const handleEntryChange = (entryKey, field, value) => {
    const updatedEntries = transactionData.entries.map((entry) =>
      entry.key === entryKey ? { ...entry, [field]: value } : entry
    );
    setTransactionData({ ...transactionData, entries: updatedEntries });
  };

  const addNewEntry = () => {
    const newEntry = {
      key: Date.now().toString(),
      account_id: null,
      amount: 0,
      entry_type: 'D',
    };
    const updatedEntries = [...transactionData.entries, newEntry];
    setTransactionData({ ...transactionData, entries: updatedEntries });
  };

  const removeEntry = (entryKey) => {
    const updatedEntries = transactionData.entries.filter((entry) => entry.key !== entryKey);
    setTransactionData({ ...transactionData, entries: updatedEntries });
  };

  const getEntryColumns = () => [
    {
      title: 'Cuenta',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => (
        <AccountingAccountSelect
          value={record.account_id}
          onChange={(value) => handleEntryChange(record.key, 'account_id', value)}
          clientId={clientId}
          API_domain={API_domain}
          auth={auth}
          accounts={clientAccounts}
        />
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <InputNumber
          value={record.amount}
          onChange={(value) => handleEntryChange(record.key, 'amount', value)}
          style={{ width: '100%' }}
        />
      ),
    },
    {
      title: 'Tipo de Entrada',
      dataIndex: 'entry_type',
      key: 'entry_type',
      render: (text, record) => (
        <Select
          value={record.entry_type}
          onChange={(value) => handleEntryChange(record.key, 'entry_type', value)}
          style={{ width: 100 }}
        >
          <Select.Option value="D">Débito</Select.Option>
          <Select.Option value="C">Crédito</Select.Option>
        </Select>
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => removeEntry(record.key)}
        />
      ),
    },
  ];

  const handleSubmit = async () => {
    try {
      setLoadingLocal(true);
      await HeroAxios.post(transactionCreationEndpoint, {
        transaction: transactionData,
        client_id: clientId,
      });
      if (onTransactionCreated) onTransactionCreated();
      setLoadingLocal(false);
      onCancel();
    } catch (error) {
      message.error('Failed to create transaction. Please try again.');
      setLoadingLocal(false);
    }
  };

  // Use transactionData.transaction_id rather than initialPayload.transaction_id
  const handleDeleteTransaction = async () => {
    if (!transactionData?.transaction_id) return;
    try {
      await HeroAxios.post(`${API_domain}/deleteTransaction`, {
        transaction_id: transactionData.transaction_id,
        client_id: clientId,
      });
      message.success('Transacción eliminada exitosamente');
      onCancel();
    } catch (error) {
      message.error('Error al eliminar la transacción');
    }
  };

  if (!transactionData) {
    return null;
  }

  return (
    <Modal
      title="Transacción"
      visible={isVisible}
      // Custom footer
      footer={[
        <div key="left" style={{ float: 'left' }}>
          {transactionData?.transaction_id && (
            <Popconfirm
              title="¿Seguro que deseas eliminar esta transacción?"
              onConfirm={handleDeleteTransaction}
              okText="Sí"
              cancelText="No"
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </div>,
        <Button key="cancel" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Guardar
        </Button>,
      ]}
      onCancel={onCancel}
      width={800}
    >
      <Input
        placeholder="Descripción de la Transacción"
        value={transactionData.description}
        onChange={(e) => handleTransactionChange('description', e.target.value)}
        style={{ marginBottom: 16 }}
      />
      <DatePicker
        value={
          transactionData.transaction_date
            ? moment(transactionData.transaction_date)
            : null
        }
        onChange={(date) =>
          handleTransactionChange(
            'transaction_date',
            date ? date.format('YYYY-MM-DD') : null
          )
        }
        style={{ marginBottom: 16 }}
      />
      <Table
        dataSource={transactionData.entries}
        columns={getEntryColumns()}
        rowKey="key"
        pagination={false}
      />
      <Button type="dashed" onClick={addNewEntry} style={{ marginTop: 16 }}>
        Agregar Entrada
      </Button>
      <div style={{ marginTop: 16 }}>
        <p>
          Total Débitos:{' '}
          {transactionData.entries
            .filter((entry) => entry.entry_type === 'D')
            .reduce((sum, entry) => sum + parseFloat(entry.amount || 0), 0)
            .toFixed(2)}
        </p>
        <p>
          Total Créditos:{' '}
          {transactionData.entries
            .filter((entry) => entry.entry_type === 'C')
            .reduce((sum, entry) => sum + parseFloat(entry.amount || 0), 0)
            .toFixed(2)}
        </p>
      </div>
    </Modal>
  );
}

export default TransactionCreatorModal;
