import React, { useEffect, useState } from 'react';
import { Table, Typography, Input, Tooltip } from 'antd';
import styled from 'styled-components';
import HeroAxios from '../../helpers/HeroAxios.js';
import { FormattedUSD } from '../FormattedUSD';

const { Text } = Typography;

const AccountingObligationsSummary = ({ onClientSelect }) => {
    const [loading, setLoading] = useState(false);
    const [summaryData, setSummaryData] = useState([]);
    const [clientNames, setClientNames] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [obligationSources, setObligationSources] = useState([]);

    const getAccountingObligationsSummary = async () => {
        setLoading(true);
        try {
            const response = await HeroAxios.post('getAccountingObligations', {
                group_by: 'obligation_name',
            });

            const { summary_table, names } = response.data;
            const uniqueSources = [...new Set(summary_table.map(item => item.source))];
            setObligationSources(uniqueSources);

            setSummaryData(summary_table);
            setClientNames(names);
        } catch (error) {
            console.error('Error fetching accounting obligations summary:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAccountingObligationsSummary();
    }, []);

    // Suppress ResizeObserver errors in console
    useEffect(() => {
        const originalError = console.error;
        console.error = (...args) => {
            if (args[0] && typeof args[0] === 'string' && args[0].includes('ResizeObserver')) {
                return;
            }
            originalError(...args);
        };
        return () => {
            console.error = originalError;
        };
    }, []);

    // Count all "late" and "this_month" items across summaryData 
    // to decide singular/plural for table column headers
    const totalLateCount = summaryData.filter(item => item.category === 'late').length;
    const totalThisMonthCount = summaryData.filter(item => item.category === 'this_month').length;

    const createTableData = () => {
        const groupedData = summaryData.reduce((acc, item) => {
            const clientId = item.accounting_client_id;
            if (!acc[clientId]) {
                acc[clientId] = {
                    key: clientId,
                    clientId,
                    clientName: clientNames[clientId],
                    obligations: {
                        late: {},
                        this_month: {},
                    },
                    total: 0
                };
            }

            const category = item.category; // 'late' or 'this_month'
            const source = item.source;

            if (!acc[clientId].obligations[category][source]) {
                acc[clientId].obligations[category][source] = {
                    amount: 0,
                    details: []
                };
            }

            acc[clientId].obligations[category][source].amount += parseFloat(item.amount);
            acc[clientId].obligations[category][source].details.push(item);

            acc[clientId].total += parseFloat(item.amount);
            return acc;
        }, {});

        return Object.values(groupedData)
            .filter(client => {
                const term = searchTerm.toLowerCase();
                return client.clientName?.toLowerCase().includes(term) ||
                    client.clientId.toString().includes(term);
            });
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'clientId',
            key: 'clientId',
            width: 40,
            render: id => <Text style={{ fontSize: '11px' }}>{id}</Text>
        },
        {
            title: 'Cliente',
            dataIndex: 'clientName',
            key: 'clientName',
            width: 50,
            render: name => <Text style={{ fontSize: '11px' }}>{name}</Text>
        },
        // Dynamically create columns for "late" and "this_month"
        ...['late', 'this_month'].map((category) => {
            const categoryCount = category === 'late' ? totalLateCount : totalThisMonthCount;
            // Decide singular/plural based on total count
            const colTitle = category === 'late'
                ? (categoryCount === 1 ? 'Vencida' : 'Vencidas')
                : (categoryCount === 1 ? 'Vence Pronto' : 'Vencen Pronto');

            return {
                title: (
                    <Text
                        style={{
                            textAlign: 'center',
                            display: 'block',
                            color: category === 'late' ? 'red' : 'orange'
                        }}
                    >
                        {colTitle}
                    </Text>
                ),
                children: obligationSources.map((source) => ({
                    title: source,
                    dataIndex: ['obligations', category, source],
                    key: `${category}_${source}`,
                    width: category === 'late' ? 80 : 100,
                    render: (data) => {
                        if (!data) return null;
                        const details = data.details;

                        if (category === 'late') {
                            const monthlyGroups = details.reduce((acc, item) => {
                                const date = new Date(item.due_date);
                                const monthKey = date.toLocaleString('es-ES', { month: 'long' });
                                if (!acc[monthKey]) {
                                    acc[monthKey] = 0;
                                }
                                acc[monthKey] += parseFloat(item.amount);
                                return acc;
                            }, {});

                            return (
                                <Tooltip
                                    title={details.map((item, index) => (
                                        <div key={index}>
                                            {item.description}<br />
                                            {new Date(item.due_date).toLocaleDateString('es-ES')} - <FormattedUSD total={item.amount} />
                                        </div>
                                    ))}
                                >
                                    <Text style={{ fontSize: '11px' }}>
                                        {Object.entries(monthlyGroups).map(([month, amount], index) => (
                                            <div key={index}>
                                                {`${month} - `}<FormattedUSD total={amount} />
                                            </div>
                                        ))}
                                    </Text>
                                </Tooltip>
                            );
                        } else {
                            // 'this_month'
                            if (source === 'MUPA') {
                                return (
                                    <Tooltip
                                        title={details.map((item, index) => (
                                            <div key={index}>
                                                {new Date(item.due_date).toLocaleDateString('es-ES')} - <FormattedUSD total={item.amount} />
                                            </div>
                                        ))}
                                    >
                                        <Text style={{ fontSize: '11px' }}>
                                            <FormattedUSD total={data.amount} />
                                        </Text>
                                    </Tooltip>
                                );
                            } else {
                                // e.g., DGI, CSS, etc.
                                return (
                                    <Text style={{ fontSize: '11px' }}>
                                        {details.map((item, index) => (
                                            <div key={index}>
                                                {item.description} - <FormattedUSD total={item.amount} />
                                            </div>
                                        ))}
                                    </Text>
                                );
                            }
                        }
                    }
                }))
            };
        })
    ];

    return (
        <div>
            <SearchContainer>
                <Input.Search
                    placeholder="Buscar por ID o Cliente"
                    onSearch={value => setSearchTerm(value)}
                    onChange={e => setSearchTerm(e.target.value)}
                    style={{ width: 300 }}
                />
            </SearchContainer>

            <TableWrapper>
                <StyledTable
                    columns={columns}
                    dataSource={createTableData()}
                    pagination={false}
                    size="small"
                    scroll={{ x: 'max-content', y: 300 }}
                    tableLayout="fixed"
                    loading={loading}
                    onRow={(record) => ({
                        onClick: () => onClientSelect(record.clientId),
                        style: { cursor: 'pointer' }
                    })}
                />
            </TableWrapper>
        </div>
    );
};

const SearchContainer = styled.div`
    text-align: left;
    margin-bottom: 16px;
`;

const TableWrapper = styled.div`
    width: 100%;
    overflow: auto;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    padding: 8px;

    /* Scrollbar styling for WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        white-space: nowrap;
        background: #fafafa;
        padding: 8px;
        font-size: 11px;
        position: sticky;
        top: 0;
        z-index: 1;
        text-align: center;
    }
    
    .ant-table-tbody > tr > td {
        padding: 8px;
        font-size: 11px;
        text-align: center;
    }

    .ant-table {
        width: 100%;
    }

    .ant-table-container {
        width: 100%;
    }
`;

export default AccountingObligationsSummary;
