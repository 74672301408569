import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import { Form, Button, message, Table, Divider, Tooltip, Tag } from 'antd';
import {
  CreditCardOutlined,
  EditOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import styled from 'styled-components/macro';
import { formatPhoneNumber } from '../../utils.js';
import CreateAccountingClientConfigSite from './CreateAccountingClientConfigSite';
import CompanySection from './CompanySection';
import DGIIntegration from './DGIIntegration';
import MunicipioIntegration from './MunicipioIntegration';
import BGIntegration from './BGIntegration';
import CSSIntegration from './CSSIntegration';
import InvuIntegration from './InvuIntegration';
import PedidosYaIntegration from './PedidosYaIntegration.js';
import { ConfigCompanyMoneySettings } from './ConfigCompanyMoneySettings.js';
import HeroAxios from '../../helpers/HeroAxios.js';

const ConfigCompanySettings = (props) => {
  const auth = useContext(authContext);
  const [accountingClientCredentials, setAccountingClientCredentials] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [credentialStatuses, setCredentialStatuses] = useState({
    dgi: { status: null, detail: null },
    css: { status: null, detail: null },
    municipio: { status: null, detail: null }
  });

  useEffect(() => {
    fetchCompanyCredentials();
  }, [props.accountingClientCompany, refresh]);

  useEffect(() => {
    const fetchCredentialStatuses = async () => {
      if (!props.accountingClientCompany.accounting_client_id) return;

      try {
        const [dgiResponse, cssResponse, municipioResponse] = await Promise.all([
          HeroAxios.get(`obligations/get-credential-status/${props.accountingClientCompany.accounting_client_id}/dgi`),
          HeroAxios.get(`obligations/get-credential-status/${props.accountingClientCompany.accounting_client_id}/css`),
          HeroAxios.get(`obligations/get-credential-status/${props.accountingClientCompany.accounting_client_id}/mupa`)
        ]);

        setCredentialStatuses({
          dgi: {
            status: dgiResponse.data.credential_status,
            detail: dgiResponse.data.credential_status_detail,
            lastRun: dgiResponse.data.last_run
          },
          css: {
            status: cssResponse.data.credential_status,
            detail: cssResponse.data.credential_status_detail,
            lastRun: cssResponse.data.last_run
          },
          municipio: {
            status: municipioResponse.data.credential_status,
            detail: municipioResponse.data.credential_status_detail,
            lastRun: municipioResponse.data.last_run
          }
        });
      } catch (error) {
        console.error('Error fetching credential statuses:', error);
      }
    };

    fetchCredentialStatuses();
  }, [props.accountingClientCompany.accounting_client_id]);

  const fetchCompanyCredentials = () => {
    if (!props.accountingClientCompany.accounting_client_id) return;

    HeroAxios.post('getAccountingClientConfigs', {
      accounting_client_id: props.accountingClientCompany.accounting_client_id,
    })
      .then((response) => {
        setAccountingClientCredentials(Array.isArray(response.data) ? response.data : []);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error('An error occurred while fetching company info', error);
        setAccountingClientCredentials([]);
        setIsLoaded(true);
      });
  };

  const getStripeSubscriptionURL = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'stripeGetSubscriptionURL',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        subscriptionId: props.subscriptionId,
      },
    })
      .then((response) => {
        if (response.data.sessionURL) {
          window.open(response.data.sessionURL, '_blank');
        }
      })
      .catch((error) => {
        console.log('Error fetching Stripe Subscription URL:', error);
      });
  };

  // Function to update specific credentials
  const updateAccountingClientConfig = async (updatedCredentials) => {
    try {
      const { id, accounting_client_id, site, login_info } = updatedCredentials;

      // Prepare the data in the format expected by the backend
      const requestData = {
        id: id,
        accounting_client_id: accounting_client_id,
        site: site,
        login_info: login_info,
      };

      const response = await axios.post(
        props.API_domain + 'updateAccountingClientConfig',
        requestData,
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );

      // Update the local state
      setAccountingClientCredentials((prevCredentials) =>
        prevCredentials.map((credential) =>
          credential.id === id
            ? { ...credential, ...updatedCredentials }
            : credential
        )
      );

      return response.data;
    } catch (error) {
      console.error(`Error updating ${updatedCredentials.site} config:`, error);
      throw error;
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.success('Copiado');
  };

  if (!isLoaded) {
    return <LoadingDiv>{antLoadingIcon}</LoadingDiv>;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div style={{ width: '100%', maxWidth: '1000px' }}>
        <Form
          layout='vertical'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <h3 style={{ marginTop: '16px', textAlign: 'left' }}>Suscripción</h3>
          <div style={{ textAlign: 'left', marginLeft: 96 }}>
            <>
              {props.subscriptionId &&
                props.subscriptionId.includes('plink') ? (
                <Button
                  onClick={() => {
                    window.open(props.subscriptionId, '_blank');
                  }}
                  type='primary'
                  style={{
                    width: 200,
                  }}
                >
                  Pagar suscripción
                  <CreditCardOutlined />
                </Button>
              ) : (
                <Button
                  onClick={getStripeSubscriptionURL}
                  style={{
                    width: 200,
                    backgroundColor: 'hsl(0, 0%, 95%)',
                    borderColor: 'hsl(0, 0%, 95%)',
                  }}
                >
                  Manejar Suscripción <CreditCardOutlined />
                </Button>
              )}
            </>
          </div>
          <Divider />
          <CompanySection
            API_domain={props.API_domain}
            copyToClipboard={copyToClipboard}
            accountingClientCompany={props.accountingClientCompany}
          />
          <Divider />
          <div style={{ textAlign: 'left' }}>
            <h3>Dinero</h3>
          </div>
          <ConfigCompanyMoneySettings
            API_domain={props.API_domain}
            copyToClipboard={copyToClipboard}
            accountingClientCompany={props.accountingClientCompany}
          />
          <Divider />

          {accountingClientCredentials.find((item) => item.site === 'dgi') ? (
            <>
              <DGIIntegration
                dgiCredentials={accountingClientCredentials.find(
                  (item) => item.site === 'dgi'
                )}
                copyToClipboard={copyToClipboard}
                updateAccountingClientConfig={updateAccountingClientConfig}
                credentialStatus={credentialStatuses.dgi.status}
                credentialStatusDetail={credentialStatuses.dgi.detail}
                lastRun={credentialStatuses.dgi.lastRun}
                accountingClientId={props.accountingClientCompany.accounting_client_id}
              />
              <Divider />
            </>
          ) : (
            <CreateAccountingClientConfigSite
              API_domain={props.API_domain}
              accountingClientId={
                props.accountingClientCompany.accounting_client_id
              }
              site='dgi'
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )}
          {accountingClientCredentials.find(
            (item) => item.site === 'municipio'
          ) ? (
            <>
              <MunicipioIntegration
                municipioCredentials={accountingClientCredentials.find(
                  (item) => item.site === 'municipio'
                )} //only the item that has site dgi
                accountingClientCredentials={accountingClientCredentials}
                copyToClipboard={copyToClipboard}
                updateAccountingClientConfig={updateAccountingClientConfig}
                credentialStatus={credentialStatuses.municipio.status}
                credentialStatusDetail={credentialStatuses.municipio.detail}
                lastRun={credentialStatuses.municipio.lastRun}
                accountingClientId={
                  props.accountingClientCompany.accounting_client_id
                }
              />
              <Divider />
            </>
          ) : (
            <CreateAccountingClientConfigSite
              API_domain={props.API_domain}
              accountingClientId={
                props.accountingClientCompany.accounting_client_id
              }
              site='municipio'
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )}
          {auth.adminEmail ? (
            accountingClientCredentials.find((item) => item.site === 'bg') ? (
              <>
                <BGIntegration
                  bgCredentials={accountingClientCredentials.find(
                    (item) => item.site === 'bg'
                  )} // only the item that has site 'bg'
                  accountingClientCredentials={accountingClientCredentials}
                  copyToClipboard={copyToClipboard}
                  updateAccountingClientConfig={updateAccountingClientConfig} // Pass update function
                />
                <Divider />
              </>
            ) : (
              <CreateAccountingClientConfigSite
                API_domain={props.API_domain}
                accountingClientId={
                  props.accountingClientCompany.accounting_client_id
                }
                site='bg'
                refresh={refresh}
                setRefresh={setRefresh}
              />
            )
          ) : (
            <></>
          )}

          {accountingClientCredentials.find((item) => item.site === 'css') ? (
            <>
              <CSSIntegration
                cssCredentials={accountingClientCredentials.find(
                  (item) => item.site === 'css'
                )} //only the item that has site dgi
                accountingClientCredentials={accountingClientCredentials}
                copyToClipboard={copyToClipboard}
                updateAccountingClientConfig={updateAccountingClientConfig}
                credentialStatus={credentialStatuses.css.status}
                credentialStatusDetail={credentialStatuses.css.detail}
                lastRun={credentialStatuses.css.lastRun}
                accountingClientId={
                  props.accountingClientCompany.accounting_client_id
                }
              />
              <Divider />
            </>
          ) : (
            <CreateAccountingClientConfigSite
              API_domain={props.API_domain}
              accountingClientId={
                props.accountingClientCompany.accounting_client_id
              }
              site='css'
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )}
          {
            <InvuIntegration
              invuCredentials={accountingClientCredentials.filter(
                (item) => item.site === 'invu'
              )}
              copyToClipboard={copyToClipboard}
              accountingClientId={
                props.accountingClientCompany.accounting_client_id
              }
              API_Domain={props.API_domain}
            />
          }
          {accountingClientCredentials.find(
            (item) => item.site === 'pedidosya'
          ) ? (
            <>
              <PedidosYaIntegration
                pedidosYaCredentials={accountingClientCredentials.find(
                  (item) => item.site === 'pedidosya'
                )} //only the item that has site dgi
                accountingClientCredentials={accountingClientCredentials}
                copyToClipboard={copyToClipboard}
                updateAccountingClientConfig={updateAccountingClientConfig} // Pass update function
              />
              <Divider />
            </>
          ) : (
            <CreateAccountingClientConfigSite
              API_domain={props.API_domain}
              accountingClientId={
                props.accountingClientCompany.accounting_client_id
              }
              site='pedidosya'
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )}
        </Form>
      </div>
    </div>
  );
};

const antLoadingIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { ConfigCompanySettings };
