import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import './App.less';
import { AdminView } from './components/Admin/AdminView.js';
// import { ComprasView } from './components/Compras/ComprasView.js';
import { ConfigView } from './components/ConfigView.js';
import { DashboardView } from './components/Procurement/DashboardView.js';
import { HelpView } from './components/HelpView.js';
import { BankView } from './components/BankView.js';
import { CardsView } from './components/CardsView.js';
import { CookieRoute } from './components/CookieRoute.js';
import { ExpensesView } from './components/ExpensesView.js';
import { EmployeesView } from './components/Hr_components/EmployeesView.js';
import { FacturasView } from './components/Procurement/FacturasView.js';
import { ImageHosterView } from './components/ImageHosterView';
import { MeetingBookedView } from './components/MeetingBookedView';
import { HomeSiderTriage } from './components/HomeSiderTriage.js';
import { LoggedInRoute } from './components/LoggedInRoute.js';
import { LoginView } from './components/LoginView';
import { NewCardPackageView } from './components/NewCardPackageView';
import { OrdenesView } from './components/Procurement/OrdenesView.js';
import { PrivateRoute } from './components/PrivateRoute.js';
import { QuotesView } from './components/Procurement/QuotesView.js';
import { ReportsView } from './components/ReportsView.js';
import { RecibidosView } from './components/Procurement/RecibidosView.js';
import { RequisitionsView } from './components/Procurement/RequisitionsView.js';
import { SharedBoxView } from './components/Compras/SharedBoxView.js';
import { SharedReportView } from './components/SharedReportView.js';
import { ProvideAuth, authContext } from './ProvideAuth.js';
import analytics from './Analytics';
import { AnalyticsProvider } from 'use-analytics';
import PageGuide from './PageGuide';
import { enquireScreen } from 'enquire-js';
import { VisualizationsView } from './components/VisualizationsView';
import { ReconciliationView } from './components/Reconciliation/ReconciliationView';
import { AccountingFinancialStatementsView } from './components/Accounting/AccountingFinancialStatementsView.js';
import { AccountingInboxView } from './components/Accounting/AccountingInboxView';
import { UpdateObligationView } from './components/UpdateObligationView';
import { PayrollApprovalView } from './components/PayrollApprovalView.js';
import { TransactionsView } from './components/Transactions/TransactionsView';
import { ConciliationView } from './components/Conciliation/ConciliationView.js';
import { OnboardingForm } from './components/OnboardingForm.js';
import { MoneyMovementsView } from './components/MoneyMovementsView.js';
import { PaperMovementsView } from './components/PaperMovementsView.js';
import { PricingForm } from './components/PricingForm.js';
import { V2ConciliationView } from './components/Conciliation/V2ConciliationView.js';
import { PayrollCalculatorView } from './components/FreemiumPayrollCalculator/PayrollCalculatorView.js';
import FrontChatInitializer from './components/FrontChatInitializer';
import { AccountingClientConfigsView } from './components/AccountingClientConfigsView.js'; // <-- New Import
import { EngineView } from './components/Engine/EngineView.js';
import { AccountantView } from './components/AccountantView.js';
import { SocketProvider } from './SocketContext';

const API_domain =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000/'
    : 'https://www.herofacturas.com/';

function LogoutComponent() {
  const auth = React.useContext(authContext);
  const history = useHistory();

  React.useEffect(() => {
    console.log('Logging out user:', auth.email);
    auth.signout(auth.email);
    console.log('Logout completed, redirecting to login...');
    history.push('/login');
  }, [auth, history]);

  return null;
}

function App() {
  const [team, setTeam] = useState();
  const [mobile, setMobile] = useState(false);
  const onboarding = window.location.search.includes('onboarding=true');
  let marginLeftMobileDependent = 200;
  useEffect(() => {
    enquireScreen((phone) => {
      setMobile(phone);
    });
    if (process.env.NODE_ENV === 'development') {
      document.title = 'DEV';
    } else {
      document.title = 'Hero';
    }
  }, []);
  {
    mobile ? (marginLeftMobileDependent = 8) : <></>;
  }

  return (
    <ProvideAuth>
      <AnalyticsProvider instance={analytics}>
        <SocketProvider>
          <Router>
            <div className='App'>
              <Switch>
                <LoggedInRoute path='/login'>
                  <LoginView API_domain={API_domain} />
                </LoggedInRoute>
                <Route path='/cookies'>
                  <CookieRoute />
                </Route>
                <PrivateRoute path='/reports'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <ReportsView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/cards'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <CardsView API_domain={API_domain} team={team} />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/bank'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <BankView API_domain={API_domain} />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/accountingInbox'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <AccountingInboxView API_domain={API_domain} />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/visualizations'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <VisualizationsView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/config'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <ConfigView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/help'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <HelpView API_domain={API_domain} />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/adminDashboard'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <AdminView API_domain={API_domain} />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/adminConfigs'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    <AccountingClientConfigsView
                      team={team}
                      onTeamSelect={setTeam}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/expenses'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <ExpensesView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                {/* <PrivateRoute path='/compras'> */}
                <PrivateRoute path='/ordenes'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <OrdenesView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>

                <PrivateRoute path='/dashboard'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <DashboardView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/requisitions'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <RequisitionsView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/quotes'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <QuotesView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/recibidos'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <RecibidosView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/invoices'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <FacturasView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/reconciliation'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    <ReconciliationView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/accounting'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    <AccountingFinancialStatementsView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/transactions'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <TransactionsView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/conciliation'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <ConciliationView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/conciliationv2'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    {/* <HomeHeader API_domain={API_domain} /> */}
                    <V2ConciliationView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/hr'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    <EmployeesView
                      API_domain={API_domain}
                      team={team}
                      onTeamSelect={setTeam}
                      mobile={mobile}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/moneyMovementsView'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    <MoneyMovementsView
                      API_domain={API_domain}
                      mobile={mobile}
                      team={team}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/paperMovementsView'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    <PaperMovementsView
                      API_domain={API_domain}
                      mobile={mobile}
                      team={team}
                      onTeamSelect={setTeam}
                    />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/engineView'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    <EngineView API_domain={API_domain} />
                  </Layout>
                </PrivateRoute>
                <PrivateRoute path='/accountantView'>
                  <HomeSiderTriage API_domain={API_domain} mobile={mobile} />
                  <Layout style={{ marginLeft: marginLeftMobileDependent }}>
                    <AccountantView API_domain={API_domain} />
                  </Layout>
                </PrivateRoute>

                <Route path='/sharedReport'>
                  <SharedReportView API_domain={API_domain} mobile={mobile} />
                </Route>
                <Route path='/sharedBox'>
                  <SharedBoxView API_domain={API_domain} mobile={mobile} />
                </Route>
                <Route path='/imageHoster'>
                  <ImageHosterView API_domain={API_domain} mobile={mobile} />
                </Route>
                <Route path='/meetingBooked'>
                  <MeetingBookedView API_domain={API_domain} mobile={mobile} />
                </Route>
                <Route path='/newCardPackage'>
                  <NewCardPackageView mobile={mobile} />
                </Route>
                <Route path='/updateObligation'>
                  <UpdateObligationView
                    API_domain={API_domain}
                    mobile={mobile}
                  />
                </Route>
                <Route path='/payrollApproval'>
                  <PayrollApprovalView API_domain={API_domain} />
                </Route>
                <Route path='/onboarding'>
                  <OnboardingForm API_domain={API_domain} />
                </Route>
                <Route path='/lafise'>
                  <PricingForm API_domain={API_domain} />
                </Route>
                <Route path='/payrollCalculator'>
                  <PayrollCalculatorView API_domain={API_domain} />
                </Route>
                <Route path='/logout'>
                  <LogoutComponent />
                </Route>
                <LoggedInRoute path='/'>
                  {/* <Route component={Home} /> */}
                </LoggedInRoute>
                {/*Remember this path has to go last*/}
              </Switch>
            </div>
          </Router>
          <PageGuide onboarding={onboarding} />
        </SocketProvider>
      </AnalyticsProvider>
      <FrontChatInitializer
        API_domain={API_domain}
        // chatId='b2da01b99ec877d2afcb4f6c30340049'
      />
    </ProvideAuth>
  );
}

export default App;
