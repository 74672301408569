import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { authContext } from '../ProvideAuth.js';

function CookieRoute(props) {
  const auth = useContext(authContext);
  auth.signin(() => {
    return false;
  });

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let endpoint = '';

  params.forEach(function (value, name) {
    if (name === 'endpoint') {
      endpoint = value; // This is the decoded string from encodeURIComponent
    }
  });

  // If `endpoint` starts with "/", you can redirect to `endpoint` directly.
  // Or you can just do '/' + endpoint if you prefer, but watch out for double-slashes.
  return <Redirect to={endpoint} />;
}

export { CookieRoute };