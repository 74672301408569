import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react';

import { Table, Affix, Button, Switch, message } from 'antd';
import {
  DownloadOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  closestCenter,
} from '@dnd-kit/core';
import {
  useSortable,
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import axios from 'axios';
import * as XLSX from 'xlsx';

import { authContext } from '../../ProvideAuth.js';
import HeroAxios from '../../helpers/HeroAxios';
import { FormattedUSD } from '../FormattedUSD.js';
import { AccountingFinancialStatementsModal } from './AccountingFinancialStatementsModal.js';
import { DefaultVisibleTooltip } from '../DefaultVisibleTooltip.js';
import { formatDateSpanishMed } from '../../utils';

export function BalanceSheetTable2(props) {
  const auth = useContext(authContext);

  // -------------------------
  // Expand/Collapse
  // -------------------------
  const [expandedKeys, setExpandedKeys] = useState([]);

  // -------------------------
  // Detail Modal
  // -------------------------
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});

  // -------------------------
  // Drag states
  // -------------------------
  const [dragEnabled, setDragEnabled] = useState(false);
  const [draggingNode, setDraggingNode] = useState(null);
  const [invalidDrop, setInvalidDrop] = useState(false);

  // -------------------------
  // The local array of nodes (with children)
  // -------------------------
  const [tableData, setTableData] = useState([]);

  // On mount or when props change, clone the incoming data
  useEffect(() => {
    if (!props.balanceSheetData || !Array.isArray(props.balanceSheetData)) {
      setTableData([]);
      return;
    }
    // Make a deep copy
    setTableData(JSON.parse(JSON.stringify(props.balanceSheetData)));
  }, [props.balanceSheetData]);

  // -------------------------
  // Pagination (sliding window for the months)
  // -------------------------
  const itemsPerPage = 5;
  // Make sure your time periods are in chronological order in props
  const sortedTimePeriods = useMemo(
    () => [...(props.balanceSheetRecentTimePeriods || [])],
    [props.balanceSheetRecentTimePeriods]
  );
  const totalMonths = sortedTimePeriods.length;
  const maxStartIndex = Math.max(totalMonths - itemsPerPage, 0);

  const [startIndex, setStartIndex] = useState(
    totalMonths > itemsPerPage ? totalMonths - itemsPerPage : 0
  );
  useEffect(() => {
    setStartIndex(totalMonths > itemsPerPage ? totalMonths - itemsPerPage : 0);
  }, [totalMonths, itemsPerPage]);

  const displayedTimePeriods = useMemo(() => {
    return sortedTimePeriods.slice(startIndex, startIndex + itemsPerPage);
  }, [startIndex, sortedTimePeriods, itemsPerPage]);

  // -------------------------
  // Convert tableData -> dataSource (slicing values for displayed months)
  // to feed into <Table />
  // -------------------------
  const createNestedData = useCallback(
    (nodes, parentKey = '') => {
      return nodes.map((node) => {
        // Use node.account_id if present, otherwise something unique
        let stableKey;
        if (node.account_id) {
          stableKey = String(node.account_id);
        } else {
          // Possibly the name or category
          stableKey = node.subcategory;
        }

        // Slice the node.values array to match displayed months
        const slicedValues = Array.isArray(node.values)
          ? node.values.slice(startIndex, startIndex + itemsPerPage)
          : [];

        // Build an object with { value1, value2, ... } for the columns
        const fieldMap = {};
        slicedValues.forEach((val, i) => {
          fieldMap[`value${i + 1}`] = val;
        });

        const newNode = {
          ...node,
          key: stableKey,
          parentKey,
          values: slicedValues,
          ...fieldMap,
        };

        if (node.children?.length) {
          newNode.children = createNestedData(node.children, stableKey);
        } else {
          delete newNode.children;
        }

        return newNode;
      });
    },
    [startIndex, itemsPerPage]
  );

  const dataSource = useMemo(() => {
    if (!tableData.length) return [];
    return createNestedData(tableData);
  }, [tableData, createNestedData]);

  // -------------------------
  // BFS to find node by key
  // -------------------------
  const findNodeInDataSource = useCallback(function findNode(arr, key) {
    for (const item of arr) {
      if (item.key === key) return item;
      if (item.children?.length) {
        const found = findNode(item.children, key);
        if (found) return found;
      }
    }
    return null;
  }, []);

  // -------------------------
  // Flatten the currently visible rows for the SortableContext
  // (only expanded rows are visible)
  // -------------------------
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  useEffect(() => setExpandedRowKeys(expandedKeys), [expandedKeys]);

  const flattenData = useCallback(
    (arr) => {
      const result = [];
      function dfs(list) {
        list.forEach((item) => {
          result.push(item.key);
          if (item.children && item.children.length && expandedRowKeys.includes(item.key)) {
            dfs(item.children);
          }
        });
      }
      dfs(arr);
      return result;
    },
    [expandedRowKeys]
  );

  const visibleRowKeys = useMemo(() => flattenData(dataSource), [dataSource, flattenData]);

  // -------------------------
  // Expand / Collapse
  // -------------------------
  const handleExpand = (expanded, record) => {
    if (!record.key) return;
    if (expanded) {
      setExpandedKeys((prev) => [...prev, record.key]);
    } else {
      setExpandedKeys((prev) => prev.filter((k) => k !== record.key));
    }
  };

  const handleCollapseAll = () => setExpandedKeys([]);
  const handleExpandAll = useCallback(() => {
    const gatherKeys = (arr) => {
      let results = [];
      arr.forEach((n) => {
        results.push(n.key);
        if (n.children?.length) results = results.concat(gatherKeys(n.children));
      });
      return results;
    };
    setExpandedKeys(gatherKeys(dataSource));
  }, [dataSource]);

  // -------------------------
  // Modal
  // -------------------------
  const showModal = (record, month) => {
    setModalContent({ ...record, month });
    setIsModalVisible(true);
  };

  // -------------------------
  // Columns
  // -------------------------
  const createColumns = useCallback(() => {
    const baseColumns = [
      {
        title: 'Cuenta',
        dataIndex: 'subcategory',
        key: 'subcategory',
        width: 400,
        render: (text, record) => {
          const isExpanded = expandedKeys.includes(record.key);
          return (
            <span style={{ fontWeight: isExpanded && record.children?.length ? 'bold' : 'normal' }}>
              {text}
            </span>
          );
        },
      },
    ];

    const timePeriodsWithMostRecentTransaction =
      props.timePeriodsWithMostRecentTransaction || {};

    const dynamicValueColumns = displayedTimePeriods.map((period, idx) => {
      const dataIndex = `value${idx + 1}`;
      const mostRecentTransactionTime = timePeriodsWithMostRecentTransaction[period] || null;
      const formattedTransactionTime = mostRecentTransactionTime
        ? formatDateSpanishMed(mostRecentTransactionTime)
        : 'No hay datos';

      return {
        title: (
          <DefaultVisibleTooltip
            title={<>Actualizado el {formattedTransactionTime}</>}
            defaultVisible={period === sortedTimePeriods[sortedTimePeriods.length - 1]}
          >
            <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
              {period || `Value ${idx + 1}`}
            </div>
          </DefaultVisibleTooltip>
        ),
        dataIndex,
        key: dataIndex,
        align: 'right',
        render: (value, record) => {
          if (value == null) return '';

          // If top-level or special category, do not open the detail modal
          // (same approach as IncomeStatement: only show link if it's not a "big category" row)
          const topLevelCats = ['Assets', 'Liabilities', 'Equity'];

          if (!topLevelCats.includes(record.category)) {
            // show link to detail
            return (
              <Button
                type="link"
                style={{ paddingRight: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  showModal(record, period);
                }}
              >
                <FormattedUSD total={value} />
              </Button>
            );
          }

          // If it's a parent with children, we can just show bold or normal text
          const isExpanded = expandedKeys.includes(record.key);
          const fontWeight = isExpanded ? 'bold' : 'normal';
          return (
            <span style={{ display: 'block', textAlign: 'right', fontWeight }}>
              <FormattedUSD total={value} />
            </span>
          );
        },
      };
    });

    return [...baseColumns, ...dynamicValueColumns];
  }, [
    expandedKeys,
    displayedTimePeriods,
    props.timePeriodsWithMostRecentTransaction,
    sortedTimePeriods,
  ]);

  const columns = useMemo(() => createColumns(), [createColumns]);

  // -------------------------
  // Drag & Drop
  // -------------------------
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 },
    })
  );

  const handleDragStart = ({ active }) => {
    if (!active) return;
    const node = findNodeInDataSource(dataSource, active.id);
    if (node) {
      setDraggingNode(node);
    }
    // Reset invalidDrop at drag start
    setInvalidDrop(false);
  };

  const handleDragOver = ({ active, over }) => {
    if (!active || !over) {
      setInvalidDrop(false);
      return;
    }
    const dragKey = active.id;
    const hoverKey = over.id;

    const dragNode = findNodeInDataSource(dataSource, dragKey);
    const hoverNode = findNodeInDataSource(dataSource, hoverKey);

    if (!dragNode || !hoverNode) {
      setInvalidDrop(false);
      return;
    }

    // If they have different parents => invalid
    if (dragNode.parentKey !== hoverNode.parentKey) {
      setInvalidDrop(true);
    } else {
      setInvalidDrop(false);
    }
  };

  const handleDragEnd = ({ active, over }) => {
    setDraggingNode(null);
    setInvalidDrop(false);

    if (!over || active.id === over.id) {
      return;
    }

    const dragKey = active.id;
    const hoverKey = over.id;

    const dragNode = findNodeInDataSource(dataSource, dragKey);
    const hoverNode = findNodeInDataSource(dataSource, hoverKey);

    if (!dragNode || !hoverNode) {
      return;
    }
    if (dragNode.parentKey !== hoverNode.parentKey) {
      message.error('Solo puedes reordenar dentro de la misma cuenta madre.');
      return;
    }

    // Rebuild new data with updated order
    const parentKey = dragNode.parentKey;
    const newData = JSON.parse(JSON.stringify(dataSource));

    function findParentNode(arr, pk) {
      // If no parentKey, treat top-level as the parent
      if (!pk) {
        return { children: arr };
      }
      for (const node of arr) {
        if (node.key === pk) return node;
        if (node.children?.length) {
          const found = findParentNode(node.children, pk);
          if (found) return found;
        }
      }
      return null;
    }

    const parentNode = findParentNode(newData, parentKey);
    if (!parentNode) {
      return;
    }
    const siblings = parentNode.children || [];
    const dragIndex = siblings.findIndex((s) => s.key === dragKey);
    const hoverIndex = siblings.findIndex((s) => s.key === hoverKey);

    if (dragIndex < 0 || hoverIndex < 0) {
      return;
    }

    const updatedSiblings = arrayMove(siblings, dragIndex, hoverIndex);
    parentNode.children = updatedSiblings;

    // Reflect changes in our local tableData
    setTableData(newData);

    // Save to backend
    HeroAxios({
      method: 'post',
      url: `${props.API_domain}/saveNewOrder`,
      data: {
        data: newData,
        client_id: props.accountingClientCompany,
        // (If needed, you can add { type: 'balanceSheet' } or similar)
      },
    })
      .then(() => {
        message.success('Nuevo orden guardado');
      })
      .catch((err) => {
        console.error('[onDragEnd] error saving:', err);
        message.error('Error guardando el nuevo orden');
      });
  };

  // Custom row that uses dnd-kit’s useSortable
  const SortableRow = (props) => {
    const record = props.record || {};

    // Disable drag for top-level categories
    const disableDrag = ['Assets', 'Liabilities', 'Equity'].includes(record.subcategory);

    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props['data-row-key'],
      disabled: !dragEnabled || disableDrag,
    });

    const style = {
      ...props.style,
      transition,
      transform: CSS.Translate.toString(transform),
      cursor: dragEnabled && !disableDrag ? 'move' : 'default',
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999,
            opacity: 0.9,
            // Color row if drop is invalid
            ...(invalidDrop && {
              backgroundColor: 'rgba(255,0,0,0.1)',
            }),
          }
        : {}),
    };

    return (
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {props.children}
      </tr>
    );
  };

  // -------------------------
  // Export to Excel
  // -------------------------
  const exportToExcel = useCallback(() => {
    // Flatten the entire tableData (not just displayed months)
    function traverseAllNodes(nodes, level = 0, output = []) {
      nodes.forEach((node) => {
        const row = {
          Subcategory: ' '.repeat(level * 4) + (node.subcategory || ''),
        };
        sortedTimePeriods.forEach((period, idx) => {
          const val = Array.isArray(node.values) ? node.values[idx] : null;
          row[period] = val ?? 0;
        });
        output.push(row);

        if (Array.isArray(node.children) && node.children.length > 0) {
          traverseAllNodes(node.children, level + 1, output);
        }
      });
      return output;
    }

    const flattenedRows = traverseAllNodes(tableData);

    const worksheet = XLSX.utils.json_to_sheet(flattenedRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Balance Sheet');
    XLSX.writeFile(workbook, 'balance_sheet.xlsx');

    // (If you want to track usage in your backend)
    axios({
      method: 'post',
      url: props.API_domain + 'trackFrontEndEvent',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        event: 'exportBalanceSheetToExcel',
        properties: {},
      },
    }).catch((error) => {
      console.error('Error tracking event:', error);
    });
  }, [tableData, sortedTimePeriods, props.API_domain, auth]);

  // -------------------------
  // Rendering
  // -------------------------
  return (
    <div style={{ textAlign: 'left', width: '100%' }}>
      <Affix offsetTop={0}>
        <div
          style={{
            marginBottom: '10px',
            backgroundColor: 'white',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Button style={{ margin: 4 }} onClick={handleCollapseAll}>
              <FullscreenExitOutlined />
              Colapsar
            </Button>
            <Button style={{ margin: 4 }} onClick={handleExpandAll}>
              <FullscreenOutlined />
              Expandir
            </Button>
            <div style={{ marginLeft: 8, display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 4 }}>Mover Cuentas:</span>
              <Switch checked={dragEnabled} onChange={setDragEnabled} />
            </div>
          </div>
          <div>
            <Button style={{ marginLeft: 12, textAlign: 'right' }} onClick={exportToExcel}>
              <DownloadOutlined /> Excel
            </Button>
          </div>
        </div>
      </Affix>

      {/* Pagination Controls */}
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Button
          icon={<LeftOutlined />}
          onClick={() => setStartIndex((prev) => Math.max(prev - 1, 0))}
          disabled={startIndex === 0}
          style={{ marginRight: '8px' }}
        >
          Más viejo
        </Button>
        <Button
          icon={<RightOutlined />}
          onClick={() => setStartIndex((prev) => Math.min(prev + 1, maxStartIndex))}
          disabled={startIndex >= maxStartIndex}
          style={{ marginLeft: '8px' }}
        >
          Más reciente
        </Button>
      </div>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        {/* Only pass the currently visible row keys to SortableContext */}
        <SortableContext items={visibleRowKeys} strategy={verticalListSortingStrategy}>
          <Table
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            onRow={(record) => ({
              record,
            })}
            components={{
              body: {
                row: SortableRow,
              },
            }}
            expandable={{
              expandedRowKeys: expandedKeys,
              onExpand: handleExpand,
              expandRowByClick: true,
              rowExpandable: (record) => !!record.children?.length,
            }}
            style={{ width: '1200px', minWidth: '800px' }}
          />
        </SortableContext>
      </DndContext>

      <AccountingFinancialStatementsModal
        API_domain={props.API_domain}
        accountingClientCompany={props.accountingClientCompany}
        modalContent={modalContent}
        isModalVisible={isModalVisible}
        title="Additional Information"
        onOk={() => setIsModalVisible(false)}
        onCancel={() => {
          setIsModalVisible(false);
          setModalContent({});
        }}
      />
    </div>
  );
}
