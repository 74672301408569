import { Select } from 'antd';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { authContext } from '../../ProvideAuth.js';

function AdminCompanySelect(props) {
  const [companyOptions, setCompanyOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const auth = useContext(authContext);

  useEffect(() => {
    setLoading(true);
    axios({
      method: 'post',
      url: props.API_domain + 'adminFetchAllCompanyOptions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        setCompanyOptions(
          response.data.map((company) => ({
            label: company[0],
            value: company[1],
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  return (
    <Select
      showSearch
      options={companyOptions}
      placeholder='Compania'
      loading={loading}
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={props.onCompanySelect}
    />
  );
}

export { AdminCompanySelect };
