/*************************************************
 * BookkeepingProgressDashboard.jsx
 *************************************************/
import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  Card,
  Row,
  Col,
  Progress,
  Statistic,
  Tooltip,
  message,
  Tag,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import HeroAxios from '../helpers/HeroAxios';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth';
import { formatDateSpanishMed } from './../utils';
import { DateFilterDropdown } from './DateFilterDropdown'; // <-- import your component

// Utility function: safely compute ratio -> percentage.
function safePercent(numerator, denominator) {
  if (!denominator || denominator === 0) return 0;
  return Math.round((numerator / denominator) * 100);
}

/**
 * This component fetches data from:
 * 1) Bot Expenses    => /getBotExpenses
 * 2) FE Emitidas     => /getFEs (docs_type=fe_emitidas)
 * 3) FE Recibidas    => /getFEs (docs_type=fe_recibidas)
 * 4) All SOTs        => /getSourcesOfTruth -> then /getSotTransactions for each
 *
 * Then it calculates how many items are:
 *  - total items
 *  - uncommitted
 *  - related
 *  - categorized
 *  - categorizedHighConfidence (new)
 *  - committed (which we display as "contabilizados")
 *
 * The tasks we want to measure are:
 *  - Relationship (i.e., "related" out of total)
 *  - Classification (i.e., "categorized" out of total)
 *  - High Confidence Classification (just a substat, no progress bar)
 *  - Commit (i.e., "contabilizados" out of total)
 *
 * We'll display a big "Estado General de la Contabilidad" card (wide),
 * and then the other data sets in a grid below.
 */
const BookkeepingProgressDashboard = ({ API_domain }) => {
  const auth = useContext(authContext);

  // -- MASTER states for each data set --
  const [botExpenses, setBotExpenses] = useState([]);
  const [feEmitidas, setFeEmitidas] = useState([]);
  const [latestUpdateEmitidas, setLatestUpdateEmitidas] = useState('');
  const [feRecibidas, setFeRecibidas] = useState([]);
  const [latestUpdateRecibidas, setLatestUpdateRecibidas] = useState('');
  const [sotData, setSotData] = useState([]);

  // -- Loading states for each data fetch --
  const [loadingBotExpenses, setLoadingBotExpenses] = useState(false);
  const [loadingFEsEmitidas, setLoadingFEsEmitidas] = useState(false);
  const [loadingFEsRecibidas, setLoadingFEsRecibidas] = useState(false);
  const [loadingSOTs, setLoadingSOTs] = useState(false);

  const [dateRange, setDateRange] = useState(null);

  // Format the "latestUpdate" fields for Recibidas/Emitidas
  const formattedLatestUpdateRecibidas =
    latestUpdateRecibidas && moment(latestUpdateRecibidas).isValid()
      ? formatDateSpanishMed(moment(latestUpdateRecibidas).toDate())
      : 'N/A';

  const formattedLatestUpdateEmitidas =
    latestUpdateEmitidas && moment(latestUpdateEmitidas).isValid()
      ? formatDateSpanishMed(moment(latestUpdateEmitidas).toDate())
      : 'N/A';

  //======================================================
  // 1) Fetch BotExpenses
  //======================================================
  const fetchBotExpenses = async () => {
    try {
      setLoadingBotExpenses(true);
      const response = await HeroAxios({
        method: 'post',
        url: 'getBotExpenses',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      if (response.status === 200) {
        const data = response.data.expenses.map((item) => ({
          ...item,
          committed: item.committed ? item.committed : false,
        }));
        setBotExpenses(data);
      } else {
        message.error('Error al traer los gastos (BotExpenses).');
      }
    } catch (error) {
      console.error('Error fetching bot expenses:', error);
      message.error('Error consiguiendo los gastos (BotExpenses).');
    } finally {
      setLoadingBotExpenses(false);
    }
  };

  //======================================================
  // 2) Fetch FE (Emitidas & Recibidas)
  //======================================================
  const fetchFEs = async (docsType) => {
    if (docsType === 'fe_emitidas') setLoadingFEsEmitidas(true);
    if (docsType === 'fe_recibidas') setLoadingFEsRecibidas(true);

    try {
      const response = await axios({
        method: 'post',
        url: API_domain + 'getFEs',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {
          docs_type: docsType, // 'fe_emitidas' or 'fe_recibidas'
        },
      });
      if (response.status === 200) {
        const transformedData = response.data.fe_list.map((item) => ({
          ...item,
          committed: item.committed !== undefined ? item.committed : false,
        }));

        if (docsType === 'fe_emitidas') {
          setFeEmitidas(transformedData);
          setLatestUpdateEmitidas(response.data.latest_update_emitidos || '');
        } else {
          setFeRecibidas(transformedData);
          setLatestUpdateRecibidas(response.data.latest_update_recibidos || '');
        }
      } else {
        message.error('Error al obtener FEs ' + docsType);
      }
    } catch (error) {
      console.error('Error fetching FEs:', error);
      message.error('Error consiguiendo las facturas electrónicas ' + docsType);
    } finally {
      if (docsType === 'fe_emitidas') setLoadingFEsEmitidas(false);
      if (docsType === 'fe_recibidas') setLoadingFEsRecibidas(false);
    }
  };

  //======================================================
  // 3) Fetch SOT => For each SOT => fetchSotTransactions
  //======================================================
  const fetchSOTsAndTransactions = async () => {
    try {
      setLoadingSOTs(true);
      const resp = await axios({
        method: 'get',
        url: API_domain + 'getSourcesOfTruth',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      const allSots = resp.data;
      const newSotData = [];

      for (const oneSot of allSots) {
        try {
          const txnResp = await axios({
            method: 'post',
            url: API_domain + 'getSotTransactions',
            auth: {
              username: auth.email,
              password: auth.token,
            },
            data: { sot_id: oneSot.sources_of_truth_id },
          });
          const latestUpd = txnResp.data.latest_update;
          const transactions = txnResp.data.sot_transactions_list.map((t) => ({
            ...t,
            amount: parseFloat(t.amount),
            committed: !!t.committed,
          }));

          newSotData.push({
            ...oneSot,
            transactions,
            latest_update: latestUpd || '',
          });
        } catch (e) {
          console.error('Error fetching SOT TXNs for ', oneSot, e);
        }
      }
      setSotData(newSotData);
    } catch (error) {
      console.error('Error fetching Sources of Truth:', error);
      message.error('Error fetching SOT data');
    } finally {
      setLoadingSOTs(false);
    }
  };

  //======================================================
  // Main useEffect to fetch everything upon mount
  //======================================================
  useEffect(() => {
    fetchBotExpenses();
    fetchFEs('fe_emitidas');
    fetchFEs('fe_recibidas');
    fetchSOTsAndTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [API_domain, auth.email, auth.token]);

  //======================================================
  // Helper to decide if an item is within the chosen date range
  //======================================================
  const inDateRange = (itemDate) => {
    if (!dateRange || dateRange.length !== 2) return true; // if no filter is selected
    const [start, end] = dateRange;
    const mDate = moment(itemDate);
    if (!mDate.isValid()) return false;
    if (mDate.isBefore(start, 'day')) return false;
    if (mDate.isAfter(end, 'day')) return false;
    return true;
  };

  //======================================================
  // Compute tasks for each data set
  //======================================================
  const computeTasks = (arr, dateField) => {
    // Filter by the user-chosen date range
    const filtered = arr.filter((item) => {
      if (!item[dateField]) return false;
      return inDateRange(item[dateField]);
    });

    const total = filtered.length;
    const committedCount = filtered.filter((e) => e.committed).length;
    const uncommittedCount = total - committedCount;

    // "Categorized" = has account_id
    const categorizedCount = filtered.filter((e) => e.account_id).length;

    // "Categorized High Confidence" = has account_id AND account_id_confidence in {0,1}
    const categorizedHighConfidenceCount = filtered.filter(
      (e) =>
        e.account_id &&
        (e.account_id_confidence == 0 || e.account_id_confidence == 1)
    ).length;

    // "Related" = has relationship array (regardless of committed)
    const relatedCount = filtered.filter(
      (e) =>
        e.relationship &&
        e.relationship.length > 0 &&
        e.relationship.some((rel) => rel.movement_table_name !== 'entries')
    ).length;

    return {
      total,
      committed: committedCount,
      uncommitted: uncommittedCount,
      categorized: categorizedCount,
      categorizedHighConfidence: categorizedHighConfidenceCount,
      related: relatedCount,
    };
  };

  //======================================================
  // Use memo to compute “progress” for each dataset
  //======================================================
  const botStats = useMemo(() => {
    return computeTasks(botExpenses, 'receipt_date');
  }, [botExpenses, dateRange]);

  const feEmitidasStats = useMemo(() => {
    return computeTasks(feEmitidas, 'emission_date');
  }, [feEmitidas, dateRange]);

  const feRecibidasStats = useMemo(() => {
    return computeTasks(feRecibidas, 'emission_date');
  }, [feRecibidas, dateRange]);

  const sotStatsArray = useMemo(() => {
    return sotData.map((oneSot) => {
      const stats = computeTasks(oneSot.transactions, 'txn_date');
      return {
        sourceId: oneSot.sources_of_truth_id,
        clientFacingName: oneSot.client_facing_name,
        sourceZohoName: oneSot.source_zoho_name,
        sourceName: oneSot.source_name,
        latestUpdate: oneSot.latest_update,
        type_of_mm: oneSot.type_of_mm,
        ...stats,
      };
    });
  }, [sotData, dateRange]);

  const totalSotStats = useMemo(() => {
    return sotStatsArray.reduce(
      (acc, s) => {
        acc.total += s.total;
        acc.committed += s.committed;
        acc.uncommitted += s.uncommitted;
        acc.categorized += s.categorized;
        acc.related += s.related;
        acc.categorizedHighConfidence += s.categorizedHighConfidence;
        return acc;
      },
      {
        total: 0,
        committed: 0,
        uncommitted: 0,
        categorized: 0,
        related: 0,
        categorizedHighConfidence: 0,
      }
    );
  }, [sotStatsArray]);

  //======================================================
  // Grand total across all data sets
  //======================================================
  const grandTotal = useMemo(() => {
    const total =
      botStats.total +
      feEmitidasStats.total +
      feRecibidasStats.total +
      totalSotStats.total;

    const committed =
      botStats.committed +
      feEmitidasStats.committed +
      feRecibidasStats.committed +
      totalSotStats.committed;

    const uncommitted =
      botStats.uncommitted +
      feEmitidasStats.uncommitted +
      feRecibidasStats.uncommitted +
      totalSotStats.uncommitted;

    const categorized =
      botStats.categorized +
      feEmitidasStats.categorized +
      feRecibidasStats.categorized +
      totalSotStats.categorized;

    const related =
      botStats.related +
      feEmitidasStats.related +
      feRecibidasStats.related +
      totalSotStats.related;

    const categorizedHighConfidence =
      botStats.categorizedHighConfidence +
      feEmitidasStats.categorizedHighConfidence +
      feRecibidasStats.categorizedHighConfidence +
      totalSotStats.categorizedHighConfidence;

    return {
      total,
      committed,
      uncommitted,
      categorized,
      related,
      categorizedHighConfidence,
    };
  }, [botStats, feEmitidasStats, feRecibidasStats, totalSotStats]);

  //======================================================
  // A helper to render each progress line
  //======================================================
  const renderLine = ({
    label,
    percent,
    count,
    successPercent = 0,
    mainColor = 'var(--green-normal)',
    successColor = 'var(--green-normal)',
    showPinkBreakdown = false,
    pinkValue = 0,
    mainValue = 0,
  }) => {
    // We'll use a grid layout so that the three lines align nicely and share the same widths
    return (
      <div style={{ marginBottom: '16px' }}>
        <div
          style={{
            // fontWeight: 'bold',
            marginBottom: '4px',
            textAlign: 'left',
            color: 'var(--grey-dark)',
          }}
        >
          {label}
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 40px 80px',
            columnGap: '12px',
            alignItems: 'center',
          }}
        >
          <Progress
            percent={percent}
            strokeWidth={12}
            showInfo={false}
            status='active'
            strokeColor={mainColor}
            success={{
              percent: successPercent,
              strokeColor: successColor,
            }}
          />
          <div style={{ textAlign: 'right' }}>{percent}%</div>

          {/* If showPinkBreakdown = true, we show (highConf) (lowConf) instead of a single count */}
          {showPinkBreakdown ? (
            <div style={{ textAlign: 'right' }}>
              {/* High Confidence portion in black, Low Confidence in pink */}
              {'  '}
              {pinkValue > 0 && (
                <Tooltip title='Clasificados autónomamente ' placement='top'>
                  <Tag color='pink'>{pinkValue}</Tag>
                </Tooltip>
              )}
              {mainValue}
            </div>
          ) : (
            <div style={{ textAlign: 'right' }}>{count}</div>
          )}
        </div>
      </div>
    );
  };

  //======================================================
  // Renders the three progress lines (contabilizados, clasificados, relacionados)
  // with the new layout:
  //   Label
  //   [Progress bar] [percentage] [counts]
  //======================================================
  const renderProgressCards = (stats) => {
    const {
      total,
      committed,
      categorized,
      related,
      categorizedHighConfidence,
    } = stats;

    // Use total as the denominator for all percentages
    const comPercent = safePercent(committed, total);
    const catPercent = safePercent(categorized, total);
    const relPercent = safePercent(related, total);

    // For stacked color in "Clasificados"
    const catHighConfPercent = safePercent(categorizedHighConfidence, total);
    const catLowConf = categorized - categorizedHighConfidence;
    const catHighConf = categorizedHighConfidence;

    return (
      <div style={{ marginTop: '24px' }}>
        {/* Contabilizados (single color = same purple as the stacked portion) */}
        {renderLine({
          label: 'Contabilizados',
          percent: comPercent,
          count: committed,
          mainColor: 'var(--green-normal)', // bullet #2: same purple as the success portion
          successColor: 'var(--green-normal)', // not really used since successPercent=0
        })}

        {/* Clasificados (stacked color bar) */}
        {renderLine({
          label: 'Clasificados',
          percent: catPercent,
          // We'll show the portion that is high confidence as success,
          // and the portion that is low confidence as the main color.
          successPercent: catHighConfPercent,
          mainColor: 'var(--pink-light)',
          successColor: 'var(--green-normal)',
          // We'll display the breakdown of highConf vs lowConf next to it
          showPinkBreakdown: true,
          mainValue: catHighConf, // black text
          pinkValue: catLowConf, // pink text
        })}

        {/* Relacionados (single color = same purple) */}
        {renderLine({
          label: 'Relacionados',
          percent: relPercent,
          count: related,
          mainColor: 'var(--green-normal)', // bullet #2: same purple as success portion
          successColor: 'var(--green-normal)', // successPercent=0 anyway
        })}
      </div>
    );
  };

  //======================================================
  // Render
  //======================================================
  return (
    <DashboardContainer>
      {/* ----------------------------------------------- */}
      {/* 1) Row for the date range filter at the top      */}
      {/* ----------------------------------------------- */}
      <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <Card
            style={{ borderRadius: '10px' }}
            bodyStyle={{ padding: '12px 16px' }}
            bordered={false}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '16px' }}>
                <strong>Filtro por Fechas:</strong>
              </span>
              <DateFilterDropdown
                selectedKeys={dateRange}
                setSelectedKeys={(val) => setDateRange(val?.[0] || [])}
                confirm={() => {}}
                clearFilters={() => setDateRange(null)}
              />
            </div>
          </Card>
        </Col>
      </Row>

      {/* Row for the big "Estado General" card */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            style={{
              marginBottom: '24px',
              borderRadius: '10px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            }}
            loading={
              loadingBotExpenses ||
              loadingFEsEmitidas ||
              loadingFEsRecibidas ||
              loadingSOTs
            }
            title={
              <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                Estado General de la Contabilidad
                <Tooltip
                  title={`Los datos se filtran por la fecha seleccionada en el rango`}
                  placement='right'
                >
                  <QuestionCircleIcon>
                    <QuestionCircleOutlined />
                  </QuestionCircleIcon>
                </Tooltip>
              </div>
            }
          >
            <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
              <Col span={8}>
                <Statistic
                  title='Contabilizados'
                  value={grandTotal.committed}
                  valueStyle={{
                    fontSize: '20px',
                    color: 'var(--green-normal)',
                  }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title='Pendientes'
                  value={grandTotal.uncommitted}
                  valueStyle={{
                    fontSize: '20px',
                    color: 'var(--yellow-dark)',
                  }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title='Total Movimientos'
                  value={grandTotal.total}
                  valueStyle={{ fontSize: '20px' }}
                />
              </Col>
            </Row>
            {renderProgressCards(grandTotal)}
          </Card>
        </Col>
      </Row>

      {/* Row for the other cards in a grid pattern */}
      <Row gutter={[16, 16]}>
        {/* FE Recibidas */}
        <Col xs={24} sm={12} md={12} lg={12}>
          <Card
            loading={loadingFEsRecibidas}
            style={{ marginBottom: '24px', borderRadius: '10px' }}
            title={
              <div>
                <div>🧾 FE Recibidas</div>
                <div
                  style={{
                    fontSize: '12px',
                    color: 'var(--grey-dark)',
                    textAlign: 'left',
                    marginTop: 4,
                  }}
                >
                  Actualizado: {formattedLatestUpdateRecibidas}
                </div>
              </div>
            }
          >
            <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
              <Col span={8}>
                <Statistic
                  title='Contabilizados'
                  value={feRecibidasStats.committed}
                  valueStyle={{ color: 'var(--green-normal)' }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title='Pendientes'
                  value={feRecibidasStats.uncommitted}
                  valueStyle={{ color: 'var(--yellow-dark)' }}
                />
              </Col>
              <Col span={8}>
                <Statistic title='Movimientos' value={feRecibidasStats.total} />
              </Col>
            </Row>
            {renderProgressCards(feRecibidasStats)}
          </Card>
        </Col>

        {/* FE Emitidas */}
        <Col xs={24} sm={12} md={12} lg={12}>
          <Card
            loading={loadingFEsEmitidas}
            style={{ marginBottom: '24px', borderRadius: '10px' }}
            title={
              <div>
                <div>🧾 FE Emitidas</div>
                <div
                  style={{
                    fontSize: '12px',
                    color: 'var(--grey-dark)',
                    textAlign: 'left',
                    marginTop: 4,
                  }}
                >
                  Actualizado: {formattedLatestUpdateEmitidas}
                </div>
              </div>
            }
          >
            <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
              <Col span={8}>
                <Statistic
                  title='Contabilizados'
                  value={feEmitidasStats.committed}
                  valueStyle={{ color: 'var(--green-normal)' }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title='Pendientes'
                  value={feEmitidasStats.uncommitted}
                  valueStyle={{ color: 'var(--yellow-dark)' }}
                />
              </Col>
              <Col span={8}>
                <Statistic title='Movimientos' value={feEmitidasStats.total} />
              </Col>
            </Row>
            {renderProgressCards(feEmitidasStats)}
          </Card>
        </Col>

        {/* Bot de Gastos */}
        <Col xs={24} sm={12} md={12} lg={12}>
          <Card
            loading={loadingBotExpenses}
            style={{ marginBottom: '24px', borderRadius: '10px' }}
            title={
              <div>
                <div>📱 Bot de Gastos</div>
              </div>
            }
          >
            <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
              <Col span={8}>
                <Statistic
                  title='Contabilizados'
                  value={botStats.committed}
                  valueStyle={{ color: 'var(--green-normal)' }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title='Pendientes'
                  value={botStats.uncommitted}
                  valueStyle={{ color: 'var(--yellow-dark)' }}
                />
              </Col>
              <Col span={8}>
                <Statistic title='Movimientos' value={botStats.total} />
              </Col>
            </Row>
            {renderProgressCards(botStats)}
          </Card>
        </Col>

        {/* SOT Cards (multiple) */}
        {sotStatsArray.map((sot) => {
          const emoji =
            sot.type_of_mm === 'card'
              ? '💳 '
              : sot.type_of_mm === 'bank'
              ? '🏦 '
              : '';

          // Build the label with the emoji prepended
          const label =
            emoji +
            (sot.clientFacingName ||
              sot.sourceZohoName ||
              sot.sourceName ||
              'SOT');
          const latestUpdate =
            sot.latestUpdate && moment(sot.latestUpdate).isValid()
              ? formatDateSpanishMed(moment(sot.latestUpdate).toDate())
              : 'N/A';

          return (
            <Col xs={24} sm={12} md={12} lg={12} key={sot.sourceId}>
              <Card
                loading={loadingSOTs}
                style={{ marginBottom: '24px', borderRadius: '10px' }}
                title={
                  <div>
                    <div>{label}</div>
                    <div
                      style={{
                        fontSize: '12px',
                        color: 'var(--grey-dark)',
                        textAlign: 'left',
                        marginTop: 4,
                      }}
                    >
                      Actualizado: {latestUpdate}
                    </div>
                  </div>
                }
              >
                <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
                  <Col span={8}>
                    <Statistic
                      title='Contabilizados'
                      value={sot.committed}
                      valueStyle={{ color: 'var(--green-normal)' }}
                    />
                  </Col>
                  <Col span={8}>
                    <Statistic
                      title='Pendientes'
                      value={sot.uncommitted}
                      valueStyle={{ color: 'var(--yellow-dark)' }}
                    />
                  </Col>
                  <Col span={8}>
                    <Statistic title='Movimientos' value={sot.total} />
                  </Col>
                </Row>
                {renderProgressCards(sot)}
              </Card>
            </Col>
          );
        })}
      </Row>
    </DashboardContainer>
  );
};

export { BookkeepingProgressDashboard };

/*************************************************
 * Styled Components
 *************************************************/
const DashboardContainer = styled.div`
  margin: 24px auto;
  min-width: 1000px;
  max-width: 1200px;
  padding: 0 12px;

  /* Example pink variable to keep the style consistent. 
     Adjust as needed or define it globally. */
  --pink-light: #ffb3d9;

  .ant-statistic-title {
    font-size: 14px;
  }
  .ant-statistic-content-value {
    font-size: 22px;
  }
`;

const QuestionCircleIcon = styled.span`
  margin-left: 8px;
  cursor: pointer;
  font-size: 16px;
  color: #aaa;
`;
