// client/src/components/AssignedAccountantsTable.js

import React, { useEffect, useState } from 'react';
import { Table, Button, Select, message, Space, Checkbox } from 'antd';
import HeroAxios from '../../helpers/HeroAxios';

export function AssignedAccountantsTable() {
    const [assignments, setAssignments] = useState([]);
    const [users, setUsers] = useState([]);
    const [accountingClients, setAccountingClients] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [showUnassignedOnly, setShowUnassignedOnly] = useState(false);

    useEffect(() => {
        fetchAssignments();
        fetchUsers();
        fetchAccountingClients();
    }, []);

    const fetchAssignments = async () => {
        try {
            const response = await HeroAxios.get('/roles/assigned_accountants');
            setAssignments(response.data);
        } catch (error) {
            console.error('Error fetching assignments:', error);
            const errorMessage = error.response?.data?.message || 'Error al obtener las asignaciones';
            if (error.response?.status === 403) {
                message.error('No tiene permisos para ver las asignaciones');
            } else {
                message.error(errorMessage);
            }
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await HeroAxios.get('/roles/accountants');
            // Sort alphabetically by name
            const sorted = response.data.sort((a, b) => a.name.localeCompare(b.name));
            setUsers(sorted);
        } catch (error) {
            console.error('Error fetching accountants:', error);
            message.error('Error al obtener los contadores');
        }
    };

    const fetchAccountingClients = async () => {
        try {
            const response = await HeroAxios.get('/getAccountingClients');
            // Sort alphabetically by client name
            const sorted = response.data.sort((a, b) => a.name.localeCompare(b.name));
            setAccountingClients(sorted);
        } catch (error) {
            console.error('Error fetching accounting clients:', error);
            message.error('Error al obtener los clientes contables');
        }
    };

    // If "Mostrar compañias sin contador" is checked,
    // filter out any clients that already appear in assignments
    const displayedClients = showUnassignedOnly
        ? accountingClients.filter(
            (client) => !assignments.some((a) => a.accounting_client_id === client.id)
        )
        : accountingClients;

    const handleCreate = async () => {
        if (!selectedUser || !selectedClient) {
            message.warning('Por favor seleccione un contador y un cliente');
            return;
        }

        try {
            await HeroAxios.post('/roles/assign_accountant', {
                user_id: selectedUser,
                accounting_client_id: selectedClient,
            });
            message.success('Asignación creada exitosamente');
            setSelectedUser(null);
            setSelectedClient(null);
            fetchAssignments();
        } catch (error) {
            console.error('Error creating assignment:', error);
            const errorData = error.response?.data;

            if (error.response?.status === 403) {
                message.error('No tiene permisos para crear asignaciones');
            } else if (errorData?.error_type === 'validation_error') {
                message.error(errorData.message || 'Error de validación');
            } else if (errorData?.error_type === 'database_error') {
                message.error('Error en la base de datos: ' + (errorData.message || ''));
            } else {
                message.error('Error al crear la asignación');
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            await HeroAxios.delete(`/roles/assigned_accountants/${id}`);
            message.success('Asignación eliminada');
            fetchAssignments();
        } catch (error) {
            console.error('Error deleting assignment:', error);
            if (error.response?.status === 403) {
                message.error('No tiene permisos para eliminar asignaciones');
            } else {
                const errorMessage = error.response?.data?.message || 'Error al eliminar la asignación';
                message.error(errorMessage);
            }
        }
    };

    const columns = [
        {
            title: 'Contador',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: 'Cliente Contable',
            dataIndex: 'accounting_client_name',
            key: 'accounting_client_name',
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (_, record) => (
                <Button danger onClick={() => handleDelete(record.id)}>
                    Eliminar
                </Button>
            ),
        },
    ];

    return (
        <div>
            <Space direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
                <Space align="start" style={{ width: '100%' }}>
                    <Space>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: 300 }}
                            placeholder="Seleccione un contador"
                            value={selectedUser}
                            onChange={setSelectedUser}
                            allowClear
                        >
                            {users.map((user) => (
                                <Select.Option key={user.id} value={user.id}>
                                    {user.name} ({user.email})
                                </Select.Option>
                            ))}
                        </Select>

                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: 300 }}
                            placeholder="Seleccione un cliente contable"
                            value={selectedClient}
                            onChange={setSelectedClient}
                            allowClear
                        >
                            {displayedClients.map((client) => (
                                <Select.Option key={client.id} value={client.id}>
                                    {client.name}
                                </Select.Option>
                            ))}
                        </Select>

                        <Button type="primary" onClick={handleCreate}>
                            Asignar Contador
                        </Button>

                        <Checkbox
                            checked={showUnassignedOnly}
                            onChange={(e) => setShowUnassignedOnly(e.target.checked)}
                        >
                            Mostrar compañías sin contador
                        </Checkbox>
                    </Space>
                </Space>
            </Space>

            <Table columns={columns} dataSource={assignments} rowKey="id" />
        </div>
    );
}