import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Spin } from 'antd'; // <-- We'll use Ant's spinner
import {
  Chart as ChartJS,
  LineElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import HeroAxios from '../../helpers/HeroAxios.js';
import './../styles.css';

ChartJS.register(
  LineElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  ChartDataLabels
);

const getCSSVariable = (variable) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
};

function SalesLineChart(props) {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  // Track local loading
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    HeroAxios.post('getAccoutingAccountBalaceForEachOfLast30days', {
      accountingClientId: props.accountingClientId,
    })
      .then((response) => {
        // Build labels and data
        const labels = response.data.map((item) => {
          const date = new Date(item.date);
          const options = { month: 'short', day: 'numeric' };
          return date.toLocaleDateString('es-ES', options);
        });
        const data = response.data.map((item) => item.balance);

        // Some colors
        const borderColor = getCSSVariable('--purple-light');
        const backgroundColor = getCSSVariable('--purple-light');
        const pointBackgroundColor = getCSSVariable('--purple-normal');
        const pointBorderColor = getCSSVariable('--purple-normal');
        const hoverBackgroundColor = getCSSVariable('--purple-dark');

        setChartData({
          labels,
          datasets: [
            {
              data,
              borderColor: borderColor,
              backgroundColor: backgroundColor,
              fill: true,
              tension: 0.4,
              pointBackgroundColor,
              pointBorderColor,
              hoverBackgroundColor,
            },
          ],
        });
      })
      .catch((error) => {
        console.error(error);
        // If there's an error, fallback to empty chart
        setChartData({ labels: [], datasets: [] });
      })
      .finally(() => {
        // Mark loading as done
        setLoading(false);
      });
  }, [props.accountingClientId]);

  // If still loading, show a spinner in place of the chart
  if (loading) {
    return (
      <div
        className='infographic-card'
        style={{
          minHeight: '240px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin tip='Cargando ventas...' />
      </div>
    );
  }

  return (
    <div className='infographic-card' style={{ position: 'relative' }}>
      <div style={{ opacity: 0.16 }}>
        <h2 style={{ color: 'var(--purple-darkest)' }}>Ventas</h2>
        <Line
          data={chartData}
          options={{
            layout: {
              padding: { top: 8, bottom: 8 },
            },
            plugins: {
              legend: { display: false },
              datalabels: {
                anchor: 'end',
                align: 'top',
                offset: 0,
                formatter: (value) => `$${value}`, // Format label as USD
                color: getCSSVariable('--purple-darkest'),
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    const value = tooltipItem.raw;
                    return `$${value.toFixed(2)}`;
                  },
                },
              },
            },
            elements: {
              point: {
                radius: 5,
                hoverRadius: 7,
                pointStyle: 'circle',
                hitRadius: 10,
                hoverBorderWidth: 2,
              },
            },
            scales: {
              x: {
                beginAtZero: false,
                ticks: { padding: 8 },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  padding: 8,
                  callback: function (value) {
                    return `$${value.toLocaleString()}`; // Format y-axis as USD
                  },
                },
              },
            },
          }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'rgba(128, 128, 128, 0.9)',
          fontSize: '18px',
          fontWeight: 'bold',
          pointerEvents: 'none',
        }}
      >
        Coming soon ⚡🙂
      </div>
    </div>
  );
}

export { SalesLineChart };
