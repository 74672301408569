import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, message, Tag, Tooltip } from 'antd';
import {
  CopyOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
  SyncOutlined
} from '@ant-design/icons';
import HeroAxios from '../../helpers/HeroAxios.js';

const DGIIntegration = (props) => {
  const [ruc, setRuc] = useState(props.dgiCredentials.login_info.ruc || '');
  const [nit, setNit] = useState(props.dgiCredentials.login_info.nit || '');
  const [loading, setLoading] = useState(false);
  const [runningIntegration, setRunningIntegration] = useState(false);

  // State to temporarily show "pending" status, indicating we're processing
  const [pendingProcess, setPendingProcess] = useState(false);

  // Helper function to check if credentials should be shown as "pending"
  // when the last run time is earlier than the updated_at for the credentials
  const isCredentialTimePending = () => {
    const lastRun = props?.lastRun
      ? new Date(props.lastRun)
      : null;
    const updatedAt = props?.dgiCredentials?.updated_at
      ? new Date(props.dgiCredentials.updated_at)
      : null;

    if (!lastRun || !updatedAt) return false;
    return lastRun < updatedAt;
  };

  const handleRunIntegration = async () => {
    if (!props.accountingClientId) {
      message.error('ID de cliente no válido');
      return;
    }

    setRunningIntegration(true);
    message.info('Actualizando obligaciones de DGI');

    // This is an async process that may take time on the backend
    // so we do not rely on immediate success or failure.
    try {
      await HeroAxios.post(
        `obligations/update-obligations-by-source/${props.accountingClientId}`,
        { source: 'DGI' }
      );
      message.success('Las obligaciones de DGI están siendo procesadas.');
    } catch (error) {
      console.error('Error running integration:', error);
      message.error('No se pudieron actualizar las obligaciones de DGI');
    } finally {
      // Re-enable the button after 30 seconds, but leave the credential status in "pendiente"
      setTimeout(() => {
        setRunningIntegration(false);
      }, 30000);
    }
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      const updatedCredentials = {
        accounting_client_id: props.dgiCredentials.accounting_client_id,
        id: props.dgiCredentials.id,
        site: 'dgi',
        login_info: {
          ruc: ruc.trim(),
          nit: nit.trim(),
        },
      };

      await props.updateAccountingClientConfig(updatedCredentials);
      message.success('Credenciales actualizadas correctamente');

      // Set "pending" status to prevent multiple clicks and indicate that the process is ongoing
      setPendingProcess(true);

      // Run the integration process right after saving
      handleRunIntegration();
    } catch (error) {
      console.error('Error during update:', error);
      message.error('Error al actualizar credenciales');
    } finally {
      setLoading(false);
    }
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleString('es-ES', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  // Determine if we need to shift the status to "Pendiente..."
  const credentialTimePending = isCredentialTimePending();
  const showPending = pendingProcess || credentialTimePending;

  let tagColor = 'default';
  let tagIcon = pendingProcess ? <SyncOutlined spin /> : null;
  let tagText = 'Estado de Credenciales: pendiente';
  let tooltipText = showPending
    ? 'La actualización puede tardar unos minutos. Puede regresar más tarde para ver el estado actualizado.'
    : 'Por favor guarde las credenciales para iniciar la validación y actualización de las obligaciones';

  // Only show other statuses if we're not pending
  if (!showPending) {
    if (props.credentialStatus === 'OK') {
      tagColor = 'success';
      tagIcon = <CheckCircleOutlined />;
      tagText = 'Credenciales OK';
    } else if (props.credentialStatus === 'Sin Datos') {
      tagColor = 'default';
      tagIcon = <InfoCircleOutlined />;
      tagText = 'Estado de Credenciales: pendiente';
      tooltipText = 'Por favor guarde las credenciales para iniciar la validación y actualización de las obligaciones';
    } else {
      tagColor = 'error';
      tagIcon = <WarningOutlined />;
      tagText = 'Error de Credenciales';
      tooltipText = props.credentialStatusDetail;
    }
  }

  return (
    <div style={{ marginBottom: 32, marginTop: 32 }}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
          <h3 style={{ display: 'inline', marginBottom: 0, marginLeft: 4, textAlign: 'left' }}>DGI</h3>
          <div style={{
            color: '#999999', fontSize: '12px', marginLeft: 16,
            alignSelf: 'flex-end'
          }}>
            última actualización de credenciales: {formatDateTime(props.dgiCredentials.updated_at)}
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: 4 }}>
          <Tooltip title={props.credentialStatusDetail}>
            <Tag color={tagColor} icon={tagIcon}>
              {tagText}
            </Tag>
          </Tooltip>
        </div>
      </div>

      <Form.Item label="RUC" style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input value={ruc} onChange={(e) => setRuc(e.target.value)} />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type="text"
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(ruc)}
            />
          </Col>
        </Row>
      </Form.Item>

      <Form.Item label="NIT" style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input value={nit} onChange={(e) => setNit(e.target.value)} />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type="text"
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(nit)}
            />
          </Col>
        </Row>
      </Form.Item>

      <div style={{ textAlign: 'left', marginLeft: 96 }}>
        <Button
          loading={loading || runningIntegration}
          type="primary"
          onClick={!runningIntegration ? handleSave : null}
          style={{ marginRight: 16 }}
        >
          Guardar y ejecutar
        </Button>
        {(pendingProcess) && (
          <div style={{ marginLeft: 8, color: '#999', textAlign: 'left', fontSize: '12px' }}>
            Hemos guardado las credenciales y estamos buscando las obligaciones.
            <br /> Actualizaremos el estado de credenciales en unos momentos.
          </div>
        )}
      </div>
    </div>
  );
};

export default DGIIntegration;
