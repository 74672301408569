// AccountingAccountsTable.js

import React, { useState, useEffect, useContext } from 'react';
import { Table, message, Button, Modal, Form, Input, Select } from 'antd';
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import HeroAxios from '../../helpers/HeroAxios.js';
import { authContext } from '../../ProvideAuth.js';
import { AccountingFinancialStatementsModal } from './AccountingFinancialStatementsModal.js';

function AccountingAccountsTable({ API_domain, accountingClientCompany }) {
  const auth = useContext(authContext);
  const [accountsData, setAccountsData] = useState([]);
  const [flattenedAccounts, setFlattenedAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newAccountName, setNewAccountName] = useState('');
  const [selectedParentAccountId, setSelectedParentAccountId] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState('');
  const [parentAccountType, setParentAccountType] = useState('');

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editedAccountId, setEditedAccountId] = useState(null);
  const [editedAccountName, setEditedAccountName] = useState('');
  const [editedAccountType, setEditedAccountType] = useState('');

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);

  const [isEntriesModalVisible, setIsEntriesModalVisible] = useState(false);
  const [entriesModalContent, setEntriesModalContent] = useState({});

  // Mapping of account types to labels/categories
  const accountTypeLabels = {
    bank: { label: 'Banco', category: 'Activo' },
    cash: { label: 'Efectivo', category: 'Activo' },
    current_asset: { label: 'Activo Corriente', category: 'Activo' },
    other_current_asset: { label: 'Otro Activo Corriente', category: 'Activo' },
    other_asset: { label: 'Otro Activo', category: 'Activo' },
    fixed_asset: { label: 'Activo Fijo', category: 'Activo' },
    credit_card: { label: 'Tarjeta de Crédito', category: 'Pasivo' },
    current_liability: { label: 'Pasivo Corriente', category: 'Pasivo' },
    other_current_liability: {
      label: 'Otro Pasivo Corriente',
      category: 'Pasivo',
    },
    long_term_liability: { label: 'Pasivo a Largo Plazo', category: 'Pasivo' },
    other_liability: { label: 'Otro Pasivo', category: 'Pasivo' },
    equity: { label: 'Capital', category: 'Capital' },
    income: { label: 'Ingreso', category: 'Ingresos' },
    sales: { label: 'Ventas', category: 'Ingresos' },
    other_income: { label: 'Otros Ingresos', category: 'Ingresos' },
    expense: { label: 'Gastos', category: 'Gastos' },
    cost_of_goods_sold: {
      label: 'Costo de Mercancías Vendidas',
      category: 'Gastos',
    },
    other_expense: { label: 'Otros Gastos', category: 'Gastos' },
  };

  // All possible account types
  const accountTypes = Object.keys(accountTypeLabels);

  // Root category names used in the fetched structure
  const rootAccountNames = [
    'Assets',
    'Liabilities',
    'Equity',
    'Income',
    'Expense',
  ];

  // 1) Fetch the account hierarchy from the backend, no sorting
  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const response = await HeroAxios.post('getAccountHierarchy', {
        client_id: accountingClientCompany.accounting_client_id,
      });
      const serverAccounts = response.data.accounts; // <-- Use them as-is
      setAccountsData(serverAccounts);
      setFlattenedAccounts(flattenAccounts(serverAccounts));
    } catch (error) {
      console.error('Error fetching account hierarchy:', error);
      message.error('Error al obtener la jerarquía de cuentas');
    }
    setLoading(false);
  };

  const handleAccountNameClick = (record) => {
    setEntriesModalContent({
      account_id: record.id,
      subcategory: record.zoho_account_name,
    });
    setIsEntriesModalVisible(true);
  };

  // 2) Create new account (no sorting)
  const handleCreateAccount = async () => {
    if (!newAccountName) {
      message.error('Por favor ingrese el nombre de la cuenta');
      return;
    }
    if (!selectedAccountType) {
      message.error('Por favor seleccione el tipo de cuenta');
      return;
    }
    setLoading(true);
    try {
      await HeroAxios.post('createAccount', {
        client_id: accountingClientCompany.accounting_client_id,
        zoho_account_name: newAccountName,
        parent_account_id: selectedParentAccountId,
        zoho_account_type: selectedAccountType,
      });
      message.success('Cuenta creada exitosamente');
      setIsModalVisible(false);
      setNewAccountName('');
      setSelectedParentAccountId(null);
      setSelectedAccountType('');
      setParentAccountType('');
      fetchAccounts(); // refresh
    } catch (error) {
      console.error('Error creating account:', error);
      message.error('Error al crear la cuenta');
    }
    setLoading(false);
  };

  // 3) Edit existing account
  const handleEditAccount = async () => {
    if (!editedAccountName) {
      message.error('Por favor ingrese el nombre de la cuenta');
      return;
    }
    if (!editedAccountType) {
      message.error('Por favor seleccione el tipo de cuenta');
      return;
    }
    try {
      await HeroAxios.post('editAccount', {
        account_id: editedAccountId,
        zoho_account_name: editedAccountName,
        zoho_account_type: editedAccountType,
        parent_account_id: selectedParentAccountId,
      });
      message.success('Cuenta actualizada exitosamente');
      setIsEditModalVisible(false);
      setEditedAccountId(null);
      setEditedAccountName('');
      setEditedAccountType('');
      setSelectedParentAccountId(null);
      fetchAccounts();
    } catch (error) {
      console.error('Error updating account:', error);
      message.error('Error al actualizar la cuenta');
    }
  };

  // 4) Delete account
  const handleDeleteAccount = async () => {
    if (!accountToDelete) {
      message.error('No hay cuenta seleccionada para eliminar');
      return;
    }
    try {
      await HeroAxios.post('deleteAccount', {
        account_id: accountToDelete.id,
      });
      message.success('Cuenta eliminada exitosamente');
      setIsDeleteModalVisible(false);
      setAccountToDelete(null);
      fetchAccounts();
    } catch (error) {
      console.error('Error deleting account:', error);
      message.error('Error al eliminar la cuenta');
    }
  };

  // On mount or change, fetch accounts
  useEffect(() => {
    fetchAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [API_domain, accountingClientCompany, auth]);

  // Expand/collapse logic
  const handleExpand = (expanded, record) => {
    setExpandedKeys((prevKeys) => {
      if (expanded) {
        return [...prevKeys, record.id];
      } else {
        return prevKeys.filter((key) => key !== record.id);
      }
    });
  };
  const handleExpandAll = () => {
    const getAllKeys = (nodes) => {
      let keys = [];
      nodes.forEach((node) => {
        keys.push(node.id);
        if (node.children) {
          keys = keys.concat(getAllKeys(node.children));
        }
      });
      return keys;
    };
    setExpandedKeys(getAllKeys(accountsData));
  };
  const handleCollapseAll = () => {
    setExpandedKeys([]);
  };

  // Flatten the accounts for populating parent-account dropdown
  const flattenAccounts = (accounts) => {
    let flatList = [];
    accounts.forEach((account) => {
      // Skip root categories
      if (!rootAccountNames.includes(account.zoho_account_name)) {
        flatList.push(account);
      }
      if (account.children) {
        flatList = flatList.concat(flattenAccounts(account.children));
      }
    });
    return flatList;
  };

  // Parent account selection
  const handleParentAccountChange = (value) => {
    setSelectedParentAccountId(value);
    const parentAccount = flattenedAccounts.find((acc) => acc.id === value);
    if (parentAccount) {
      setParentAccountType(parentAccount.zoho_account_type);
      setSelectedAccountType(parentAccount.zoho_account_type);
    }
  };

  // Show/hide modals
  const showCreateAccountModal = () => {
    setIsModalVisible(true);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
    setNewAccountName('');
    setSelectedParentAccountId(null);
    setSelectedAccountType('');
    setParentAccountType('');
  };
  const showEditAccountModal = (record) => {
    setEditedAccountId(record.id);
    setEditedAccountName(record.zoho_account_name);
    setEditedAccountType(record.zoho_account_type);
    setSelectedParentAccountId(record.parent_account_id || null);
    setIsEditModalVisible(true);
  };
  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setEditedAccountId(null);
    setEditedAccountName('');
    setEditedAccountType('');
  };
  const showDeleteAccountModal = (record) => {
    setAccountToDelete(record);
    setIsDeleteModalVisible(true);
  };
  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false);
    setAccountToDelete(null);
  };

  // Table columns
  const columns = [
    {
      title: 'Nombre de la Cuenta',
      dataIndex: 'zoho_account_name',
      key: 'zoho_account_name',
      render: (text, record) => {
        const translatedNames = {
          Assets: 'Activos',
          Liabilities: 'Pasivos',
          Equity: 'Capital',
          Revenue: 'Ingresos',
          Expenses: 'Gastos',
        };
        const displayName = translatedNames[text] || text;
        return record.is_category ? (
          <strong>{displayName}</strong>
        ) : (
          <a onClick={() => handleAccountNameClick(record)}>{displayName}</a>
        );
      },
    },
    {
      title: 'Tipo de Cuenta',
      dataIndex: 'zoho_account_type',
      key: 'zoho_account_type',
      render: (text) =>
        accountTypeLabels[text]
          ? `${accountTypeLabels[text].label} - ${accountTypeLabels[text].category}`
          : text,
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (text, record) => {
        // If it's a root category or a parent with children, hide edit/delete
        if (
          (record.children && record.children.length > 0) ||
          rootAccountNames.includes(record.zoho_account_name)
        ) {
          return null;
        }
        return (
          <span style={{ display: 'flex', gap: '8px' }}>
            <Button onClick={() => showEditAccountModal(record)} style={{ border: 'none' }}>
              <EditOutlined /> Editar
            </Button>
            <Button
              onClick={() => showDeleteAccountModal(record)}
              danger
              style={{ border: 'none' }}
            >
              <DeleteOutlined />
            </Button>
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Button style={{ marginRight: 8 }} onClick={handleCollapseAll}>
          <FullscreenExitOutlined /> Colapsar
        </Button>
        <Button style={{ marginRight: 8 }} onClick={handleExpandAll}>
          <FullscreenOutlined /> Expandir
        </Button>
        <Button type='primary' style={{ marginRight: 8 }} onClick={showCreateAccountModal}>
          <PlusOutlined /> Crear nueva cuenta
        </Button>
      </div>

      {/* Create Account Modal */}
      <Modal
        title='Crear nueva cuenta'
        visible={isModalVisible}
        onCancel={handleModalCancel}
        onOk={handleCreateAccount}
        okText='Crear'
        cancelText='Cancelar'
      >
        <Form layout='vertical'>
          <Form.Item label='Nombre de la cuenta' required>
            <Input
              value={newAccountName}
              onChange={(e) => setNewAccountName(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='Cuenta padre'>
            <Select
              value={selectedParentAccountId}
              onChange={handleParentAccountChange}
              placeholder='Seleccione una cuenta padre (opcional)'
              allowClear
            >
              {flattenedAccounts.map((acc) => (
                <Select.Option key={acc.id} value={acc.id}>
                  {acc.zoho_account_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='Tipo de cuenta' required>
            <Select
              value={selectedAccountType}
              onChange={(value) => setSelectedAccountType(value)}
              placeholder='Seleccione un tipo de cuenta'
            >
              {accountTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {`${accountTypeLabels[type].label} - ${accountTypeLabels[type].category}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {/* Edit Account Modal */}
      <Modal
        title='Editar cuenta'
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        onOk={handleEditAccount}
        okText='Guardar cambios'
        cancelText='Cancelar'
      >
        <Form layout='vertical'>
          <Form.Item label='Nombre de la cuenta' required>
            <Input
              value={editedAccountName}
              onChange={(e) => setEditedAccountName(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='Tipo de cuenta' required>
            <Select
              value={editedAccountType}
              onChange={(value) => setEditedAccountType(value)}
              placeholder='Seleccione un tipo de cuenta'
              disabled // If you want to keep it locked
            >
              {accountTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {`${accountTypeLabels[type].label} - ${accountTypeLabels[type].category}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='Cuenta padre'>
            <Select
              value={selectedParentAccountId}
              onChange={(value) => setSelectedParentAccountId(value)}
              placeholder='Seleccione una cuenta padre (opcional)'
              allowClear
            >
              {flattenedAccounts.map((acc) => (
                <Select.Option key={acc.id} value={acc.id}>
                  {acc.zoho_account_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {/* Delete Account Modal */}
      <Modal
        title='Eliminar cuenta'
        visible={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        onOk={handleDeleteAccount}
        okText='Eliminar'
        cancelText='Cancelar'
      >
        <p>
          ¿Está seguro de que desea eliminar la cuenta{' '}
          <strong>{accountToDelete?.zoho_account_name}</strong>?
        </p>
      </Modal>

      {/* Modal for showing ledger entries */}
      <AccountingFinancialStatementsModal
        API_domain={API_domain}
        accountingClientCompany={accountingClientCompany.accounting_client_id}
        modalContent={entriesModalContent}
        isModalVisible={isEntriesModalVisible}
        onCancel={() => setIsEntriesModalVisible(false)}
        onOk={() => setIsEntriesModalVisible(false)}
      />

      <Table
        dataSource={accountsData}
        columns={columns}
        loading={loading}
        pagination={false}
        style={{ width: '800px' }}
        expandable={{
          expandedRowKeys: expandedKeys,
          onExpand: handleExpand,
          expandRowByClick: true,
        }}
        rowKey={(record) => record.id}
      />
    </div>
  );
}

export { AccountingAccountsTable };
