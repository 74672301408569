import React, { useContext, useEffect, useState } from 'react';
import {
  Affix,
  Button,
  Col,
  Layout,
  Row,
  message,
  Table,
  Tabs,
  Typography,
} from 'antd';
import styled from 'styled-components/macro';
import {
  LoadingOutlined,
  MailOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import EmailReviewModal from '../EmailComposer/EmailReviewModal.js';
import { generatePDF } from './GeneratePDF';
import 'jspdf-autotable';
import { formatDateSpanishLong } from '../../utils';
import { BalanceSheetTable2 } from './BalanceSheetTable2.js';
import { IncomeStatementTable2 } from './IncomeStatementTable2.js';
import { AccountingTransactionsTable } from './AccountingTransactionsTable.js';
import { AccountingAccountsTable } from './AccountingAccountsTable.js';
import { AccountingInfographicsContainer } from './AccountingInfographicsContainer.js';

const { Content } = Layout;
const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;

// Spinner
const AffixDiv = styled.div`
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 8px;
  text-align: left;
`;
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Obscuring logic for the Income Statement
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const subcategoryMapping = {
  'Ingresos por Servicios de Fisioterapia': 'Ingresos por venta de comida',
  'Honorarios profesionales Pablo': 'Honorarios profesionales Juan Perez',
  'Honorarios profesionales Grerys': 'Honorarios profesionales Maria Jimenez',
  'Honorarios profesionales Cilia': 'Honorarios profesionales Michael Jordan',
  'Insumos de fisioterapeutas': 'Insumos de producción',
};

const obscureSubcategory = (subcategory) => {
  return subcategoryMapping[subcategory] || subcategory;
};

const obscureIncomeStatementData = (data, factor) => {
  const obscureValuesRecursively = (node) => ({
    ...node,
    subcategory: obscureSubcategory(node.subcategory),
    values: node.values.map((val) => (val * factor).toFixed(2)),
    children: node.children.map(obscureValuesRecursively),
  });
  return data.map(obscureValuesRecursively);
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Obscuring logic for the Balance Sheet
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const balanceSheetSubcategoryMapping = {
  'Tarjeta Hero Salomon Cohen': 'Tarjeta Hero Michael Jordan',
  'Tarjeta Hero Pablo Lima': 'Tarjeta Hero Juan Perez',
  'Equipo de Fisoterapia': 'Equipo de cocina',
  'Depreciacion Equi. de Fisioterapia': 'Depreciacion Equi. de Cocina',
  'Equipo de Gym': 'Mobiliario restaurante',
  'Depreciacion Equi. de Gym': 'Depreciacion Mobiliario restaurante',
  'Honorarios por pagar Cilia': 'Honorarios por pagar Juan Perez',
  'Honorarios por pagar Salo': 'Honorarios por pagar Michael Jordan',
  'Honorarios por pagar Grerys': 'Honorarios por pagar Maria Jimenez',
  'Honorarios por pagar Pablo': 'Honorarios por pagar Linda Perez',
  'Cuenta por pagar Infinity Eq': 'Cuenta por pagar Compañía X',
  'Cuenta por pagar Cilia Amar': 'Cuenta por pagar Compañía Z',
  'Aporte Accionista Pablo Lima': 'Aporte Accionista Peter Pan',
  'Aporte Accionista Cilia Amar': 'Aporte Accionista Juan Perez Sr.',
};

const obscureBalanceSheetSubcategory = (subcategory) => {
  return balanceSheetSubcategoryMapping[subcategory] || subcategory;
};

const obscureBalanceSheetData = (data, factor) => {
  const obscureValuesRecursively = (node) => ({
    ...node,
    subcategory: obscureBalanceSheetSubcategory(node.subcategory),
    values: node.values.map((val) => (val * factor).toFixed(2)),
    children: node.children.map(obscureValuesRecursively),
  });
  return data.map(obscureValuesRecursively);
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Main Component
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export function AccountingFinancialStatementsView(props) {
  const auth = useContext(authContext);
  const isAdmin = auth.adminEmail;

  // Tab management
  const [activeTab, setActiveTab] = useState('1');

  // Company info and loading
  const [companyLoading, setCompanyLoading] = useState(true);
  const [accountingClientCompany, setAccountingClientCompany] = useState(null);
  const [hasFinancialStatements, setHasFinancialStatements] = useState(false);

  // Income statement
  const [incomeStatementData, setIncomeStatementData] = useState(null);
  const [incomeStatementLoading, setIncomeStatementLoading] = useState(false);
  const [incomeStatementRecentTimePeriods, setIncomeStatementRecentTimePeriods] =
    useState([]);
  const [
    timePeriodsWithMostRecentTransaction,
    setTimePeriodsWithMostRecentTransaction,
  ] = useState([]);

  // Balance sheet
  const [balanceSheetData, setBalanceSheetData] = useState(null);
  const [balanceSheetLoading, setBalanceSheetLoading] = useState(false);
  const [balanceSheetRecentTimePeriods, setBalanceSheetRecentTimePeriods] =
    useState([]);

  // Email modal
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [emailData, setEmailData] = useState({});

  // Fetch company info once
  useEffect(() => {
    if (!auth.email || !auth.token) return;

    setCompanyLoading(true);
    axios
      .post(
        `${props.API_domain}getUserAccountingClientCompanyInfo`,
        {},
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      )
      .then(async (companyResponse) => {
        const {
          accounting_client_id,
          accounting_client_company_id,
          name,
          ruc,
          logo_image_url,
        } = companyResponse.data;

        if (!accounting_client_id) {
          setHasFinancialStatements(false);
          return;
        }

        // Optional fetch for the base64 logo
        let logo_base64 = null;
        try {
          const logoResponse = await axios.post(
            `${props.API_domain}getAccountingClientLogoSource`,
            { selectedAccountingClientId: accounting_client_id },
            {
              auth: {
                username: auth.email,
                password: auth.token,
              },
            }
          );
          logo_base64 = logoResponse.data.logo_image_base64 || null;
        } catch (e) {
          console.error('Error fetching logo:', e);
        }

        setAccountingClientCompany({
          accounting_client_id,
          accounting_client_company_id,
          name,
          ruc,
          logo_image_url,
          logo_base64,
        });
        setHasFinancialStatements(true);
      })
      .catch((error) => {
        message.error('Error al obtener la información de la empresa contable');
        console.error(error);
      })
      .finally(() => {
        setCompanyLoading(false);
      });
  }, [auth.email, auth.token, props.API_domain]);

  
  useEffect(() => {
    if (!accountingClientCompany) return;
    if (activeTab !== '1') return;
    fetchIncomeStatement();
  }, [activeTab, accountingClientCompany, props.API_domain, auth.email, auth.token]);

  // Then define the actual fetch logic in a function:
  const fetchIncomeStatement = () => {
    setIncomeStatementLoading(true);
    setIncomeStatementData(null);

    const clientToFetch =
      accountingClientCompany.accounting_client_id === 4
        ? 10
        : accountingClientCompany.accounting_client_id;

    axios
      .post(
        `${props.API_domain}getIncomeStatementData`,
        { client_id: clientToFetch },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      )
      .then((incomeResponse) => {
        const {
          incomeStatementData,
          timePeriods,
          timePeriodsWithMostRecentTransaction,
        } = incomeResponse.data;

        const recentMonthsCount = 12;
        const recentTimePeriods = timePeriods.slice(-recentMonthsCount);
        setIncomeStatementRecentTimePeriods(recentTimePeriods);
        setTimePeriodsWithMostRecentTransaction(
          timePeriodsWithMostRecentTransaction
        );

        const sliceValuesRecursively = (node, count) => ({
          ...node,
          values: node.values.slice(-count),
          children: node.children.map((child) =>
            sliceValuesRecursively(child, count)
          ),
        });

        // Filter to last 10 months
        const filteredIncomeStatementData = incomeStatementData.map((cat) =>
          sliceValuesRecursively(cat, recentMonthsCount)
        );

        // Obscure if demo ID
        let finalIncomeStatementData = filteredIncomeStatementData;
        if (accountingClientCompany.accounting_client_id === 4) {
          finalIncomeStatementData = obscureIncomeStatementData(
            filteredIncomeStatementData,
            0.62
          );
        }

        // Add 'key' fields
        const keyedIncomeStatementData = keyData(finalIncomeStatementData);
        setIncomeStatementData(keyedIncomeStatementData);
      })
      .catch((error) => {
        console.error('Error fetching income statement:', error);
        message.error('Error al obtener los datos del estado de resultados');
      })
      .finally(() => {
        setIncomeStatementLoading(false);
      });
  };


  // Fetch Balance Sheet data when tab = "2"
  // 1) Fetch once on mount or whenever `accountingClientCompany` changes
  useEffect(() => {
    if (!accountingClientCompany) return;

    fetchBalanceSheet();
  }, [accountingClientCompany, props.API_domain, auth.email, auth.token]);

  // 2) Re-fetch whenever user explicitly clicks tab 2
  useEffect(() => {
    if (!accountingClientCompany) return;
    if (activeTab !== '2') return;

    fetchBalanceSheet();
  }, [activeTab, accountingClientCompany, props.API_domain, auth.email, auth.token]);

  // Then define the actual fetch logic in a function:
  const fetchBalanceSheet = () => {
    setBalanceSheetLoading(true);
    setBalanceSheetData(null);

    const clientToFetch =
      accountingClientCompany.accounting_client_id === 4
        ? 10
        : accountingClientCompany.accounting_client_id;

    axios
      .post(
        `${props.API_domain}getBalanceSheetData`,
        { client_id: clientToFetch },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      )
      .then((balanceResponse) => {
        const { balanceSheetData, timePeriods } = balanceResponse.data;

        const recentMonthsCount = 12;
        const recentTimePeriods = timePeriods.slice(-recentMonthsCount);
        setBalanceSheetRecentTimePeriods(recentTimePeriods);

        const sliceValuesRecursively = (node, count) => ({
          ...node,
          values: node.values.slice(-count),
          children: node.children.map((child) =>
            sliceValuesRecursively(child, count)
          ),
        });

        // Filter to last 10 months
        const filteredBalanceSheetData = balanceSheetData.map((cat) =>
          sliceValuesRecursively(cat, recentMonthsCount)
        );

        // Obscure if demo ID
        let finalBalanceSheetData = filteredBalanceSheetData;
        if (accountingClientCompany.accounting_client_id === 4) {
          finalBalanceSheetData = obscureBalanceSheetData(
            filteredBalanceSheetData,
            0.62
          );
        }

        // Add 'key' fields
        const keyedBalanceSheetData = keyData(finalBalanceSheetData);
        setBalanceSheetData(keyedBalanceSheetData);
      })
      .catch((error) => {
        console.error('Error fetching balance sheet:', error);
        message.error('Error al obtener los datos del balance');
      })
      .finally(() => {
        setBalanceSheetLoading(false);
      });
  };


  // Utility to assign each node a unique key
  const keyData = (rawData) => {
    const generateKeys = (data, parentKey = '') => {
      return data.map((item, index) => {
        const key = parentKey ? `${parentKey}-${index + 1}` : `${index + 1}`;
        const children = item.children ? generateKeys(item.children, key) : [];
        return { ...item, key, children };
      });
    };
    return generateKeys(rawData);
  };

  const getLastDayOfMonth = (yearMonth) => {
    const [year, month] = yearMonth.split('-').map(Number);
    const date = new Date(year, month, 1);
    date.setDate(date.getDate() - 1);
    return date.toISOString().split('T')[0];
  };

  // Generate PDF
  const handleGeneratePDF = (outputOnlyBlob = false) => {
    return generatePDF(
      outputOnlyBlob,
      accountingClientCompany,
      incomeStatementRecentTimePeriods,
      balanceSheetRecentTimePeriods,
      balanceSheetData,
      incomeStatementData,
      props.API_domain,
      auth
    );
  };

  // Prepare + show email modal
  const showEmailModal = async () => {
    try {
      const getDataResponse = await axios.get(
        `${props.API_domain}getDataForAccountingClient/${accountingClientCompany.accounting_client_id}`,
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );

      const { emails } = getDataResponse.data;
      const pdfBlob = await handleGeneratePDF(true);

      const lastIncomePeriod =
        incomeStatementRecentTimePeriods[
        incomeStatementRecentTimePeriods.length - 1
        ];
      const accountingDate = getLastDayOfMonth(lastIncomePeriod);
      const accountingDateLong = formatDateSpanishLong(accountingDate);

      setEmailData({
        to: emails.join(',') + ',contabilidad@herofacturas.com',
        subject: `Reporte Contable - ${accountingClientCompany.name} - ${accountingDateLong}`,
        body: `Hola estimados ${accountingClientCompany.name},

Adjunto pueden ver un reporte de su contabilidad al ${accountingDateLong}.
        
Hero`,
        attachments: [{ name: 'reporte_contable.pdf', content: pdfBlob }],
        metadata: {
          accounting_client_id: accountingClientCompany.accounting_client_id,
          accounting_client_name: accountingClientCompany.name,
          accounting_date: accountingDate,
          accounting_date_long: accountingDateLong,
        },
      });

      setIsEmailModalVisible(true);
    } catch (error) {
      console.error('Error preparing email data:', error);
      message.error('Failed to prepare email data. Inténtalo de nuevo.');
    }
  };

  // Download general ledger
  const handleDownloadLedger = async () => {
    try {
      const response = await axios.get(
        `${props.API_domain}downloadGeneralLedger`,
        {
          responseType: 'blob',
          auth: {
            username: auth.email,
            password: auth.token,
          },
          params: {
            accounting_client_id: accountingClientCompany.accounting_client_id,
          },
        }
      );

      const blob = new Blob([response.data], {
        type: 'text/csv;charset=utf-8;',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'general_ledger.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading general ledger:', error);
      message.error('No se pudo descargar el mayor general. Inténtalo de nuevo.');
    }
  };

  // Tab switch
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const tabs = [
    {
      key: '1',
      label: `Estado de Resultados`,
      children: incomeStatementData ? (
        <div>
          <IncomeStatementTable2
            API_domain={props.API_domain}
            accountingClientCompany={accountingClientCompany.accounting_client_id}
            incomeStatementData={incomeStatementData}
            incomeStatementRecentTimePeriods={incomeStatementRecentTimePeriods}
            timePeriodsWithMostRecentTransaction={timePeriodsWithMostRecentTransaction}
          />
        </div>
      ) : (
        <Table loading={incomeStatementLoading} />
      ),
    },
    {
      key: '2',
      label: `Balance`,
      children: balanceSheetData ? (
        <div>
          <BalanceSheetTable2
            API_domain={props.API_domain}
            accountingClientCompany={accountingClientCompany.accounting_client_id}
            balanceSheetData={balanceSheetData}
            balanceSheetRecentTimePeriods={balanceSheetRecentTimePeriods}
            timePeriodsWithMostRecentTransaction={timePeriodsWithMostRecentTransaction}
          />
        </div>
      ) : (
        <Table loading={balanceSheetLoading} />
      ),
    },
    ...(isAdmin
      ? [
        {
          key: '3',
          label: `Diarios Manuales 🦸`,
          children: (
            <AccountingTransactionsTable
              API_domain={props.API_domain}
              accountingClientCompany={accountingClientCompany}
            />
          ),
        },
        {
          key: '4',
          label: `Cuentas Contables 🦸`,
          children: (
            <AccountingAccountsTable
              API_domain={props.API_domain}
              accountingClientCompany={accountingClientCompany}
            />
          ),
        },
      ]
      : []),
  ];

  // If still loading company info, show spinner
  if (companyLoading) {
    return (
      <LoadingDiv>
        {antIcon}
      </LoadingDiv>
    );
  }

  return (
    <Content
      style={{
        margin: '0 0 10px',
        overflow: 'initial',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      {hasFinancialStatements && accountingClientCompany ? (
        <>
          <Affix>
            <AffixDiv>
              <h1>
                {accountingClientCompany.logo_image_url && (
                  <img
                    src={accountingClientCompany.logo_image_url}
                    alt='Company Logo'
                    style={{
                      width: '100px',
                      objectFit: 'contain',
                      borderRadius: '4px',
                      marginRight: '16px',
                    }}
                  />
                )}
                {accountingClientCompany.name}
              </h1>
            </AffixDiv>
          </Affix>

          <AccountingInfographicsContainer
            API_domain={props.API_domain}
            accountingClientCompany={accountingClientCompany}
            incomeStatementData={incomeStatementData}
            incomeStatementRecentTimePeriods={incomeStatementRecentTimePeriods}
            balanceSheetData={balanceSheetData}
            balanceSheetRecentTimePeriods={balanceSheetRecentTimePeriods}
          />

          <div
            style={{
              color: 'var(--grey-darkest)',
              textAlign: 'left',
              padding: '16px',
            }}
          >
            <Row>
              <Col xs={24} md={12} xxl={12}>
                <h2>Reportes Contables</h2>
              </Col>
              <Col xs={24} md={12} xxl={12}>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    type='secondary'
                    onClick={showEmailModal}
                    disabled={!incomeStatementData || !balanceSheetData}
                    style={{ marginRight: 10 }}
                  >
                    <MailOutlined />
                    Mandar por <b> Correo</b>
                  </Button>

                  <Button
                    onClick={() => handleGeneratePDF()}
                    type='secondary'
                    style={{ marginRight: 10 }}
                  >
                    <DownloadOutlined />
                    PDF
                  </Button>

                  {isAdmin && (
                    <Button onClick={handleDownloadLedger} type='secondary'>
                      <DownloadOutlined />
                      Mayor 🦸
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <Tabs
              activeKey={activeTab}
              onChange={handleTabChange}
              style={{ width: '100%', paddingLeft: '5%' }}
            >
              {tabs.map((tab) => (
                <TabPane tab={tab.label} key={tab.key}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    {tab.children}
                  </div>
                </TabPane>
              ))}
            </Tabs>
          </div>
        </>
      ) : (
        <Row justify='center' align='middle' style={{ minHeight: '100vh' }}>
          <Col xs={22} sm={18} md={14} lg={12}>
            <Typography align='center'>
              <Title level={2}>
                ¿Estás buscando servicios mensuales de contabilidad?
              </Title>
              <Paragraph>
                Aquí podrías ver tu Estado de Resultados (P&L) y tu balance de
                situación (Balance Sheet) en tiempo real.
              </Paragraph>
              <Button
                type='primary'
                size='large'
                onClick={() => {
                  const phoneNumbers = ['50766181519', '50766759599'];
                  const selectedPhone =
                    phoneNumbers[
                    Math.floor(Math.random() * phoneNumbers.length)
                    ];
                  window.open(
                    `https://api.whatsapp.com/send?phone=${selectedPhone}&text=Soy%20usuario%20de%20Hero%20y%20quiero%20contabilidad.`,
                    '_blank'
                  );
                }}
              >
                Conversemos Ahora
              </Button>
            </Typography>
          </Col>
        </Row>
      )}

      <EmailReviewModal
        visible={isEmailModalVisible}
        onCancel={() => setIsEmailModalVisible(false)}
        initialValues={emailData}
        API_domain={props.API_domain}
      />
    </Content>
  );
}
