import {
  CommentOutlined,
  FlagFilled,
  FlagOutlined,
  LoadingOutlined,
  SearchOutlined,
  CheckSquareOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Empty, Radio, Spin, message, Table, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { DateFilterDropdown } from './DateFilterDropdown.js';
import { DescriptionSearchDropdown } from './DescriptionSearchDropdown.js';
import { authContext } from '../ProvideAuth.js';
import { get_moments_from_month_name } from '../utils.js';
import { FormattedUSD } from './FormattedUSD.js';
import { AccountingAccountSelectBank } from './AccountingAccountSelectBank.js';
import { convertToIntDateFormat } from '../utils.js';
import './styles.css';
import HeroAxios from '../helpers/HeroAxios.js';
import RelationshipModalV2 from './RelationshipModalV2';
import { sourceMapping } from '../utils.js';
import TransactionCreatorModal from './Accounting/TransactionCreatorModal';
import { RelationshipButton } from './RelationshipButton';
import { CommitViewButton } from './CommitViewButton.js';
import { SocketContext } from '../SocketContext.js';

// Double-filter dropdowns
import { CommitDoubleFilterDropdown } from './CommitDoubleFilterDropdown';
import { AccountDoubleFilterDropdown } from './AccountDoubleFilterDropdown';
import { RelationshipDoubleFilterDropdown } from './RelationshipDoubleFilterDropdown';

const BankTable = (props) => {
  const [bankRows, setBankRows] = useState([]);
  const [flagging, setFlagging] = useState([]); // holds the key to the row that is flagging
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const auth = useContext(authContext);
  const [accountFilterOptions, setAccountFilterOptions] = useState([]);
  const [latestUpdate, setLatestUpdate] = useState(null);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);
  const [relationshipModalVisible, setRelationshipModalVisible] =
    useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState(null);
  const [selectedRecordForRelationship, setSelectedRecordForRelationship] =
    useState(null);

  const [isTransactionModalVisible, setIsTransactionModalVisible] =
    useState(false);
  const [selectedMovement, setSelectedMovement] = useState(null);
  const { socket, startSocket, stopSocket } = useContext(SocketContext);

  const fetchAccountOptions = (selectedSot) => {
    props.setLoading(true);
    axios({
      method: 'post',
      url: props.API_domain + 'getAccountsOptions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        selectedSot: selectedSot,
      },
    })
      .then((response) => {
        props.setAccountOptions(response.data);
        props.setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching accounting accounts:', error);
      });
  };

  const openRelationshipModal = (record, relationship) => {
    setSelectedRecordForRelationship(record);
    setSelectedRelationship(relationship);
    setRelationshipModalVisible(true);
  };

  useEffect(() => {
    props.setLoading(true);
    setBankRows([]);
    setLatestUpdate(null);

    const fetchBankRows = async () => {
      const sot_id = props.selectedSot
        ? props.selectedSot.sources_of_truth_id
        : null;
      if (!sot_id) {
        props.setLoading(false);
        return;
      }

      try {
        const response = await axios({
          method: 'post',
          url: props.API_domain + 'getSotTransactions',
          data: { sot_id },
          auth: { username: auth.email, password: auth.token },
        });
        setLatestUpdate(response.data.latest_update);

        // Transform data to match table columns
        const transformedBankRows = response.data.sot_transactions_list.map(
          (item) => ({
            ...item,
            key: item.id,
            Descripcion: item.description,
            Fecha: item.txn_date,
            Date: new Date(item.txn_date),
            fechaFormatted: convertToIntDateFormat(item.txn_date),
            Credito: item.amount > 0 ? Math.abs(item.amount) : null,
            Debito: item.amount < 0 ? Math.abs(item.amount) : null,
            balance: item.extra_info.balance,
            sotTxnId: item.id,
            flagged: item.flagged,
            comments: item.comments,
            committed: item.committed,
          })
        );

        transformedBankRows.sort((a, b) => {
          // Sort descending by date, then by id
          const dateDifference = b.Date - a.Date;
          if (dateDifference !== 0) return dateDifference;
          return b.sotTxnId - a.sotTxnId;
        });
        setBankRows(transformedBankRows);

        // For your own usage
        props.setCurrentData(transformedBankRows);
        setProgressNumbers(transformedBankRows);
      } catch (error) {
        console.error('Error fetching SOT data', error);
      }

      props.setLoading(false);
      if (props.selectedSot) {
        fetchAccountOptions(props.selectedSot);
      }
    };

    fetchBankRows();
  }, [props.selectedSot, props.uploadChanged, props.submittedToggle]);

  useEffect(() => {
    getAccountFilterOptions();
  }, [props.accountOptions, bankRows]);

  useEffect(() => {
    setSelectedRowKeys(props.selectedSotTxnsInMassEdit.map((x) => x.id));
  }, [props.selectedSotTxnsInMassEdit]);

  const getAccountFilterOptions = () => {
    const uniqueAccounts = new Map();
    uniqueAccounts.set(null, { text: 'Sin cuenta asignada', value: 'none' });

    const accountOptionsMap = new Map();
    props.accountOptions.forEach((option) => {
      accountOptionsMap.set(option.id, option);
    });

    bankRows.forEach((item) => {
      if (item.account_id && !uniqueAccounts.has(item.account_id)) {
        const accountOption = accountOptionsMap.get(item.account_id);
        if (accountOption) {
          uniqueAccounts.set(item.account_id, {
            text: accountOption.name,
            value: item.account_id.toString(),
          });
        }
      }
    });

    // Convert the map to an array and sort it alphabetically
    const sortedAccounts = Array.from(uniqueAccounts.values())
      .filter((account) => account.value !== 'none')
      .sort((a, b) => a.text.localeCompare(b.text));

    // Add the 'none' option at the beginning
    const noneOption = uniqueAccounts.get(null);
    if (noneOption) {
      sortedAccounts.unshift(noneOption);
    }

    setAccountFilterOptions(sortedAccounts);
  };

  const onAccountChange = (value, record, newConfidence) => {
    const updatedBankRows = bankRows.map((item) => {
      if (item.key === record.key) {
        return {
          ...item,
          account_id: value,
          account_id_confidence: newConfidence,
        };
      }
      return item;
    });
    setBankRows(updatedBankRows);
  };

  const updateSotTxnFlagged = (record) => {
    setFlagging([...flagging, record.key]);
    axios({
      method: 'post',
      url: props.API_domain + 'flagSotTxn',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: { sot_txn_id: record.sotTxnId },
    })
      .then(() => {
        setFlagging(flagging.filter((key) => key !== record.key));
        // Update local state
        const updatedBankRows = bankRows.map((item) => {
          if (item.key === record.key) {
            return { ...item, flagged: !item.flagged };
          }
          return item;
        });
        setBankRows(updatedBankRows);
      })
      .catch((error) => {
        console.error('Error flagging transaction: ', error);
      });
  };

  const onSelectedRowsChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    props.setSelectedSotTxnsInMassEdit(selectedRows);
  };

  const rowSelectionConfig = auth.adminEmail
    ? {
        selectedRowKeys,
        onChange: onSelectedRowsChange,
      }
    : null;

  // Build the "existingFilters" for Relación:
  // We'll gather all distinct relationship "type_of_mm" or "movement_table_name"
  // (excluding "entries" if you like, same as your existing code).
  // Also, we include "no_relationship" as well.
  const relationshipTypesSet = new Set();

  bankRows.forEach((br) => {
    if (br.relationship && br.relationship.length > 0) {
      br.relationship.forEach((rel) => {
        // skip entries if you want
        if (
          rel.type_of_mm !== 'entries' &&
          rel.movement_table_name !== 'entries'
        ) {
          const t = rel.type_of_mm || rel.movement_table_name;
          relationshipTypesSet.add(t);
        }
      });
    }
  });

  const relationshipFilterOptions = [
    ...Array.from(relationshipTypesSet).map((type) => {
      const beautifulName = sourceMapping[type] || type;
      return { text: beautifulName, value: type };
    }),
    { text: 'Sin Relación', value: 'no_relationship' },
  ];

  // =====================================
  // Double Filter Helpers
  // =====================================

  // Relationship double filter
  function relationshipDoubleFilter(value, record) {
    // value is { relationshipType: [...], agentFilter: 'all'|'AUTONOMOUS_AGENT'|'human' }
    if (!value) return true; // no filter at all

    const { relationshipType = [], agentFilter = 'all' } = value;

    // (1) Relationship type filter
    let passType = false;

    // If no relationshipType is selected at all, pass automatically
    if (!relationshipType.length) {
      passType = true;
    } else {
      // If user selected 'no_relationship'
      if (relationshipType.includes('no_relationship')) {
        if (!record.relationship || record.relationship.length === 0) {
          // It's valid if we wanted "no_relationship"
          passType = true;
        }
      }

      // Filter out 'entries'
      const relevantRels =
        record.relationship?.filter(
          (rel) =>
            rel.type_of_mm !== 'entries' &&
            rel.movement_table_name !== 'entries'
        ) || [];

      // Check if at least one relevant relationship is in relationshipType
      const matchedType = relevantRels.some((rel) =>
        relationshipType.includes(rel.type_of_mm || rel.movement_table_name)
      );

      // If we didn't pass yet with 'no_relationship', see if matched a relevant relationship
      if (matchedType) passType = true;
    }

    if (!passType) return false;

    // (2) Agent filter
    if (agentFilter === 'all') {
      return true;
    } else if (agentFilter === 'AUTONOMOUS_AGENT') {
      // Pass if at least one relevant relationship was created by AUTONOMOUS_AGENT
      const relevantRels =
        record.relationship?.filter(
          (rel) =>
            rel.type_of_mm !== 'entries' &&
            rel.movement_table_name !== 'entries'
        ) || [];
      return relevantRels.some(
        (rel) => rel.audit_usersfacturas_id === 'AUTONOMOUS_AGENT'
      );
    } else if (agentFilter === 'human') {
      // Pass if at least one relevant relationship was created by a user != 'AUTONOMOUS_AGENT'
      const relevantRels =
        record.relationship?.filter(
          (rel) =>
            rel.type_of_mm !== 'entries' &&
            rel.movement_table_name !== 'entries'
        ) || [];
      return relevantRels.some(
        (rel) => rel.audit_usersfacturas_id !== 'AUTONOMOUS_AGENT'
      );
    }

    // default
    return true;
  }

  // Commit double filter
  function commitDoubleFilter(value, record) {
    // value = { commitStatus: 'committed'|'not_committed'|null, agentFilter: 'all'|'AUTONOMOUS_AGENT'|'human' }
    if (!value) return true; // no filter

    const { commitStatus, agentFilter } = value || {};

    // (1) commitStatus
    if (commitStatus === 'committed') {
      if (!record.committed) return false;
    } else if (commitStatus === 'not_committed') {
      if (record.committed) return false;
    }

    // (2) agentFilter
    // If row is not committed, there's no "who committed it"
    // So if user wants 'AUTONOMOUS_AGENT' or 'human', that doesn't apply => we fail
    if (!record.committed && agentFilter !== 'all') {
      return false;
    }

    if (agentFilter === 'all') {
      return true;
    }

    // For committed items, see if there's an 'entries' relationship that might say who created it
    const entriesRels =
      record.relationship?.filter(
        (rel) =>
          rel.movement_table_name === 'entries' || rel.type_of_mm === 'entries'
      ) || [];

    // If there are no 'entries' relationships but it's committed, we might not know who created it => return false
    if (entriesRels.length === 0) {
      return false;
    }

    if (agentFilter === 'AUTONOMOUS_AGENT') {
      // pass if EVERY entry was created by 'AUTONOMOUS_AGENT'
      return entriesRels.every(
        (rel) => rel.audit_usersfacturas_id === 'AUTONOMOUS_AGENT'
      );
    } else if (agentFilter === 'human') {
      // pass if at least one was NOT 'AUTONOMOUS_AGENT'
      return entriesRels.some(
        (rel) => rel.audit_usersfacturas_id !== 'AUTONOMOUS_AGENT'
      );
    }

    // default
    return true;
  }

  // Account double filter
  function accountDoubleFilter(value, record) {
    // value = { accountIds: [...], agentFilter: 'all'|'AUTONOMOUS_AGENT'|'human' }
    if (!value) return true;

    const { accountIds = [], agentFilter = 'all' } = value;

    // (1) accountIds
    // If user selected some accountIds, the row must match one of them
    if (accountIds.length > 0 && !accountIds.includes(record.account_id)) {
      return false;
    }

    // (2) agentFilter => logic depends on your definitions
    if (agentFilter === 'all') return true;

    const conf = parseFloat(record.account_id_confidence || '0');

    if (agentFilter === 'AUTONOMOUS_AGENT') {
      return (conf < 1 && conf > 0) || (conf == 0 && record.account_id);
    } else if (agentFilter === 'human') {
      return conf >= 1;
    }

    return true;
  }

  const columns = [
    {
      title: 'Descripción',
      dataIndex: 'Descripcion',
      key: 'Descripcion',
      width: 420,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DescriptionSearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record.Descripcion.toLowerCase().includes(value.toLowerCase()),
      render: (descripcion) => (
        <Tooltip placement='topLeft' title={descripcion}>
          {descripcion}
        </Tooltip>
      ),
    },
    {
      title: 'Credito',
      dataIndex: 'Credito',
      key: 'Credito',
      width: 100,
      showSorterTooltip: false,
      align: 'right',
      sorter: (a, b) => parseFloat(a.Credito || 0) - parseFloat(b.Credito || 0),
      render: (credito) =>
        credito && <FormattedUSD total={parseFloat(credito)} />,
    },
    {
      title: 'Debito',
      dataIndex: 'Debito',
      key: 'Debito',
      width: 100,
      showSorterTooltip: false,
      align: 'right',
      sorter: (a, b) => parseFloat(a.Debito || 0) - parseFloat(b.Debito || 0),
      render: (debito) => debito && <FormattedUSD total={parseFloat(debito)} />,
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
      width: 100,
      showSorterTooltip: false,
      align: 'right',
      sorter: (a, b) => parseFloat(a.balance || 0) - parseFloat(b.balance || 0),
      render: (balance) => <FormattedUSD total={parseFloat(balance)} />,
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaFormatted',
      key: 'fechaFormatted',
      width: 120,
      sorter: (a, b) => new Date(a.txn_date) - new Date(b.txn_date),
      showSorterTooltip: false,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        const current_date = new Date(record.Fecha);
        current_date.setHours(current_date.getHours() + 5);
        if (typeof value === 'string') {
          let filter_dates = get_moments_from_month_name(value);
          return (
            current_date >= filter_dates[0] && current_date <= filter_dates[1]
          );
        }
        return current_date >= value[0] && current_date <= value[1];
      },
    },
    {
      title: 'Cuenta Contable ',
      dataIndex: 'account_id',
      key: 'account_id',
      width: 160,
      // Instead of the single "filters" usage, we use our custom double-filter dropdown:
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <AccountDoubleFilterDropdown
          // We'll pass the needed accounts. We'll build an array of { account_id, zoho_account_name } from 'accountFilterOptions'
          // Since 'accountFilterOptions' has { text, value }, let's re-map them:
          accounts={accountFilterOptions.map((opt) => ({
            account_id: opt.value === 'none' ? null : parseInt(opt.value, 10),
            zoho_account_name: opt.text,
          }))}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => accountDoubleFilter(value, record),
      render: (_, record) => {
        return (
          <div>
            <AccountingAccountSelectBank
              API_domain={props.API_domain}
              accountOptions={props.accountOptions}
              account_id_confidence={record.account_id_confidence}
              record={record}
              onChange={onAccountChange}
              allowClear={true}
              disabled={record.committed || !auth.adminEmail}
              showThumbUp={
                record.account_id_confidence < 1 &&
                record.account_id_confidence > 0 &&
                !record.committed
              }
            />
          </div>
        );
      },
    },
    {
      title: 'Relación',
      dataIndex: 'relationship',
      key: 'relationship',
      width: 200,
      // We'll use RelationshipDoubleFilterDropdown:
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <RelationshipDoubleFilterDropdown
          existingFilters={relationshipFilterOptions}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => relationshipDoubleFilter(value, record),
      render: (relationship, record) => {
        if (relationship && relationship.length > 0) {
          return relationship
            .filter(
              (rel) =>
                rel.type_of_mm !== 'entries' &&
                rel.movement_table_name !== 'entries'
            )
            .map((rel, index) => {
              const relatedMovementSource =
                rel.type_of_mm || rel.movement_table_name;
              const beautifulName =
                sourceMapping[relatedMovementSource] || relatedMovementSource;
              return (
                <RelationshipButton
                  key={rel.relationship_id || index}
                  record={record}
                  rel={rel}
                  beautifulName={beautifulName}
                  openRelationshipModal={openRelationshipModal}
                  index={index}
                />
              );
            });
        } else {
          return '';
        }
      },
    },
    ...(auth.adminEmail
      ? [
          {
            title: '🦸‍♂️',
            dataIndex: 'committed',
            key: 'committed',
            width: 80,
            // We'll replace the single filters with our double-filter dropdown:
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <CommitDoubleFilterDropdown
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
              />
            ),
            filterIcon: (filtered) => (
              <CheckSquareOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            onFilter: (value, record) => commitDoubleFilter(value, record),
            render: (committed, record) => (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {committed ? (
                  <CommitViewButton
                    record={record}
                    onClick={() => openTransactionModal(record)}
                  />
                ) : (
                  ''
                )}
              </span>
            ),
          },
          {
            title: '🦸‍♂️',
            dataIndex: 'flagged',
            key: 'flagged',
            width: 80,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Radio.Group
                  onChange={(e) => {
                    setSelectedKeys(
                      e.target.value !== undefined ? [e.target.value] : []
                    );
                    confirm();
                  }}
                  value={selectedKeys[0]}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Radio value={true}>
                    <FlagFilled />
                  </Radio>
                  <Radio value={false}>
                    <FlagOutlined />
                  </Radio>
                </Radio.Group>
                <Button
                  onClick={() => {
                    clearFilters();
                    confirm();
                  }}
                  size='small'
                  style={{ width: 90, marginTop: 8 }}
                >
                  Reset
                </Button>
              </div>
            ),
            filterIcon: (filtered) => (
              <FlagOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            onFilter: (value, record) => {
              if (value === true) {
                return record.flagged === true;
              } else if (value === false) {
                return record.flagged === false;
              }
              return false;
            },
            render: (_, record) =>
              record.account_id && (
                <>
                  {flagging.includes(record.key) ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          spin
                          style={{ color: 'black', marginLeft: 12 }}
                        />
                      }
                    />
                  ) : (
                    <Tooltip
                      placement='left'
                      title={
                        record.flagged ? 'Quitar duda' : 'Marcar como duda'
                      }
                    >
                      <Button
                        className='icon-button'
                        onClick={() => updateSotTxnFlagged(record)}
                        style={{ marginLeft: 5 }}
                      >
                        <>
                          {record.flagged ? <FlagFilled /> : <FlagOutlined />}{' '}
                          {record.comments && (
                            <Tooltip title={record.comments}>
                              <CommentOutlined />
                            </Tooltip>
                          )}
                        </>
                      </Button>
                    </Tooltip>
                  )}
                </>
              ),
          },
        ]
      : []),
  ];

  const setProgressNumbers = (filteredBankRows) => {
    props.setCurrentData(filteredBankRows);

    // We'll recalc the progress counts from the filtered data here:
    const cutoffDate = moment('2024-10-31');
    const bankRowsAfterCutoff = filteredBankRows.filter(
      (row) =>
        moment(row.txn_date).isAfter(cutoffDate) ||
        moment(row.txn_date).isSame(cutoffDate, 'day')
    );

    const uncommittedBankRowsCount = bankRowsAfterCutoff.filter(
      (row) => !row.committed
    ).length;

    const categorizedBankRowsCount = bankRowsAfterCutoff.filter(
      (row) => !row.committed && row.account_id !== null
    ).length;

    const highConfidenceBankRowsCount = bankRowsAfterCutoff.filter(
      (row) => !row.committed && row.account_id_confidence >= 1
    ).length;

    const relatedBankRowsCount = bankRowsAfterCutoff.filter(
      (row) => !row.committed && row.relationship && row.relationship.length > 0
    ).length;

    // Update parent so ProgressOverview re-renders with new filtered counts
    props.setUncommittedBankRowsCount(uncommittedBankRowsCount);
    props.setCategorizedBankRowsCount(categorizedBankRowsCount);
    props.setHighConfidenceBankRowsCount(highConfidenceBankRowsCount);
    props.setRelatedBankRowsCount(relatedBankRowsCount);
  };

  // NEW: MOVED PROGRESS COUNTS TO onChange
  const onChange = (pagination, filters, sorter, extra) => {
    // `currentDataSource` is the table's data after filtering/pagination/sorting.
    const filteredBankRows = extra.currentDataSource;
    setProgressNumbers(filteredBankRows);
  };

  const onRefresh = async () => {
    setIsUpdateDisabled(true);
    // startSocket(auth.email);
    try {
      await HeroAxios.post('runBankRefresh', {
        sot_id: props.selectedSot.sources_of_truth_id,
      });
      message.success('Estamos actualizando tu banco');
      setIsUpdateDisabled(false);
    } catch (error) {
      message.error('No tenemos integración con esta cuenta de banco');
      setIsUpdateDisabled(false);
    }
  };

  const openTransactionModal = (record) => {
    setSelectedMovement({
      movement_table_name: 'sot_transactions',
      movement_id: record.id,
    });
    setIsTransactionModalVisible(true);
  };

  return (
    <div>
      {latestUpdate ? (
        <div
          style={{
            color: 'var(--grey-dark)',
            marginLeft: 8,
            paddingTop: 16,
            textAlign: 'left',
          }}
        >
          <SyncOutlined
            style={{
              fontSize: '12px',
              cursor: isUpdateDisabled ? 'not-allowed' : 'pointer',
              marginRight: '4px',
              opacity: isUpdateDisabled ? 0.5 : 1,
              animation: isUpdateDisabled ? 'spin 1s linear infinite' : 'none',
            }}
            onClick={() => {
              if (!isUpdateDisabled) {
                onRefresh();
              }
            }}
            title={
              isUpdateDisabled
                ? 'Actualizando...'
                : 'Actualizar datos del banco'
            }
          />
          Actualización más reciente: {latestUpdate}
        </div>
      ) : null}
      <Table
        rowSelection={rowSelectionConfig}
        dataSource={bankRows}
        columns={columns}
        pagination={{ defaultPageSize: 100 }}
        style={{ width: '100%' }}
        loading={props.loading}
        onChange={onChange}
        onRow={(record) => ({
          onClick: () => {
            console.log('record', record);
          },
        })}
        locale={{
          emptyText: (
            <Empty
              style={{ paddingTop: '20vh', paddingBottom: '20vh' }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <div>Selecciona un banco para ver sus transacciones</div>
                </>
              }
            />
          ),
        }}
      />
      {relationshipModalVisible && (
        <RelationshipModalV2
          visible={relationshipModalVisible}
          onCancel={() => setRelationshipModalVisible(false)}
          relationship={selectedRelationship}
          setRecords={setBankRows}
          selectedRecordForRelationship={selectedRecordForRelationship}
          setRelationshipModalVisible={setRelationshipModalVisible}
          API_domain={props.API_domain}
          accounting_clients_id={props.selectedSot?.accounting_client_id}
          tableOG='sot_transactions'
        />
      )}
      <TransactionCreatorModal
        isVisible={isTransactionModalVisible}
        onCancel={() => setIsTransactionModalVisible(false)}
        prepareTransactionEndpoint={'getTransactionDataForMovement'}
        transactionCreationEndpoint={'editTransaction'}
        initialPayload={{
          movement_table_name: selectedMovement?.movement_table_name,
          movement_id: selectedMovement?.movement_id,
        }}
        API_domain={props.API_domain}
        auth={auth}
        clientId={props.selectedSot?.accounting_client_id}
        onTransactionCreated={() => {
          message.success('Transacción editada exitosamente');
        }}
      />
    </div>
  );
};

export { BankTable };
