export const verticalProviders = {
  '946-416-107393': 'TECNILAB',
  '42973-2-290057': 'ILUMITEC',
  '9-158-573': 'ACARREO DON TUTO',
  '2992-193-47043': 'CAMARA',
  '257-563-58968': 'FAUSTO',
  '613458-1-453512': 'PANAMA WASTE MANAGEM',
  '155705019-2-2021': 'RDJ',
  '1707909-1-687925': '4 IMAGE',
  '1707909-1-687925': '4 SUP',
  '8-738-1922': 'ABDIEL V.',
  '9-148-961': 'ABEL',
  '8-82-385': 'ABILIO CUBILLOS PRIV',
  '1479836-1-643453': 'ABRASIL, CO. INC.',
  '1865502-1-716171': 'AC CONTRATACIONES',
  '1747809-1-695691': 'AC1',
  '8-799-1724': 'ACABADOS',
  '4-242-864': 'ACARREO MOISES',
  '26584-21-224614': 'ACCESORIOS IND',
  '2562613-1-828068': 'ACE PMA. GEOSYNTHETI',
  '1732694-1-692789': 'ACE PMA. INTERNACION',
  '57521-68-339142': 'ACERO DECORATIVO',
  '1900656-1-722422': 'ACERTA',
  '206-357-50180': 'ACETIOXIGENO',
  'E-8-2662': 'ADALBERTO FERNANDEZ',
  '9-220-717': 'ADAN NUÑEZ',
  '8-738-1922': 'ADM ABDIEL',
  '4-745-2483': 'ADM ANA',
  '4-729-888': 'ADM CELYNETH',
  '8-770-897': 'ADM JOCELYN',
  '8-392-52': 'ADM LEONIDES',
  C01524255: 'ADM LUISA',
  '44886-127-297736': 'ADMINISTRADORA',
  '8-849-1456': 'ADOLFO',
  '155639576-2-2016': 'AFILIADA-MACKAY',
  '155639596-2-2016': 'AFILIADA-SINOE',
  '855-534-103651': 'AGENCIAS DOMINGO',
  '1621672-1-670343': 'AGRO ALQUILER',
  '608078-1-452714': 'AGRUPACION PETROLERA',
  '8-299-365': 'AGUSTIN GONZALEZ',
  '2-709-1637': 'AGUSTIN O.',
  '1796421-1-704577': 'AGV CONSULTING',
  '549-288-119651': 'AIRCO',
  '19905-47-181183': 'AIRE',
  '4-222-634': 'AIRES MOVIL QUINTERO',
  '155622102-2-2016': 'AIRES SOLUTIONS',
  '8-461-440': 'ALADINO MAGUE',
  '8-748-803': 'ALBERTO G.',
  '8-165-1129': 'ALBERTO NAVARRO',
  '155644090-2-2017': 'ALC ECO SOLUTIONS',
  '155663180-2-2018': 'ALC SOLUCIONES',
  '155663180-2-2018': 'ALC SOUCIONES',
  '8-887-1402': 'ALDAIR GONZALEZ',
  'PE-9-105': 'ALEGRIA NO.2',
  '2-147-182': 'ALEJANDRO',
  'AU-732-147': 'ALEJANDRO LOAIZA',
  '8-858-1032': 'ALEX E. MOCK HERRERA',
  '8-857-1938': 'ALEXANDRA B.',
  '8-309-387': 'ALEXIS CAMAÑO',
  '40463-86-279623': 'ALFOMBRAS',
  '155637756-2-2016': 'ALFOMBRAS PERSONALIZ',
  '2-124-665': 'ALFREDO SEGUNDO',
  '541848-1-441803': 'ALISS',
  'E-8-93529': 'ALMACEN MULTICOLOR J',
  '436-287-95110': 'ALMACENES',
  '155659921-2-2018': 'ALQUILERES EVENTOS',
  '1828237-1-710071': 'ALQUILERES YAYO',
  '2183246-1-770700': 'ALQUITEC',
  '1711322-1-688493': 'ALTA PESCA',
  '441-254-95199': 'ALUMA',
  '1121902-1-564647': 'ALUWOOD',
  '8-264-373': 'ALYSSA LINCE',
  '8-737-1188': 'AM LANDSCAPING',
  '155685652-2-2019': 'AMAUTA 9 PANAMA, S.A',
  '8-925-1614': 'ANA A.',
  '4-769-1213': 'ANA LEZCANO',
  '2330422-1-794398': 'ANA M',
  '8-361-6': 'ANGEL',
  '8-439-767': 'ANGEL MONTILLA',
  '28125-164-230274': 'ANKARA',
  '558357-1-444639': 'ANTILLANA',
  '8-442-337': 'ANTONIO BOSQUEZ',
  '8-357-409': 'ANTONIO CHOCK',
  '8-750-207': 'ANTONIO DOMINGUEZ',
  '608078-1-452714': 'AP',
  '155644695-2-2017 DV27': 'APLICACIONES',
  '2-103-667': 'APOLAYO EXPRESS',
  '2241051-1-779757': 'AQUALINE EXPRESS',
  '61439-29-349382': 'AQUATEC PUM & SPA, S',
  '2562339-1-828047': 'ARAPLEI CORP S.A.',
  '8-916-1212': 'ARCEDIA MOSQUERA',
  '2379848-1-801940': 'ARCO TECHNOLOGIES',
  '2368608-1-800387': 'ARENAS',
  '1187-45-4704': 'ARIAS ALEMAN & MORA',
  '8-759-1323': 'ARIS H.',
  '1586540-1-663522': 'ARISTORE',
  '2-132-529': 'ARNOLDO BERMUDEZ',
  '155590996-2-2015': 'ARQ SUPPLY',
  '31294-1-368217': 'ARQUI',
  '2240284-1-779594': 'ARQUILUZ',
  '1279112-1-600106': 'ARQUITAINER',
  '2053307-1-748373': 'ARQUITECTOS, S.A.',
  '9374-1-365284': 'ARTECASA',
  '8-NT-2-743237': 'ASAMBLEA DE PROPIETA',
  '2355505-1-798545': 'ASAP',
  '42484-63-287958': 'ASEGURADORA',
  '30746-2-240130': 'ASEGURADORA ANCON',
  '9346-174-95819': 'ASESORES EN CONCRETO',
  '1112362-1-562409': 'ASESORIA',
  '3724-103-53659': 'ASSA',
  '8-515-352': 'AUGUSTO C.',
  '155711667-2-2021': 'AUSTIN MAMA´S',
  'PE-9-1213': 'AUT',
  '603-203-124985': 'AUTO CENTRO',
  'N-19-1614': 'AUTO REP. 3 NOVIEMBR',
  '24492-56-213007': 'AW PEST CONTROL',
  '2-752-438': 'AZAEL M.',
  '155649326-2-2017': 'AZALEA 9B, S.A.',
  '155649084-2-2017': 'AZUR GROUP',
  '47101-2-306017': 'BAC',
  '280-134-61098': 'BANCO GENERAL',
  '1269902-1-597539': 'BANESCO',
  '43736-46-292633': 'BANISTMO',
  '2598928-1-833551': 'BAÑOMOVIL',
  '2534247-1-823874': 'BATHROOM SOLUTIONS',
  '7-56-310': 'BAUDILIO DELGADO',
  '9-734-1083': 'BC INGENIERIA',
  '2-737-184': 'BENEDICTO O.',
  '2-144-536': 'BENIGNO MENDOZA',
  '8-824-385': 'BERTA CUBILLOS',
  '47497-89-307311': 'BEYO INTERNACIONAL',
  '541848-1-441803': 'BICOCAS',
  '2329816-1-794330': 'BISTRO',
  'N-21-608': 'BIT',
  '8-740-1652': 'BLADIMIR O.',
  '2649061-1-41732': 'BLMP',
  '2564297-1-838337': 'BLOQUES DEL PACIFICO',
  '1205912-1-583191': 'BOMBAS',
  '231138-1-399978': 'BOMBASA',
  '2-145-79': 'BREDIO HERNANDEZ',
  AT259678: 'BRIGITH CHICA',
  '1933516-1-728512': 'BRUNIC',
  '2506296-1-819700': 'BUPA',
  '2014852-1-742414': 'BUSINESS EXPRESS',
  '571483-1-446710': 'BUSINESS SOFTWARE',
  '8-923-2348': 'C&S PANAMA',
  '155691947-2-2020': 'CABAÑAS Y SOLUCIONES',
  '30394-2-238626': 'CABLE O.',
  'E-8-180865': 'CAFETERIA NUEVO SABO',
  'N-21-2044': 'CAIJIN QIU CHINA MOV',
  '1245640-1-592101': 'CAMPOTENCIA',
  '55371-13-333416': 'CANAL',
  '53284-129-327308': 'CANTERA GONZALILLO',
  '7698-106-83230': 'CAPRICORNIO',
  '7-67-632': 'CAR WASH',
  '155609005-2-2015': 'Car Wash & Grill',
  '2-723-233': 'CAR WASH Y PARKING',
  '1080323-1-554308': 'CARBONE',
  '122-395-32518': 'CARDOZE',
  'E-8-184967': 'CARLOS AGUDELO',
  '6-76-280': 'CARLOS BAZAN',
  'E-8-107787': 'CARLOS CAÑAS',
  '8-357-352': 'CARLOS CASTILLO',
  'E-1158-26838': 'CARLOS ISTURIZ',
  '8-940-1443': 'CARLOS LEZCANO',
  '8-752-38': 'CARLOS LUIS ARAUZ',
  '10-9-254': 'CARLOS ROBINSON',
  '9-718-2339': 'CARLOS RODRIGUEZ',
  'E-8-65731': 'CARLOS VILLATORO',
  '8-805-410': 'CARLOS VILUCE',
  '8-861-816': 'CARMEN',
  '9-81-1607': 'CARMEN DE SALDAÑA',
  '2386225-1-802888': 'CASA BRUJA',
  '466-489-101996': 'CASA EGEO',
  '155628013-2-2016': 'CASA STIZZOLI',
  '969997-1-529829': 'CASCO',
  '2172964-1-769029': 'CASTILLO MADERO',
  '155624207-2-2016': 'CEMENTO CHAGRES PRIV',
  '1603854-1-666821': 'CENTRAL AMERICA APPL',
  '8-851-1160': 'CENTRO',
  '55876-0021-334590': 'CENTRO AUDIO',
  'N-16-445': 'CENTRO DE PINTURA',
  '937-138-19820': 'CENTRO INDUSTRIAL',
  '15953-161-152552': 'CENTRO MEDICO CHANIS',
  '155629558-2-2016': 'CENTROLUM PANAMA, S.',
  '8-812-1224': 'CESAR C.',
  '2-714-1220': 'CESAR F.',
  '2-735-1239': 'CESAR S.',
  '2408469-1-806020': 'CESISA',
  '2075641-1-752261': 'CGM EQUIPOS',
  '1045467-1-546234': 'CH CAROLINA HERRERA',
  '8-712-848': 'CHRISTIAN HENRIQUEZ',
  '2-221-78': 'CIA INTERNACIONAL',
  '2367438-1-800216': 'CIMENTA',
  '994855-1-534959': 'CLARO',
  '6-702-1763': 'CLEMENTE A.',
  '8-725-1250': 'CLEMENTE M.',
  '462241-1-433104': 'CM PREVENTIVA',
  '2268232-1-784684': 'COAMCO',
  '4-772-690': 'COBA',
  '9-124-178': 'COLE-INST.ROL',
  '8-805-1708': 'COLE-OBTAVIO',
  '2292821-1-788848': 'COLEQUIPOS',
  '155684189-2-2019': 'COLORES ILIMITADOS',
  '30444-139-238858': 'COMERCIAL',
  '2203359-1-773640': 'COMFORTHOME',
  '152435-1-386206': 'COMPAÑIA DE SEGURIDA',
  '151758-1-386069': 'COMPAÑIA DE TEJIDOS',
  '896-366-105129': 'COMPAÑIA SAR',
  '56617-42-336607': 'COMPUTER',
  '56617-42-336607': 'COMPUTER PLACE',
  '155712364-2-2021': 'CONCRELAB PANAMÁ',
  '1228487-1-588065': 'CONST',
  '8-765-505': 'CONST. BOCAS-PANAMA',
  '2396974-1-804407': 'CONST. GISA',
  '6-706-1735': 'CONST. R & C',
  '8-787-1222': 'CONST. SANCHEZ',
  '4-713-817': 'CONST.ACAB',
  '8-819-777': 'CONST.ECON',
  '2478571-1-815868': 'CONST.MM',
  '155690598-2-2020': 'CONSTRUCCIONES',
  '4-733-1686': 'CONSTRUCCIONES JOSE',
  '8-902-438': 'CONSTRUCTORA',
  '155612883-2-2015': 'CONSTRUCTORA EVELYN',
  '155651274-2-2017': 'CONSTRUMEC',
  '62137-36-351360': 'CONWAY',
  '130-377-34706': 'COPA',
  '727-146-119991': 'COPANAC',
  '152435-1-386206': 'COPASECUVA',
  '19466-97-177556': 'COPIAS RAPIDAS',
  '1077389-1-553678': 'COPPER GROUP, S.A.',
  '1303119-1-606092': 'COQUI',
  '59479-77-344466': 'CORE LABORATORIES',
  '2-161-542': 'CORNELIO R.',
  '1971892-1-735548': 'CORONA',
  '3420-15-51058': 'CORPINSA',
  '2147734-1-764872': 'CORPORACION FINANCIE',
  '1808704-1-706828': 'CORPORACION S.S.C.',
  '1797271-1-704735': 'CORSETTI',
  '2628455-1-837648': 'COSTA FOODS',
  '2547417-1-825864': 'CRISTAL EXPRESS',
  PAS070625774: 'CRISTOPHER T',
  '504380-1-19629': 'CUBIAS',
  '8-NT-1-12761': 'CUERPO DE BOMBEROS',
  '2604483-1-834389': 'CULINARY GOURMET',
  '1952065-1-731975': 'CULTO PUBLICIDAD',
  '8-843-1066': 'CW SOLUCIONES',
  '8-831-1230': 'DANIEL CECEÑA',
  '8-470-473': 'DANIEL FLORES',
  '8-711-1895': 'DANIEL RODRIGUEZ',
  '694246-1-467218': 'DARKINS',
  '3-713-1356': 'DAVID CISNEROS',
  '8-881-24': 'DAVID SANCHEZ',
  '2-149-468': 'DAVID VERGARA',
  '8-754-1343': 'DAYAANA M.',
  '8-746-2056': 'DAYAN',
  '33548-12-252346': 'DECO',
  '50786-15-319217': 'DECO ACCESORIOS',
  '641-94-108394': 'DECORACIONES',
  '27047-36-226926': 'DELICIAS CASERAS',
  '5-22-289': 'DELIS ASPRILLA',
  '1932625-1-728313': 'DELLAROCCA',
  '2451617-1-812350': 'DESAROLLO',
  '273003-1-406331': 'DESARROLLO INMOBILIA',
  '155655274-2-2017': 'DESEISA',
  '8-995-374': 'DIEGO FERNANDEZ',
  '6-713-2409': 'DIEGO H.',
  '8-817-1838': 'DIEGO TORAL',
  '1312829-1-608607': 'DIGITAL SURVEY PANAM',
  '1402512-1-628224': 'DIMSA',
  '8-712-1347': 'DIOSELINA NUÑEZ',
  '1719593-1-690057': 'DIPSA',
  '8-841-1875': 'DISCOVERY STORE',
  '9-123-819': 'DISEÑO ABREGO',
  '8-884-1185': 'DISFRUTA MARKET',
  '1644283-1-674355': 'DIST',
  '1835351-1-711000': 'DIST. EL PARAISO',
  '8-741-1584': 'DIST. GENERAL',
  '1296422-1-604364': 'DISTRIBUIDORA MALLAS',
  '704243-1-469449': 'Distribuidora ROAD',
  '207939-1-396393': 'DOLCO',
  '155642124-2-2016': 'DRENAJE Y ACABADOS',
  '2559278-1-827532': 'DRYWALL',
  '808-349-126446': 'DULCERIA',
  '1480780-1-643575': 'DUNKIN DONUTS',
  '22356-138-200249': 'DURMAN',
  '2577777-1-830452': 'EA',
  '1542396-1-655901': 'ECHO SUPPLIERS',
  '50198-67-317494': 'ECLIPSE',
  '155637191-2-2016': 'ECOFIBRA',
  '2509762-1-820194': 'ECOFONT SOLUTION S.',
  '1848722-1-713290': 'ECOGREEN',
  '253525-1-403482': 'ECONOPRECIOS',
  '1822974-1-709335': 'ECOTEC',
  '2588862-1-832056': 'EDEMET',
  '8-479-94': 'EDGAR',
  '3-721-93': 'EDGAR MADRID',
  '8-749-2141': 'EDGAR PINEDA',
  '8-762-243': 'EDGAR R',
  '8-493-111': 'EDGAR REYES',
  '2282800-1-787224': 'EDIFIC',
  '155615043-2-2015': 'EDIFICACIONES RA',
  '1556022718-2-2015': 'EDIFICIO PH DESIGN',
  '2104347-1-757420': 'EDIL PANAMA',
  'E8-109832 ': 'EDISON DIAZ',
  '8-866-1346': 'EDUARDO BARRIA',
  '8-334-829': 'EDUARDO HIDALGO',
  '9-162-557': 'EDUARDO PENALBA',
  '9-749-1681': 'EDUARDO RODRIGUEZ G.',
  '9-121-1812': 'EDWIN A.',
  '2-709-731': 'EDWIN CORDOBA',
  PASAR991603: 'EDWIN GONZALEZ',
  '8-812-315': 'EDWIN PITTI',
  '4-125-1203': 'EFRAIN  STAFF',
  '1529636-1-653209': 'EL CANGREJO 77',
  '1367163-1-620949': 'EL FUERTE LA CHORRER',
  'E-1158-26838': 'EL GRAN ESCUDO',
  '61624-1-372612': 'EL HOMBRE',
  '9-706-2570': 'ELEADIS A.',
  '155659349-2-2017': 'ELECTRICONTROLES',
  '25717-2-220038': 'ELECTRIHOGAR',
  '998-470-112423': 'ELECTRONICA',
  'N-21-758': 'ELECTRONICA GEO',
  'PE-14-1379': 'ELECTRONICA HONGKONG',
  '63444-11-354998': 'ELECTRONICA JAPONESA',
  '8-741-927': 'ELEGANT CARS',
  '2885-2-45956': 'ELEMENTOS INDUSTRIAL',
  '9-742-975': 'ELEONOR',
  '55624-79-333990': 'ELEVADORES GOLDSTAR',
  '6-69-212': 'ELIAS',
  '2-725-1077': 'ELIAS SANCHEZ',
  '987695-1-533551': 'ELIMAR DISTRIBUIDOR',
  '8-800-2395': 'ELIZABETH',
  '8-745-207': 'ELOY',
  '8-841-767': 'EMANUEL CARMICHAEL',
  '6-55-791': 'EMERITA GOMEZ',
  '8-915-1307': 'EMMANUEL',
  '8-841-767': 'EMMANUEL CARMICHAEL',
  '8-793-2173': 'EMMANUEL MOSES',
  '155599193-2-2015': 'EMMAYS',
  '687603-1-465745': 'EMPAQUES JETFA',
  '1080323-1-554308': 'EMPRESAS CARBONE',
  '1628016-1-671329': 'EMPRESAS YADORED',
  '45408-55-299957': 'ENA CORREDORES',
  '8-950-24117': 'ENRIQUE AVILA',
  '57983-56-340439': 'ENSA',
  '1634357-1-672463': 'EPISTMO',
  '2359862-1-799090': 'EPPS PANAMA',
  '8-760-1969': 'EQUIPOS NGM',
  '155611806-2-2015': 'EQUIPOS R',
  '1584240-1-663113': 'EQUIPOS Y MAQUINARIA',
  '394576-1-423463': 'EQUIPOS Y PROYECTOS',
  '1006385-1-537214': 'EQUIPOS Y TALLER',
  '2185784-1-771010': 'EQUISER',
  '12010-68-119752': 'EQUITEM',
  '155625215-2-2016': 'EQUITRANS',
  '8-301-342': 'ERIC',
  '8-799-477': 'ERICK ESPINOSA',
  '799-501-144983': 'ERIDANI',
  '9-705-1531': 'ERIS SOTO',
  '2-135-14': 'ERUBHEY LUNA',
  '155624806-2-2016': 'ESCAPE',
  '36726-28-264538': 'ESPUMAS',
  '8-730-1952': 'ESTRUCTURAS CONTEMPO',
  '908-242-19211': 'ETERNA PLAQUE',
  '8-NT-1-23044': 'ETICA',
  '8-807-680': 'EURI NAVARRO',
  '2068344-1-751048': 'EURIBIADES BARRIOS',
  '2325580-1-793711': 'EURO CONSORZIO',
  '8-385-711  ': 'EUSEBIO PACHON',
  '8-142-384': 'EUSEVIA ZAMORA',
  '1403474-1-628418': 'EVENTOS Y EVENTOS',
  '8-272-853': 'EVERALDO',
  '1347823-1-616558': 'E-VISION',
  '553020-1-443698': 'EXTINTORES',
  '8-455-98': 'EYBAR',
  '1264949-1-596440': 'EYR PANAMA',
  '8-392-12': 'EZEQUIEL AIZPRUA',
  '7-102-9': 'EZEQUIEL QUINTERO',
  '8-514-1702': 'FABIAN ALFARO',
  '8-530-2040': 'FABRICA EDEIDA',
  '4283-8-58158': 'FABRICA INT. CALZADO',
  '438412-1-429831': 'FAMILY DOLAR',
  '776902-1-486934': 'FARMA PLUS',
  '28125-164-230274': 'FARMACIA SAN GERARDO',
  '2123632-1-760780': 'FARMACIAS EUROPEAS',
  '155668509-2-2018': 'FDC MAINTENANCE',
  '2436156-1-810135': 'FEDALVI',
  '155618602-2-2015': 'FEDERAL SECURITY',
  '40034-101-277995': 'FEDPA',
  '1209-114-126105': 'FEINSA',
  '24638-93-213823': 'FELIPE RODRIGUEZ',
  '8-923-892': 'FELIX LEZCANO',
  '748965-1-479629': 'FERGU',
  '1000-331-112415': 'FERGUNSON',
  '8-228-414': 'FERNANDO ALVARADO',
  '6-703-2397': 'FERNANDO BATISTA',
  '2-102-1248': 'FERNANDO P.',
  '8-487-512': 'FERNANDO PORCELL',
  '155605918-2-2015': 'FERRALLAS',
  '155674761-2-2019': 'FERRECENTER',
  '155725350-2-2022': 'FERRECENTER CABIMA',
  '155643349-2-2017': 'FERREMASTER PRIVAL',
  '8-813-1587': 'FERRESTORE',
  'N-19-1156': 'FERRETERIA 88',
  '8-812-1680': 'FERRETERIA ALI',
  'PE-9-1213': 'FERRETERIA GORGONA',
  '155697061-2-2020': 'FERRETERIA HOME CENT',
  '8-774-2093': 'FERRETERIA LA BAHIA',
  '1902966-1-723021': 'FERRETERIA PLAYA COR',
  '8-869-2057': 'FERRETERIA SONG',
  '8-903-1631': 'FERRETERIA WILSON',
  '8-237-1518': 'FIDEL TORRES R.',
  '19141-0038-175056': 'FINANCOMER',
  '3305-148-49959': 'FINANZAS GENERALES',
  '155634754-2-2016': 'FINCA',
  '155634754-2-2016': 'FINCA LOS MARIN, S.A',
  '12117-222-120843': 'FIREMASTER',
  '9-140-838': 'FLORENTINO ALVARADO',
  '2483281-1-816571': 'FONSECA SOFTWARE, S.',
  '155640940-2-2016': 'FOOTWEAR HIDALGO',
  '15094-101-145958': 'FORMAS UNIVERSALES',
  '456-599-99936': 'FORMETAL',
  '213618-1-397361': 'FORMICA DISTRIBUTION',
  '505333-22-318586': 'FORTESA',
  '3-74-1232': 'FRANCISCO  MOR',
  '9-708-2312': 'FRANCISCO A.',
  '7-107-483': 'FRANCISCO DE GRACIA',
  '8-741-1554': 'FRANCISCO J. GOMEZ',
  '8-123-843': 'FRANCISCO M',
  '6-710-699': 'FRANCISCO RODRIGUEZ',
  FB502711: 'FRANCY',
  AQ529591: 'FREDY OJEDA',
  '381326-1-421752': 'FRIENDS FAMILY SERVI',
  '8-480-942': 'FRUTERIA MINI MARKET',
  '518-407-112698': 'FUCASA',
  '1112134-1-562327': 'FUMI PRO',
  '2611827-1-835481': 'FUMIGADORA PANAMA',
  '7-74-199': 'FUMIGADORA SELECTA',
  '1004195-1-536701': 'FUMITEX',
  '25030434-3-2016': 'FUND SENDEROS DE LUZ',
  '96352-1-3059': 'FUND. CEN',
  '518-407-112698': 'FUNDICION',
  '2217547-1-775888': 'G&G INGENIEROS, S.A.',
  '66104-114-363217': 'GALERIA',
  '436-287-95110': 'GASES',
  '8-727-1441': 'GASPAR C.',
  '155659992-2-2018': 'GASPRO',
  '8-260-179': 'GASTEL TUÑON',
  '2392712-1-803829': 'GEDECAR',
  C01993004: 'GEOVANNY ZELEDON PAD',
  '1264843-1-596415': 'GIC',
  '7-103-301': 'GILBERTO GARRIDO',
  '1779474-1-701653': 'GLASSYG, S.A.',
  '48870-93-312745': 'GLOBAL BRANDS',
  '155602484-2-2015': 'GLOBAL ILUMINATION',
  '1765038-1-698998': 'GLOBAL PARKING',
  '2389318-1803321': 'GLOBAL PUMP',
  '938619-1-523242': 'GLOPER',
  '8-516-1887': 'GLORIELA',
  '1308215-1-607559': 'GMG',
  '2619280-1-836478': 'GNG',
  '2246972-1-780780': 'GO RENTALS',
  '652-212-129962': 'GOLY',
  '155711234-2-2021': 'GR PROPERTY',
  '2076697-1-752464': 'GREAT VIEW',
  '155626066-2-2016': 'GRUACO',
  '155645096-2-2017': 'GRUAS',
  '2110333-1-758424': 'GRUAS AAA',
  '8-492-893': 'GRUAS Y TRANSPORTES',
  '155604102-2-2015': 'GRUMONHER',
  '155701501-2-2021': 'GRUPO ADIPSA',
  '155589097-2-2014': 'GRUPO ALQUIMAQ, S.A.',
  '155589917-2-2014': 'GRUPO CASTEL',
  '155697721-2-2020': 'GRUPO DE INVERSIONES',
  '63275-2-354567': 'GRUPO DISCOVERY',
  '155596391-2-2015': 'GRUPO ENDARA MURALLE',
  '2588079-1-831952': 'GRUPO GOMEZ',
  '2129767-1-761745': 'GRUPO JM PANAMA',
  '155712027-2-2021': 'GRUPO MEPCO',
  '155598393-2-2015': 'GRUPO MJE',
  '155621991-2-2016': 'GRUPO SEIN',
  '40860-45-281434': 'GRUPO UPS',
  '155644568-2-2017': 'GRUPO VASTAGO',
  '1239993-1-590917': 'GRUPO VIVE',
  '155668158-2-2018': 'GRUPO WILLYS',
  '8-748-1239': 'GUILLERMO GONZALEZ',
  '155678527-2-2019': 'H&C EMPRESA',
  '2452911-1-812521': 'H&R CONSTRUCCIONES',
  '1640109-1-673569': 'HACIENDA',
  'PE-9-1289': 'HAI CHAO HAU LEUNG',
  '28878-23-233240': 'HAPPY COPY',
  '1987570-1-738131': 'HAVA INTERNATIONAL',
  '8-438-185': 'HEAVY METAL',
  '8-871-1845': 'HECTOR T.',
  '1363193-1-620203': 'HELVEX',
  '8-502-511': 'HERIKA H.',
  '8-291-238': 'HERMAN DEGRACIA',
  '8-526-1380': 'HERNAN MOSQUERA',
  '27770-59-229110': 'HERRAMIENTAS Y TORNI',
  '8-251-853': 'HERRERIA MANUEL',
  '1112362-1-562409': 'HERTZ',
  '1030948-1-542774': 'HIELO CRISTAL',
  '2098340-1-756331': 'HIM',
  '386941-1-422480': 'HOME',
  '1403506-1-628432': 'HOME ELECTRICIDAD',
  '8-800-2004': 'HOME HANDYMAN',
  '1989717-1-738465': 'HORMIGON',
  '29658-1-368019': 'HORMIGON EXPRESS',
  '11286-40-113797': 'HOTEL SAN CARLOS',
  '2157466-1-766370': 'HUMANIDEA, S.A.',
  '8-460-605': 'HUMBERTO MORENO',
  '2065065-1-750454': 'HUURRE PANAMA',
  '678826-1-464082': 'ICAPSA',
  '8-NT-1-10284': 'IDAAN',
  '155645778-2017': 'IDIMACOL,S.A.',
  '2268023-1-784648': 'IL TEATRO',
  '25654-72-219757': 'IMAGEN GALERIA',
  '18439-166-171151': 'IMP SEV',
  '196-8-47222': 'IMPADOEL',
  '155608420-2-2015': 'IMPERIO ELECTRICO',
  '1157200-1-573083': 'IMPORT EXPORT MAYCE',
  '373960-1-70621': 'IMPORTADORA & EXPORT',
  '35226-2-259295': 'IMPORTADORA BATERIAS',
  '1556665446-2-2018': 'INDUSTRIA PANAMEÑA',
  '2355-163-40903': 'INDUSTRIAS EUPAN',
  '155610187-2-2015': 'INFINITY',
  '1271678-1-598065': 'ING. A',
  '9-140-245': 'INGENIERIA ALFA',
  '36470-37-263562': 'INGENIEROS GEOTECNIC',
  '935634-1-522617': 'INMOBILIARIA CIELO A',
  '14332-83-139405': 'INMOBILIARIA RESIDEN',
  '155679946-2-2019': 'INSA',
  '2152680-1-765592': 'INSIGNIA FINANCIAL',
  '8-NT-2-13611': 'INST RAUL',
  'E-8-134098': 'INSTALACIONES F&F',
  '1556390-1-658256': 'INTACO PANAMA S.A.',
  '33987-137-254116': 'INTERA',
  'E-8-13698': 'INTERAMERICANA DE UN',
  '2092851-1-755466': 'INTERNATIONAL GROUP',
  '2610216-1-806194': 'INV. DJC',
  '834590-1-502479': 'INV. JIMAD',
  '2602061-1-834036': 'INV.JENISA',
  '2458299-1-813185': 'INVERSION ZHZ PRITTY',
  '8-825-412': 'INVERSIONES',
  '1534393-1-654134': 'INVERSIONES CREPUSCU',
  '155650126-2-2017': 'INVERSIONES HPC',
  '1480780-1-643575': 'INVERSIONES RIO',
  '155660766-2-2018': 'INVERSIONES UNICO',
  '1324143-1-611272': 'IPD PREVEN',
  '155639594-2-2016': 'IRAWANDI',
  '24492-56-213007': 'IRENE CRISTY, S.A.',
  '3-729-1403': 'ISAIAS ANDRION',
  '8-813-413': 'ISIS SANDOVAL',
  '8-704-1387': 'ISKYAM BERNAL',
  '8-234-256': 'ISOLEND',
  '8-764-256': 'ISRAEL B.',
  '8-702-2340': 'ISRAEL HERRERA',
  '155595180-2-2015': 'ITALIAN FINISHES',
  '8-304-633': 'ITZEL LEWIS',
  '8-715-1303': 'ITZEL RAMOS',
  '8-794-2083': 'IVETH M.',
  '8-754-1080': 'IVIS OBALDIA',
  '49115-120-313810': 'J P & ASOCIADOS',
  '2364301-1-799749': 'J.J. TRANSP',
  '8-500-603': 'JACOB RAMOS',
  '8-752-779': 'JAIME AREVALO',
  '3-710-2479': 'JAIME DE LEON',
  '7-710-2377': 'JAIME FLORES',
  '8-165-1079': 'JAIME SALAS',
  '3-733-1174': 'JAIRO ALVARADO',
  '8-NT-2-27669': 'JAIRO MATEUS',
  '4-717-1244': 'JAMIE G.',
  '2264126-1-783916': 'JANUAR',
  '8-461-440': 'JAVIER MAGUE',
  '8-741-1313': 'JAVIER RAMOS',
  '8-338-947': 'JAVIER YANGUEZ',
  '9857-220-101047': 'JELLINI',
  AX208750: 'JENNY ORREGO',
  '8-829-1506': 'JESSICA BLANCO',
  '8-707-315': 'JESUS CORREA',
  '1464405-1-640486': 'JETCO',
  '8-758-618': 'JHONNY ACARREO',
  '1601513-1-666431': 'JIDSA',
  '8-825-412': 'JIMMY YAÑEZ',
  '8-892-2428': 'JOEL DOMINGUEZ',
  AU095979: 'JOHANA G.',
  '8-1013-2214': 'JOHN MOSES',
  '4-99-2565': 'JORGE AVILES',
  '8-929-1081': 'JORGE G.',
  '2-714-540': 'JORGE LORENZO',
  '2-702-2244': 'JORGE O.',
  '2-718-654': 'JORGE UREÑA',
  '2-709-700': 'JOSE A.',
  '8-529-605': 'JOSE A. S.',
  '8-488-702': 'JOSE ANGULO',
  '9-145-45': 'JOSE BARRERA',
  AU606475: 'JOSE CANO HENAO',
  '8-730-987': 'JOSE D',
  '6-83-284': 'JOSE LUIS CHAVEZ',
  '8-772-1831': 'JOSE LUIS SOTO',
  '8-349-222': 'JOSE MELGAREJO',
  '8-235-1029': 'JOSE MOJICA',
  '8-911-118': 'JOSE RAUL S.',
  '8-766-2206': 'JOSE ZHONG CHUNG',
  '2-119-271': 'JOSELITO S.',
  '8-816-464': 'JOSHUA SUÑE',
  'E-8-91934': 'JOUL',
  '8-237-663': 'JRAULDIEZ',
  '2-721-1030': 'JUAN DOMINGUEZ',
  '8-780-1923': 'JUAN MIGUEL LOO',
  '2-102-2609': 'JUAN O.',
  '8-723-2315': 'JUAN RAUL',
  '317300-1-412496': 'JUANRA',
  '4-262-495': 'JULIO PITTI',
  '8-837-138': 'JUVENAL A.',
  '8-983-2302': 'KEVIN JAVIER CEDEÑO',
  '8-894-1028': 'KEYRIS SALDANA',
  '2381575-1-802236': 'KOBO',
  '1821414-1-708978': 'KONZERTA',
  '22330-1-367028': 'KOPY  KAT INC.',
  '1114-207-118013': 'LA CASA DE LA BATERI',
  '1693288-1-685048': 'LA CASA DEL EQUIPO F',
  '155151-1-386703': 'LA CASA DEL TANQUE',
  '155594203-2-2015': 'LA CASA DEL TELEFONO',
  '2432150-1-809388': 'LA ONDA LOS ANDES',
  '323501-1-413507': 'LABORAT.',
  '1892188-1-721142': 'LABORATORIO CLINICO',
  '155636345-2-2016': 'LABORATORIO EXPRESS',
  '155627472-2-2016': 'LABORATORIO TECNICO',
  '10189-2-104236': 'LAS HADAS',
  '53284-129-327308': 'LATINOAMERICANA',
  '8-743-830': 'LCC',
  '1673264-1-680183': 'LED LIFE CORP',
  '2691790-1-846905': 'LEGAL LINKS',
  '3-NT-2-45334': 'LEOMAR ARAUJO',
  '8-415-286': 'LEONEL MORALES MARIN',
  '2336943-1-795680': 'LETRAS VISUAL MARKET',
  '1746002-1-695357': 'LETREROS & GRAFICOS',
  '4-745-1190': 'LEYDIS LOPEZ',
  '8-726-1288': 'LIANA RIOS',
  '1840337-1-711890': 'LIFTOR PANAMA',
  '6-88-433': 'LILIANA DIAZ',
  '8-846-2377': 'LIZ MARIE',
  '233107-1-400353': 'LOAM ARTS',
  '155718399-2-2022': 'LORIMAR',
  '8-858-2046': 'LOVIBLOOM',
  '8-734-1886': 'LTF MANTENIMIENTOS',
  '2-734-1077': 'LUIS A. R.',
  '25029303-3-2016': 'LUIS ALFARO',
  '8-870-2179': 'LUIS C',
  '8-354-121': 'LUIS DIAZ',
  '8-800-1239': 'LUIS ELOY',
  '8-870-2179': 'LUIS J.',
  '8-825-1107': 'luis MEDINA',
  '8-859-275': 'LUIS MORALES',
  '8-342-349': 'LUIS R. RAMOS B.',
  C01524255: 'LUISA L.',
  '8-425-104': 'LUMENES HD',
  '1765961-1-699157': 'LUMI',
  '1131936-1-567333': 'LURIAN',
  AU49769: 'LUZ CARIME ORTIZ',
  AU497690: 'LUZ ORTIZ',
  '1575414-1-661569': 'LUZMER',
  '8-232-190': 'M&J SUPPLIERS',
  '1386415-1-625291': 'M.A.M.',
  '537091-1-441028': 'MAC STORE',
  '1368933-1-621296': 'MACCAFERRI DE PANAMÁ',
  '652-212-129962': 'MACHETAZO',
  '155639576-2-2016': 'MACKAY',
  '57918-43-340215': 'MADE IN ITALY',
  '8-804-2375': 'MADELYN M.',
  '2-717-259': 'MAGDIEL MARTINEZ',
  '849508-1-505051': 'MAIL',
  '1754349-1-697054': 'MANAS CAFE',
  '8-515-1554': 'MANUEL A.',
  '8-257-24': 'MANUEL CASTILLO',
  '5-709-1510': 'MANUEL ISAZA',
  '8-737-1753': 'MAQUIR OTERO',
  '8-769-272': 'MARCIAL',
  '8-862-1068': 'MARCOS LOO',
  '1910578-1-724412': 'MARIA DOS,S.A.',
  '8-824-2177': 'MARIA FLORA TRIGAS A',
  '8-864-811': 'MARIBEL AGUILAR',
  '8-786-1104': 'MARIO MARTINEZ',
  '4-738-2200': 'MARLON',
  '9-737-2115': 'MARY AGUILAR',
  '155617937-2-2015': 'MAS VIDA',
  '155612583-2-2015': 'MASTER GROUP PANAMA',
  '159470-1-387492': 'MASTERS SIGNS',
  '8-308-487': 'MAT SANTA FE',
  '8-851-871': 'MAT. Y PINTURAS YU',
  '1375546-1-622612': 'MATCO PRIVAL',
  '155655240-2-2017': 'MATERIALES MIGUEL H.',
  '8-949-385': 'MATERIALES MULTIPLE',
  '32180-161-246536': 'MATERIALES OROZCO, S',
  '1891245-1-720993': 'MATERIALES WU',
  '8-851-871': 'MATERIALES Y PINTURA',
  '3-713-1486': 'MAURICIO ANDRION',
  '11-19-2263': 'MAXI',
  'C-6-56-957': 'MAXIMO GONZALEZ',
  '155591597-2-2015': 'MAXINDUSTRIAS',
  '849508-1-505051': 'MBE CHAME',
  '557-538-101617': 'MC DONALDS',
  '2628455-1-837648': 'MC DONALDS COSTA',
  '59185-80-343740': 'MDM SEC',
  '8-NT-1-12571': 'MEF',
  '1049279-1-547092': 'MEGA SILANTS',
  ' 279-8532-365138 ': 'MERCADEO',
  '278447-1-407056': 'MERIS MONTES O SALUD',
  '7-709-847': 'METAL SHOP',
  '916-586-106273': 'METALES S.A.',
  '753467-1-480659': 'METRO GOLDEN',
  '541-81-118009': 'METRO PLUS',
  '8-897-1376': 'METROS CUADRADOS',
  'E-8-121882': 'MICHAEL ROTTENBERG',
  '8-851-1160': 'MIGUEL',
  '8-761-64': 'MIGUEL AROSEMENA',
  '8-962-1763': 'MIGUEL R',
  '8-160-114': 'MIGUEL RODRIGUEZ',
  '8-847-827': 'MIKEYBIS ALONSO',
  '8-847-827': 'MIKEYDIS ALONSO',
  '2673251-1-844031': 'MIL DETALLES',
  '66093-28-363182': 'MIMI FASHIONS',
  '314026-1-411961': 'MINERA SAN CARLOS, S',
  '8-825-1102': 'MINI MARKET MIX',
  '1895740-1-721744': 'MINI MED',
  '8-137-800': 'MINI SUPER CAMPO LIM',
  '8-962-1420': 'MINI SUPER JULISSA',
  'N-19-2405': 'MINI SUPER SAN FERNA',
  '1177396-1-577437': 'MINI-MAX',
  '8-NT-2-5498': 'MINISTERIO DE AMBIEN',
  '8-864-200': 'MINI-SUPER ESTEBAN',
  '4-97-2152': 'MIRNA BRENES',
  '1300361-1-605417': 'MISCELLANY',
  '8-NT-1-12607': 'MITRADEL',
  '8-NT-1-12607': 'MITRADEL.',
  '8-823-2287': 'MITZI',
  '155631030-2-2016': 'MIXO',
  '2-98-2102': 'MODESTO AGRAZAL',
  '2-103-1278': 'MOISES',
  '8-526-1673': 'MOISES MOSQUERA',
  '8-810-741': 'MOISES RAUL SAENZ',
  '3-712-540': 'MONSA',
  '128856-1-382436': 'MONTEMAR E HIJOS',
  '8-NT-1-14274': 'MOP',
  '155594311-2-2015': 'MTE PANAMA',
  '8-510-395': 'MULT. Y SERV. JACEK',
  '3-NT-2-22287': 'MULT.CHRISTIAN',
  '10881-146-110583': 'MULTI COMERCIAL',
  '1002-214-109639': 'MULTI REPUESTO',
  '8-NT-2-14859': 'MULTI SERVICIOS',
  '22480-45-201122': 'MULTIBANK INC.',
  '94683-1-377731': 'MULTIBAR',
  '1467315-1-641243': 'MULTICONTAINER',
  '8-NT-2-732458': 'MULTILLAVES PACIFICO',
  '8-499-355': 'MULTISERVICIO JOSEPH',
  '2689154-1-846399': 'MULTISERVICIOS GG',
  '8-NT-2-14859': 'MULTISERVICIOS NEW',
  '8-NT-2-32799': 'MULTISERVICIOS Y ACA',
  '8-752-514': 'MUNDO ACRILICO',
  '1052560-1-547986': 'Mundo Ferretero',
  '8-NT-1-12701': 'MUNICIPIO DE PANAMA',
  '8-NT-1-21897': 'MUNICIPIO SAN CARLOS',
  '8-NT-1-12667': 'MUNICIPIO SAN MIGUEL',
  '2-NT-1-753': 'MUNICIPO DE ANTON',
  '8-791-1200': 'MWM',
  '8-718-697': 'NADIEZDA',
  '155630390-2-2016': 'NATA',
  '8-703-59': 'NAZARIO O.',
  '9120-32-93747': 'NCH PANAMA',
  '2-713-2003': 'NELSON ALEXANDER MOR',
  '82-30-15216': 'NESTLE',
  '8-701-944': 'NEYLA SMITH',
  '2058697-1-749331': 'NIEROSA',
  '8-894-1303': 'NINIBETH L.',
  '593634-1-450499': 'NOX-CRETE',
  '2220787-1-776420': 'O C WINDOWS',
  '656-413-132700': 'O D PANAMA',
  '1556409-2-2016': 'O ZAPATO',
  '1048969-01-547050': 'OCB',
  '6-706-659': 'OESTE VET',
  '155657269-2-2017': 'OFFICE OUTLET',
  '2548144-1-825980': 'OGD',
  PASG213891: 'OLDEMAR ANARIBA',
  '8-471-144': 'OLMEDO GARCIA',
  '2-108-386': 'OMAR LASTRA',
  '155594857-2-2015': 'OMIZ',
  '8-954-2202': 'ORLANDO H.',
  '8-701-2352': 'ORLANDO HENRIQUEZ',
  '8-740-1652': 'OSORIO B',
  '3-715-1944': 'OSVALDO DAVIS',
  '8-NT-2-8965': 'P.H. BUENAVENTURA',
  '8-NT-2-10477': 'P.H. CONDOMINIO',
  '8-NT-2-211106': 'P.H. SANTAMARIA',
  '8-161-827': 'PABLO TORRES',
  '2023051-1-743689': 'PACEQUIP',
  '272750-1-406249': 'PACIFICA SALUD',
  '155639500-2-2016': 'PACKIT',
  '8-872-1615': 'PALMS CAR WASH',
  '155646971-2-2017': 'PANA COMPU',
  '66159-91-363381': 'PANAFOTO',
  '66159-91-363381': 'PANAFTO',
  '67-36-20135': 'PANAMA AUTO',
  '2335668-1-795413': 'PANAMA BOTANICALS',
  '1158561-1-573333': 'PANAMA FORKLIFT',
  '155632738-2-2016': 'PANAMA IMPORTS',
  '2261991-1-783510': 'PANAMA LIGHTING',
  '155630704-2-2016': 'PANAMA POLIGRAFIA',
  '2410916-1-806320': 'PANAMA SAFETY',
  '866-176-101773': 'PANAMOTOR',
  '407877-1-2019': 'PANAVEN',
  '155664718-2-2018': 'PANAWEB, S.A.',
  '46446-56-303710': 'PANEDI',
  '835943-1-502623': 'PAREC',
  '2515816-1-821115': 'PARKET',
  '2054724-1-748584': 'PARKING',
  '8-NT-1-23097': 'PATRONATO',
  '8-285-896': 'PATTERSON',
  '8-790-2035': 'PAULLETTE PEREZ',
  '14142-188-137811': 'PAYDAY',
  '4-206-479': 'PEDRO',
  '143-95-36679': 'PEMCO',
  '874008-1-509731': 'PENROD',
  '155688011-2-2019': 'PERFILES AZULES',
  PAS93841137: 'PERSONAJES PANAMA',
  '1016-327-115007': 'PESQUEROS',
  '155644168-2-2017': 'PETROLEOS',
  '2157184-1-766323': 'PETROLEOS CHITRE',
  '8-NT-2-7437': 'PH BLOC',
  '8-NT-2-5000690': 'PH PLAZA SIGNATURE',
  '155684766-2-2019': 'PINTAR PANAM',
  '2-721-555': 'PINTUCIELOS',
  '766-19-135058': 'PINTUCO',
  '1943312-1-730349': 'PINTURAS',
  '5-NT-2-702073': 'PINTURAS DECORATIVAS',
  '825-543-101039': 'PINTURAS DEL ISTMO',
  '1916511-1-725423': 'PINTURAS NAZARETH',
  '155602718-3-2015': 'PL SERVICES',
  '155632738-2-2016': 'PLASTICOS',
  '155632738-2-2016': 'PLASTICOS DE PANAMA',
  '155631407-2-2016': 'PLASTIMEX',
  '1580821-1-662504': 'PLOMERIA',
  '155631762-2-2016': 'PLOMERIA-2',
  '1994898-1-739253': 'POLYMATH GROUP',
  '741180-1-477895': 'PORTAFOLIO',
  '155621096-1-2016': 'POST',
  '2645515-1-840083': 'POSTNET',
  '2113280-1-758901': 'POSTNET JJ',
  '59849-1-372370': 'POWER GEN',
  '39293-52-274981': 'POWERTRONIC',
  '1507-374-1379': 'PPZA',
  '1075658-1-553254': 'PREASA',
  '1718242-1-689824': 'PREFABRICADOS',
  '1372866-1-622049': 'PREFABRICADOS PANAMA',
  '155614710-2-2015': 'PREMEDIC',
  '1831840-1-710541': 'PREMIUM CONSTRUCTION',
  '1925708-1-727160': 'PREMIUM PAINT',
  '1478646-1-643314': 'PRESTA PANAMA, S.A.',
  '898-241-102416': 'PRICE SMART',
  '60573-2-347155': 'PRICEWATERHOUSE',
  '155660810-2-2018': 'PRIMA PORTA',
  '764361-1-483448': 'PRINT SOLUTIONS',
  '60812-21-347739': 'PRISMA',
  '35700-84-260974': 'PRISMA COLORS',
  '155596424-2-2015': 'PRIVAL SM BUSINESS',
  '32883-12-249602': 'PRO',
  '32883-12-249602': 'PRO DESARROLLO',
  '118726-1-3811444': 'PROCESADORA',
  '186-505-46878': 'PROD.SUP.',
  '389-449-86115': 'PRODUCTOS',
  '71580-1-374088': 'PRODUCTOS SEGURIDAD',
  '2027396-1-744319': 'PRODY',
  '55560-31-333873': 'PROFESSIONAL CLEANER',
  '48058-37-309596': 'PROFUTURO',
  '1602616-1-666594': 'PRONTO',
  '155618602-2-2015': 'PROTECCION',
  '2432601-1-809477': 'PROTEK',
  '1157200-1-573083': 'PTYTEC COMPUTER SHOP',
  '155712177-2-2021': 'PUMA ENERGY CHANIS',
  '155720085-2-2022': 'PUMA ENERGY LLANO',
  '155703776-2-2021': 'PUMA ENERGY SPEEDWAY',
  '155664121-2-2018': 'PUMA ENERGY V. LUCRE',
  '155669058-2-2018': 'PUNTO',
  '1914582-1-725026': 'PUNTO PAGO PANAMA',
  '8-761-1958': 'PURE TECH',
  '35811-0021-261362': 'PURISSIMA',
  '373-54-11533': 'QUIJANO',
  '155626357-2-2016': 'RADIOS AND SIGNALS',
  '8-750-305': 'RAMON MOSES',
  '8-852-555': 'RAMON NORIEGA',
  '6-711-465': 'RANNDY ARENA',
  '155705019-2-2021': 'RDJ ACERTA',
  '155597031-2-2015': 'RED',
  '1707501-1-687817': 'REDE SOLUTIONS, S.A.',
  '337-429-8502': 'REFRIGERAMA, S.A.',
  '8-NT-1-22244': 'REGISTRO',
  '155652353-2-2017': 'REMAX',
  '2572478-1-829702': 'RENTA',
  '1856382-1-714680': 'REP GISA',
  '237799-1-401090': 'REP. COMERCIALES',
  '15025-77-145429': 'REP. MUNDIALES',
  '8-720-2159': 'REP.REM.',
  '740329-1-477714': 'REPAGENSA',
  '8-720-2159': 'REPARACIONES',
  '10342-10-105577': 'REPAYCAR',
  '10342-10-105577': 'REPUESTOS',
  '2511977-1-820466': 'RESTAURANTE ACHA',
  '2089204-1-754807': 'RETRO Y PALA S.A.',
  '342-240-75526': 'RIBA SMITH',
  '6-79-179': 'RICARDO C.',
  '8-735-830': 'RICARDO H.',
  '8-204-1552': 'RICARDO OSORIO',
  '8-927-1627': 'RICARDO PAYARES',
  '8-743-1891': 'RICARDO V.',
  '9-203-396': 'RICARDO VARGAS',
  '8-518-2009': 'RICAURTE VALDESPINO',
  '20149-1-366721': 'RIGSA',
  '48717-9-311974': 'RINCON TABLEÑO',
  '1226206-1-587545': 'RITA CHANGMARIN',
  '8-833-1424': 'ROBERTO CASTRO',
  '3-88-1628': 'ROBERTO CHONG',
  '696272-1-467757': 'ROCAYOL SAFETY',
  '204-394-49730': 'RODELAG',
  '939572-1-523411': 'RODIO SWISSBORING',
  '155703200-2-2021': 'RODMAN STORE',
  '8-824-131': 'RODRIGO RODRIGEZ',
  '8-471-30': 'ROGELIO FRIAS',
  '1126614-1-565823': 'ROGER',
  C02566135: 'ROGER ARGUELLO',
  '8-296-716': 'ROLANDO GOMEZ',
  '8-476-710': 'ROLANDO TORRES',
  '155624503-2-2016': 'ROM HEM',
  '8-836-156': 'RONALD ROCHA',
  '1516113-1-650591': 'ROTULACION',
  '3-105-57': 'ROYCE OLIVEROS',
  '6-56-526': 'RUTILIO FALCON',
  '228076-1-399498': 'SAFETY MANAGEMENT',
  '155680341-2-2019': 'SAFETY SOLUTIONS',
  '800741-1-494880': 'SALVAVIDAS',
  '8-241-545': 'SAMUEL BRENES ROSAS',
  AX209542: 'SAMUEL GONZALEZ',
  '9-717-1305': 'SAMUEL VALDES',
  '150-291-38284': 'SAN FERNANDO',
  '8-308-487': 'SANTA FE',
  '15599686-2-2015': 'SANTA MARIA',
  '155695999-2-2020': 'SAPRO',
  '8-271-655': 'SARLAT HERNANDEZ',
  '1818141-1-708506': 'SAUGA SERVICES S.A.',
  '8-833-2461': 'SAUL NUÑEZ',
  '8.33E+11': 'SAYER PANAMA',
  '266485-1-842660': 'SEASONS PLUS, S.A.',
  '61648-22-349880': 'SECUTIRY',
  '155703071-2-2021': 'SEGURIDAD UNIDA',
  '743-444-139841': 'SELECTA',
  '155656168-2-2017': 'SEMCO PANAMA',
  '37822-77-269113': 'SEMM',
  '8-518-1911': 'SENSACION MUSICAL',
  '2116800-1-759501': 'SEQUIP S.A.',
  FB602339: 'SERGIO BERMUDEZ',
  '1575414-1-661569': 'SERV GENE LUZMER',
  '73-561-21585': 'SERV. ELEC',
  '2-103-1278': 'SERV. EQ.MAB',
  '2484497-1-8166720': 'SERV. GENERALES',
  '8-769-272': 'SERV. MARIALIS',
  '2-728-1016': 'SERV. TOPOGRAFIA JER',
  '8-495-50': 'SERVI JOSEPH',
  '155615435-2-2015': 'SERVICENTRO DIGITEC',
  'E-8-67076': 'SERVICENTRO POWER',
  '8-782-703': 'SERVICIOS',
  '953261-1-526421': 'SERVICIOS ELEC',
  '73-561-21585': 'SERVICIOS ELECTRICOS',
  '155676013-2-2019': 'SERVICIOS GENERALES',
  '8-782-7063': 'SERVICIOS JC',
  '8-508-196': 'SERVICIOS MARTINEZ',
  '46855-36-305252': 'SERVICIOS MIVA S.A.',
  '1768761-1-32271': 'SERVICIOS NOTARIALES',
  '3-85-736': 'SERVICIOS TECNICO',
  '1658160-1-677076': 'SERVICIOS URRIOLA',
  '2-103-1278': 'SERVICIOS Y EQUIPO M',
  '8-441-267': 'SERVIPLOM',
  '8-NT-2-5000690': 'SIGNATURE',
  '203004-1-395633': 'SIKA PANAMA',
  '1947493-1-731199': 'SILOS Y TANQUES',
  C02253204: 'SILVIO GUERRERO',
  '1607293-1-667501': 'SIMPLIFICA T',
  '1963768-1-734094': 'SINCO PANAMA',
  '155639596-2-2016': 'SINOE',
  '551903-1-443497': 'SISTEMA',
  '2649787-1-840606': 'SISTEMA DE ACCESOS',
  '2407344-1-805887': 'SMART BILL PTY',
  '8-NT-2-710911': 'SMART TV PTY',
  '2325206-1-793655': 'SMARTBRIX',
  '155677253-2-2019': 'SMARTHCELL, S.A.',
  '155714159-2-2021': 'SMASH PADEL CLUB',
  '2652487-1-841025': 'SOLAR SHIELD',
  '58125-84-340838': 'SOLDADURA MOVIL',
  '8-771-845': 'SOLDARURA',
  '2229328-1-777730': 'SOLINTEX',
  '155626891-2-2016': 'SOLUCION',
  '8-788-626': 'SOLUCIONES ELECTRICA',
  '3-NT-2-45334': 'SOLUTIONS & SERVICES',
  '155624356-2-2016': 'SONCAR',
  '1204398-1-582879': 'SONDEL',
  '8-440-930': 'SONIA',
  '199-72-8677': 'SPIA',
  '155647218-2-2017': 'STONE MASON',
  '2493499-1-817936': 'STRAUSS WATER PANAMA',
  '8-984-1954': 'STWAR HERNANDEZ',
  '257643-1-404101': 'SUBWAY',
  '15558559-2-214 DV 12': 'SUMMA',
  '2652487-1-841025': 'SUNSAVE',
  '8-NT-1-23087': 'SUNTRACS',
  '8-110-350': 'SUPER',
  '155697704-2-2020': 'SUPER 7',
  '938386-1-523191': 'SUPER MALLAS PRIVAL',
  '1798861-1-705035': 'SUPER MEGA MAQUINAS',
  '853111-1-505797': 'SUPER P.',
  '565187-1-445548': 'SUPER SERVICE',
  '1-736-150': 'SUPERCELL',
  '155666546-2-2018': 'SURTECSA',
  '8-513-1001': 'SUSANA',
  '2394690-1-804097': 'SVG',
  '45971-2-301969': 'SYDE PANAMA',
  'E-8-52288': 'TA FONG FAN',
  '7782-187-83801': 'TALLER DE VIDRIOS',
  '4-231-794': 'TALLER LA SUEGRA',
  '42238-41-287163': 'TALLER MAGNOLIA',
  '7275-236-80238': 'TAMEX',
  '62137-36-351360': 'TARGET',
  '1409842-1-629646': 'TAS PANAMA',
  PAS66414465: 'TAYDE BENCOMO',
  '8-805-1708': 'TEC PLOM PRIVAL',
  '8-417-502': 'TECNI PISCINAS',
  '43674-37-292400': 'TECNICA DE SEGUROS',
  '1055885-1-548713': 'TECNO',
  '1716946-1-689507': 'TECNOCONTROL',
  '1634290-1-672445': 'TECNOGRUAS',
  '2-713-249': 'TEILO M.',
  '798602-1-494216': 'TELECOM',
  '36566-81-263914': 'TESLAB, S.A.',
  '838-469-101213': 'TEXACO',
  '1153929-1-572298': 'TEXACO-LAS AMERICAS',
  '2290153-1-788391': 'TEXACO-OBARRIO',
  '2290153-1-788391': 'TEXACO-OMEGA',
  '155601143-2-2015': 'TEXACO-PUENTE DE REY',
  '2440820-1-810814': 'TEXACO-SANTA ELENA',
  '155635983-2-2016': 'THERMAL PTY, S.A.',
  '2158079-1-766480': 'TIENDAS',
  '47028-19-305805': 'TIGO',
  '1508-31-30247': 'TITAN',
  '2-106-2337': 'TOMAS HENRIQUEZ',
  '155622701-2-2016': 'TOP CAR WASH',
  '8-349-222': 'TOPOGRAFIA AVANZADA',
  '1837621-1-711474': 'TOPOGRAFO',
  '23024-191-205095': 'TORNILLOS Y TORNILLO',
  '1245640-1-592101': 'TOROTRAC',
  '2595737-1-833075': 'TORRE',
  '2584813-1-831534': 'TOTAL CLEAN',
  '8-515-824': 'TRANS. Y SER. AJC',
  '48536-020-311170': 'TRANSMUNDI',
  '8-799-477': 'TRANSP ESPINOSA CAMA',
  '1591609-1-664595': 'TRANSP LEZCANO',
  '2649787-1-840606': 'TRANSP. AMBIENTALES',
  '7-102-397': 'TRANSP.R',
  '8-812-315': 'TRANSPORTE EAP',
  '8-816-1257': 'TRANSPORTE J.D.G.G.',
  '8-701-944': 'TRANSPORTE N SMITH',
  '8-337-731': 'TRAZOS',
  '155613113-2-2017': 'TRISOLUTIONS',
  '1464450-1-640505': 'TRIUMPH',
  '817-476-134499': 'TROPICAL SERVICES',
  '594-120-104838': 'TROPIGAS DE CHIRIQUI',
  '56876-24-337263': 'TRULY NOLEN',
  '8864-123-91468': 'T-SHIRTS INTERA',
  '303303-1-410024': 'T-SHIRTS MAX',
  '715646-1-471818': 'TUBOTEC',
  '8-806-2422': 'TYRESA',
  '1091-157-111481': 'ULTRACOM',
  '37822-77-269113': 'UNIDAD CORONARIA',
  ' 1184795-1-578982 ': 'UNISPAN',
  '2674965-1-844278': 'UNIVERSAL ENGINEERIN',
  '8-741-1415': 'URIEL VALLE',
  '1897440-1-722047': 'UTILI OFFICE ROVER',
  '8-NT-1-12515': 'UTP',
  '155690286-2-2020': 'UXMAL CENTRAL',
  '155618398-2-2015': 'VALCOR',
  '1848547-1-713252': 'VALE GENERAL',
  '5-701-1248': 'VALENTIN RODRIGUEZ',
  '8-901-1260': 'VALERIO HERNANDEZ',
  '689185-1-466063': 'VALESTE',
  '155689280-2-2019': 'VAPRO ECO',
  '8-796-1011': 'VEN Y REP MARQUIÑO',
  '1724547-1-691084': 'VENTAS KARINA',
  '2501704-1-819122': 'VENTAS Y ALQ DIHEBA',
  '8-755-2464': 'VENTAS Y SERVICIOS M',
  '8-907-1998': 'VENTAS Y SUMINISTROS',
  '155618602-2-2015': 'VERTICAL PANAMA',
  '1347426-1-616465': 'VESCON',
  '2445872-1-811553': 'VETRO',
  '25042160-3-2020': 'VILLALBA INGENIEROS',
  '25040435-3-2019': 'VIRTU',
  '980755-1-532051': 'VISUAL',
  '155625148-2-2016': 'VIZION GROUP',
  '1693278-1-685039': 'VOLKETAS',
  '61158-2-348497': 'VOLTRAN',
  '8-774-1935': 'WALTER NAVARRO',
  '8-267-740': 'WERNER CRUZ',
  '23898-2-210205': 'WINDOW',
  '155645236-2-2017': 'WRAP',
  '231753-1-400109': 'WURTH',
  '9-720-2229': 'Xavier Atencio',
  'E-8-71519': 'XIN SHI ZHUO',
  '2645515-1-840083': 'Y & J NETCORPORATION',
  '8-779-146': 'YAIR SAMANIEGO',
  '8-709-812': 'YENNI FRAGO',
  '8-883-646': 'YERAL CAMARENA',
  '4-770-1540': 'YOHNY',
  134671253: 'YORGELIS GARCIA',
  '963942-1-528502': 'YOYTEC',
  '28995-39-233664': 'ZENTRUM',
  '43174-34-290776': 'ZINC',
  '8-504-525': 'ZORAIDA',
  '46004-187-302083': 'CABLE',
  '9-723-1185': 'FUMIGADORA AB',
  '155701025-2-2021': 'MANTRA',
  '2442455-1-811063': 'PRINT PROMO',
  '2480734-1-3185': 'MULTIPLAZA',
  '2627819-1-837599': 'COLEGIO BLILINGUE',
  '155721408-2-2022': 'ARREGLOS GB',
  '25773-160-220355': 'OFFICE DEPOT',
  '2321277-1-793023': 'ESTACIONAMIENTOS UNI',
  '155712127-2-2021': 'CLICK PARKING',
  '5837-93-70420': 'EL COSTO',
  '419758-1-427175': 'DOLLAR CAR RENTAL',
  '356-19-77860': 'SUPER XTRA',
  '2456294-1-812932': 'UBER',
  '2637581-1-838992': 'TORNILLOS Y FIJACION',
  '37754-34-268790': 'EXTRALUM DE PANAMA',
  '38682-54-272473': 'VENDELA',
  '8-766-2206': 'COMICS',
  '8-822-1263': 'DOTA & FER',
  '8-908-1029': 'GASES INDUSTRIALES',
  '8-480-942': 'JOSE BALBINO',
  '2-106-1790': 'NOTARIA DECIMO III',
  '36986-150-265674': 'CENTRAL DE PINTURAS',
  '2454804-1-812754': 'MARTIN CENTRO',
  '7875-49-84465': 'FLORENCIA',
  '1732443-1-692732': 'VILSA',
  '8-NT-1-13653': 'MINSA',
  'N-19-1523': 'FRUTERIA MIMI',
  '980755-1-532051': 'INK VISUAL',
  '8-342-506': 'EDUARDO',
  '8-513-1071': 'PLOMERIA YUBAN',
  '2303096-1-790181': 'MAYPROVC',
  '155671764-2-2018': 'GRUPO KUANG',
  '985987-1-533212': 'DIGICEL',
  '13-NT-2-707696': 'GABRIEL CHAVEZ',
  '337-297-72453': 'TAMBOR',
  '155671764-2-2018': 'FERRETERIA EL BUENO',
  '346-266-76470': 'FARMACIA',
  '1809947-1-707081': 'CONSTRUVENTAS',
  '420368-1-427273': 'LATICRETE',
  '2288845-1-788241': 'PAVELUM',
  '8-766-2206': 'ALMACEN COMICS',
  '8-179-815': 'FRANCISCO OBANDO',
  '8-423-67': 'TELESFORO RODRÍGUEZ',
  '186-505-46878': 'EL LLANTERO',
  '8-769-1099': 'PUBLIMAX',
  '155624970-2-2016': 'CALZASHOES',
  '155596713-2-2015': 'PRINT WORKS',
  '18219-85-169844': 'AGUA CRISTALINA, S.A',
  '4-202-201': 'ANGELA DUARTE',
  '8-444-928': 'KAIROS',
  '2-151-974': 'TRANSPORTE REYES',
  '630-483-123250': 'ORLYN',
  '8-515-824': 'ALEXIS CARBAJO',
  '8-NT-2-41204': 'CASTELLANOS',
  '434-15-93796': 'SUPER 99',
  '155658741-2-2017': 'VENTAS Y SUM. INDUST',
  '882-597-101619': 'BONAVEL',
  '226121-1-399165': 'LANCO',
  'E-8-120432': 'PROCONSER',
  '724675-1-473907': 'HIDROCA PANAMA',
  '8-812-2190': 'REINALDO FERNANDEZ',
  '2650272-1-840671': 'GYPSUM Y ACABADOS',
  '7-702-478': 'FRANCO ARQUITECTURA',
  '858922-1-506773': 'TECSAN',
  '155601395-2-2015': 'BOTANICA PIZZA',
  '155648115-2-2017': 'MENDEZ INTERNATIONAL',
  '11524-2-115657': 'PETRO',
  '2179592-1-770133': 'EURO',
  '155671968-2-2018': 'GREEN BOX',
  '30773-73-240340': 'DISCOVERY CENTER',
  '155678922-2-2019': 'DARSAFETY PANAMÁ',
  '309078-1-411222': 'FERRAMENTA',
  '12150-178-121154': 'ELECTRISA',
  '938386-1-523191': 'SUPER MALLAS',
  '8-510-395': 'JACEK',
  '155598432-2-2015': 'ISOBOX',
  '155658395-2-2017': 'FINCA RAMI',
  '456104-1-432290': 'AEROPUERTO',
  '413-439-12379': 'CAPAC',
  '155599901-2-2015': 'GM ACABADOS',
  '30815-22-240563': 'SUPER REY',
  '2220794-1-776422': 'COHESA',
  '14731-147-143066': 'CONSTRUQUIP CORP.',
  '16429-109-156121': 'ABOLU',
  '155618602-2-2015': 'DECON',
  103158615: 'EVA MOSQUERA RAMIREZ',
  '1952065-1-731975': 'CULTO',
  '1803903-1-705870': 'METRO SUPPLIES',
  '155705019-2-2021': 'SOL. ING. RDJ / LV',
  '155617130-2-2015': 'KARRARA STONE',
  '8-743-2462': 'JORGE TORIBIO',
  '125-103-33907': 'ARGOS',
  '436-287-95110': 'PRAXAIR',
  '8091-204-85890': 'ELMEC S.A.',
  '155618602-2-2015': 'MBE SANTAMARIA S.A.',
  '155658132-2-2017': 'ACDECO',
  '9388-15-96104': 'ADITIVOS DE PANAMA',
  '16369-68-155679': 'VEASA',
  '155660031-2-2018': 'METALPLAS',
  '8-381-689': 'GR SOLDADURA',
  '138-289-35920': 'NOVEY',
  '155646071-2-2017': 'RAPIDAIRE PROYECTOS',
  '20241-15-183729': 'VAGSA',
  '15430-249-148718': 'DOIT CENTER',
  '155645344-2-2017': 'ALQUI-EQUIPOS',
  '2391004-1-803567': 'COMSTAL PANAMA',
  '4-741-567': 'ELEMENT NOVO',
  '30170-22-237573': 'RAENCO',
  '8-824-385': 'TRANSPORTES CUBILLOS',
  '8-NT-2-32640': 'ACARREO POLY',
  '155686823-2-2019': 'PREMUR',
  '9-723-842': 'INGENIERIA AGUILAR',
  '2069791-1-751271': 'HOPSA',
  '8-900-7547': 'JOSE JULIAN',
  '2564297-1-828337': 'BLOQ',
  '155719471-2-2022': 'CONSORCIO PALACIOS',
  '155695715-2-2020': 'WONDERFOODS',
  '155724952-2-2022': 'TYLSA',
  '1923000-1-726508': 'FACHADAS',
  '1129851-1-566686': 'RENTA EQUIPOS',
  '907198-1-516233': 'L Y L',
  '1539486-1-655346': 'PLUS RENT, S.A.',
  '8-442-407': 'MIGUEL MARTIN',
  '1199766-1-581801': 'PAZOS',
  '3-81-2743': 'ING. Y CONST. FRANCO',
  '24687-150-214130': 'BUSINESS',
  '8-824-385': 'ABILIO CUBILLOS',
  '53138-2-326853': 'SERMARU',
  '49930-2-316585': 'ADVANCE',
  '155652952-2-2017': 'PANABLOCK',
  '1470710-1-641892': 'DPI INFRAESTRUCTURA',
  '1881919-1-719307': 'MASTERLAB',
  '8-518-1185': 'EDIFICACIONES FUENTE',
  '155602602-2-2015': 'CMP PLOMERIA',
  '2278132-1-786373': 'L Y L ALQUILER',
  '155640029-2-2016': 'OPERAA',
  '521-136-113133': 'COCHEZ',
  '2362184-1-799470': 'IHO ESPACIOS',
  '1263-270-126460': 'KARTINVEST',
  '510-302-109858': 'IMPLOSA',
  '155624207-2-2016': 'CEMENTO CHAGRES',
  '8-NT-2-27486': 'DE ALBA',
  '33739-33-253068': 'METALPAN',
  '155986513-2-2018': 'MAZE',
  '30462-1-368083': 'RAMPAZ',
  '155595835-2-2015': 'CAP',
  '4-733-1686': 'JOSE ARAUZ CACERES',
  '19808-233-180375': 'CATTAN',
  '985948-1-533206': 'AIRMAX GROUP',
  '1375546-1-622612': 'MATCO',
  '1385795-1-625136': 'AMT ARQUITECTURA',
  '155621991-2-2016': 'SEIN',
  '2114443-1-759080': 'GRUPO CHOCK REALTY',
  '155643349-2-2017': 'FERREMASTER',
  '650-529-126088': 'COMASA',
  '436213-1-429559': 'VENTANAS SOLAR',
  '8-392-12': 'SOLUCIONES',
  '8-780-224': 'MULTISERVICIOS O&E',
  '3394-105-50823': 'GRUPO SPIEGEL',
  '1332815-1-613367': 'EQUIPOS UTILES',
  '155646057-2-2017': 'GAMASONIK 2',
  '4-734-1947': 'SILVERIO QUIROS',
  '8-799-1577': 'EDGAR DE LA FLOR',
  '383628-1-422032': 'CONST. HUMKERELY',
  '2176422-1-769652': 'SERVICIOS BARRANCO',
  '8-851-1416': 'JC ARQUITECTO',
  '47617-9-307864': 'JOSE BERNAL',
  '8-805-1708': 'TECNIC PLOM PANAMA',
  '8-518-1185': 'EDIF. FUENTES - LV',
  '1885065-1-719834': 'CONSTRUMARKET',
  '968-92-106427': 'APARICIO',
  '13416-237-132168': 'CORREAGUA',
  '1258681-1-595012': 'FIRE',
  '1470710-1-641892': 'DESARRO DE PROYECTO',
  '383628-1-422032': 'HUMKERELY',
  '318460-1-412667': 'PLAS',
  '155618856-2-2015': 'CONCRETEX PTY S.A.',
};

export const asyrProviders = {
  '26118383-1-836304': 'A2Z INVESTMENTS',
  '2517518-1-821315': 'ABANDA',
  '786659-1-22095': 'ABOGADOS ATTORNEYS',
  '15430-249-148718': 'ACE INTERNATIONAL HA',
  '2524158-1-822252': 'ADFPANAMA,S.A.',
  '778-454-138120': 'ADIDASORIGINALS',
  '456104-1-432290': 'aeropuerto internaci',
  '277-83-60760': 'AGENCIASFEDUROSA',
  '1621672-1-670343': 'AGROALQUILER',
  '1980961-1-724110': 'Aircarecorp',
  '44417-112-295616': 'ALISCOSA',
  '541848-1-441803': 'ALISS',
  '463-340-100699': 'almacen ideal s.a',
  '155618022-2-2015': 'alta eficiencia s.a',
  '1860695-1-715291': 'american pets s.a',
  '1593012-1-664908': 'AMORE',
  '1586540-1-663522': 'ARISTORE',
  '1293546-1-603750': 'ARONSTUDIO, S.A.',
  '25033638-3-2017': 'arquiluz',
  '346-226-76470': 'ARROCHA',
  'E-8-119001': 'ASEGURA',
  '1918587-1-725737': 'ASHEGROUP',
  '2480734-1-3185': 'ASSETSTRUST',
  '48740-140-312112': 'ATENAS, S.A.',
  '155598179-2-2015': 'ATHANASIOU',
  '33578-2-252466': 'ATHENSPIZZA',
  '2421159-1-807848': 'BALBOA 545 CORP',
  '155671883-2-2018': 'BAR&DRINKSCORP',
  '2693573-1-847122': 'BARRIO PIZZA, S.A.',
  '155646448-2-2017': 'BARRIOPORRASSA',
  '1161940-1-573917': 'BDOTAX',
  '1573488-1-661214': 'BEIRUT',
  '2317813-1-792478': 'BENDINGPANAMA',
  '8-301-936': 'BENJAMINLIAO',
  '1542958-1-656012': 'BITACLE INVESTMENT,',
  '2317813-1-792478': 'BLENDING PANAMA,SA',
  '270-35-61112': 'BOTICA',
  '42855-75-289551': 'BRISTOL',
  '2622858-1-836942': 'BRUN',
  '155660156-2-2018': 'BRUTTITO',
  '155612891-2-2018': 'BRUTTO',
  '8-712-250': 'BTCARNES',
  '1144-157-124040': 'budget rent car',
  '155696267-2-2020': 'BUZCAMAZON, INC',
  '155682724-2-2019': 'BV GOURMET',
  '30394-0002-238626': 'CABLEONDA',
  '2212544-1-775200': 'cafe neto s.a',
  '2567147-1-828836': 'CAFEMAR',
  '155615588-2-2015': 'CALIX',
  '383-513-85711': 'CAMILO A PORRAS',
  '155677140-2-2019': 'CAPITAL STAR3',
  '39889-23-277344': 'CARIBBEAN FRANCHES',
  '155629469-2-2016': 'CARNESRCSA',
  '155648241-2-2017': 'carpe diem internati',
  '155602864-2-2015': 'CASAALEJANDRO',
  '1181670-1-578263': 'CASABLANCA',
  '2426418-1-808590': 'CASACASCO',
  '2581825-1-831145': 'CASAJAGUAR',
  '1603854-1-666821': 'centralamericaapplia',
  '2685376-1-845765': 'CENTRALHOTEL',
  '11422-77-114867': 'cerro punta s.a',
  '28878-23-233240': 'CHAITEN',
  '155631018-22016': 'CHICK N POP',
  '652-212-129962': 'cia goly s.a',
  '2495490-1-818185': 'CINEMAPROPERTIES',
  '934082-1-522297': 'CITYPARKING',
  '994855-1-534959': 'CLARO',
  '155712127-2-2021': 'click parking darien',
  '2524158-1-8222252': 'COCOMARKET',
  '155621999-2-2016': 'COFFEEMAXSA',
  '2453585-1-812598': 'COFFEEROASTERS',
  '2627819-1-837599': 'colegio bilingue las',
  '103-2-463': 'COLEGIO NACIONAL',
  '30444-139-238858': 'COMERCIALDEMOTORES',
  '69-554-21245': 'COMPAÑIA CHIRICANA',
  '2361390-1-799363': 'Constructores M Y H',
  '1099-185-120196': 'consultenos s.a',
  '62137-36-351360': 'CONWAY',
  '155644110-2-2017': 'cookstown holding in',
  '155646161-2-2017': 'COOLMOTORS',
  '635269-1-465266': 'COPIPANAMA',
  '8073-31-85771': 'COSTAAZUL',
  '242477-31276-185': 'COURTYARD',
  '950723-1-525827': 'COZI',
  '950723-1-525827': 'COZZISA',
  '47814-83-308-700': 'crepes & waffles',
  '1278070-1-599795': 'DELIFARM',
  '105765-1-379278': 'DELIGOURMET',
  '649-503-11585': 'DELIRYS',
  '2261248-1-783368': 'DELISTMO',
  '5458-0162-067519': 'DHLPANAMA',
  '838815-1-503154': 'DIGITALIZA',
  '8-884-1185': 'disfruta market',
  '1556226-2-2016': 'dog spot s.a',
  '15430-249-148718': 'DOITCENTER',
  '1675920-1-680848': 'DOMINOSPIZZA',
  '57699-49-339574': 'DONLEE',
  '155663671-2-2018': 'DORASERV, S.A.',
  '155607824-2-2015': 'DOSCUCHARAS',
  '155673844-2-2018': 'drakefoodservices s.',
  '155630472-2-2016': 'DRYCLEANCOCOSA',
  '808-349-126446': 'dulceria momi',
  '909-459-103719': 'E POTRO C Y CIA, S.A',
  '1848722-1-713290': 'ecogreen s.a',
  '253525-1-403482': 'ECONOPRECIOSSA',
  '57983-2-340436': 'EDEMET',
  '45820-27-2869': 'edificio ph royal ce',
  '155616959-2-2015': 'ELBRASERO, S.A.',
  '5837-93-70420': 'elcosto',
  '912255-1-517351': 'ELIAKIM, S.A.',
  '270-36-61112': 'ELJAVILLO',
  '8091-0204-085890': 'ELMEC, S.A.',
  '155606333-2-2015': 'ELSANDWICHBAR',
  '2081474-1-7533113': 'ELYON PANAMA',
  '650-525-126088': 'empresas melo s.a',
  '541848-1-441803': 'EMPRESASBICOCAS',
  'PE-8-2552': 'ENRIQUECHONG',
  '155647805-2-2017': 'ENTREPANES',
  '8-70-443': 'ERNESTOCALVO',
  '2321277-1-793023': 'ESTACIONAMIENTOS UND',
  '155677503-2-2019': 'estacionamientoscome',
  '2290153-1-788391': 'ESTACIONESDEPASOSA',
  '594524-1-450642': 'ESTACIONPUNTAPACIFIC',
  '2321277-1-793023': 'ESTAUNIDOS',
  '2265164-1-784140': 'ETRO',
  '1542958-1-656012': 'EUPHORIA CAFE, S.A.',
  '2325580-1-793711': 'EURO CONSORCIO 55',
  '30261-1-368066': 'EUROBOUTIQUES',
  '155630529-2-2016': 'eurocersa, s.a.',
  '155618993-2-2015': 'EUROSTARS',
  6955421245: 'EXCEL AUTOMATRIX',
  'E-8-163921': 'fabiola de freitas',
  '438412-1-429831': 'FAMILY DOLAR',
  '346-266-76470': 'farmacia arrocha',
  '346-266-76470': 'FARMACIAARROCHA',
  '652-212-129962': 'farmaciaselmachetazo',
  '655-419-132915': 'FARMACIASREVILLA',
  '49679-106-746': 'FEDERAL EXPRESS CORP',
  '441-74-94389': 'FELIPE MOTTA, S.A.',
  '155646659-2-2017': 'femas trading s.a',
  '8-792-2363': 'FERMIN NGTEM',
  '2486907-1-817046': 'FINANCENTROESTSA',
  '155642301-2-2015': 'FITNESSFOOD',
  '30302-25-238158': 'FLAVORAMA',
  '13493-2-132798': 'fletes chavale',
  '441-74-94389': 'FMOTTA',
  '45008-12-298240': 'FODDIE',
  '152987-1-386304': 'FORTAMADOR',
  '155674474-2-2019': 'fr group s.a',
  '902-308-105023': 'franquicias panamena',
  '155631088-2-2016': 'FRESCURASA',
  '39889-23-277344': 'FRIDAYS',
  '213618-1-397361': 'furnite',
  '155616222-2-2015': 'FUTPADCLUBSA',
  '155679801-2-2019': 'GADEA WHEELS PTY',
  '6659-80-75941': 'gala',
  '66104-114-363217': 'GALERIAPORTOFINOSA',
  '39274-16-274902': 'GAUCHOS',
  '155589219-2-2014': 'GELATIAMO',
  '155609150-2-2015': 'GELATO&COPANAMA',
  '85253-1-376213': 'GEO MEDIA',
  '2369706-1-000512': 'gifthy things',
  '29192-12-234284': 'GOLD MEDAL, S.A.',
  '155692927-2-2020': 'GPPA STORES PANAMA',
  '8-722-845': 'GRAFICOSINTEGRADOS',
  '155666601-1-2018': 'GRUPO AJI ASIA',
  '155630000-2-2016': 'GRUPO AMANO, S.A.',
  '155650961-2-2017': 'GRUPO MEGA MARKET',
  '624505-1-455354': 'grupo oczoro interna',
  '155629732-2-2016': 'GRUPO SIEMPRE HAY',
  '682017-1-464676': 'GRUPO TODO A DOLLAR',
  '2316043-1-792249': 'GRUPOMAREVASA',
  '2357-1-364165': 'GRUPOPRIMAVERAHOLDIN',
  '474700-1-728275': 'H^KMOTORSPORT',
  '62638-120-352754': 'HABANOSCAFE',
  '155599714-2-2015': 'HAKOL GROUP S.A',
  '155700049-2-2020': 'happy hospitality s.',
  '155661614-2-2018': 'HARRAKA FRANCHISE, S',
  '1762712-1-698569': 'HASSYUA S.A',
  '200855-1-395347': 'HAYGAB,S.A.',
  '1237856-1-590407': 'HEALT STORE, S.A.',
  '1237856-1-590407': 'HEALTHSTORE',
  '155690445-2-2020': 'hej mode panama 77 s',
  '463-446-101019': 'hermes paris',
  '1155326-1-572665': 'HILTON',
  '2252861-1-781904': 'HISPANO AMERICANA DE',
  '8-347-652': 'home system panama',
  '37279-2-266781': 'HOT EXPRESS',
  '711-409-118751': 'HOTELEJECUTIVO',
  '2475076-1-815379': 'HOUSE HUNTERS PANAMA',
  '510-302-109858': 'implosa',
  '558-472-101708': 'IMPORTADORA MADURO S',
  '434-15-93796': 'importadora ricamar',
  '155683930-2-2019': 'importadorayexportaz',
  '155644102-2-2017': 'INDANDOUT',
  '541-81-118009': 'INMOBILIARIADONANTON',
  '30815-22-240563': 'INMODONANTONIOSA',
  '2243982-1-780264': 'INTERAMERICAN SPORT',
  '155665741-2-2018': 'inversiones aglio co',
  '155647713-2-2017': 'INVERSIONES KNX 906',
  '553984-1-443873': 'INVERSIONES LA PAPA',
  '155699759-2-2020': 'inversiones mofa s.a',
  '155670200-2-2018': 'inversiones rgp 18 s',
  '155676300-2-2019': 'inversiones Shals',
  '155639000-2-2016': 'inversiones suarez m',
  '1004636-1-536784': 'inversiones textiles',
  '155660766-2-2018': 'inversiones unico s.',
  '735-383-117197': 'INVERSIONESENCANTO',
  '33867-89-253572': 'INVERSIONESFATIMA',
  '2113280-1-758901': 'INVERSIONESJJ',
  '886674-1-512095': 'INVERSIONESLASNER',
  '155647869-2-2017': 'INVERSIONESMERLOYSA',
  '1075684-1-553258': 'INVERSIONESVANCOUVER',
  '682-67-187522': 'INVERSIONESYIMY',
  '25033638-2-2017': 'JALEO',
  '155663940-2-2018': 'JASMIN NOIR',
  '9857-220-101047': 'JELLINI',
  E: 'JEOVANI HONDOY',
  '135-125-35457': 'JOYERIA MERCURIO',
  '34351-064-255725': 'KARABERRI',
  '2785-0053-c0010478': 'katz y lopez',
  '902-308-105023': 'KFC',
  '2599862-1-833694': 'KIMIARHOLDING,S.A.',
  '2573036-1-829764': 'KITCHEN',
  '607-24-124369': 'KOMEX INTERNATIONAL',
  '22330-1-367028': 'KOPYKAT',
  '811194-1-498031': 'KOTOWA COFFE HOUSE,',
  '15566811-2-2018': 'krume distributions',
  '1603854-1-666821': 'la cuisine',
  '155616016-2-2015': 'LA NETA, S.A.',
  '155601704-2-2015': 'lab center s.a',
  '155609904-2-201551': 'laboratorios vidatec',
  '155668534-2-2018': 'LACASTAÑUELA',
  '155632478-2-2016': 'LAMALAGUE;A',
  '266293-1-405537': 'LAUVI, S.A.',
  'E-8-90695': 'LAVANDERIACHAN',
  '886674-1-512095': 'LE;OSYCARBON',
  '1303676-1-606293': 'LEGUI,S.A.',
  '2519941-1-821664': 'LIDOEXPRESS',
  '729804-1-475013': 'LIFEHOMEOFFICEINC',
  '1409699-1-629620': 'logytechmobilepanama',
  '553-231-100703': 'LUMICENTROSA',
  '537091-1-441028': 'MACSTORE',
  '57918-43-340215': 'MADE IN ITALY',
  '155657355-2-2017': 'MAITO',
  '63305-34-354656': 'MAJORBUSINESSINC',
  '155590918-2-2015': 'MAKOTO',
  '741609-1-477946': 'MANOLO',
  '873341-1-509594': 'MANUELBATISTA',
  '155657355-2-2017': 'mao bar s.a',
  '26770-151-225610': 'MAPISA',
  '430-408-95374': 'MAQUINASFRANQUEADORA',
  '2660258-1-842135': 'MARIAJOSEDECORACIONE',
  '155590567-2-2015': 'MARIETTA',
  '476834-1-435087': 'MARIVA',
  'N-20-2306': 'MARTAELENAAGULAR',
  '8-851-871': 'materiales y pintura',
  '155672098-2-2018': 'MAZA PANAMA',
  '8-905-1226': 'melissa yip lee',
  '30815-22-240563': 'METROPLUS',
  '31844-219-245108': 'METROTRADINGIMPORTER',
  '909-459-103719': 'MI AMIGA WINE & LIQU',
  '155668251-2-2018': 'midi local C',
  '155691073-2-2020': 'mijares&diciembre s.',
  '390-247-84722': 'MINI BF PANAMA, S.A.',
  '8-nt-2-747954': 'ministeriodecultura',
  '8-792-2362': 'MS LE MARE',
  '155672647-2-2018': 'mumupa multi s.a',
  '21908-287-196388': 'MUSICA Y DEPORTES, S',
  '1573488-1-661214': 'NABUDA, S.A.',
  '155590270-2-2014': 'NACIONSUSHI',
  '18636-0076-172220': 'NAPOLI',
  '155664208-2-2018': 'NEXT LEVEL',
  '33209-109-250936': 'NIKOSCAFE',
  '2-106-1790': 'NOTARIA CUARTA DEL C',
  '2-106-1790': 'notaria decimo terce',
  '4-201-226': 'notaria tercera del',
  '8-250-338': 'NOTARIADUODECIMA',
  '8-321-334': 'NOTARIANOVENA',
  '2-106-1790': 'NOTARIAPUBLICA',
  '8-160-469': 'NOTARIAPUBLICADECIMA',
  '8-509-985': 'notariapublicaprimer',
  '8-509-985': 'NOTARIAPUBLICAQUINTA',
  '8-421-593': 'notariapublicasegund',
  '8-316-581': 'NOTARIAPUBLICASEXTAD',
  '8-509-985': 'NOTARIAQUINTA',
  '5-703-602': 'NOTARIAUNDECIMA',
  '138-289-35920': 'NOVEY',
  '656-413-132700': 'officedepot',
  '155605985-2-2015': 'OFFICLIPSA',
  '155623139-2-2016': 'ONTARIO COFFE',
  '155671071-2-2018': 'OPES GROUP,S.A.',
  '630-483-123250': 'ORLYN SA',
  '155601084-1-2015': 'oskal obarrio s.a',
  '576205-1-447400': 'PALADAR',
  '5-N5-2-5001593': 'PANACOMMERCE',
  '66159-91-363381': 'PANAFOTO',
  '2335668-1-795413': 'PANAMA BOTANICALS',
  '386-153-85326': 'PANAMAONTE, S.A.',
  '1114423-1-562931': 'PANDORA',
  '25033638-3-2017': 'PANORAMA PERSIANAS',
  '48740-140-312112': 'PANOS',
  '2165450-1-767771': 'PARKINGMANAGMENT',
  '2054724-1-748584': 'PARKINGSOLUTIONS',
  '30688-33-239941': 'PARTESIMPORTADAS',
  '25918-1-367552': 'PDARTE, S.A.',
  '1429523-1-633818': 'PETITPARIS',
  '11524-2-115657': 'PETROLEOSDELTA',
  '2415299-1-807010': 'PFCHANGS',
  '2085001-1-754015': 'PG',
  '8-NT-2-107156': 'PHSL55',
  '2044137-1-746894': 'PIDAPIZZA',
  '25918-1-367552': 'PIETRA D ARTE',
  '281524-40889-98': 'PIZZASITALIANASSA',
  '155686379-2-2019': 'PLAYA ESCONDIDA SERV',
  '1690886-1-684394': 'POLLOTROPICAL',
  '370948-1-420312': 'POWER CLUB SOHO',
  '1052567-1-547988': 'PREMIER UNIVERSE',
  '1925708-1-727160': 'Premiun Paint Panama',
  '2357204-1-798763': 'PRETELTGOURMETMEATS',
  '898-241-102416': 'PRICESMART',
  '52090-108-323391': 'PRIORITY',
  '61131-53-348038': 'PROPIEDADESPATTERSON',
  '155606770-2-2015': 'PSYCHOBUNNY',
  '2243988-1-780265': 'pull&bear',
  '2085001-1-754015': 'PURIFICACION GARCIA,',
  '8-727-18555': 'RC',
  '6-700-771': 'REBECA RAMIREZ',
  '2561013-1-827825': 'REDEPRECIO.COM',
  '155603779-2-2015': 'REDLION',
  '1767235-1-699413': 'refri store',
  '9-154-548': 'RESTCHARROMEXICANO',
  '450-444-100314': 'RESTDELASAMERICAS',
  '836454-1-502728': 'RESTLOSANOSLOCOS',
  '155637970-2-2016': 'retails s.a',
  '2560759-1-827788': 'riande aeropuerto s.',
  '342-240-75526': 'RIBASMITH',
  '2454804-1-812754': 'rimani inc',
  '2115541-1-759299': 'round 1 s.a',
  '2421176-807851': 'ROYALFOODSERVICES',
  '836454-1-502728': 'RTELOSAÑOSLOCOS',
  '155631698-2-2016': 'ruruda s.a',
  '349-420-75483': 'SABORNS',
  '155650250-25-2017': 'salva mi maquina s.a',
  '155593196-2-2015': 'SALVAJE',
  '529409-1-439947': 'SALVATORE FERRAGAMO',
  '836454-1-502728': 'san markus s.a',
  '58691-37-342414': 'SANFERNANDOCAFE',
  '1050730-1-547546': 'SAQUELLA',
  '15944-38-152503': 'SERTO S.A',
  '155663310-2-2018': 'servialimentospanave',
  '8-819-343': 'SERVICIOS ELECTRICOS',
  '15944-38-152503': 'serviciotecnicosorte',
  '1508-31-30247': 'shalom s.a',
  '557-538-101617': 'SISTEMASMCOPCO',
  '2612390-1-835570': 'SKYBULLSA',
  '2212047-1-775087': 'SLF CONTINENTAL S.A',
  '155638023-2-2016': 'SOLISLEGAL',
  '723636-1-473681': 'SOLUCIONES INCORPORA',
  '779488-1-487774': 'soluciones medico ho',
  '8-315-379': 'Soportec Panama',
  '640277-1-457975': 'SORTIS',
  '14142-188-137811': 'SOSA',
  '638045-1-457554': 'SPORT WEAR CORPORATO',
  '21094-39-18991': 'SPORTLINE AMERICA',
  '1692370-1-684786': 'STARBUCKS',
  '155703122-2-2021': 'STARTUP CORNER S.A',
  '2243930-1-780245': 'stradivarius multipl',
  '155624283-2-2016': 'STRANGERSCLUB',
  '155589033-2-2014': 'STREGA',
  '780588-1-22034': 'SUCRE',
  '2369706-1-800512': 'SUGARTHINGS',
  '2355505-1-798545': 'SUMINISTROS ASAP',
  '2317241-1-792410': 'SUPER LUXE RETAIL',
  '434-15-93796': 'SUPER99',
  '356-19-77860': 'SUPERMERCADOS XTRA',
  '30815-22-240563': 'SUPERREY',
  '155662910-2-2018': 'sweet y cinnamon s.a',
  '337-297-72453': 'TAMBOR, S.A.',
  '62137-36-351860': 'target s.a',
  '8-704-1202': 'TECHKNOW',
  '47028-19-305805': 'TELEFONICA',
  '2290153-1-788391': 'TEXACO',
  '155601395-2-2015': 'the plaza coos del m',
  '2265164-1-784140': 'THEART',
  '782613-1-488662': 'THEBUENAVENTURA',
  '155633113-2-2016': 'THEKITCHEN, S.A.',
  '155654981-2-2017': 'TIENDA C2-10',
  '2158079-1-766480': 'TIENDASDECONVENIENCI',
  '1974558-1-736039': 'TOP DESIGN, S.A.',
  '155704423-2-2021': 'top minds panama s.a',
  '65219-68-360435': 'TOVA S.A',
  '1070445-1-551977': 'TRF SERVICES, S.A.',
  '155657803-2-2017': 'TROPICS HEALTHY EATI',
  '155608843-2-2015': 'ULTRABIKEX PANAMA',
  '1091-157-111481': 'ULTRACOM',
  '155607942-2-2015': 'UNIDO',
  '2369270-1-800455': 'UNIFARMA',
  '98407-1-378314': 'UNOEXPRESS',
  '529391-1-439940': 'VAUXHALL MORTGAGE',
  '2531311-1-823422': 'VERAL',
  '8-839-1240': 'vet city',
  '1075684-1-553258': 'victoria secret',
  '2548634-1-826029': 'viga solutions s.a',
  '155677183-2-2019': 'wendys street mall',
  '8-nt-2-29023': 'wenndy elvira gomez',
  '155584833-2-2014': 'WERNER TECNOLOGY, S.',
  '2027928-1-744381': 'WINEBAR',
  '1672601-1-680004': 'WINGZONE',
  '1276512-1-599393': 'WORLDLANDINVESTMENTS',
  '155677371-2-2019': 'XIMI VOGUE DORADO',
  '963942-1-528502': 'YOYTEC',
  '1637439-1-673107': 'ZAHLE COMMERCIAL COR',
};

export const twoOceansProviders = {
  '2360335-1-799200': '4 BISTRO CAFE, S.A.',
  '8-501-887': 'A. Icaza',
  '4-251-460': 'A. SALINA',
  '198523-1-395014': 'A.I.R AVALUOS',
  '155651351-2-2017': 'AA PROP SHOP S.A.',
  '8-300-319': 'ABARROTERIA JOSE',
  '8-184-748': 'ABARROTERIA MARGIL',
  '2-132-263': 'ABEL NAAR',
  '310-2-66830': 'Abernathy',
  '16429-109-156121': 'Abolu, S.A.',
  '1179329-1-577842': 'ABONOS DEL PACIFICO,',
  '4-251-460': 'ABRIL SALINAS',
  '41315-23-283176': 'Accountants & Consul',
  '155646398-2-2017': 'ACERO ROAG',
  '1900656-1-722422': 'ACERTA SEGUROS',
  '2511977-1-820466': 'ACHA COSTA DEL ESTE',
  '48786-2-312367': 'ADEMAR, S.A.',
  '35944-190-261754': 'Admin MyM',
  '25017-2-216031': 'Administraciones D.G',
  '1184-537-111303': 'ADMIRALTY',
  '8-NT-2-6064': 'ADUANAS',
  '57366-2-338628': 'Africafe Holding Cor',
  '855-534-103651': 'Agencias Domingo',
  '16485-10-15628': 'AGENCIAS PALM LOPEZ',
  '934466-1-522384': 'Agripa Import, S.A.',
  '1621672-1-670343': 'AGRO-ALQUILER',
  '30688-106-239948': 'Agroganadera',
  '6-57-1083': 'AGUSTIN BERNAL',
  '09-734-2180': 'AGUSTIN UREÑA',
  '2317269-1-792416': 'AIR QUALITY SERVICES',
  '155591373-2-2015': 'AIRCO',
  '2681529-1-845228': 'AL VECCHIO FORNO',
  '3-727-1972': 'ALBA CAÑIZALES',
  '8-911-2167': 'ALBERTO GONZALEZ',
  '2-132-2463': 'Alberto Martinez',
  '8-313-832': 'Alberto Tamburrelli',
  '571539-1-446713': 'ALBROOK FOODS, S.A.',
  '1008397-1-537608': 'Albrook Italian Food',
  '2-132-263': 'Alcibiades Arturo Ro',
  '2-132-263': 'Alcibiades Nuñez',
  '9-207-639': 'Alejandro Rios',
  '8-984-1388': 'ALEJANDRO RIOS JR',
  '8-949-1049': 'ALEXANDER PEREZ',
  '8-740-1021': 'Alexis Peñalba',
  '503-265-13371': 'ALFARO FERRER & RAMI',
  '1749564-1-696093': 'ALLA ONDE UNO',
  '80763-1-496948': 'Alliance Transport',
  'N-16-341': 'ALMACEN BRITTA',
  '1-736-1946': 'ALMACEN EFICENTRO',
  '140831-1384305': 'Altamar Panama, S.A.',
  '61949-50-350735': 'Alumicentro de Panam',
  '8-950-1920': 'AMANCIO TEJADA MORGA',
  '13595-1-383496': 'AMERICA COMERCIAL CO',
  '18686-70-172575': 'AMERICAN TRUCKING',
  '8-894-2380': 'Ana Teresa De Gracia',
  '8-530-500': 'Anabel Herbert',
  '8-791-666': 'Anibal Aspedilla',
  '7-706-719': 'Anthony Rodriguez',
  '4-723-2171': 'ANTONIO ESPINOSA',
  '8-197-67': 'ANTONIO HERRERA',
  '5-19-767': 'ANTONIO HURTADO',
  '8-949-649': 'ANTONIO PEREZ',
  '45340-118-299555': 'APETITO 24',
  '61439-29-349382': 'AQUATEC-PUMP & SPA',
  '2445116-1-811467': 'Archipelago Hotel In',
  '8-255-260': 'Ariel Montero',
  '8-72-44': 'Ariel Zarpo',
  '64919-128-359508': 'Arrendamientos Aereo',
  '816-540-120-662': 'ARTIMEX, S.A.',
  'AU 347795': 'ARTURO ACUÑA ARRIETA',
  '37489-2-267627': 'Arturo Arauz (Tocume',
  '52783-28-325787': 'Arturo Arauz Interna',
  '8337-2-87831': 'Arturo Arauz, S.A.',
  '1758903-1-697882': 'ASADOS',
  '1008620-1-537652': 'ASADOS GABY ALBROOK',
  '2435158-1-809987': 'ASADOS VICTORIA',
  '1357459-1-618943': 'ASAP',
  '30746-2-240130': 'Aseguradora Ancon',
  '8-912-2043': 'ASHLON RODRIGUEZ',
  '215764-1-397670': 'Asia Auto Import S.A',
  '3724-103-53659': 'ASSA',
  '2480734-1-3185': 'ASSETS TRUST & CORP',
  '1112-337-120884': 'Associated Steamship',
  '155621356-2-2016': 'ASTURCOM',
  '155621356-2-2016': 'ASTURCOM SERVICIOS,',
  '2144353-1-764308': 'ATHANASIOU',
  '48740-140-312112': 'ATHENS PIZZA NO. 2',
  '1307889-1-607449': 'Atlantic Bunkering',
  '155626095-2-2016': 'Atlantic Crewboats',
  '1240919-1-591147': 'Atlantic Ship Suppli',
  '170-126-4192': 'Atlas',
  '17297-39-162907': 'Audio Foto',
  '8-NT-2-3982': 'AUPSA',
  '31616-69-244068': 'AUSEL',
  '49692-20-315863': 'AUSTIN POWDER PANAMA',
  '603-203-124985': 'AUTO CENTRO',
  '1675286-1-680669': 'AUTO CENTRO 2001,',
  '15736-109-150985': 'AUTO CROMO S.A.',
  '2595622-1-833050': 'AUTO ELECTRIC IMPORT',
  '36193-83-262625': 'AUTO MART',
  '8-870-2162': 'Auto Rep. y Ferret.',
  'N-17-998': 'AUTO REPUESTO FELIPE',
  'N-18-927': 'Auto Repuesto Maraño',
  'N-19-1748': 'Auto Repuestos Felip',
  '2168326-1-768217': 'AUTOAIRE',
  '8-NT-1-13577': 'AUTORIDAD DE TRANSIT',
  '8-NT-1-12532': 'Autoridad Maritima',
  '8-NT-2-6064': 'AUTORIDAD NACIONAL D',
  '8-NT-2-3982': 'AUTORIDAD PANAMEÑA',
  '5-17-748': 'B.T. CONTRATISTA',
  '47101-2-306017': 'BAC International',
  '38552-9-271952': 'BAHSA',
  '8-NT-1-11404': 'Balboa Yacht Club',
  '858-372-102850': 'BALINERAS Y EQUIPO',
  '858-372-102850': 'BALISA',
  '158080--387251': 'BAMBITO',
  '35090-124-258812': 'BANCO ALIADO',
  '280-134-61098': 'Banco General',
  '8-NT-1-12528': 'Banco Hipotecario',
  '985189-1-533017': 'BANCO LAFISE',
  '1280564-1-600446': 'Banco Panama',
  '8-472-818': 'BANDERAS BERNAL',
  '8-823-151': 'Bar Happy Zone',
  '30688-106-239948': 'Bar Muelle',
  '334-118-72930': 'Baterias Nacional',
  '17383-49-163504': 'BENEDETTI E HIJOS, S',
  '558900-1-444724': 'Bennigan´s',
  '776761-1-486887': 'BIRCO INTERNATIONAL,',
  '1880043-1-718916': 'BLUE POWER ELECTRONI',
  '708516-1-470523': 'Boulevard Albroock',
  '2511977-1-820466': 'BRANKOL CORPORATION',
  '1348556-1-616715': 'BRISAS DE AMADOR, S.',
  '155606118-2-2015': 'BROCHETAS',
  '2262545-1-2133': 'Burger King',
  '155627992-2-2016': 'BURGER KING 7',
  '571483-1-446710': 'Business Software &',
  '504380-1-19629': 'C.F.&CO.ABOGADOS',
  '46004-187-302083': 'CABLE & WIRELESS',
  '30394-2-238626': 'Cable Onda',
  '155626035-2-2016': 'CAFE BILAL',
  '635-567-131005': 'Cafe Boulevard Balbo',
  '1775882-1-700850': 'Cafe Cup S.A.',
  '2684453-1-845627': 'Cafe Madero',
  '295709-1-409413': 'CAFE PEATONAL, S.A.',
  '2189995-1-771726': 'Cafeteria Manolo',
  '8-NT-1-10200': 'CAJA DE AHORROS',
  '1175-352-23818': 'CALAVINSA',
  '1175-352-23818': 'CALDERAS Y LAVANDERI',
  '9-125-971': 'CAMILO BATISTA',
  '46492-8-303832': 'Canal Supplies',
  '601815-1-451839': 'CANNELA CORP',
  '1183324-1-578664': 'CAPITAL BANK',
  '777563-1-487093': "CAPTAIN'S STORE & SE",
  '2071293-1-751536': 'CARBON DE PALO',
  '122-395-32518': 'Cardoze',
  '1967436-1-734718': "CARL'S JR.",
  '8-775-794': 'CARLOS HERNANDEZ',
  '9-892-2196': 'CARLOS LEE ZHUO',
  '8-885-1780': 'CARLOS MARQUEZ',
  '8-219-2319': 'CARLOS MENACHO',
  '8-313-124': 'CARLOS SIANCA',
  '8-306-39': 'Carlos Yanguez',
  '155636071-2-2016': 'CARNES TIO GOLLITO',
  '8-815-1933': 'CARNICERIA MARAVILLA',
  '9559-243-97788': 'CASA COSMOSS',
  '466-489-101996': 'Casa Egeo',
  'N-19-1838': 'CASA FINA',
  '2205545-1-774045': 'CASA JUAN SIU, S.A.',
  '1774761-1-700693': 'Casa Ken Wah, S.A.',
  '8-831-1419': 'Casa La Tosca',
  '155626982-2-2016': 'CASA LIMPIA SERVICE',
  '8-190-955': 'Catalino Valencia',
  '1990-139-37214': 'CAYCAT',
  '10-704-1960': 'Cecilio Martinez',
  '2161-2-8272': 'Cedeño & Mendez',
  '1084003-1-555218': 'Central America Prop',
  '1713371-1-688901': 'Central de Baterias,',
  '1255-519-126037': 'Central de Tuercas y',
  '11248-24-113454': 'Centralam Panama, S.',
  '8-NT-2-20289': 'CENTRO CAMINO DE CRU',
  '1980565-1-736956': 'CENTRO COMERCIAL SOH',
  '1396989-1-1060': 'CENTRO COMERCIAL WES',
  '758246-1-481884': 'CENTRO DE EDUCACION',
  '8-NT-2-22766': 'CENTRO DE SALUD',
  '937-138-19820': 'Centro Industrial',
  '27621-11-228669': 'Centro Marino',
  'E-8-104862': 'CERNA GONZALEZ',
  '8-937-1768': 'CESAR L. DE LA CRUZ',
  '1725567-1-691286': 'CESAR PIZZA, S.A.',
  '28878-23-233240': 'CHAITEN INTEROCEANIC',
  '1832102-1-710609': "CHICHO'S PIZZA",
  '1025497-1-541493': 'CHICKEN FACTORY, S.A',
  '35925-11-261718': 'Chindo Internacional',
  '2-221-78': 'CIA. INTERNACIONAL',
  '1060-263-21847': 'CIA. NEYRA, S.A.',
  '21653-55-194138': 'Cidel Service S.A.',
  '15569960-2-2020': 'CIGAR CLUB PANAMA, S',
  '2544356-1-825416': 'CINNABON',
  '222-582-52813': 'CISA',
  '934082-1-522297': 'City Parking Panama',
  '721-313-125925': 'CLARAMAR',
  '8-881-344': 'CLEMENTE CONCEPCION',
  '155712127-2-2021': 'CLICK PARKING PANAMA',
  '539-153-116071': 'Clinicas y Hospitale',
  '9-702-647': 'Clodomiro Muñoz Gon',
  '292-101-10236': 'Club de Yates y Pesc',
  '811-557-101609': 'CLUB DEPORTIVO UNION',
  '1903-36-2502': 'Club Nautico Caribe',
  '521-136-113133': 'Cochez y Cia., S.A.',
  '36063-116-262228': 'COCI AIRE',
  '2453585-1-812598': 'COFFEE ROASTERS',
  '650-529-126088': 'COMASA RIO ABAJO',
  '2179477-1-770107': 'Combustibles de Prim',
  '776902-1-486934': 'Comercial Farmaceuti',
  '24471-79-212888': 'COMERCIAL SANTA ANA',
  '1333688-1-613519': 'COMIDA ANIMAL, S.A.',
  '8-825-1464': 'Comida Dia a Dia',
  '433157-1-429089': 'Comidas Repidas Inte',
  '8-259-383': 'COMISARIATO MELISA',
  '8-762-1123': 'COMISARIATO SAN JOSE',
  '9344-19-95753': 'COMPAÑIA FUMIGADORA',
  '896-366-105129': 'COMPAÑIA SAR, S.A.',
  '170-126-4192': 'Compañia Atlas',
  '2-221-78': 'Compañia Internacion',
  '896-366-105129': 'Compañia Sar, S.A.',
  '63429-0059-354966': 'Computer Connection',
  '54693-20-331528': 'Comtel, S.A.',
  '5-NT-2-5002330': 'CONSORCIO AGUAS DE C',
  '8-393-50': 'COOK 4 YOU',
  '2734-1-364219': 'COOL MART, S.A.',
  '130-377-34706': 'Copa Airlines',
  '06041085': 'Copland Events Ltd',
  '12931-1-365887': 'Corporacion de Produ',
  '155659479-2-2017': 'CORPORACION INQUIVEP',
  '8073-31-85771': 'Costa Azul',
  '4-202-201': 'COSTA DE ORO',
  '2628455-1-837648': 'COSTA FOCDS S.A.',
  '560-15-117738': 'CREDITOS Y SERVICIOS',
  '4-137-1185': 'CRISTIAN FLORES',
  '921600-1-519473': 'Cristobal Marine',
  '504380-1-9629': 'CUBIAS & FUNG',
  '2471167-1-814903': 'CURRENT TECHNOLOGIES',
  '8-792-333': 'Dagel Mendoza',
  'PE-13-1242': 'DAILY ACOSTA',
  '60172-2-346131': 'DALBEZ S.A.',
  '2-738-1308': 'DANIEL A. GARCIA R.',
  'E-8-56122': 'Daniel Desena Medina',
  '8-856-1231': 'Daniel Sarco',
  '8-769-1775': 'Danna Service',
  'E-8-108523': 'DAVID CARO CORREA',
  '8-907-493': 'DEINIEL VEGA',
  '5990-74-71492': 'Del Prado',
  '105765-1-379278': 'DELI GOURMET, S.A.',
  '8-762-1123': 'DELIZAS GOURMETS',
  '11524-2-115657': 'DELTA CALLE 50',
  '11524-2-115657': 'DELTA CHANIS',
  '11524-2-115657': 'DELTA LOS ROBLES',
  '594524-1-450642': 'DELTA PUNTA PACIFICA',
  '9-181-99': 'DEMETRIO',
  '8-887-1571': 'DENI DANIEL RODRIGUE',
  '3-744-232': 'DENIS RIOS',
  '4-85-949': 'Denzil Bernal Tello',
  '2440820-1-810814': 'DEVENTER HOLDING, S.',
  '445-27-1748': 'Diablo Spinning Club',
  '5977-19-71425': 'Dic, S.A.',
  '8-416-584': 'Didimo Rivera',
  '7-75-763': 'Didimo Vergara1',
  '305-545-9557': 'DIESEL PRO POWER, IN',
  '10-753-164': 'DILION ROY KASTULE',
  '875331-1-509984': 'Dilupa Panama, S.A.',
  '708-53-120389': 'Dimar, S.A.',
  '4-137-1755': 'DINO RODRIGUEZ',
  '8-840-1584': 'Diogenes Gonzalez',
  '30773-73-240340': 'Discovery Center',
  '2325346-1-2195': 'DIST. CUMMINS DE PMA',
  '18993-78-174370': 'Distribuidora G. DE',
  '711832-1-471238': 'Distribuidora La Dia',
  '1096-99-113799': 'Distribuidora Lewis',
  '27621-11-228669': 'DISTRIBUIDORA MARINE',
  '7940-39-34941': 'Distribuidora Prospe',
  '704243-1-469449': 'Distribuidora Roag',
  '31318-33-242633': 'DISTRIBUIDORA UPIM',
  '15430-249-148718': 'Do It Center',
  '8-376-804': 'Domingo Solano',
  '1675920-1-680848': 'DOMINOS PIZZA',
  'E-8-127343': 'DONGCHENG JIANG',
  '1070406-1-551970': 'DORADO FOOD',
  '8-765-644': 'DOREILYS ZULEIKA LOP',
  '311494-1-411579': 'DORIDIS, S.A.',
  '1639795-1-673532': 'DRAGON CELL',
  '808-349-126446': 'DULCERIA MOMI',
  '33182-56-250806': 'DURALLANTA',
  '33182-56-250806': 'DURALLANTAS, S.A.',
  '909-459-103719': 'E PORTO C Y CIA, S.A',
  '545679-1-442500': 'e-Business Distribut',
  '1738348-1-693845': 'EASTERN MARINE SUPPL',
  '1720-823-1-690293': 'ECO CHEMICAL, INC.',
  '155684734-1-2019': 'EDDAS',
  '57983-2-340436': 'EDEMET',
  '8-848-916': 'EDGAR CARRERA',
  '8-33-2044': 'EDGARDO VILLAR',
  '155602718-2-2015': 'EDIFICIO P.H. DESIGN',
  '45820-27-2869': 'EDIFICIO PH ROYAL',
  '7-44-184': 'EDILIO AMABLE BROCE',
  '8-718-548': 'Eduardo Madrid',
  '8-867-1934': 'EDWIN A. VASQUES',
  '1531875-1-653634': 'EDWIN MARINE SERVICE',
  '155697887-2-2020': 'EL BRASERO',
  '60920-1-372510': 'EL FUERTE',
  '186-505-46878': 'EL LLANTERO',
  '60926-1-8725': 'EL MACHETAZO',
  '8-800-2258': 'EL MUNDO DE LOS REGA',
  '5990-74-71492': 'EL PRADO',
  '2047415-1-747433': 'EL RANCHO',
  '8-914-760': 'EL RINCONCITO PANAME',
  '2029751-1-744658': 'EL TRAPICHE',
  '155629101-2-2016': 'EL TRAPICHE FAST FOO',
  '1197742-1-581371': 'ELABORACIONES DIK',
  '12150-178-121154': 'Electricidad',
  '9-730-853': 'Electricidad R & P',
  '25717-2-220038': 'Electrihogar',
  '155598693-2-2015': 'Electro Diesel, S. A',
  '6599-124-75498': 'ELECTRO INYEC DIESEL',
  '8-843-2009': 'Electronica Max Audi',
  '2885-2-45956': 'ELEMENTOS INDUSTRIAL',
  '6-50-2544': 'Elias Luna',
  '5-706-618': 'ELIECER MOSQUERA',
  '9-733-382': 'ELIO DAGMAR RODRIGUE',
  '2-125-798': 'Elizabeth Alveo',
  '8091-204-85890': 'ELMEC',
  '5-22-302': 'ELVIS',
  '8-355-961': 'ELVIS H. RODRIGUEZ',
  '5-22-302': 'ELVIS LOPEZ',
  '8-355-961': 'Elvis Rodriguez',
  '00069822778081': 'EMBUTIDORA DON VINCE',
  '8-356-972': 'Emilio Palomeras',
  '1980285-1-736916': 'Empresas BATALLA S.A',
  '1080323-1-554308': 'Empresas Carbone',
  '39378-29-275330': 'ENA Norte S.A.',
  '45408-55-299957': 'ENA Sur, S.A.',
  '45408-55-299957': 'ENA-PANAPASS (PREPAG',
  '5-124-580': 'ENRIQUE ARBOLEDA',
  SF0425974: 'Enrique Polanco',
  '898846-1-514496': 'EPAGO INTERNATIONAL',
  '2047719-1-747477': 'EPV Marine, S.A.',
  '1047184-1-546710': 'EQUIPAMIENTOS Y MAQU',
  '1307650-1-607407': 'Equipos y Partes Pan',
  '1307650-1-607407': 'EQUIPSA',
  '8-742-1644': 'ERASMO VASQUEZ',
  '8-61-410': 'Eric Camano',
  '4-792-1040': 'ERICK A. CORDOBA',
  '4-792-1040': 'ERICK H. CORDOBA',
  '8-822-406': 'Ernesto Pineda',
  'E-8-431-62': 'ERNESTO POLANCO',
  '63440-2-354972': 'ESTACION ACCEL PEDRE',
  '838-469-101213': 'Estacion Casobar',
  '2185130-1-770922': 'ESTACION DE COMBUSTI',
  '11286-40-113797': 'Estacion Hotel San C',
  '3849-14-54692': 'ESTACION LA BOCA',
  '6-89-138': 'Estacion Lili',
  '2185130-1-770922': 'Estacion Milla Uno',
  '2191980-1-771953': 'ESTACION PUMA ARRAIJ',
  '2195338-1-772453': 'ESTACION PUMA CURUND',
  '2158776-1-766579': 'ESTACION PUMA RODMAN',
  '2157184-1-766323': 'Estacion Puma Via Ar',
  '2185130-1-770922': 'ESTACION PUMA VIA PO',
  '594524-1-450642': 'Estacion Punta Pacif',
  '155594161-2-2015': 'ESTACION SAN NICOLAS',
  '2573069-1-829772': 'ESTACION TERPEL',
  '630-483-123250': 'ESTACION TERPEL COST',
  '625499-1-455526': 'Estacion Texaco Beta',
  '1377855-1-623196': 'ESTACION TEXACO FRAN',
  '155602006-2-2015': 'Estacion Texaco Gala',
  '956054-1-526865': 'Estacion Texaco Omeg',
  '2290153-1-788391': 'ESTACION TEXACO RIGE',
  '14784-27-143635': 'Estacionamiento CMP',
  '2149215-1-764024': 'ESTACIONAMIENTO LA A',
  '2071972-1-751668': 'ESTACIONAMIENTO Y MO',
  '3489-14-54692': 'ESTACIONES',
  '2290153-1-788391': 'Estaciones de Paso',
  '594524-1-450642': 'Estancion P.Pacifico',
  '908-242-19211': 'ETERNA PLAQUE, S.A.',
  '8-257-412': 'EUSEBIO ADEL LEIRA',
  '1779034-1-701532': 'Eventos 2000, S.A.',
  '1578617-1-662118': 'Express Rent a Car',
  '28726-153-232616': 'EXTRA',
  '2518638-1-821465': 'FACTORY FASHION',
  '2629292-1-837740': 'FAI LOK S.A.',
  '1589899-1-664229': 'FALAFEL KING S.A.',
  167961366148: 'FARMACIA AMILKA',
  '270-35-61112': 'FARMACIA EL JAVILLO',
  '1978218-1-736579': 'FARMACIA JANETH',
  '30109-82-235998': 'FARMACIA SANTA AGUED',
  '346-266-76470': 'FARMACIAS ARROCHA',
  '346-266-15683': 'FARMACIAS ARROCHAS',
  '2493475-1-817933': 'FARMACIAS SAN ANTONI',
  '938350-1-523184': 'Fastrack',
  '8-847-2348': 'Fatima del Carmen Mo',
  '9-700-2328': 'FAUSTINO HERNANDEZ',
  '257-563-58968': 'FAUSTO SALAZAR, S.A.',
  '2-109-241': 'FEDERICO MONTERO R',
  '49679-106-746': 'FEDEX',
  '92569-1-377445': 'FELICIDAD EXPRESS',
  '64637-25-357671': 'FELICIDAD HERMANOS,',
  '441-74-94389': 'Felipe Motta e Hijo',
  '441-74-91389': 'FELIPE MOTTA, S.A.',
  'E-810-4454': 'Felipe Polanco',
  'E-8-84910': 'FELIX',
  '8-708-749': 'Felix Martinez',
  'E-8-84910': 'FELIX MON',
  'E-8-84910': 'FELIX MORALES',
  '766722-1-21912': 'Fernando Castillo',
  '8-313-659': 'FERNANDO DUMANOIR',
  '155699750-2-2020': 'FERNELLS INVESTMENTS',
  '2234860-1-778661': 'Ferremar',
  '2370232-1-800572': 'Ferret. El Albanil',
  '2370232-1-800572': 'FERRET. EL ALBAÑIL,',
  '8-210-1437': 'FERRETERIA CASA FRAN',
  '342975-1-416373': 'FERRETERIA EUREKA',
  '2454804-1-812754': 'FERRETERIA MARTIN',
  '8-407-502': 'FERRETERIA PYTHON',
  '5-13-96': 'Ferreteria y Autos',
  '380-489-83910': 'FERTICA, S.A.',
  '62013-103-350949': 'FERTILIZANTES SUPERI',
  '215308-1-397621': 'FIBROPINTURAS, S.A.',
  '2670746-1-843613': 'FICE PANAMA, S.A.',
  '16821-38-159113': 'FILPAK',
  '31952-17-245574': 'Filtros Carossi',
  '2486907-1-812046': 'FINANCENTRO EST S.A.',
  '12117-222-120843': 'Firemaster',
  '13493-2-132798': 'FLETES CHAVALE S.A.',
  '8-736-2275': 'Florencio Herrera',
  '4-244-961': 'Fonda Josepth',
  '1894621-1-721566': 'FONDA KEVIN',
  '8-441-10': 'Fonda La Suerte',
  '8-777-600': 'Fonda Mar Estrella',
  '4-202-220': 'FONDA MARICELLYS',
  '8-128-784': 'FONDA MARISOL',
  '7-71-607': 'FONDA RAMIRIN',
  '8-839-232': 'Fonda Todo Hay',
  '155589621-2-2014': 'FOOD TECHNOLOGICS',
  '45008-12-298240': 'FOODIE',
  '444103-1-430599': 'FORMAX, S.A.',
  '213618-1-397361': 'FORMICA',
  '155674474-2-2019': 'FR GROUP, S.A.',
  '40979-56-281813': 'FRAMAR PANAMA',
  '2-102-1621': 'FRANCISCO CALDERON',
  '3-67-727': 'Francisco Lewis',
  '8-812-2301': 'FRANCISCO VASQUEZ',
  '3-115-941': 'Freddy Morris',
  '39889-23-277344': "FRIDAY'S",
  '39389-23-277344': 'FRIDAY´S',
  '772-441-117276': 'Friolin, S.A.',
  '8-419-902': 'FROILAN ORTEGA',
  '66093-28-363182': 'Fruteria Mimi',
  '2164777-1-767612': 'Fuel Injection, S.A.',
  '902679-1-515225': 'FUERTE - LA ONDA',
  '5-700-1924': 'Fumigadora Don Pocho',
  '1857-2-7259': 'FUNDACION NATURA',
  '1968567-1-734899': 'Furniture City, S.A.',
  '4-713-1817': 'FUSION TECNOLOGICA',
  '2196917-1-772659': 'G & H, S.A.',
  '8-263-414': 'Gabriel Dumanoir',
  '8-461-35': 'GABRIEL LOPEZ',
  '4-285-440': 'Gabriel Rivera',
  '1697480-1-685819': 'Gasolinera La Boca',
  '39274-16-274902': 'GAUCHOS STEAK HOUSE',
  '8-725-2010': 'Genaro Moran',
  '1636513-1-672914': 'GENUINE CHICKEN',
  '138-289-35920': 'Geo F. Novey, Inc.',
  '8-853-1332': 'Gerardo Tapia',
  '8-NT-2-2510': 'German Forero',
  '908636-1-516610': 'GINZA MOTORS S.A.',
  'E-8-122171': 'GIORGIO VILLANI',
  '8-474-282': 'Giovani Ortiz',
  '2209898-1-774721': 'GK DIESEL Y MULTISER',
  '11581-341-116139': 'Glidden',
  '657714-1-460702': 'Global Audio Video',
  '231152-1-399982': 'GLOBAL AUTO INVERSIO',
  '40979-25-281810': 'Global Bank',
  '1961913-1-733811': 'Global Marine Panama',
  '447620-1-431125': 'GLOBAL RESTAURANT GR',
  '3-737-1977': 'GOLDEN MINI MARKET',
  '13753-173-134817': 'Gran Futuro',
  '1760479-1-698163': 'Grand Canal Cafe',
  '105765-1-379278': 'Grand Deli Gourmet',
  '2124206-1-760851': 'Group Happiness, S.A',
  '1426865-1-633206': 'GRUPO BRILLANTE, S.A',
  '836417-1-482201': 'Grupo Choy',
  '1195213-1-580963': 'Grupo Edmonds',
  '1276195-1-599325': 'GRUPO INDEX, S.A.',
  '37215-2-266499': 'GRUPO LOS PUEBLOS',
  '682073-1-464676': 'GRUPO TODO A DOLAR,',
  '353-62-76827': 'Gudico',
  '307930-1-411029': 'GUESS MULTIPLAZA',
  '8-268-981': 'Guillermo Mcnulty',
  '8-842-1523': 'GUILLERMO RODRIGUEZ',
  '18835-171-173482': 'Gulf Oil Panama, S.A',
  '1499269-1-647290': 'HACIENDA COLOMBIANA',
  '10-707-2449': 'Haniel Tejada Herrer',
  '28878-23-233240': 'HAPPY COPY',
  '640277-1-457975': 'HARTIN TRADING, S.A.',
  '8-314-421': 'HECTOR DECORATION',
  '08-0314-0421': 'Hector Torres M.',
  '155709245-2-2021': 'HELIO UAV, S.A. HERO',
  '8-498-476': 'Heriberto Ledezma',
  '51070-52-320234': 'HERMANOS GAGO, S.A.',
  '8-355-316': 'HERMINIO SANDOVAL B',
  '27770-59-229110': 'HERRAMIENTAS Y TORNI',
  '724675-1-473907': 'HIDROCA',
  'N/A': 'HODGES MARINE',
  '2069791-1-751271': 'HOPSA',
  '2069775-1-751270': 'HOPSA, S.A.',
  '1347373-1-616448': 'HOROKO GRILL',
  '5409-60-67198': 'HOSPITAL SANTA FE, S',
  '37279-2-266781': 'Hot Express',
  '1808647-1-706815': 'HOT RANCH 66',
  '1025-165-21304': 'HSBC Seguros',
  '6-713-1224': 'HUMBERTO LUNA',
  '465528-1-433500': 'HYDROSERVICES CORP.',
  '30688-106-239948': 'I.B.C.',
  '121-261-33279': 'IASA PANAMA, S.A.',
  '991356-1-534267': 'IBS CONSULTING, S.A.',
  '58478-44-341836': 'IBS PLUS, INC.',
  '291950-1-408882': 'ICAMOS, S.A.',
  '121-261-33279': 'IIASA',
  '367422-1-25177': 'iInversiones y Const',
  '2268023-1-784648': 'IL TEATRO, S.A.',
  '520023-1-923868': 'Implementos y Herram',
  '510-302-109858': 'IMPLOSA',
  '4127-235-56968': 'IMPORTADOR SUPERIOR',
  '558-472-101708': 'IMPORTADORA MADURO',
  '7-75-920': 'IMPRENTA DON BOSCO',
  '155653393-2-2017': 'Impresiones del Istm',
  '8-361-879': 'Indira Morella',
  '1730759-1-692400': 'INDUSTRIAL & MARINE',
  '8-825-1927': 'INGRID MONTALVO',
  '30815-22-240563': 'Inmobiliaria Don Ant',
  '770-590-130159': 'INMOBILIARIA VASQUEZ',
  '155635405-2-2016': 'INSIGNIA BUSINESS SO',
  '8-NT-1-12631': 'INSTITUTO PANAMEÑO D',
  '8-703-28': 'Inter. De Partes Ele',
  '2595622-1-833050': 'INTER. PARTES ELECTR',
  '364866-1-419533': 'Intermarine Supplies',
  '12539-142-124373': 'International Manage',
  '1242591-1-591558': 'INTERSTATE BATTERIES',
  '578-536-121794': 'INVERSIONES BARRIA,',
  '274345-1-406499': 'INVERSIONES EDELCA,',
  '735-383-117197': 'INVERSIONES ENCANTO',
  '1356169-1-618628': 'INVERSIONES GOMART',
  '145762-1-384839': 'Inversiones Henmot',
  '155683681-2-2019': 'INVERSIONES J.F. 202',
  '886674-1-512095': 'Inversiones Lassner',
  '33309-2-251303': 'INVERSIONES SAMIL, S',
  '367422-1-25177': 'Inversiones y Constr',
  '682-67-187522': 'INVERSIONES YIMY, S.',
  '4-740-1499': 'IRINA KARINA CHAVEZ',
  '155700331-2-2020': 'IRON & CONTRUCTION D',
  '8-952-1113': 'IRVIN MARISCAL',
  '4-763-475': 'IRVING R. BERNAL J.',
  '8-900-740': 'ISAAC A. ROUSE G.',
  '8-248-241': 'Isaac Mena',
  '155593086-2-2015': 'ISLA MANAGEMENT CORP',
  '1184-537-111303': 'Islamorada Internaci',
  '8-730-463': 'ISRAEL PEREZ',
  '41355-35-283362': 'Italdieselec',
  '8-729-1292': 'IVAN A. BELTRAN M.',
  '8-39-1982': 'Ivan Arturo Domingue',
  '4-729-437': 'IVAN PEREZ',
  'E-8-104459': 'Javier Polanco',
  '4-251-460': 'Javier Salina',
  '8-816-1790': 'Jean Paul De Janon',
  '4-729-437': 'JEAN PEREZ',
  '8-904-1772': 'Jean pieere Nelson',
  '8-904-1772': 'Jean-pieere Nelson',
  '8-737-862': 'JEREMIAS DELGADO C',
  '8-464-310': 'Jesus Aleman',
  '4-749-826': 'JESUS GAITAN G.',
  '9-737-1458': 'Jesus Gonzalez',
  '155611337-1-2015': 'JET BOSS',
  '656936-1-460609': 'JL ELECTRONICS, S.A.',
  '155682191-2-2019': 'JMAK MIFERRE, S.A.',
  '9-707-2187': 'Joel Caballero',
  '8-856-368': 'JOHN JAIRO LABRADOR',
  '9-731-1286': 'JORGE M. GUERRA R.',
  'E-8-70391': 'Jorge Ochoa',
  '7-94-275': 'JORGE VEGA',
  '4-744-2031': 'Jose Aguilar',
  '09-734-2180': 'JOSE AGUSTIN URIETA',
  '4-744-2031': 'JOSE BATISTA',
  '6-57-1083': 'JOSE BERNAL',
  '8-426-806': 'JOSE CORDOBA',
  '8-302-801': 'Jose Eduardo Leon',
  '8-858-585': 'Jose Eriberto Rodrig',
  '8-518-315': 'Jose Martinez',
  '2-125-341': 'Jose Mendoza',
  '5-701-341': 'JOSE PEREA',
  '9-734-2180': 'JOSE URIETA',
  '4-745-1370': 'Josepth Saldaña',
  '8-865-505': 'JOSTINE CASTILLO',
  '3-751-2323': 'JOSUE R. HALL NUÑEZ',
  '2-746-425': 'JOSUE VILLALOBOS',
  '8-700-1793': 'JOVANKA DEL CID',
  '059991843': 'JUAN MEDINA',
  '8-908-1695': 'JUAN RODRIGUEZ',
  'PASAPORTE AO107987': 'Julia Sandoval de Vi',
  '8-373-56': 'JULIAN L.',
  '4-278-422': 'JULIO BARRIA',
  '3-737-525': 'JULIO C. ALVAREZ',
  '8-870-2162': 'July Feng Zhang',
  '8NT-2-7590': 'Junior Car Wash',
  '8-42-128': 'KADIR SALINAS',
  '34351-064-': 'KARABERRI, S.A.',
  '8-765-1115': 'KELVIN OMAR MENACHO',
  '902-308-105023': 'KFC',
  '2205721-1-774103': 'KOOL KAR SERVICES',
  '811194-1-498031': 'KOTOWA COFFEE HOUSE',
  '1697480-1-685819': 'La Boca Gas Station',
  '35226-2-259295': 'La Casa de la Bateri',
  '2350894-1-797719': 'LA CASA DE LA CUERIN',
  '2020592-1-743300': 'LA CASA REAL DE PMA',
  '428489-1-428727': 'La Eskinita',
  '8-845-460': 'LA FONDA DE PAPA EN',
  '155653913-2-2017': 'LA LOMA PARKING GROU',
  '30688-106-239948': 'La Playita Amador',
  '1609190-1-667938': 'LA TABERNA DEL CANAL',
  '8-726-686': 'LA ZONA',
  '1959862-1-733343': 'LABISA INTL. GROUP,',
  '155624032-2-2016': 'LALIZAS',
  '2506923-1-819773': 'Lasturiana, S.A.',
  'E-8-194193': 'LAURIBETH VALERA',
  'N-19-2450': 'LAVANDERIA COLORS',
  '1825584-1-709713': 'LAYNE COMMERCIAL SER',
  '5-713-1714': 'LEIBOR PAZ',
  '8-713-319': 'LEONARDO CEDEÑO',
  '729590-1-474973': 'Leonardo Express, S.',
  '6606-1-373276': 'LEONARDO LONGAT',
  '8-528-1244': 'Leonel Perez C.',
  '7-94-2730': 'Leticia Gonzalez Vel',
  '1477450-1-643123': 'Letter Express Courr',
  '8-834-2400': 'Lightblinds',
  '836454-1-502728': 'LOS AÑOS LOCOS',
  '854128-1-1505977': 'LOTTO FOODS, S.A.',
  '45087-1-370271': 'LOUSIANNA KITCHEN',
  '155688340-2-2019': 'LP CAPITAL INVESTMEN',
  '45087-1-370271': 'LP RESTAURANT OPERAT',
  '13597-109-133529': 'Lubricantes Delta',
  '8-745-1357': 'LUIS A. ORO REYES',
  '5-721-411': 'LUIS CARISORI',
  '8-410-840': 'Luis Castillo',
  '8-344-672': 'LUIS CAÑIZALES',
  '8-954-1363': 'LUIS E. VALDES',
  '8-210-1084': 'Luis Esposito',
  '8-880-628': 'Luis Salazar',
  '51319-137-320920': 'Lumicentro, S.A.',
  '938-553-106007': 'Lung Fung S.A.',
  '2563985-1-828265': 'M/S AMADOR',
  'N-17-663': 'M/S PLAZA VALENCIA',
  'PE-6-434': 'M/S Y PANADERIA ROBE',
  '2612753-1-835610': 'MAAGOO FISH TACOS',
  '537091-1-441028': 'Mac Store',
  '652-212-129962': 'Machetazo',
  '1999530-1-739965': 'MAD PACIFIC CORP.',
  '1059726-1-549472': 'MADDEN',
  '1059726-1-549472': 'MADDEN - COLON',
  '63612-20-355520': 'Madecaro S.A.',
  '85443-1-376232': 'MAIL BOXES ETC.',
  '158080--387251': 'MANANTIAL DE VOLCAN,',
  '155662378-2-2018': 'Manantial Pma Norte',
  '155614854-2-2015': 'MANGUERAS HIDRAULICA',
  '8-410-840': 'MANO SERVICES',
  6713726: 'MANUEL CRUZ',
  '4-259-347': 'Manuel M',
  '8-323-40': 'Manuel Martinez',
  '4-259-347': 'Manuel Morales',
  '8-278-107': 'Manuel Perez',
  '8-525-2416': 'MANUEL PEÑA',
  '8-729-853': 'MANUEL ULLOA',
  '51870-9-322298': 'MAPIEX AVIATION',
  '8-323-40': 'MARCELINO MUÑOZ',
  '5-722-790': 'MARCELO ZARCO M.',
  '5-8-666': 'Marco A. Cordoba',
  '3-69-915': 'Margarita S. de Esco',
  '9-210-829': 'MARIA E. SOTO TORIBI',
  '8-852-1340': 'Maria Hernandez',
  '9-124-1691': 'MARIA ROMERO',
  '8-265-781': 'MARIBEL HERNANDEZ',
  '997765-1-535526': 'Marine Blenders',
  '8-278-107': 'MARIO A. PEREZ',
  '9-259-347': 'Mario Ariel Chamen',
  '9-700-22': 'MARIO BATISTA',
  '8-278-107': 'Mario Perez',
  '4-784-2368': 'MARIO RIVERA',
  '6-182-140': 'MARIO RODRIGUEZ',
  '1-716-626': 'Mario Villalobo',
  '983095-1-572': 'MARKET GLASS OH & S',
  '9-475-157': 'Martha Almanza Madri',
  '2454804-1-812754': 'MARTIN CENTRO FERRET',
  '4-2-25720': 'Martin Salina',
  '887813-1-512264': 'Masala Indian Cusine',
  '16831-38-159113': 'Master Direct Panama',
  '8-751-164': 'Mateo Mendoza B.',
  '942151-1-52400': 'Materiales Chong',
  'N-19-1269': 'Materiales y Ferrete',
  '63612-20-355520': 'Mauricio Deportes',
  '344-219-74838': 'MAX E. JIMENEZ, S.A.',
  '155591597-2-2015': 'MAX INDUSTRIAS',
  '724751-1-473927': 'Maxi Car & Shop',
  '8-323-40': 'MAXIMILIANO MUÑOZ',
  '155591597-2-2015': 'MAXINDUSTRIAS, S.A.',
  '2479533-1-815980': 'Maxxi Tronic',
  '9-181-99': 'MAYA',
  '34347-8-255691': 'Mayher, S. A.',
  '517268-1-438062': 'McDonald',
  '434-15-93796': 'MEGA DEPOT',
  '8-372-116': 'Melania Pottinger de',
  '650-529-126088': 'Melo',
  '8-327-568': 'MELVA M. GALVEZ',
  '187254-1-393285': 'MERCADO LA TERMINAL,',
  '2381729-1-802256': 'Mesolju S.A.',
  '638-562-129776': 'METALICA PEREZ, S.A.',
  '30815-22-240563': 'METRO PLUS',
  '155715260-2-2021': 'MF FOOD, S.A.',
  2082638: 'MI RANCHITO',
  '26873-83-226131': 'Miami Express',
  '8-841-1697': 'Michael Murillo',
  '1-724-1700': 'Miguel Arcia',
  '5-704-482': 'Miguel Miranda',
  '3631-133-52892': 'Miguez y Cia.',
  '8-': 'MILVANA SERRACIN',
  '8-726-686': 'Mini Suoer La Zona',
  'PE-9-1626': 'Mini Sup y Panaderia',
  'N-19-2470': 'MINI SUP. Y SED. GLO',
  'N-17-979': 'MINI SUPER',
  'N-19-1914': 'Mini Super 4 de juli',
  'PE-8-2552': 'MINI SUPER ALICIA CH',
  '8-874-411': 'MINI SUPER CARNICENT',
  '7-72-513': 'Mini Super Cheng',
  '8-865-396': 'MINI SUPER CHON',
  '8-886-082': 'MINI SUPER FABULOSO',
  '8-828-2308': 'Mini Super Falan',
  'N-20-434': 'MINI SUPER FUNG',
  '3-90-91': 'MINI SUPER GENERAL N',
  '8-849-1931': 'MINI SUPER H C',
  '8-822-301': 'MINI SUPER HOU',
  '8-837-381': 'MINI SUPER J.K.',
  '8-808-177': 'MINI SUPER JHONNY',
  'N-20-387': 'MINI SUPER JOSE Y SU',
  '4-259-429': 'MINI SUPER KADI',
  'N-20-600': 'MINI SUPER LA ECONOM',
  '8-888-711': 'MINI SUPER LA PRIMER',
  '8-916-360': 'MINI SUPER LA ZONA',
  '8-779-1889': 'MINI SUPER LAW',
  '8-849-1931': 'MINI SUPER MERCEDES',
  '1625670-1-670929': 'MINI SUPER MIAMI GAL',
  '1994791-1-739233': 'MINI SUPER MIGUEL',
  'N-19-1696': 'MINI SUPER PLAZA VAL',
  '8-834-221': 'MINI SUPER Y CARNICE',
  'N-16-873': 'MINI SUPER Y FRUTERI',
  'PE-9-1626': 'mini super y panader',
  'N-19-2470': 'MINI SUPER Y SEDERIA',
  '1808647-1-706815': 'MINIMARKET SHOPPET',
  '1895740-1-721744': 'MINIMED',
  '8-841-1985': 'MINISUPER Y FERR LEE',
  '256767-34573-11': 'MIRAMAR DEVELOPMENT',
  '8-294-805': 'MIRIAM RODRIGUEZ',
  '7-92-280': 'MITZI ESCUDERO V.',
  '8-803-444': 'MITZI O. RODRIGUEZ E',
  '155631030-2-2016': 'MIXO, S.A.',
  '155609694-2-2015': 'MK PANAMA RETAIL, S.',
  '8-861-350': 'Mobil Electronics',
  '4211-81-57606': 'Moderna Comercial',
  '7753-108-83601': 'MON, S.A',
  '412487-1-426135': 'MOTOPARTES MPH, S.A.',
  '15219-169-146898': 'MOTOR PARTES, S.A.',
  '1247593-1-592480': 'Motores de Segunda',
  '1687833-1-683760': 'Movicell',
  '2251653-1-78166': 'MOVIL CARDS, S.A.',
  '47028-19-305805': 'Movistar',
  '3622123-1-743474': 'MR. AREPAS, S.A.',
  '28242-0002-230727': 'MUEBLERIA TROPICAL',
  '1183433-1-578682': 'MUEBLES DEL PACIFICO',
  '2520100-1-827100': 'MUELLE 67',
  '62614-8-352542': 'MULTI FINANCIAMIENTO',
  '8-838-44': 'MULTI MARKET',
  '1002-214-109639': 'MULTI REPUESTOS, S.A',
  '8-493-818': 'Multi Servicios I.J.',
  '30831-116-240657': 'Multi-Partes, S.A.',
  '8-396-125': 'MULTICOLOR',
  '36765-2-264774': 'MULTIMAX',
  '1187-179-110584': 'Multiplastic',
  '8-236-1544': 'MULTISER. VIALCO',
  '8-493-818': 'Multiservicios I.J.',
  '8-707-506': 'MULTISERVICIOS L.B.',
  '36765-2-264774': 'Multitek Pacifico',
  '8-NT-1-12701': 'MUNICIPIO DE PANAMA',
  '2508060-1-819944': 'NARVAL MARINE, S.A.',
  AZ0449347: 'NELSIDA B. PIMENTEL',
  '7-118-852': 'Nelson Ariel Velasqu',
  '295709-1-409413': 'NELSON`S CAFE PEATON',
  '57749-2-339693': 'Neotec',
  '9-754-316': 'Nereida Sanchez',
  '8-332-859': 'NIDIA E. BECERRA',
  '33209-109-250936': "Niko's Cafe.",
  '33209-109-250936': 'Niko´s Cafe',
  '33209-109-250936': 'Niko´s Cafe,',
  '8-832-1307': 'Noel Herrera',
  '5-713-1710': 'NOIDER PAZ',
  '1435581-1-634819': 'North Star Suppliers',
  '8-421-593': 'NOTARIA SEGUNDA',
  '956751-1-527074': 'Novedades El Viajero',
  '138-289-35920': 'NOVEY',
  '5-713-1710': 'Noyder Paz',
  'E-8-86914': 'NUEVO RESTAURANTE JO',
  '2-722-810': 'O. RAMOS',
  '155703889-2-2021': 'OCEAN PARKING, S.A.',
  '52663-2-325361': 'Ocean Pollution Cont',
  '2687065-1-846069': 'OCEANCO SAFETY & SUP',
  '57740-69-339686': 'OCEANO',
  '656-413-132700': 'OFFICE DEPOT',
  '9-730-853': 'OIDEN RODRIGUEZ',
  '8-902-341': 'OMAR O. JAEN CASTILL',
  '8-521-290': 'Omar Rodriguez',
  '1675920-1-680848': 'OPERADOR PANAMAX',
  '2598467-1-833502': 'OPERADORA CENTRAL DE',
  '32410-153-247549': 'OPTICA LOPEZ',
  '1883816-1-719630': 'Optima Compañia de S',
  '8-928-931': 'ORLANDO JAEN',
  '2-722-810': 'ORLANDO RAMOS',
  '8-432-90': 'ORLANDO RUDAS',
  '630-483-123250': 'Orlyn, S.A',
  '9-759-1786': 'OSCAR CARRASCO',
  '8-712-1413': 'OSCAR MADRID',
  '8-873-786': 'Osmar Rodriguez',
  '8-856-121': 'Osvaldo Morales',
  '1512-25-30305': 'OTEC DE PANAMA, S.A.',
  '8-867-926': 'OVIDIO A.G.',
  '57366-2-338628': 'OZONE CAFE',
  '35226-11-259296': 'P.H. Plaza Concordia',
  '155660256-2-2018': 'PAELLA CATERING, S.A',
  '2082508-1-753526': 'PAN COLOMBIA, S.A.',
  '1341064-1-615028': 'PanaCELL',
  '734163-1-476099': 'PANACHINA CORPORATIO',
  '8-760-1725': 'PANADERIA CREMAPAN 2',
  '8-248-576': 'PANADERIA DAVID',
  '6-70-350': 'PANADERIA Y DULCERIA',
  '8-868-36': 'PANADERIA Y REFRE LA',
  '1308142-1-607540': 'PANADERIA,DULCERIA R',
  '66159-91-363381': 'Panafoto, S.A.',
  '836430-1-502723': 'PANAFRUTA, S.A.',
  '67-36-20135': 'Panama Auto',
  '18686-70-172575': 'PANAMA DIESEL POWER',
  '1012645-1-538647': 'PANAMA LIVING SOLU',
  '87184-1-376530': 'PanamaShipping',
  '456498-1-432343': 'PANAMEÑA DE HELADOS',
  '2687112-1-846083': 'PAPARAZZI, PIZZA PAS',
  '8-787-108-2010-197877': 'PAQTS. CORP.',
  '2054724-1-748584': 'PARKING SOLUTIONS',
  '682-67-187522': 'PARRILLADA JIMMY',
  '1669379-1-679380': 'parrillada latino',
  '62227-67-351557': 'PARTES AUTOMATICAS,',
  '1048-240-113266': 'Partes Diesel, S.A.',
  '1242591-1-591558': 'PARTS TRADING PANAMA',
  '30148-102-237493': 'PASTELERIA NOEL',
  '1303676-1-606293': 'PATAGONIA GRILL',
  '406984-1-425359': 'PATSA',
  '1189790-1-579944': 'PAYPAL',
  'N-20-210': 'PC CELL',
  '8-NT-2-710658': 'PEDIDOS YA, S.A.',
  '143-95-30679': 'PEMCO',
  '143-95-36679': 'PEMCO, S.A.',
  '1016-327-115007': 'Pesqueros',
  '2157184-1-766323': 'PETROLEOS CHITRE S.A',
  '11524-2-115657': 'Petroleos Delta, S.A',
  '8-NT-2-6145': 'PH CAPITAL PLAZA BUS',
  '15559945-72-2015': 'PHILADELPHIA MANAGEM',
  '428297-1-428400': 'PICOTA E HIJAS, S.A.',
  '2044137-1-746894': 'PIDA PIZZA PANAMA, S',
  '836748-1-502778': 'PINTURA J.P., S.A.',
  '902-308-105023': 'PIZZA HUT EXPRESS',
  '281524-40889-98': 'PIZZAS ITALIANA S.A.',
  '30688-106-239948': 'Playita',
  '517268-1-438062': 'Plaza Food, S.A.',
  '8-482-319': 'POLLO COLORAO NO. 4',
  '45087-1-370271': 'POPEYES',
  '66104-114-363217': 'PORTO FINO',
  '39293-52-274981': 'Powertronic Corporat',
  '39293-52-274981': 'Powertronic Services',
  '2567606-1-828909': 'PPG INDUSTRIES PANAM',
  '155602864-2-2015': 'PREASCA, S.A.',
  '1925708-1-727160': 'Premium Paint Panama',
  '2357204-1-798763': 'PRETELT GOURMET MEAT',
  '898-241-102416': 'PRICESMART PANAMA',
  '1570764-1-6606666': 'PRINT PLUS, S.A.',
  '2442455-1-811063': 'PRINT PROMO INC.',
  '45402-43-299911': 'PRODUCTOS MUNDIALES',
  '186-505-46878': 'Productos Superiores',
  '1650104-1-675446': 'Professional Ship Su',
  '8-441-713': 'PROM. Y SERVICIOS BU',
  '8-441-713': "PROMO Y SERV. BURU'S",
  '92569-1-377445': 'PROSPERO BUSINESS',
  '816-389-68814': 'PROTECSA',
  '2135596-1-762765': 'PROVADO, S.A.',
  '64552-9-358308': 'Proyectos Concaba, S',
  '2207748-1-774411': 'PSI TRADING AND LOGI',
  '155688340-2-2019': 'PUMA 4 DE JULIO',
  '2164777-1-767612': 'PUMA ALBROOK',
  '155688340-2-2019': 'PUMA BETHANIA',
  '2164777-1-767612': 'PUMA DOMINGO DIAZ',
  '2364349-1-799753': 'PUMA EL BOSQUE',
  '155703776-2-2021': 'PUMA ENERGY SPEEDWAY',
  '2179477-1-770107': 'PUMA SUPER 7',
  '2164777-1-767612': 'PUMAS ALBROOK',
  '2179477-1-770107': 'PUMAS BRISAS',
  '1486813-1-644866': 'Pumas las Americas',
  '35811-21-261362': 'Purissima, S.A.',
  '9-181-99': 'R. MAYA',
  '31077-20-241613': 'R.A. FRANCO Y ASOCIA',
  '664506-1-461699': 'RADIADORES ALASKA',
  '30170-22-237573': 'RAENCO INTERNACIONAL',
  '9-62-635': 'RAFAEL CORDERO',
  '3-37-850': 'Rafael Guardia',
  '8-223-1214': 'RAFAEL MURRELL',
  '8-853-1501': 'Rafael Valdes',
  '9-708-121': 'RAFAEL VASQUEZ A.',
  '8-879-2375': 'RAMSES H. GOMEZ M.',
  '34844-12-257928': 'RAPID LUNCH, S.A.',
  '8-282-560': 'Raul Bosquez',
  'E-8-92454': 'RAUL FUENTES GOMEZ',
  '2-154-163': 'RAUL PEREZ',
  '15626-2-150129': 'REFRESQUERIA LA MODE',
  '17502-17-164597': 'REFRESQUERIA LAS ABE',
  '8-NT-1-22244': 'REGISTRO PUBLICO',
  '4711-2-61584': 'REINSA',
  '3-719-1337': 'Renato Cañizales',
  '8-371-865': 'Rene E. Gonzalez C.',
  '8-472-146': 'Renzo A. Clarke',
  '8-853-977': 'REPARACIONES MARTINE',
  '2024629-1-743927': 'REPOSTERIA DORA',
  '12486-20-123919': 'REPRESENTACIONES CAN',
  '2435158-1-809987': 'REPRESENTACIONES SUS',
  '2027408-1-744322': 'Republic Parking Alb',
  '8-849-2059': 'Repuestos Comerciale',
  '2349064-1-797481': 'Repuestos Peter, S.A',
  '48740-140-312112': 'REST PIZZERIA PASTA',
  '7-80-705': 'Rest.  La Cazuela',
  'PE-9-1448': 'REST.  SABOR DEL PAC',
  '2268023-1-784648': 'REST. AZAFRAN',
  '836454-1-502728': 'Rest. Años Locos',
  '68460-1-373690': 'Rest. Bar El Bodegon',
  '428297-1-428400': 'REST. BAR MENDOZA SP',
  '1330938-1-612951': 'REST. BAR Y BILLAR',
  '844040-1-504055': 'REST. BEIRUT AMADOR',
  '8-773-1163': 'Rest. Calidonia',
  '9-154-548': 'REST. CHARRO MEXICAN',
  '1503093-1-648083': 'REST. CHUEN MEI',
  '8073-31-85771': 'REST. COSTA AZUL',
  '8-853-195': 'Rest. Delicias Yeimy',
  '8-827-538': 'Rest. Don Kee',
  '1590834-1-664441': 'Rest. El Asador',
  'N-17-966': 'Rest. El Cantones',
  '4831-45-62483': 'REST. EL LEON DE ORO',
  'E-887226': 'Rest. El Rincon Chit',
  'N-17-979': 'Rest. Freeway #1',
  '423717-1-427739': 'REST. GOLDEN',
  'N-19-1859': 'Rest. Hot Chicken 3',
  '1838690-1-711611': 'REST. KWANG CHOW',
  '721-313-125925': 'Rest. La Casa del Ma',
  '1060-534-116592': 'Rest. La Estancia',
  '33309-2-251303': 'Rest. La Fe',
  '8-259-383': 'Rest. Las Americas',
  '8-823-151': 'REST. MEGAPOLIS',
  '1697366-1-685797': 'Rest. Mr. Chung',
  'E-8-91553': 'REST. NEW BAI LE YUA',
  '175649-1-391013': 'Rest. Panama Grill',
  '1690886-1-684394': 'REST. POLLO TROPICAL',
  'N-19-2464': 'Rest. Precio Popular',
  'N-20-438': 'Rest. Reina',
  '8-830-2431': 'Rest. Reina Calidoni',
  '2215253-1-775564': 'Rest. Reina Meliza',
  '23992-104-210636': 'Rest. Siete Mares',
  '1519184-1-651215': 'Rest. Terminal, S.A.',
  '9-134-648': 'Rest. Villa Zaita',
  '575-37-101777': 'REST. Y PIZZERIA NAP',
  '836370-1-415491': 'RESTAURANTE ARRECIFE',
  'E-8-52526': 'RESTAURANTE DON ALEX',
  'E-8-93063': 'Restaurante Doradita',
  '1598845-1-665957': 'RESTAURANTE EL CIRUE',
  'N-20-2221': 'RESTAURANTE EL DELIC',
  '39198-95-274569': 'Restaurante El Jade',
  'E-8-87568': 'RESTAURANTE HOLIDAY',
  'E-8-81107': 'RESTAURANTE J. MAC',
  'E-8-47776': 'Restaurante Jennifer',
  'E-8-58182': 'Restaurante Jenny Pl',
  'E-8-83713': 'Restaurante Jeny',
  '8-515-2029': 'RESTAURANTE KALUAS',
  'E-8-87239': 'RESTAURANTE KIARA',
  'N-20-438': 'RESTAURANTE LA REINA',
  '197873-1-394916': 'Restaurante Las Excl',
  '4-197-150': 'RESTAURANTE MAIRA',
  '1425965-1-632974': 'RESTAURANTE MAITO',
  'PE-14-283': 'RESTAURANTE MR.PANDA',
  '8-221-211': 'Restaurante Mundis',
  '817813-1-499549': 'Restaurante Pastisim',
  '51107-94-320347': 'RESTAURANTE PIZZERIA',
  '8-830-2431': 'Restaurante Reina',
  '2215253-1-775564': 'Restaurante Reina Me',
  '7267-2-80143': 'RESTAURANTE WAIKIKI',
  '2589677-1-832153': 'RESTAURANTE WESTLAND',
  '2435158-1-809987': 'Restaurante y Asados',
  'N-20-372': 'RESTAURANTE YEE',
  '8-837-2193': 'Restaurante Yinche',
  '8NT-2-27356': 'REY DEL SHAWARMA',
  '8-426-516': 'REYNALDO SANTANA',
  '342-240-75526': 'RIBA SMITH',
  '4-239-430': 'RIBELINO SERRANO NIE',
  '305-414-68335': 'RICARDO PEREZ',
  '8-371-9546': 'RICKO`S CAFE',
  '7-112-299': 'Rigoberto Fruto',
  '1168495-1-575637': "RINO'S RISTORANTE E",
  'PASS 488073379': 'ROBERT THOREN',
  '8-419-141': 'ROBERTO GALLARDO',
  '696372-1-467757': 'ROCAYOL SAFETY & IND',
  '148772-1-645006': 'Rock Burger',
  '5-710-966': 'RODRIGO ORTEGA',
  '155702480-2-2021': 'RODRIGUEZ VEGA SA',
  '8-500-344': 'ROGERD E. MORENO',
  '8-971-1700': 'ROLANDO RANGEL',
  '8-757-1697': 'ROMERO SERVICES',
  '10-703-164': 'ROY DILEON',
  '362664-1-419255': 'RSM PANAMA, S.A.',
  '2180949-1-770371': 'S & P FOOD',
  '8-870-872': 'S/M LLUVIA DE ORO',
  '4-251-460': 'SALINAS',
  '4-251-460': 'SALINAS DELIVERY SER',
  '155650250-2-2017': 'SALVA MI MAQUINA, S.',
  '2639916-1-839327': 'SANTA MARIA HOTEL',
  '4-745-1370': 'Santiago Saldana',
  '8-947-2103': 'SANTIAGO SANTOS',
  '5-19-767': 'Saru',
  '783317-1-488848': 'SASHA TOP SERVICES',
  '325347-1-413868': 'SEAFARERS TRAINING C',
  '1274067-1-598838': 'Seapoint Corp.',
  '2664585-1-842660': 'SEASONS PLUS',
  '462960-1-433176': 'Seaturck',
  '9-700-2331': 'Seda Castillo',
  '1756995-1-697531': 'SEGURIDAD UNIDA ELEC',
  '1760088-1-697531': 'SEGURIDAD UNIDA, S.A',
  '9-707-1936': 'Serv.y Ventas Guarum',
  '1100470-1-559637': 'Servicentro Aries, S',
  '37966-192-269690': 'SERVICENTRO NACIONAL',
  '13-NT-2-5002983': 'SERVICIO TECNICAT',
  '73-561-21585': 'Servicios Electricos',
  '73-561-21585': 'Servicios Electronic',
  '8-158-15': 'SERVICIOS MOJICA',
  '3-119-570': 'Servicios Morice',
  '8-739-967': 'SERVICIOS TECNICOS H',
  '24271-91-211974': 'SERVICIOS Y TALLERES',
  'E-8-70391': 'SERVIDRIOS',
  '8-413-508': 'SERVIELECTRA',
  'E-8-70391': 'Servividrios',
  'E-8-70391': 'SEVIDRIOS',
  '3-716-33': 'SHALYM DELGADO',
  '155679234-2-2019': 'SHAWARMA DAMASCO, S.',
  '10189-2-104236': 'SHERATON PANAMA HOTE',
  '33337-51-251442': 'SHOPET BRISAS',
  'N-20-210': 'SHUI QING FU NG',
  '8-1050-1361': 'SHUI SHUANG CHONG',
  '1093682-1-557816': 'SIENA 404, S.A.',
  '1135843-1-568374': 'Silverking Investmen',
  '557-538-101617': 'SISTEMAS MCOPCO PANA',
  '551903-1-443497': 'Siwireless.com',
  '155605892-2-2015': 'SKECHERS PANAMA',
  '1209307-1-583901': 'SLB SOLUTIONS, INC',
  '155630336-2-2016': 'Smartphone',
  '572-1-118040': 'SOCIEDAD URBANIZADOR',
  '51107-94-320347': 'SORRENTO',
  '31260-34-242388': 'Southeastern Power',
  '257643-1-404101': 'Subway',
  '155616265-2-2015': 'SUITSUPPLY',
  '1906999-1-723731': 'SUMINISTROS ALIMENCI',
  '1368597-1-621231': 'SUMINISTROS Y RODAMI',
  '434-15-93796': 'Super 99',
  '4-740-1499': 'SUPER CENTER',
  '8-796-1026': 'SUPER CENTRO CORONA',
  '902679-1-515225': 'Super Centro El Fuer',
  '30815-22-240563': 'Super El Rey',
  '28726-153-232616': 'Super Extra',
  '155690888-2-2020': 'SUPER KOSHER',
  '4-242-817': 'SUPER MERCADO CHANG',
  '8-884-470': 'SUPER MERCADO NICASI',
  'N-19-2486': 'Super Mercado San',
  '155696684-2-2020': 'SUPER Y PANADERIA SO',
  '1410-269-28074': 'SUPERA',
  '1165-302-127461': 'SUPERC KLIN',
  '902679-1-515225': 'Supercentro El Fuert',
  '24627-1-367362': 'SUPERCENTRO EL TAJON',
  '8-890-550': 'SUPERCENTRO LA FORTU',
  '8-854-304': 'Supercentro Natali',
  'PE-9-1463': 'Superm. Panamericano',
  '30815-22-240563': 'Supermercado el Rey',
  '4-740-1499': 'Supermercado Feli Ho',
  '1424640-1-632696': 'Supermercado Kam',
  'N-20-1830': 'SUPERMERCADO L & L',
  '5-23-333': 'SUPERMERCADO LUCKY S',
  '3-64-1492': 'SUPERMERCADO MI FAVO',
  'PE-9-1463': 'supermercado Panamer',
  '8-819-195': 'SUSANA KUANG',
  '824430-1-500660': 'SUZUKI',
  '8-966-1648': 'SYMON A. CASTILLO M.',
  '467-484-103645': 'SYSCO',
  '8864-0123-91468': 'T SHIRTS',
  '300303-1-41002': 'T-SHIRTS MAX, S.A.',
  '902-308-105023': 'TACO BELL',
  '1567816-1-660206': 'TALAYOTES, S.A.',
  '8-310-774': 'TALLER ARIEL TEJADA',
  '4-142-1985': 'TALLER BLANCO',
  '2418-12-41537': 'Taller Cidel Motor',
  'E-8-21746': 'Taller de Soldadura',
  '9321-131-95539': 'TALLER MAGNOLIA',
  'PE-9-411': 'TALLER PEDRO MIGUEL',
  '337-297-72453': 'TAMBOR, S.A.',
  '783254-1-488807': 'Tamburrelli',
  '5-19-841': 'TAPIZADOS PERCI',
  '1988289-1-738243': 'Te Cafe, S.A.',
  '403570-1-424808': 'TECH & HOUSE',
  '56761-12-337002': 'TEISA, S.A.',
  '47028-19-305805  DV 18': 'Telefonica Moviles',
  '1542358-1-655893': 'TEMPO DESIGN INC.',
  '1522273-1-651875': 'Ten Food, S.A.',
  '2221004-1-776477': 'TERPEL SAN ANTONIO 2',
  '155602006-2-2015': 'TEXACO GALAXY',
  '2290153-1-788391': 'Texaco Rigel',
  '155627434-2-2016': 'THE CORNER 76',
  '155601980-2-2015': 'The Mediterranean Re',
  '155620936-2-2016': 'THE PANAMA CLINIC',
  '1135843-1-568374': 'THE WESTIN PANAMA',
  '1926753-1-727276': 'Thunder LS',
  '2643040-1-839728': 'TIENDAS AUTOCASA',
  '2158079-1-766480': 'TIENDAS DE CONVENIEN',
  '155624064-2-2016': 'TIENDAS DE PASO, S.A',
  '942869-1-524169': 'TITATIUM TOWER CORP.',
  '11988-147-119590': 'Tito Torres, S.A.',
  '155682847-2-2019': 'TO GO KITCHENS, INC.',
  '759170-1-482099': 'Toners Seguros, S.A.',
  '254774-1-403664': 'TORNERIA YREC YASURY',
  '254774-1-403664': 'Torneriayrectificado',
  '23024-0191-205095': 'Tornillo y Tornillos',
  '2367609-1-800244': 'TOTAL MARINE SUPPLY',
  '2-95-745': 'TRANQUILINO VERGARA',
  '2-95-745': 'TRANQUILIO VERGARA',
  '2451764-1-812364': 'TRANS OCEAN NETWORK',
  '1272462-1-598267': 'Transporte 44, S.A.',
  '8-708-749': 'TRANSPORTE DANTE',
  '155660657-2-2018': 'TRANSPORTE HUPERNIKA',
  '2165861-1-767774': 'Transporte Luna VIP',
  '8-474-282': 'Transporte y Equipos',
  '8-440-520': 'TRANSPORTE YAZMINE',
  '498846-1-814345': 'TROPIFUEL, S.A.',
  '594-120-104838': 'TROPIGAS DE CHIRIQUI',
  '60949-1-372514': 'TROPIMAC, S.A.',
  '56876-24-337263': 'Truly Nolen',
  '155670231-2-2018': 'TSOILEE, S.A.',
  '1476937-1-643053': 'TU SEGURIDAD.COM S.A',
  '7110-26-79016': 'TZANETATOS',
  '1091-157-111481': 'ULTRACOM',
  '155631807-2-2016': 'UNIDO DEL ESTE SA',
  '28835-134-525': 'UNITED AIRLINES, INC',
  '2260512-1-783203': 'UNIVERCELL DE PANAMA',
  '9-720-131': 'URIEL PINEDA',
  '521347-1-438253': 'VALE PANAMA',
  '278643-1-407080': 'Validreef, S.A.',
  '541-81-118009': 'VARIBE Y CIA, S.A.',
  '38682-54-272473': 'Vendela S.A.',
  '8-343-311': 'Vicencio Solis',
  '8-441-1003': 'VICTOR M ALVAREZ',
  '8-306-191': 'VICTOR M. ARNAEZ',
  '8-203-1867': 'VICTOR M. HALL',
  '8-928-206': 'VICTOR RODRIGUEZ',
  '8-585-795': 'VICTOR SAMUDIO',
  '16369-68-155679': 'Vidrios y Espejos Am',
  'E-8-70934': 'VILMA HERRERA DE CAC',
  '1900088-1-722364': 'WEN DORADO, S.A.',
  '155666775-2-2018': 'WENDY`S',
  '1900088-1-722364': 'WENDY`S 1',
  '2213785-1-775372': 'WONDERFUL, INC',
  '8-229-1951': 'XAVIER LOMBA ORTIZ',
  'E-8-87708': 'XIONGHUI WU',
  '28726-153-232616': 'XTRA',
  '8-701-1148': 'Yasser Arafat Herrer',
  '8-762-299': 'YIMAR MORENO',
  '2420441-1-807767': 'Yoo Restaurante',
  '963942-1-528502': 'Yoytec Computer, S.A',
  '300309-1-410027': 'YUDA INTERNACIONAL',
  '8-': 'ZARINA COUTTE',
};

export const refripolarProviders = {
  '1995745-1-739368': '100 PORCIENTO COOL',
  '32906-107-249419': 'A D R, S.A.',
  '155626252-2-2016': 'A1 MULTISERVICIOS, S.A',
  '15430-249-148718': 'ACE INTERNATIONAL',
  '20804-20-187819': 'ADMINISTRACIÓN CANCUN S,A',
  '1979638-1-736806': 'ADMINISTRADORES Y FRANQUICIAS, S.A.',
  '855-534-103651': 'AGENCIAS DOMINGO,S.A.',
  '16219-85-169844': 'Agua Cristalina S.A.',
  '25042516-3-2020': 'AIR CONDITIONING',
  '155618022-2-2015': 'Alta Eficiencia',
  '48740-140-312112': 'ATHE NS PIZZA BRISAS',
  '8-830-2215': 'AUTO REPUESTO PANAMERICANA',
  '155696088-2-2020': 'BACKOFI, S.A.',
  '529409-1-439947': 'BELLINI, S.A',
  '155616202-2-2015': 'BOX PANAMA 10,S.A.',
  '2537314-1-824364': 'BRIGHT CAPITAL CORPORATION',
  '155627992-2-2016': 'Burger King',
  '24687-150-214130': 'Business Suppliers',
  '275-308-60860': 'CENTRO DE AGENCIAS',
  '652-212-129962': 'CIA GOLY SA',
  '155712127-2-2021': 'CLICK PARKING PANAMA, S.A.',
  '521-136-113133': 'COCHEZ Y CIA',
  '1557055428-2-2021': 'Cool Systems International Inc.',
  '1077389-1-553678': 'Copper Group',
  '155705879-2-2021': 'DACORP GROUP',
  '30773-73-240340': 'DISCOVERY CENTER',
  '15430-249-148718': 'Do it Center',
  '459854-1-432800': 'DOLAR DISCOUNT, S.A.',
  '45820-27-2869': 'EDIFICIO PH ROYAL CENTER',
  '714715-1-471701': 'EJP ELECTRONICS, S.A.',
  '12150-178-121154': 'Electrisa',
  '714715-1-471701': 'ELECTRONICA JAPON',
  '8-905-680': 'ELECTRONICA KENCO',
  '2885-2-45956': 'ELEMENTOS INDUSTRIALES, S.A.',
  '45408-55-299957': 'ENA',
  '2053456-1-748386': 'ESTACIONAMIENTO REVOLUTION,S.A.',
  '155682586-2-2019': 'EXPAND PANAMA,S.A.',
  '346-266-76470': 'FARMACIAS ARROCHA, S.A.',
  '938350-1-523184': 'FASTRACK, S.A.',
  'PE-9-1606': 'FERRETERIA PROFELEC',
  'N-19-2457': 'FERRETERÍA MARTIN, S.A.',
  '902-308-105023': 'FRANQUICIAS PANAMEÑAS,S.A.',
  '2225-11-39563': 'FRIOLIN SEGUNDO,S A.',
  '772-441-117276': 'FRIOLIN,S.A.',
  '766487-1-484112': 'Frioworld',
  '8-952-1745': 'FRUTERIA CAMINO REAL',
  '2037221-1-745799': 'Growand Corp',
  '510-302-109858': 'IMPORTADORA DE PLOMERIA S A',
  '434-15-93796': 'IMPORTADORA RICAMAR, S.A',
  '541-81-118009': 'INMOBILIARIA DON ANTONIO, S.A.',
  '169-299-42313': 'La Clima',
  '1059726-1-549472': 'MADDEN-COLON',
  '597-46-103707': 'MAPFRE',
  '2454804-1-812754': 'Martin Centro Ferretero',
  '9-84-807': 'Mini Super Tesoro',
  '8-509-985': 'NOTARIA PÚBLICA LICDO JORGE GANTES',
  '138-289-35920': 'Novey',
  '630-483-123250': 'ORLYN',
  '155622908-2-2016': 'PAINT SOLUTIONS & REPAIR INC',
  '2569882-1-829306': 'Panagruas Maporal, S.A.',
  '2410916-1-806320': 'PANAMA SAFETY',
  '2119457-1-760045': 'PARK ONE CORP.',
  '11524-2-115657': 'Petroleos Delta',
  '155644168-2-2017': 'PETRÓLEOS SAN AGUSTÍN,S.A.',
  '8-NT-2-10286': 'PH CENTRO COMERCIAL BOULEVARD BALBOA',
  '8-752-1730': 'PIEZAS ORIGINALES AC',
  '436-287-95110': 'PRAXAIR  PANAMA,S .A',
  '1075658-1-553254': 'Preasa',
  '898-241-102416': 'Pricesmart',
  '8-723-2152': 'RAUL HURTADO',
  '155631334-2-2016': 'RefriCool',
  '155699387-2-2022': 'REFRIGERACIONES PEKIN',
  '2604563-1-834402': 'Refrigeraciones Polar, S.A.',
  '1767235-1-699413': 'REFRISTORE',
  '342-240-75526': 'RIBA SMITH, S.A.',
  '990255-1-534049': 'SABORES INTERNACIONALES,S.A.',
  '922818-1-519758': 'SERVICENTRO PLAZA TOCUMEN',
  '73-561-21585': 'SERVICIOS ELÉCTRICOS,S.A.',
  '1508-31-30247': 'SHALOM, S.A.',
  '638045-1-457554': 'SPORTWEAR CORPORATION, S.A.',
  '8-812-1502': 'SUPER TIRES PANAMA',
  '28680-26-232375': 'SUPLIMEDIC',
  '2375455-1-801394': 'SY&D ADVANCED',
  '8864-0123-091468': 'T-SHIRTS INTERAMERICA,S.A',
  '1567816-1-660206': 'TALAYOTES,S.A',
  '8-NT-2-5003134': 'TALLER DE MECANICA STYLE',
  '155709723-2-2022': 'TEPSA DE PANAMÁ',
  '2349408-1-797505': 'THERMO AMERICA GROUP',
  '30394-2-238626': 'TIGO',
  '942869-1-524169': 'Titatium Tower Corp',
  '155696916-2-2020': 'Tools Center Pnamá, S.A.',
  '155631871-2-2016': 'TORRE GLOBAL PARKING CORPORATION',
  '2547939-1-825936': 'UNICLIMA PANAMA',
  '529391-1-439940': 'Vauxhall Morthae Corp',
};

export const mcgowenProviders = {
  '1423-78153': 'Acarreos Lobo',
  '15430-249-148718': 'Ace',
  '18219-85-169844': 'Aguas Cristalinas',
  '271773-1-406185': 'Aguaseo',
  '8-785-2247': 'Aitian Center',
  '8-766-2206': 'Almacen Comics',
  '155597314-2-2015': 'Arawa',
  '603-203-124985': 'Auto Centro',
  'N-18-927': 'Auto Repuesto Mara',
  '8-NT-2-17099': 'Autoridad de Aseo',
  '35226-2-259295': 'Casa de Las Baterias',
  '466-489-101996': 'Casa Egeo',
  '28791-9-232875': 'Central de Lubricant',
  '937-138-19820': 'Centro Industrial',
  '28878-23-233240': 'Chaiten',
  '155625362-2-2016': 'Check Engine',
  '185-310-46305': 'Cia Atlas',
  '155712127-2-2021': 'Click Parking',
  '521-136-113133': 'Cochez',
  '155668956-2-2018': 'Comis Margarita',
  '105765-1-379278': 'Deli Gourmet',
  '27047-36-226926': 'Delicias Caseras',
  '59426-8-344310': 'Design Works',
  '155706773-2-2021': 'Detailing Via Porras',
  '2440820-1-810814': 'Deventer',
  '899907-1-514687': 'Dinamic Car Services',
  '7940-39-84941': 'Dist Prosperidad',
  '704243-1-469449': 'Dist Roag',
  '15430-249-148718': 'Do It',
  '155673844-2-2018': 'Drake Food',
  '33182-56-250806': 'Durallantas',
  '652-212-129962': 'El Machetazo',
  '12150-178-121154': 'Electrisa',
  '2885-2-45956': 'Elementos Industrial',
  '1556000001-2-2022': 'Empresa factura',
  '1080323-1-554308': 'Empresas Carbone',
  '650-529-126088': 'Empresas Melo',
  '1903738-1-723197': 'ENA',
  '2290153-1-799391': 'Estaciones de Paso',
  '346-266-76470': 'Farmacia Arrocha',
  '541-81-118009': 'Farmacia Metro',
  '155712796-2-2021': 'Farmacias Sobeys',
  '441-74-94389': 'Felipe Motta',
  '32966-52-249929': 'Ferreteria Edwin',
  '8-878-310': 'Fonda Exp Sant',
  '2262284-1-783573': 'Garcia Lopez',
  '1631494-1-671931': 'Global Customs',
  '155681390-2-2019': 'Grupo Auto Comercial',
  '155680187-2-2019': 'Grupo MMG',
  '682073-1-464676': 'Grupo Todo a Dollar',
  ' 1469541-1-641676 ': 'GSQ Panama',
  '28878-23-233240': 'Happy Copy',
  '724675-1-473907': 'Hidroca',
  '155697061-2-2020': 'Home Ctr 01',
  '2069791-1-751271': 'Hopsa',
  '923868-1-520023': 'Ihersa',
  '510-302-109858': 'Implosa',
  '434-15-93796': 'Importadora Ricamar',
  '541-81-118009': 'Inmobiliaria Don Ant',
  '2566699-1-828743': 'Inv HM',
  '561493-1-445109': 'Inv Petroleras',
  '2348858-1-797432': 'Inv. Happy Moments',
  '1555708848-2-2021': 'Isafelu',
  '9857-220-101047': 'Jellini',
  '381935-1-421833': 'JLS Group',
  '155612725-2-2015': 'Jumbo Mkt',
  '8-843-1229': 'Katrina Caceres',
  '994980-1-534997': 'La Casa de la Fiesta',
  '155740243-2-2023': 'LingLong Tire',
  '1999530-1-739965': 'Mad Pacific',
  '1059726-1-549472': 'Madden Colon',
  'E-8-100812': 'Marcelo Coelho',
  '4-830-1765': 'Maribel Montezuma',
  'N-21-1617': 'Materiales Lo Maximo',
  '155736363-2-2023': 'Mendez',
  '29192-12-234284': 'Mercado del Calzado',
  '916-586-106273': 'Metales',
  '3-752-6': 'Multicentro ACB',
  '155655209-2-2017': 'N N & M Inversiones',
  '155655209-2-2017': 'N N & M Inversiones',
  '155597320-2-2015': 'Nardex',
  '33209-109-250936': 'Nikos',
  '138-289-35920': 'Novey',
  '656-413-132700': 'Office Depot',
  '2126852-1-761215': 'Office Plus',
  '155623139-2-2016': 'Ontario Coffee',
  '1675920-1-680848': 'Operad Panamax',
  '630-483-123250': 'Orlyn',
  '52090-108-323391': 'PAAL',
  '66159-91-363381': 'Panafoto',
  '143-95-36679': 'Pemco',
  '11524-2-115657': 'Petroleos Delta',
  '155638908-2-2016': 'Petroleos San Miguel',
  '155738759-2-2023': 'Platinum',
  '155647104-2-2017': 'Porto Horizonte',
  '49859-17-316363': 'Portucan',
  '825-543-101039': 'PPG',
  '898-241-102416': 'Pricesmart Panama',
  '155703276-2-2021': 'Puma Arraijan',
  '155702480-2-2021': 'Puma Centenial',
  '155707471-2-2021': 'Puma El Bosque',
  '155720710-2-2022': 'Puma Via Porras',
  '24082-9-211065': 'Red Dragon',
  '155622411-2-2016': 'Rep Comerciales 8',
  '1880981-1-720495': 'Repues y Sparis Poto',
  '9-736-64': 'Repuestos 88',
  '342-240-75526': 'Riba Smith',
  '8-369-614': 'Roberto Fast Toys',
  '204-394-49730': 'Rodelag',
  '40034-0101-277995': 'Seguros Fedpa',
  '30773-0073-240340': 'Semfyl',
  '944-499-108345': 'Servicarga',
  '155705062-2-2021': 'Servicentro 4 Altos',
  '155748625-2-2024': 'Servicentro Fabros',
  '203004-1-395633': 'Sika',
  '1833767-1-710822': 'Simycon',
  '557-538-101617': 'Sist Mcopco',
  '356-19-77860': 'Super XTRA',
  '858922-1-506773': 'Tecsan',
  'E-8-86968': 'Tianmei Wu',
  '47028-19-305805': 'Tigo',
  '155696916-2-2020': 'Tools Center',
  '8-803-2128': 'Torneria Lopez',
  '2082495-1-753512': 'Ultra Petroleum',
  '155697798-2-20200': 'Uno Movil',
  '38682-54-272473': 'Vendela',
  'N-20-238': 'Weijung Feng',
  '8-NT-2-739634': 'Yunliang Zeng',
  'E-8-87713': 'Yuqun Cai',
};

export const pcaCevicheriaProviders = {
  '1747809-1-695691': 'AC1 SUPPLY PANAMA',
  '630-483-123250': 'Accel Quick-Shop',
  '15430-249-148718': 'ACE INTER. HARDWARE',
  '8-882-2201': 'Adan Revilla',
  '8-944-1794': 'Adilson Jimenez',
  '155684102-2-2019': 'AGUACATE 24, S.A.',
  '1008397-1-537608': 'ALBROOK ITALIAN',
  '2095085-1-755833': 'Aldo Panama',
  AW756474: 'Alejandro C. Ramirez',
  '10-712-1123': 'Aleny Urriola',
  126126202: 'Alexaida Mendoza',
  '155656002-2-2017': 'Aliss',
  89761266: 'Allison Centella',
  '155682304-2-2019': 'AM PM',
  '155646927-2-2017': 'Amortiguadores',
  '8-944-1229': 'Ana Martinez',
  '8-984-586': 'Angel Gonzalez',
  '8-932-214': 'Antonio Campbell',
  '2601581-1-833950': 'AQUA 507',
  '2597587-1-833386': 'Aquaice Panama S.A.',
  '1037697-1-544213': 'Arboretun G. P.LTD',
  '8-769-212': 'Armando Calvo',
  '155673023-2-2018': 'Auto 2001 PTY, S.A',
  '8-736-308': 'Bohemian',
  '46004-187-302083': 'Cable & Wireless',
  '30394-2-238626': 'CABLE ONDA S.A',
  'E-8-90062': 'Candy Party',
  '62662-43-352811': 'CAPACOL S.A',
  '155710531-2-2021': 'Carfuel Services S.A',
  '8-791-2403': 'Carlos Adame',
  'AT 698749': 'Carolina Lopez',
  103021845: 'Carolina Mirabal',
  '41390-2-283532': 'CASA DE LA CARNE',
  '155695145-2-2020': 'Cateriano & Botero A',
  '1998-139-37214': 'CAYCAT S.A',
  '555-328-101285': 'CERVECERIA PAN',
  '8-510-379': 'Cesar Orozco Duque',
  '12-705-1349': 'Cipriano Caballero',
  '521-136-113133': 'COCHEZ',
  '1041-1-363971': 'COCLESANA DE DIST.',
  '24471-19-212884': 'Comercial Santa Ana',
  '896-366-105129': 'Compañia Sar S.A',
  '8-NT-2-750374': 'CONSATCA',
  '560-15-117738': 'Creditos y Servicios',
  AS208464: 'Cristian Alvarez',
  '155713792-2-2021': 'Culinaria Group, S.A',
  '8-1237-2018': 'Dalicio Peña',
  '3-57-112': 'Daniel G. Rojas P.',
  '09651463': 'Darwin Mora',
  '8-992-118': 'David Acosta Zheng',
  '26622-30-224801': 'Deli Fish Panama S.A',
  '11524-2-115657': 'Delta',
  '11524-2-115657': 'Delta San Gabriel',
  AY224565: 'Diana Tobar',
  '1682251-1-682541': 'DIPSA',
  '10-701-1798': 'Distr. de Mariscos I',
  '50388-28-318111': 'Distribuidora Lily',
  '585569-1-448942': 'Dot Design S.A.',
  123883711: 'Douglas Vasquez',
  '253525-1-403482': 'Econoprecios S.A',
  '2588862-1-832056': 'Edemet',
  107151726: 'Ediliano Morris',
  '63444-11-354998': 'ELECT. JAPONESA',
  '1880043-1-718916': 'Electronics, S.A',
  '4-724-551': 'Eliecer Lopez',
  '8-715-891': 'Elis Cedeño',
  '8-813-1053': 'Elisa Moran',
  C02150002: 'Elizabeth Ramirez',
  '8091-204-85890': 'ELMEC, S.A',
  '8-932-214': 'Elquis A. Campbell',
  78222014: 'Emeglys Vilchez',
  '541848-1-441803': 'Empresas Bicosas S.A',
  '1080323-1-554308': 'Empresas Carbone S.A',
  '650-529-126088': 'Empresas Melo S.A',
  '57983-56-340439': 'ENSA',
  A049021956: 'Erik Ayala',
  AV028355: 'Erwin Medina',
  '838-469-101213': 'Estacion Casobar',
  '155602096-2-2015': 'Estaciones Amigas',
  '10-713-1445': 'Eudelcio Guardia',
  '155697799-2-2020': 'F & M Distribuitors',
  AX193249: 'Fabiana Triana',
  '8-NT-2-701577': 'Farah Academy',
  '346-266-76470': 'Farmacia Arrocha, S.',
  '2123632-1-760780': 'Farmacia Europea S.A',
  '1860738-1-715295': 'FAUNA PANAMA, S.A.',
  '441-74-94389': 'Felipe Mota S.A',
  '8-968-102': 'Felix Martinez',
  '155708403-2-2021': 'Feria Libre',
  '155656002-2-2017': 'Fernando Chong Loo',
  '1867414-1-716473': 'FERNANDO RIVERA',
  '155708408-2-2021': 'FONDA MAMA GALLINA',
  '45008-12-298240': 'FOODIE MARKET',
  '6727-75-76441': 'FORTUNATO MAN',
  '18423-0061-171041': 'FRAN GALY S.A',
  89311700: 'Gabriel Atencio',
  '155659992-2-2018': 'GASPRO',
  '40485-72-279728': 'General Suppliers S.',
  '138-289-35920': 'GEO F NOVEY',
  '2238436-1-779251': 'Gourmet Management P',
  '10420-0212-106436': 'Gradual Invest S.A',
  '155711277-2-2021': 'GRUPO CHONG',
  '155724672-2-2022': 'Grupo Climacomfort',
  '768384-1-484718': 'GRUPO DAHER, S.A',
  '2012-1-105947': 'Grupo GN Inversiones',
  '1366130-1-620789': 'Grupo La Reina S.A',
  '682078-1-464676': 'G-Todo A Dolar S.A',
  '10-703-2151': 'GUMERCILIO SALCEDO',
  '533-555-116945': 'H. TZANETATOS',
  '155599714-2-2015': 'Hakol Group S.A',
  '1237856-1-590407': 'Health Store S.A',
  '2069791-1-751271': 'Hopsa S.A',
  '8-NT-1-10284': 'IDAAN',
  '35226-2-259295': 'Imp. de baterias S.A',
  '434-15-93796': 'IMP. RICAMAR',
  '1696451-1-685581': 'Imported Exclusive M',
  '541-81-118009': 'INM. DON ANTO.',
  '51070-52-320284': 'Inm. y Exp.Herm.Gago',
  '8-nt-2-726340': 'Inox Soluciones',
  '098613375': 'International Food',
  '2510630-1-820291': 'Inv. Ghasb, S.A',
  '155691031-2-2020': 'Inv. Lucky Rey 2020',
  '155671805-2-2018': 'INV. NUÑEZ',
  '155660766-2-2018': 'INV. UNICO',
  '155615908-2-2015': 'Inv. y Serv.4603 Cor',
  '2470369-1-814748': 'ITALIA CAR WASH',
  '8-269-138': 'Ivan Vargas',
  '7-703-1638': 'IZENITH MAYLIN G. D.',
  '8-810-96': 'Jairo Cruz Salinas',
  132203090: 'Javier Hernandez',
  '8-NT-2-750374': 'Jesus Perez',
  AX298960: 'Jhon Torres',
  '8-740-9': 'Joacin Iglesia',
  '8-445-2021': 'Jonathan Rodriguez',
  '8-704-2151': 'Jose Abdon Castillo',
  216007717: 'Joseph Charles Arce',
  C02254733: 'Juan Oporta',
  '4-797-1349': 'Juan Tugri',
  '8-985-1008': 'Justin Herrera',
  '5837-93-72429': 'Kadima S.A',
  '2332147-1-794667': 'Katowa S.A.',
  '155661478-2-2018': 'KC Rola Inc.',
  '1177396-1-577437': 'KING MAX S.A',
  '155689831-2-2020': 'KLINER AMERICA',
  '607-24-124369': 'Komex Internacional',
  '8-943-1423': 'Kristel Savarain',
  '10-701-219': 'KUNARUMBAS',
  '155696294-2-2020|49': 'La Casa de Estufas',
  '994980-1-534997': 'La Casa de la Fiesta',
  '155675867-2-2019': 'La Printeria S.A',
  163373812: 'Leiker Rivero',
  '8-773-1041': 'Leila Mendoza',
  17481082: 'Liliana Buy',
  '2509031-1-820101': 'LISANTO S.A',
  '8-514-481': 'LOGO',
  '8-711-2054': 'Lucia Berrios',
  '13-NT-2-724289': 'Luis Lugo',
  163379339: 'Luis Montiel',
  125703440: 'Luis Montiel ( Padre',
  '553-231-100103': 'Lumicentro S.A',
  '1547-682-1-656808': 'M Y R Distribuitors',
  '9-96-190': 'M/S In And Out',
  '2321624-1-793061': 'MAG SECURITY, S.A',
  '155625591-2-2016': 'Manley Group, S.A',
  '1953617-1-732233': 'MARCA ALIMENTARIA',
  '8-727-512': 'Marcelino Aguilar',
  '4-744-927': 'Marelsa Rodriguez',
  1232390535: 'Maria A. Acevedo',
  '8-800-553': 'Marianela Martinez',
  '8-530-1577': 'Mariscos E.V.E S.A',
  '10-705-1260': 'Mariscos Fausto Miss',
  '4-764-633': 'MARTIN GUERRA',
  '25116-68-216612': 'Master Parts & Ser.',
  '155705968-2-2021': 'MIRAMAR',
  '3-749-2081': 'MULTISERVICE',
  '13-NT-2-731741': 'Multiservicios Decon',
  '8-438-906': 'Nasser Guerra',
  '1572076-1-660901': 'Nayco Corp',
  'E-8-197600': 'Nelson Patiño',
  'E-8-71504': 'Nelson Polanco',
  '10-705-3': 'Nixon Hackin',
  '8-707-101': 'NOTARIA NOVENA',
  '656-413-132700': 'OFFICE DEPOT',
  '155682304-2-2019': 'Operadora de Tiendas',
  '155654106-2-2017': 'Pan Comido S.A',
  '2122579-1-760611': 'PANAMA CHEFS, S.A',
  '2557417-1-827154': 'PANAMEÑA DE PLASTICO',
  132438126: 'Paola Gomez',
  '34557-82-256682': 'PAPELERIA COMERCIAL',
  '934245-1-522338': 'PCA',
  '8-961-2022': 'Peruvian Food Yiting',
  '8-249-10': 'PESCADO DEL MAR',
  '11524-2-115657': 'PETROLEOS DELTA',
  '8-NT-2-34115': 'PH Centro Comercial',
  '449887-29-298110': 'Power Electronics S.',
  '898-241-102416': 'PRICESMART',
  '1664284-1-678265': 'PRODUCTOS DE LIMP',
  '292069-01-408892': 'PRODUCTOS KEIKO',
  '407-850-1018': 'Publix',
  '30170-22-237573': 'Raenco Internacional',
  '2-748-2185': 'Raul Guerrero',
  '1767235-1-699413': 'Refri Store S.A',
  '655655-1-460399': 'Repuestos y Ac. de P',
  '8-779-820': 'Restaurant & H. C.',
  '10-701-723': 'Reutelia Gonzalez',
  '342-240-75526': 'RIBA SMITH',
  98372342: 'Risneidy Parra',
  'E-8-113250': 'Romina Forti',
  96615786: 'Ruben Labrador',
  '30773-0074-240340': 'Semfyl S.A',
  '73-561-21585': 'Servicios Electricos',
  '4-744-927': 'Servicios F&M',
  '155628373-2-2016': 'SERVIPLUS PANAMA',
  '1508-31-30247': 'SHALOM, S.A.',
  '1667679-1-678987': 'SIAI,S.A',
  'E-8-173913': 'Sofiglass & Asoc.',
  '723636-1-473681': 'Soluciones Inco. S.A',
  136110439: 'Stiven Lopez',
  '155711867-2-2021': 'SUMINISTRO  NF.S.A',
  '45008-12-298240': 'Super Cosechas S.A',
  '711822-1-471236': 'SUPER GAS',
  '986925-1-533389': 'Super Materiales',
  '155651447-2-2017': 'Super Outlet',
  '467-484-103645': 'SYSCO',
  '47100-0030-306009': 'Systems S.A.',
  '810-214-121046': 'Taller Elect. JM',
  '62137-36-351360': 'Target S.A.',
  '15563080-2-2017': 'Techomex S.A',
  '2593932-1-832766': 'The Circle Networkin',
  '1641619-1-673845': 'TJ SERVICES',
  '986925-1-533389': 'TKL Import Export SA',
  '155656002-2-2017': 'Tomates Rigo',
  '65219-68-360495': 'TOVA S.A',
  '10-32-390': 'Transporte Alfaro',
  '10-703-2192': 'Transporte de Carga',
  '594-120-104838': 'Tropigas de Chiriqui',
  '300303-1-410024': 'T-Shirt Max S.A',
  '674-77-109176': 'Ventas Y Mercadeo',
  '8-820-2309': 'Victor Tejada',
  '155668620-2-2018': 'VIDA Y SALUD MINIM',
  '155687615-2-2019': 'Vima Foods Panama',
  '407-354-5665': 'Walmart',
  129989639: 'Walter Diaz Espina',
  142654918: 'Wilfredo Millan',
  163278603: 'Yemilis Montiel',
  '10-716-1004': 'Yoriel Boyd',
  '963942-1-528502': 'Yoytec Computer S.A.',
  '233107-1-400353': 'ZHONGCRATES S.A',
};

export const pcaBlueAdventureProviders = {
  '310-2-66830': 'ZINC Y CARRIOLAS S.A',
  '155712674-2-2020': 'YM DESING',
  '356-19-77860': 'XTRA',
  '155668267-2-2018': 'WOS AND ACADEMY, INC',
  '8-742-593': 'VIANNEY BERNAL',
  '2-60-724': 'V Y S EXTINTORES D',
  '1091-157-111481': 'ULTRACOM',
  '8864-123-091468': 'T-SHIRTS INTERAMERIC',
  '155660657-2-2018': 'TRANSPORTE HUPERNIKA',
  '155627165-1-2016': 'TRANSPORTE EMANUEL',
  '2-143-777': 'TRANSERVI MELENDEZ',
  '2451764-1-812364': 'TRANS OCEAN',
  '8-846-1248': 'THE NORTHERN FARM',
  '2593932-1-832766': 'THE CIRCLE NET',
  '155677687-2-2019': 'TESA MOTORS CORP',
  '155677982-2-2019': 'TEAM AUTO MUNDO PTY,',
  '155629896-2-2016': 'SURTITROPIK, S.A.',
  '37215-2-266499': 'SUPER KOSHER S.A.',
  '45008-12-298240': 'SUPER COSECHAS S.A.',
  '1647259-1-674887': 'SUKIMOTOR',
  '155676007-2-2019': 'SQR LIVING, S.A.',
  '506286-1-436522': 'SPORTS FACTORY, S.A.',
  '551588-1-443488': 'SPIRITS WINE GROUP',
  '106634-1-379432': 'SMART MEDIA INC.',
  '31301-11-242424': 'SILABA MOTORS, S.A.',
  '2205299-1-773993': 'SHIANVANI GROUP',
  '73-561-21585': 'SERVICIOS ELECTRICOS',
  '2159458-1-766676': 'SERVICIOS AVANZADOS',
  '30773-0073-240340': 'SEMFYL, S.A.',
  '155675657-2-2019': 'RR INDUSTRIAL S.A.',
  '155702480-2-2021': 'RODRIGUEZ VEGA',
  '204-394-49730': 'RODELAG s.a.',
  '305-414-68335': 'RICARDO PEREZ',
  '342-240-75526': 'RIBA SMITH',
  '4-175-518': 'R. J. SERVICIOS',
  '155686590-2-2019': 'PROYECTO CACAO S.A.',
  '62070-3-351143': 'PROSERV, S.A.',
  '186-505-46878': 'PRODUCTOS SUPERIORES',
  '8-772-339': 'PRODUCTOS STANZIOLA',
  '898-241-102416': 'PRICESMART',
  '155694847-2-2020': 'PREMIUM SEAFOOD',
  '155601143-2-015': 'PMP',
  '23189-186-206461': 'PLYWOOD OROZCO, S.A.',
  '2421655-1-807928': 'PINEAPPLE TECHNOLOGI',
  '155697417-2-2020': 'PHMD, CORP.',
  '11524-2-115657': 'PETROLEOS DELTA S.A.',
  '960092-1-527757': 'PET PANAMA, S.A.',
  '2644852-1-839963': 'PEGGYS KITCHEN, S.A.',
  '6245503-1-455353': 'PEDERSEN',
  '934245-1-522338': 'PCA',
  '375742-1-420918': 'PANHELLENIC ENTERPRI',
  '431086-1-428837': 'PANAMA SPRINGS, S.A.',
  '565187-1-445548': 'PANAMA LININGS SA',
  '155607221-2-2015': 'PANAMA BREWERS',
  '67-37-20135': 'PANAMA AUTO, S.A.',
  '66159-91-36338': 'PANAFOTO, S.A.',
  '155623302-2-2016': 'PANABLU',
  '630-483-123250': 'ORLYN, S.A.',
  '656-413-132700': 'O D PANAMA, S.A.',
  '26856-002-225997': 'NIMAR',
  '2647005-1-840256': 'NEVA SOLUTION CORP.',
  '57983-2-340436': 'NATURGY',
  '98914-1-378379': 'MOVIALARM S.A.',
  '8-531-2291': 'MORENO S',
  '1841-85-35470': 'MODAS Y DEPORTES',
  '8-966-2158': 'MICHELL LIN',
  '1907616-1-723827': 'MI CASA CHIC, S.A.',
  '916-586-106273': 'METALES S.A.',
  '32427-32-247676': 'MELTON INTERNATIONAL',
  '352668-1-18458': 'MDU LEGAL',
  '1301206-1-605594': 'MASPOR MARINE PANAMA',
  '8-738-184': 'MARYAM ESTELA BAIZ P',
  '29672-116-237038': 'MANICO S A',
  '158080-1-387251': 'MANANTIAL DE VOLCAN',
  '2334656-1-2163': 'MAKITA PANAMA INC',
  '537091-1-441028': 'MAC STORE CORP',
  '553-231-100703': 'LUMICENTRO',
  '8-519-851': 'LUIS ESTEVEZ',
  '8-814-954': 'LIBIA XIE YU',
  '8-NT-2-75710': 'LA CASA DEL AGUACATE',
  '2689749-1-846544': 'LA CASA DE LAS FIEST',
  '155663931-2-2018': 'KOKORO',
  '2350884-1-797717': 'KITCHEN TOTAL',
  '155702079-2-2021': 'KING HASS CORPORATIO',
  '155634609-2-2016': 'KELAPA, S.A.',
  '2626140-1-837377': 'JQ SECURITY SERVICES',
  'E-8-159489': 'JOSE TOMAS IRATO ZEA',
  '8-945-1461': 'JONATHAN THOMAS',
  '155647945-2-2017': 'JJ CENTRAL AMERICAN',
  'N-20-36': 'JIGGING MASTER P',
  '52822-40-325944': 'JARDINES DEL BONSAI',
  '1184-537-111303': 'ISLAMORADA INT',
  '2417478-1-807412': 'INVERSIONES PAURVICA',
  '148876-1-402837': 'INVERSIONES AGRO S.A',
  '2323803-1-793392': 'INNOVACION FORESTAL',
  '32812-1-249262': 'IMPORTADORA VIRZI',
  '434-15-93796': 'IMPORTADORA RICAMAR',
  '558-472-101708': 'IMPORTADORA MADURO,',
  '510-302-109858': 'IMPLOSA',
  '923868-1-520023': 'IMPLEMENTOS Y HERRAM',
  '25604-0033-219435': 'IM. EXPORT AMERICAN',
  '1098-112-114847': 'I.A.R.S.A.',
  '155626689-2-2016': 'HPR PANAMA INT',
  '2069791-1-751271': 'HOPSA',
  '155648980-2-2017': 'HOME COLLECTION PANA',
  '659-2-14637': 'HIELO AMEGLIO, S.A.',
  '5-714-284': 'HIBERTO PEREZ',
  '446-121-95840': 'HERRERIA SANTIAGO',
  '15599714-2-2015': 'HAKOL GROUP, S.A.',
  '533-555-116945': 'H. TZANETATOS, INC.',
  '155645072-2-2017': 'GRUPO SOMA NATURAL',
  '155618556-2-2015': 'GRUPO ANCOAL INC',
  '1961913-1-733811': 'GLOBAL MARINE',
  '48870-0093-312745': 'GLOBAL BRANDS',
  '138-289-36920': 'GEO F NOVEY, INC',
  '66104-114-363217': 'GALERIA PORTO FINO S',
  '26378-48-223629': 'GABYSOL S.A.',
  '59712-37-345054': 'FUERTE AMADOR RESORT',
  '2164777-1-767612': 'FUEL INJECTION S.A.',
  '18423-61-171041': 'FRAN GALY S.A.',
  '43408-2-291519': 'FORMETAL, S.A.',
  '7875-49-84465': 'FLORENCIA INTERNCOME',
  '2370232-1-800572': 'FERRETERIA EL ALBAÑI',
  '441-74-94389': 'FELIPE MOTTA, S.A.',
  '346-266-76470': 'FARMACIAS ARROCHA',
  '8-828-825': 'EXTINTORES DELGADO',
  '155602006-2-2015': 'ESTACIONES AMIGAS',
  '21986-197-197153': 'EQUIPSA',
  '155674986-2-2019': 'ENTERPRISE SA',
  '1591993-1-664692': 'ENMARCADOS, S.A.',
  '650-529-126088': 'EMPRESAS MELO, S.A.',
  '1080323-1-554308': 'EMPRESAS CARBONE S.A',
  '155655307-2-2017': 'EMERGENCIA MEDICA ZE',
  'PE-11-1645': 'ELECTRONICA PAN',
  '12150-178-121154': 'ELECTRISA',
  '155654634-2-2017': 'EL PERICO MAÑOSO',
  '670-267-134620': 'EL CISNE, S.A.',
  '33182-56-250806': 'DURALLANTAS, S.A.',
  '311494-1-411579': 'DORIDIS, S.A.',
  '30815-22-240563': 'DON ANTONIO',
  '2110-223-38505': 'DOIT CENTER',
  '2095126-1-755847': 'DISTRIBUIDORA OMLIN',
  '865-524-103137': 'DISTRIBUIDORA MUSICA',
  '27621-11-228669': 'DISTRIBUIDORA MARINE',
  '18993-78-174370': 'DISTRIBUIDORA G DE P',
  '310-2-66830': 'DISTR MUSICAL',
  '2095126-1-755847': 'DISTOMSA',
  '311-77-66961': 'DIST. COMERCIAL GROU',
  '105765-1-379278': 'DELI GOURMET S A',
  '8-843-1066': 'CW SOLUCIONES',
  '46004-187-302083': 'CW PANAMA',
  '1255-519-126037': 'CTYT, S.A.',
  '560-15-117738': 'CREDITOS Y SERVICIOS',
  '8-728-840': 'CONTROL TECK',
  '2025521-1-744067': 'CONCRETOS DEL SOL',
  '896-366-105129': 'COMPAÑIA SAR SA',
  '1269688-1-597454': 'COINSA',
  '155670207-2-2018': 'CLICKBOX',
  '555-328-101285': 'CERVECERIA PANAMA SA',
  '155693905-2-2020': 'CERVECERIA KRAKEN SA',
  '155661188-2-2018': 'CERVECERIA EL ALERO',
  '155604012-2-2015': 'CERVECERIA CLANDESTI',
  '155589633-2-2014': 'CERVECERIA CENTRAL',
  '2675-58-42880': 'CENTRO MARINO',
  '1255-519-126037': 'CENTRAL DE TUERCAS',
  '155611343-2-2015': 'CENTENARIO BREWCO',
  '35226-2-259295': 'CASA DE LAS BATERIAS',
  '2386225-1-802888': 'CASA BRUJA, S.A.',
  '386304-1-422375': 'CARIBBEAN INTERNATIO',
  '155626235-2-2016': 'CARDENAS RIOS S.A.',
  '1482157-1-643857': 'CAMSAL, CORP.',
  '46004-187-302083': 'C&W',
  '155673105-2-2018': 'BSC CORP, S.A.',
  '1693988-1-685146': 'BOSTON CORP S.A',
  '207-163-49793': 'BOLSAS Y CARTUCHOS',
  '757435-1-481686': 'BOCASAS SA',
  '2078345-1-752815': 'BLUE CANAL INVESTMEN',
  '155665530-2-2018': 'BLACK MARKET PTY',
  '1140106-1-569382': 'BINAT INTERINVEST',
  '1276205-1-599327': 'BAYSHORE TECHNOLOGIE',
  '262-395-6572': 'AUTO IMPORT S.A.',
  '563341-1-445318': 'ASEO CAPITAL S.A.',
  '2646231-1-840151': 'ART STORE S.A.',
  '8-756-1426': 'ANGEL ISAAC PEÑA',
  '155611514-2-2015': 'AMALIAS BAKERY',
  '7099-11-78962': 'ALMACENAJES, S.A.',
  '1773858-1-700518': 'AGROPESCA',
  '355245-1-418116': 'AGRO LOS PUEBLOS',
  '277-83-60760': 'AGENCIAS FEDURO, S.A',
  '310-2-66830': 'ABERNATHY S.A',
};

export const pcaGlobalLoungeProviders = {
  '694326-1-467231': 'AGC',
  '15430-249-14871': 'AIH',
  '456104-1-432290': 'AITSA',
  '155626941-2-2016': 'ALI',
  '2053307-1-748373': 'AR',
  '155594217-2-2015': 'BPC',
  '396054-1-423662': 'CA',
  '521-136-113133': 'CC',
  '1859460-1-33213': 'CCA',
  '20362-172-185459': 'CE',
  '20821-1-366835': 'CEI',
  '155685133-2-2019': 'CET',
  '652-212-129962 ': 'CG',
  '30394-2-238626': 'CO',
  '126988-1-597454': 'COINSA',
  '1740287-1-694190': 'Crowne Plaza',
  '560-15-117738': 'CYS',
  '311-77-66961': 'DCG',
  '985987-1-533212': 'DG',
  '5458-162-67519': 'DHL',
  '1682251-1-682541': 'DIPSA',
  '2562458-1-828257': 'Display Center',
  '808-349-126446': 'DM',
  '1682251-1-682541': 'DP',
  '346-266-76470': 'FA',
  '441-74-94389': 'FM',
  '2151297-1-765347': 'FVL',
  '7-703-1638': 'FYV',
  '138-289-35920': 'GF',
  '155647082-2-2017': 'GLN',
  '155599780-2-2015': 'GPE',
  '155642043-2-2016': 'GR',
  '592722-1-450338': 'H&CO',
  '9077-254-93387': 'HI',
  '48735-98-312071': 'HLL',
  '30815-22-240563': 'IDA',
  '36470-37-263562': 'IG',
  '558-472-101708': 'IM',
  '592944-1-20432': 'Infante & Perez',
  '1075684-1-553258': 'Inversiones Vancouve',
  '155608067-2-2015': 'IP',
  '561493-1-445109': 'IPP',
  '434-15-93796 ': 'IR',
  '155612725-2-2015': 'JM',
  '8-780-714': 'JR',
  '5837-93-70420': 'KA',
  '743-444-139841': 'La Imp. Selecta S.A.',
  '1752209-1-696588': 'LFS',
  '155670920-2-2018': 'LKG',
  '16831-38-159113': 'MDP',
  '1090572-1-556984': 'MHP',
  '51279-9-320796': 'MI',
  '36765-2-264774': 'MP',
  '1276522-1-599396': 'NS',
  '656-413-132700 ': 'OD',
  '2569763-1-829282': 'OPG',
  '630-483-123250': 'OSA',
  '69-275-1951': 'PAP',
  '934245-1-522338': 'PCA',
  '11524-2-115657': 'PD',
  '155631762-2-2016': 'PER',
  '66159-91-363381': 'PF',
  '898-241-102416 ': 'PP',
  '62070-3-351143': 'PR',
  '342-240-75526': 'RB',
  '8-309-916': 'RDL',
  '1695496-1-685399': 'RED',
  '204-394-49730': 'RSA',
  '2218148-1-776013': 'SEN',
  '1508-31-30247': 'SH',
  '2449856-1-812090': 'Sima 96',
  '1647259-1-674887': 'Sukimotors S.A.',
  '895-64-104231': 'Sura',
  '551588-1-443488': 'SWG',
  '356-19-77860': 'SX',
  '155615059-2-2015': 'SYY',
  '6506-102-74921': 'Telecom',
  '65219-68-360495': 'TO',
  '418958-1-427069': 'VGT',
  '38682-54-272473': 'VSA',
  '1460454-1-639798': 'VTS',
  '62758-19-353075': 'WSC',
  'N-20-336': 'YFC',
};

export const grProviders = {
  '8-NT-1-22244': 'REGISTRO',
  '1903738-1-723197': 'ENA',
  '510-302-109858': 'IMPLOSA C',
  '521-136-113133': 'COCHEZ',
  '825-543-101039': 'PPG',
  '1042805-1-545537': 'FORTUNE',
  '515-111-12086': 'COPA AIRLI',
  '8-NT-1-12701': 'MUNICIPIO',
  '2635032-1-838688': 'PREMIUMPR0',
  '54435-0024-330883': 'PAZKO',
  '715646-1-471818': 'TUBOTEC',
  '66159-91-363381': 'PANAFOTO',
  '11524-0002-115657': 'PETROLEOS',
  '898-241-102416': 'PRICE SMAR',
  '30815-22-240563': 'DON ANTONI',
  '541-81-118009': 'DON ANTONI',
  '155623139-2-2016': 'ONTARIO',
  '8-851-123': 'DIST UNICO',
  '18219-85-169844': 'AGUAS',
  '2464220-1-813984': 'DOIT',
  '574-14-102616': 'MOTTA',
  '8-NT-2-17296': 'ANATI',
  '2346119-1-797053': 'NATUPROS',
  '346-266-76470': 'F.ARROCHA',
  '8-NT-1-10284': 'IDAAN',
  '8-NT-217099': 'A. ASEO',
  '772-441-117276': 'FRIOLIN',
  '8815-206-90886': 'ARTES TEC',
  '656-413-132700': 'OFFICE DEP',
  '963942-1-528502': 'YOYTEC',
  '382638-1-421897': 'CASIOLANDI',
  '155674179-2-2018': 'COMICS',
  '155624207-2-2016': 'CEMENTO CH',
  '1368933-1-621296': 'MACCAFERRI',
  '12150-178-121154': 'ELECTRISA',
  '3724-103-53659': 'ASSA',
  '1091-157-111481': 'ULTRAMAR',
  '2329816-1-794330': 'DELICE',
  '937-138-19820': 'C  INDUST',
  '434-15-93796': 'SUPER 99',
  '342-240-75526': 'RIBASMITH',
  '537091-1-441028': 'MAC STORE',
  '518-407-112698': 'FUNDICION',
  '155707309-2-2021': 'CHINA DEPO',
  '305-414-68335': 'R PEREZ',
  '65219-68-360495': 'STEVENS',
  '441-74-94389': 'FMOTTA',
  '1711322-1-688493': 'ALTA PESCA',
  '541848-1-441803': 'ALISS',
  '203-438-49939': 'LA BOTTEGA',
  '52887-52-326145': 'QUICKSERVI',
  '138-289-35920': 'GEO',
  '558-472-101708': 'FELIX',
  '155725856-2-2022': 'DIST 22',
  '18993-78-174370': 'G DE P',
  '2673158-1-844013': 'DIST  JAPS',
  'E-8-93190': 'DIST.MACK',
  '155733332-2-2023': 'DIST.MULTI',
  '155637272-2-2016': 'DIST.ODEL',
  '1644283-1-674355': 'DIST.ROMAN',
  '155681762-2-2019': 'DON BRISAS',
  '1278199-1-599850': 'DRINKING',
  '553-231-100703': 'LUMICENTRO',
  '155594211-2-2015': 'LA CASA',
  '1603854-1-666821': 'LA CUISINE',
  '253525-1-403482': 'ECONOPRECI',
};

export const nsolarProviders = {
  '63856-93-356639': '4 COLOR',
  '1707909-1-687925': '4 SUPPLIERS-02',
  '2-50-737': 'ABARROTERIA-01',
  '8-395-965': 'ABDEL-01',
  '8-264-566': 'ABRAHAM-01',
  '8-724-2130': 'ACARREOS-01',
  '1285186-1-601563': 'ACCES-01',
  '15430-249-148718': 'ACE-01',
  '126215-1-382126': 'ACERO-01',
  '1939377-1-729621': 'ACTWELL-01',
  '420074-1-427216': 'ADMINISTRADORA-01',
  '8-1003-2382': 'ADOLFO-01',
  '8-865-2441': 'ADONIS-01',
  '8-922-1375': 'Adri-02',
  '9-714-2400': 'ADRIANA-01',
  '2602313-1-834060': 'ADVISORY-01',
  '8-817-35': 'Adyr-01',
  '1142958-1-569990': 'AERONAUTICA-01',
  '1739598-1-694071': 'AEROPOST-01',
  '456104-1-432290': 'AEROPUERTO-01',
  '2027408-1-744322': 'AEROPUERTO-02',
  '33189-124-250842': 'AGASAJOS-01',
  '63730-9-355878': 'AGENCIAS-01',
  '60992-1-372519': 'AGENTE-01',
  '25032161-3-2016': 'AGRANDEL-01',
  '1621672-1-670343': 'AGRO-01',
  '155720166-2-2022': 'AGUAS-01',
  '8-514-708': 'AGUSTIN-01',
  '8-945-1210': 'AGUSTIN-02',
  '1-27-1684': 'AIR MARGARITA-01',
  '549-288-119651': 'AIRCO-01',
  '19905-47-181183': 'AIRE-01',
  1202011381385: 'AIRES-01',
  '4852-8-062703': 'AIRPANAMA-01',
  '155702517-2-2021': 'AIRPORT-01',
  '155679572-2-2019': 'AJISEN-01',
  '4-203-153': 'ALAIN-01',
  '186635-1-716337': 'ALBANIA-01',
  '3-58-402': 'ALBERTO-01',
  '8-321-367': 'ALBERTO-02',
  '8-234-600': 'ALBERTO-03',
  '8-942-588': 'ALBERTO-04',
  '8-813-1120': 'ALBERTO-05',
  '8-468-357': 'ALCIDES-01',
  '4-776-742': 'ALCIDES-02',
  '8-985-1209': 'ALEJANDRA-01',
  '8-396-749': 'ALEJANDRO-01',
  87103467: 'ALEJANDRO-02',
  '8-808-2495': 'ALEJANDRO-03',
  '8-904-2272': 'ALEJANDRO-04',
  '8-278-1030': 'ALEJANDRO-05',
  '8-1008-258': 'ALEJANDRO-06',
  '4-740-474': 'ALEX-01',
  '4-729-176': 'ALEXIS-01',
  '6-717-723': 'ALEYSMARIE-01',
  '2603351-1-834217': 'ALFA-01',
  '4-772-794': 'ALFREDO-01',
  '8-780-1058': 'ALFREDO-02',
  '2584933-1-831541': 'ALFRUB-01',
  '566674-1-445716': 'ALIADO-01',
  '155627992-2-2016': 'ALIMENTOS-01',
  '44417-112-295616': 'ALISCO-01',
  '155682722-2-2019': 'ALISERFRI-01',
  '27687-190-228819': 'ALISERVI-01',
  'N-19-72': 'ALMACEN-01',
  '8-766-2206': 'ALMACEN-02',
  '2619511-1-836509': 'ALMACEN-03',
  '8-892-2459': 'ALMACEN-04',
  '8-777-2478': 'ALMACEN-05',
  '8-887-1814': 'ALMACEN-06',
  '8-526-1801': 'ALQUILERES-01',
  '438914-1-429890': 'ALQUILERES-02',
  '155630325-2-2016': 'ALQUILO-01',
  '155618022-2-2015': 'ALTA-01',
  '4-206-330': 'ALVARO-01',
  '2100737-1-35893': 'AMC & ASSOCIATES',
  '2100737-1-35893': 'AMC CONSULTORES-01',
  '8-762-1361': 'AMET-01',
  AR961611: 'AMPARO-01',
  '8-434-699': 'ANA CECILIA-01',
  '8-888-418': 'ANA CRISTINA-01',
  '8-905-1492': 'ANA GABRIELA-01',
  '8-722-872': 'ANA MERCEDES-01',
  '8-968-150': 'ANA-01',
  '56566-10-336490': 'ANCON-01',
  '8-906-2270': 'Andres-01',
  '4-791-1568': 'ANDY-01',
  '8-914-1277': 'ANGEL-01',
  '8-834-1769': 'ANTONIO-01',
  '8-455-299': 'ANTONIO-02',
  'N-21-1454': 'ANTONIO-03',
  '8-817-2038': 'ANUBIS-01',
  '474-57-1541': 'APEDE-01',
  '1710117-1-688248': 'APO-01',
  '500844-1-435796': 'ARCOSOL-01',
  '7-84-607': 'ARGELIS-01',
  '47311-38-306650': 'ARGUDO-01',
  'E-8-216349': 'ARIANA-01',
  '8-944-2308': 'ARIEL-01',
  '43357-101-291326': 'ARKANSAS-01',
  123009584: 'ARMANDO-01',
  '3733-20-13599': 'AROSEMENA-01',
  '429283-1-428561': 'ARQUI-01',
  '1144-157-124040': 'ARRENDADORA-01',
  '396257-1-423688': 'ART-01',
  '8815-206-90886': 'ARTES-01',
  '63886-66-356319': 'ARTES-02',
  '155585363-2-2014': 'ARTISANA-01',
  '8-876-631': 'ARTURO-01',
  '8-840-1184': 'ARTURO-02',
  '8-861-1351': 'ARTURO-03',
  '155729345-2-2022': 'AS BUILT-01',
  '8-811-2444': 'ASADOS-01',
  '8-NT-2-5000690': 'ASAMBLEA DE PROP.-01',
  '8-NT-2-28137': 'ASAMBLEA DE PROP.-02',
  '1133986-1-567901': 'ASAP-01',
  '1109128-1-561639': 'ASI VIAJO-01',
  '2449-8-009233': 'ASOCIACION-01',
  '8-NT-2-28137': 'ASOCIACION-02',
  '3724-103-53659': 'ASSA-01',
  '2480734-1-3185': 'ASSETS-01',
  '756-374-135990': 'ASSICURAZIONI-01',
  '756-374-135990': 'ASSICURAZIONI-02',
  '2144353-1-764308': 'ATHANASIOU-01',
  '155647659-2-2017': 'ATHENS-01',
  '48740-140-312112': 'ATHENS-02',
  '807631-1-496948': 'ATL-01',
  '8-237-2621': 'AURORA-01',
  '155711667-2-2021': 'AUSTIN-01',
  '8-909-1272': 'AUTO REPUESTOS',
  '36945-17-265492': 'AUTO-01',
  '904964-1-515656': 'AUTO-02',
  '15736-109-150985': 'AUTO-03',
  '603-203-124985': 'AUTO-04',
  '2428-157-41704': 'AUTOESCUELA-01',
  '2532397-1-823616': 'AUTOMOTRIZ-01',
  'N-19-1614': 'AUTOREPUESTO-01',
  '8-NT-2-6064': 'AUTORIDAD-01',
  '8-NT-1-13577': 'AUTORIDAD-02',
  '8-NT-2-17296': 'AUTORIDAD-03',
  '8-NT-1-13178': 'AUTORIDAD-04',
  '8-NT-1-12507': 'AUTORIDAD-3',
  '2102-230-38370': 'B B CAPACITACION-01',
  '256437-1-403921': 'B.T.U.-01',
  '51013-40-319894': 'BAHIA-01',
  '53887-45-329374': 'BALBOA-01',
  '1936398-1-1729073': 'BALERKEL-01',
  '1280564-1-600446': 'BANCO-01',
  '280-134-61098': 'Banco-02',
  '35090-124-258812': 'BANCO-03',
  '8-NT-1-12541': 'BANCO-04',
  '37405-45-267330': 'BANK-0',
  '37405-45-267330': 'BANK-01',
  '5071-214-6447': 'BANVIVIENDA-01',
  'N-20-1229': 'BAR RESTAURANTE-01',
  '4-789-553': 'BASILIO-01',
  '8-NT-2-26371': 'BAVARIAN-01',
  '61510-23-349564': 'BAVARIAN-02',
  '155715956-2-2021': 'BAY MOTORS - 01',
  '155704724-2-2021': 'BBQ-01',
  '155649030-2-2017': 'BEERMARKT-01',
  '8-NT-1-12761': 'BENEMERITO-01',
  '8-460-784': 'BERTA-01',
  '8-873-2400': 'BETTER-01',
  '4-747-685': 'BIENMESABE-01',
  '155668654-2-2018': 'BIG LOBSTER-01',
  '515702-1-437873': 'BIGCOMERCIO-01',
  'N-21-608': 'BITPHONE-01',
  '1880043-1-718916': 'BLUE POWER-01',
  '155665721-2-2018': 'BLUE-01',
  '155665721-2-2018': 'BLUE-02',
  '8-NT-2-26371': 'BMW-01',
  '155646789-2-2017': 'BOCAS-01',
  '4-812-2229': 'BODAPHONE-01',
  '2534800-1-823959': 'BOGA-01',
  '4-217-114': 'BOKDO`S-01',
  '155601395-2-2015': 'BOTANICA-01',
  '270-35-61112': 'BOTICA-01',
  '2542677-1-825192': 'BOUZUIÑAS-01',
  '91037-1-377228': 'BRAIN-01',
  '155679446-2-2019': 'BROKER-01',
  '1144-157-124040': 'BUDGET-01',
  '2115031-1-759186': 'BUFFALO-01',
  '571483-1-446710': 'BUSINESS-01',
  '24687-150-214130': 'BUSINESS-02',
  '155704355-2-2021': 'CABAÑA-01',
  '30394-2-238626': 'CABLE-01',
  '46004-0187-302083': 'CABLE-02',
  '155602891-2-2015': 'CABLEADOS-01',
  '155685697-2-2019': 'CABSHA',
  '147-569-40208': 'CAFE-01',
  '66113-2-363241': 'CAFE-02',
  '155653873-2-2017': 'CAFE-03',
  '2212544-1-775200': 'CAFE-04',
  '9-743-2050': 'CAFE-05',
  '3-116-362': 'CAFE-06',
  '8-NT-2-4249': 'CAJA-01',
  '8-NT-1-10200': 'CAJA-02',
  '87-524-6185': 'CAMARA-01',
  '25031656-3-2016': 'CAMARA-02',
  '55370-20-333366': 'CAMARON-01',
  '8-710-1804': 'CAMILO-01',
  '1482157-1-643857': 'CAMSAL-01',
  '1749618-1-696097': 'CAMSCO-01',
  '2553037-1-826571': 'CANAL-01',
  '601815-1-451839': 'CANELA-01',
  '601815-1-451839': 'CANNELA-01',
  '655-128-131705': 'CANON-01',
  '4-152-452': 'CAR WASH-01',
  '8-NT-2-48310': 'CAR-01',
  '1967436-1-734718': 'CAR-02',
  '8-805-1687': 'CAR-03',
  '155647857-2-2017': 'CAR-04',
  '1080323-1-554308': 'CARBONE-01',
  '122-395-32518': 'CARDOZE-01',
  '125570-1-382067': 'CARIBBEAN-01',
  '4-120-214': 'CARLOS-01',
  1713927802: 'CARLOS-02',
  '3-704-924': 'CARLOS-03',
  '8-841-2065': 'CARLOS-04',
  '4-116-940': 'CARLOS-05',
  '4-233-2400': 'CARLOS-07',
  '8-798-2154': 'CARLOS-08',
  '5-700-1384': 'CARLOS-09',
  '8-961-1449': 'CAROLINA-01',
  '1371719-1-621793': 'CARSI-01',
  '20823-1-366836': 'CARSI-02',
  '2157560-1-766381': 'CASA MADRE-01',
  '41390-2-283532': 'CASA-01',
  '9-743-1516': 'CASA-02',
  '516-462-113464': 'CASA-03',
  '155697784-2-2020': 'CASA-04',
  '1916325-1-725360': 'CASCO-01',
  '638045-1-457554': 'CAT-01',
  '8-807-276': 'CELL-01',
  '43085-133-290460': 'CEMENTO-01',
  '470-3-11361': 'CENTRAL-01',
  '155683831-2-2019': 'CENTRAL-02',
  '2-725-884': 'CENTRAL-03',
  '36986-150-265674': 'CENTRAL-04',
  '937-138-19820': 'CENTRO-01',
  '1757534-1-697641': 'CENTRO-02',
  '1651373-1-675681': 'CENTRO-03',
  '2047804-1-747499': 'CENTRO-04',
  '8-844-867': 'CENTRO-05',
  '1476578-1-643001': 'CEPA-01',
  '9944-73-101955': 'CEREBRO-01',
  '155673544-2-2018': 'CERO',
  '11422-77-114867': 'CERRO-01',
  '1734921-1-693209': 'CERVECERIA-01',
  '155599753-2-2015': 'CERVEZAS-01',
  '1725567-1-691286': 'CESAR-01',
  '8-287-903': 'CESAR-02',
  '6-41-842': 'CESAR-03',
  '8-726-1475': 'CET-01',
  '28878-23-233240': 'CHAITEN-01',
  '1710117-1-688248': 'CHELA-01',
  '263820-1-404976': 'CHENPO-01',
  '1025497-1-541493': 'CHICKEN-01',
  '155714954-2-2021': 'CHICKEN-02',
  '155624937-2-2016': 'CHIEF-01',
  '155703842-2-2021': 'CHILLIN PANAMA',
  '8-948-1048': 'CHRISMAR-01',
  '8-948-1048': 'CHRISMAR-017',
  '8-418-888 ': 'CHRISTIAN-01',
  '2-221-78': 'CIA-01',
  '652-212-129962': 'CIA-02',
  '41050-93-282064': 'CIF-01',
  '1946589-1-731020': 'CIMENTATEC-02',
  '4-261-811': 'CIPRIANO-01',
  '31107-1-368192': 'CITY-01',
  '934082-1-522297': 'CITY-02',
  '155705848-2-2021': 'CIUDAD-01',
  '155641924-2-2016': 'CL INVESTMET-01',
  '155716199-2-2021': 'CLAYTON-01',
  '155712127-2-2021': 'CLICK-01',
  '4-138-2060': 'CLINICA-01',
  '150-291-38284': 'CLINICA-02',
  '47422-19-307091': 'CLINICA-03',
  '20452-22-185199': 'CLINICA-04',
  '8-838-522': 'CLM',
  '292-101-10236': 'CLUB-01',
  '69-210-21057': 'CLUB-02',
  '134-587-36316': 'CLUB-03',
  '521-136-113133': 'COCHEZ-01',
  '18317-11-170443': 'COLE-01',
  '1123394-1-564974': 'COLEGIO-01',
  '2179477-1-770107': 'COMBUSTIBLES-01',
  '155664121-2-2018': 'COMBUSTIBLES-02',
  '85459-1-376235': 'COMEDSA-01',
  '133262-1-383127': 'COMERCIALIZADORA-01',
  '9781-35-100208': 'COMERINSA-01',
  '155627282-2-2016': 'COMICOMP-01',
  '433157-1-429089': 'COMIDAS RAPIDAS-01',
  '944536-1-524476': 'COMPAÑIA-01',
  '652-212-12992': 'COMPAÑIA-02',
  '130-377-34706': 'COMPAÑIA-03',
  '956082-1-526870': 'COMPAÑIA-04',
  '185-310-46305': 'COMPAÑIA-05',
  '1739598-1-694071': 'COMPRAS-01',
  '63429-59-354966': 'COMPUTER-01',
  '9-731-1035': 'COMPUTER-02',
  '661-1-363838': 'COMUNICACIONES-01',
  '1743053-1-694754': 'CONALCA-01',
  '284560-1-407807': 'CONCESIONARIOS-01',
  '2078063-1-752745': 'CONCRETEX-01',
  '155669641-2-2018': 'CONSORCIO -01',
  '1099-185-120196': 'CONSULTENOS-01',
  '155637705-2-2016': 'CONSULTORES-01',
  '2100737-1-35893': 'CONSULTORES-02',
  '155627463-2-2016': 'CONSUMER-01',
  '62137-36-351360': 'CONWAY-01',
  '2-48-212': 'COOLECHE-01',
  '515-111-12086': 'COPA-A1',
  '8-849-1793': 'COPI-01',
  '610-179-120870': 'COPI-02',
  '19466-97-177556': 'COPIAS-01',
  '4-718-197': 'COPIAS-02',
  '4-735-358': 'COPY-01',
  '4-748-1664': 'COPY-02',
  '1444006-1-636360': 'CORPORACION-01',
  '2195338-1-772453': 'CORPORACION-02',
  '2168720-1-768282': 'CORPORACION-03',
  '2978-2-046909': 'CORPORACION-04',
  '155715736-2-2021': 'CORPORACION-05',
  '2298365-1-789465': 'CORPORACION-06',
  '741609-1-477946': 'CORPORACION-07',
  '19886-183-181046': 'CORPORACION-08',
  '155628108-2-2016': 'CORTES-01',
  '2628455-1-837648': 'COSTA-01',
  '190229-1-393739': 'COSTA-02',
  '950723-1-525827': 'COZI-01',
  '89025-1-376845': 'CREACIONES-01',
  '47814-83-308700': 'CREPES-01',
  '8-753-24': 'CRISTIAN-01',
  '8-986-2470': 'CRISTIAN-03',
  '155677606-02-2019': 'CTC LOGISTICS-01',
  '2416994-1-807360': 'CUBITA-01',
  '1240012-1-590923': 'CUPASA',
  '1980778-1-736995': 'DACHES',
  '10904-263-110825': 'DAISOL-01',
  '8-412-131': 'DALTON-01',
  '155691947-2-2020': 'DALUNAY,S.A.',
  '4-768-2062': 'DALVIZ-01',
  '8-349-802': 'DAMARIS-01',
  '8-1017-1342': 'DANIELA -01',
  '8-847-860': 'DANTE-01',
  '694246-1-467218': 'DARKINS-01',
  '8-888-2327': 'DARLYN-01',
  '1727918-1-691745': 'DATA-01',
  '9-750-811': 'DAVID-02',
  '8-484-584': 'DAVID1',
  '8-913-755': 'DAYEDIS-01',
  '8-734-1989': 'DAYRA-01',
  '1470-32-005877': 'DE OBALDIA.01',
  '25039053-3-2019': 'DELCO-01',
  '2311752-1-791554': 'DELI-01',
  '105765-1-379278': 'DELI-02',
  '155626978-2-2016': 'DELI-03',
  '9-211-795': 'DELI-04',
  '2430150-1-809102': 'DELICIAS-01',
  '649-503-117585': 'DELIRYS-01',
  'PE-10-1067': 'DELTA-01',
  '1798864-1-705036': 'DEMETRIO-01',
  '273003-1-406331': 'DESARROLLO-01',
  '8-494-926': 'DESIREE-01',
  '155660123-2-2018': 'DEV NINJA-01',
  '2440820-1-810814': 'DEVENTER-01',
  '8-NT-1-12571': 'DGI-01',
  '5458-162-67519': 'DHL-01',
  '8-927-886': 'DIEGO-01',
  '708-53-120389': 'DIMAR-01',
  '8-352-109': 'DIMAS-01',
  '8-342-756': 'DIONICIO-01',
  '10-706-2437': 'DIONISIO-01',
  '1719593-1-690057': 'DIPSA-01',
  '8-NT-1-12571': 'DIRECCION-02',
  '30773-73-240340': 'DISCOVERY-01',
  '64102-0040-356855': 'DISNAEMPA-01',
  '637448-1-457409': 'DISTRIBUIDORA-01',
  '1096-99-113799': 'DISTRIBUIDORA-02',
  '155685254-2-2019': 'DISTRIBUIDORA-03',
  '8-783-1818': 'DISTRIBUIDORA-04',
  '1542917-1-655996': 'DISTRIBUIDORES-01',
  '155688174-2-2019': 'DITEINCO',
  '155741884-2-2023': 'DLC-01',
  '15430-249-148718': 'DOIT-01',
  '8-915-775': 'DOMINGO-01',
  '1675920-1-680848': 'DOMINOS-01',
  '1709328-1-688124': 'DON WEST-01',
  '155645136-2-2017': 'DON-01',
  '43231-21-290939': 'DOPORTO-01',
  '8-973-740': 'DORA-01',
  '2368411-1-800366': 'DOS CUCHARAS-01',
  '8-775-573': 'DR LLANTAS-01',
  '155673844-2-2018': 'DRAKE-01',
  '26209-61-222826': 'DULCERIA-01',
  '808-349-126446': 'DULCERIA-02',
  '6-55-288': 'DUMAS-01',
  '724711-1-473918': 'DUNAMIS-01',
  '33182-56-250806': 'DURALLANTAS-01',
  '961-479-6025': 'DURLING-01',
  '22356-138-200249': 'DURMAN-01',
  '54148-55-330185': 'DUTY-02',
  '909-459-103719': 'E PORTS -01',
  '545679-1-442500': 'EBD-01',
  '8-768-1311': 'ECO-01',
  '65565-8-361601': 'ECONOUTILES-01',
  '8-440-549': 'EDGAR-01',
  '8-874-2461': 'EDGAR-02',
  '8-818-1317': 'EDGAR-03',
  '8-NT-2-20289': 'EDIFICIO-01',
  '8-903-415': 'EDUARDO-01',
  '6-65-423': 'EDUARDO-02',
  '8-223-442': 'EDUARDO-03',
  '8-838-522': 'EDWARD-01',
  '373639-1-420688': 'EIGER-01',
  '7-85-981': 'EL CHICHEMITO-01',
  '186-505-46878': 'EL LLANTERO-01',
  '572-395-119290': 'EL MACHETAZO-01',
  '8-762-822': 'EL RANCHO-01',
  '8-762-822': 'EL RANCHO-02',
  '8-866-2500': 'EL TAPICERO',
  '155693874-2-2010': 'EL TRAPICHE-01',
  '8-732-1685': 'ELECTRI-03',
  '804254-1-495966': 'ELECTRIC-02',
  '28717-2-220038': 'ELECTRICIDAD-01',
  '155604405-2-2015': 'ELECTRICO-01',
  '12150-178-121154': 'ELECTRISA-01',
  '1541-377-30966': 'ELECTRO-01',
  '124367-1-381937': 'ELECTROINDUSTRIAL-01',
  '8-806-275': 'ELECTRONIC-02',
  'PE-14-1559': 'ELECTRONICA-01',
  '155698681-2-2020': 'ELECTRONICA-02',
  '57983-56-340439': 'ELEKTRA',
  '2885-2-45956': 'ELEMENTOS-01',
  '4-702-93': 'ELIECER-01',
  '8-746-351': 'ELIECER-02',
  '8-362-412': 'ELIX-01',
  '8-861-371': 'ELIX-02',
  '8-507-516': 'ELMIR-01',
  '155730414-2-2022': 'ELOOP-01',
  '8-420-320': 'ELVIS-01',
  '2081474-1-753313': 'ELYON-01',
  '8-235-1311': 'EMANUEL-01',
  '155737683-2-2023': 'EMBASSY-01',
  '9-728-822': 'EMBOBINADOS-01',
  '4-794-1144': 'EMIL-01',
  '8-987-1027': 'EMMANUEL-01',
  '155588866-2-2014': 'EMPRESA-01',
  '1080323-1-554308': 'EMPRESAS CARBONE-01',
  '650-529-126088': 'EMPRESAS-01',
  '48877-27-312765': 'EMPRESAS-02',
  '155734752-2-2023': 'EMPRESAS-03',
  '45408-55-299957': 'ENA-01',
  '2-725-884': 'ENCENDIDO-01',
  '874111-1-509746': 'ENERGIA-01',
  '678263-1-464003': 'ENERGY-01',
  '1100276-1-559554': 'ENESA-01',
  '7-106-473': 'ENMARCADOS-01',
  '4-774-350': 'ENOC-01',
  '4-146-2131': 'ENRIQUE M-01',
  '8-919-98': 'ENRIQUE-01',
  '57983-56-340439': 'ENSA-01',
  '898846-1-514496': 'EPAGO-01',
  '1210-507-121639': 'EPIS-01',
  '15186-39-146698': 'EQUIPO-01',
  '155667546-2-2018': 'ERGO-01',
  '3-111-604': 'ERIC-02',
  '4-706-143': 'ERICK-01',
  '9-709-339': 'ERNELINDA-01',
  '8-360-643': 'ERNESTO-01',
  '4-170-429': 'ERUNDINA-01',
  '11524-2-115657': 'ES CORONADO-01',
  '11524-2-115657': 'ES VILLA CACERES-01',
  '133390-1-383151': 'ESSO-01',
  '2173005-1-769041': 'ESTACINAMIENTOS-05',
  '31107-1-368192': 'ESTACINAMIENTOS-07',
  '4-713-1245': 'ESTACION-01',
  '41978-47-285997': 'ESTACION-02',
  '1153929-1-572298': 'ESTACION-03',
  '2185130-1-770922': 'ESTACION-04',
  '303365-1-410448': 'ESTACION-05',
  '630-483-123250': 'ESTACION-06',
  '630-483-123250': 'ESTACION-07',
  '2164777-1-767612': 'ESTACION-08',
  '155594161-2-2015': 'ESTACION-09',
  '45987-107-302047': 'ESTACION-10',
  '1069588-1-551769': 'ESTACION-11',
  '155707471-2-2021': 'ESTACION-12',
  '630-483-123250': 'ESTACION-13',
  '42101-24-286597': 'ESTACION-14',
  '2063514-1-750188': 'ESTACIONAMIENTOS-01',
  '2321277-1-793023': 'ESTACIONAMIENTOS-02',
  '2053456-1-748386': 'ESTACIONAMIENTOS-03',
  '1-32-331': 'ESTACIONAMIENTOS-04',
  '2321277-1-793023': 'ESTACIONAMIENTOS-06',
  '2015108-1-742465': 'ESTACIONAMIENTOS-07',
  '14784-27-143635': 'ESTACIONAMIENTOS-08',
  '155677503-2-2019': 'ESTACIONAMIENTOS-09',
  '2149039-1-765060': 'ESTANTERIAS-01',
  '8-955-1099': 'ESTEFANIA-01',
  '908-242-19211': 'ETERNA-01',
  '1724824-1-691136': 'ETERNITY-01',
  '1656027-1-676639': 'EUROPARTNERS-01',
  '4-728-1184': 'EVER-01',
  '2494949-1-818134': 'EXPEDITORS-01',
  '17123-12-161493': 'EXPRESO-01',
  '1621087-1-670251': 'EXPRESS-01',
  '155703702-2-2021': 'EXTREME',
  '8-102-1430': 'FABIOLA-01',
  '8-366-656': 'FABRIZIO-01',
  '2122313-1-760567': 'FALCO-01',
  '438412-1-429831': 'FAMILY-01',
  '53602-101-328353': 'FARCLINIC-01',
  '4-146-1458': 'FARMACIA-01',
  '3377-2-50620': 'FARMACIA-02',
  '53602-101-328353': 'FARMACIA-03',
  '346-266-76470': 'FARMACIA-04',
  '2123632-1-760780': 'FARMACIA-05',
  '294561-1-409212': 'FARMACIA-06',
  '1271700-1-598071': 'FARMACIA-07',
  '655-419-132915': 'FARMACIAS-02',
  '181-578-4474': 'FARMALIFE-01',
  '755-247-117717': 'FARMAS-01',
  '155688104-2-2019': 'FASHION CLEAN-01',
  '155597419-2-2015': 'FAST-01',
  '6-59-392': 'FATIMA-01',
  '257-563-58968': 'FAUSTO-01',
  '49679-106-746': 'FEDERAL-01',
  '8-906-2059': 'FEDERICO-01',
  '8-899-2470': 'FELICITY-01',
  '8-862-2388': 'FELIPE -01',
  '441-74-94389': 'FELIPE-01',
  '24638-93-213823': 'FELIPE-02',
  '2083576-1-35704': 'FERNANDEZ-01',
  '8-220-2362': 'FERNANDO-02',
  '8-1023-1794': 'FERNANDO-03',
  '201395-1-395419': 'FERREMAX-01',
  '155666184-2-2018': 'FERREMAX-02',
  '2548634-1-826029': 'FERRESHOP',
  '1902966-1-723021': 'FERRETERIA-01',
  '1703-154-33814': 'FERRETERIA-02',
  '8-819-2246': 'FERRETERIA-03',
  'N-20-430': 'FERRETERIA-04',
  '2-114-424': 'FERRETERIA-05',
  '32966-52-249929': 'FERRETERIA-06',
  'N-19-2496': 'FERRETERIA-07',
  'N-20-599': 'FERRETERIA-08',
  '8-923-2172': 'FERRETERIA-09',
  '8-898-1147': 'FERRETERIA-10',
  '9-122-2332': 'FERRETERIA-11',
  '8-869-2057': 'FERRETERIA-12',
  '2370232-1-800572': 'FERRETERIA-13',
  '8-775-604': 'FERRETERIA-14',
  '8-939 -584': 'FERRETERIA-15',
  '47716-71-308247': 'FERRETERIA-16',
  '8-860-207': 'FERRETERIA-17',
  '1409046-1-629451': 'FERRETERIA-18',
  '8-995-486': 'FERRETERIA-19',
  '8-854-1518': 'FERRETERIA-20',
  '49867-93-316410': 'FERRETERIA-21',
  'E-8-90870': 'FERRETERIA-22',
  '155610091-2-2015': 'FERRETERIA-23',
  'N-19-1215': 'FERRETERIA-24',
  '8-896-2303': 'FERRETERIA-25',
  '1703-154-33814': 'FERRETERIA-26',
  'N-20-1423': 'FERRETERIA-27',
  '2406611-1-805773': 'FERRETOTAL-01',
  '8-781-2407': 'FERRICENTRO-01',
  '8-1063-2430': 'FERRICENTRO-02',
  '155727706-2-2022': 'FEWORITE-01',
  '4-157-478': 'FILDER-01',
  '1565484-1-659788': 'FILTROS-01',
  '2486907-1-817046': 'FINANCENTRO-01',
  '155658395-2-2017': 'FINCA-01',
  '155614338-2-2015': 'FIRESECURITY-01',
  '4-204-165': 'FLAT -01',
  '13493-2-132798': 'FLETES-01',
  '8-771-187': 'FONDA-01',
  '8-420-929': 'FONDA-02',
  '7-45-63': 'FONDA-03',
  '9-166-297': 'FONDA-04',
  'N-20-1522': 'FONDA-05',
  '2436422-1-810162': 'FOODIE-01',
  '2555958-1-826978': 'FORKLITEC-01',
  '14342-0104-139493': 'FORMAS Y SISTEMAS-01',
  '12657-192-125369': 'FORMAS-01',
  '4-727-1254': 'FRANCIS-01',
  '4-727-1254': 'FRANCISCO-02',
  '8-836-15': 'FRANCISCO-03',
  '8-385-72': 'FRANKLIN-01',
  '5044-11-64201': 'FRANKLIN-02',
  '902-308-105023': 'FRANQUICIAS-01',
  '155676623-2-2019': 'FRANQUICIAS-02',
  '1967436-1-734718': 'FRANQUICIAS-03',
  '3-729-12': 'FRIENDS-01',
  '2164777-1-767612': 'FUEL-01',
  '9-115-663': 'FUMIGADORA-01',
  '2611827-1-835481': 'FUMIGADORA-02',
  '4391-2-15115': 'FUNDA-01',
  '173106-1-4047': 'FUNDACION',
  '25029558-3-2016': 'FUNDACION-01',
  '3007-2-11-24-8': 'FUNDACION-02',
  '47707-36-308211': 'FURSYS-01',
  '4-794-216': 'GABRIEL-02',
  '155595836-2-2015': 'GABRIEL-03',
  '8-876-1939': 'GABRIEL-04',
  '3-201-136': 'GABRIEL-05',
  '8-953-371': 'GABRIELA-01',
  '155681390-2-2019': 'GAC MOTOR-01',
  '51070-52-320234': 'GAGO-01',
  '2638659-1-839147': 'GAL-01',
  '391924-1-423108': 'GAMA,S.A.',
  '550-490-101750': 'GASES',
  '8-811-986': 'GASPAR-01',
  '155645663-2-2017': 'GASTRONOMIA-01',
  '341113-1-416137': 'GAUCHOS-01',
  '8-909-1272': 'GENESIS-01',
  '19740-125-179882': 'GENTILE-01',
  '138-289-35920': 'GEO F-01',
  '4-730-825': 'GEOVANI-01',
  '1471971-1-642162': 'GIGA-01',
  '8-260-1284': 'GISELA-01',
  '8-310-864': 'GISELLE-01',
  '155711763-2-2021': 'GL FUEL-01',
  '7-93-1782': 'GLADYS-01',
  '1053931-1-548246': 'GLB-01',
  '40979-25-281810': 'GLOBAL-01',
  '48870-93-312745': 'GLOBAL-02',
  '1308215-1-607559': 'GMG-01',
  '155703889-2-2021': 'GOCEAN PARKIG-01',
  '884775-1-511797': 'GOL DE ORO-01',
  '155689280-2-2019': 'GOMOSA-01',
  '4-147-2723': 'GRACIELA-01',
  '8-854-200': 'GRACIELA-02',
  '155735966-2-2023': 'GRAMANET-01',
  '1241742-1-591340': 'GRAN HOTEL-01',
  '105765-1-379278': 'GRAND-01',
  '4663-223-61221': 'GRECO-01',
  '155697097-2-2020': 'GREENSPACE-01',
  '2384-104-41141': 'GRUAS-01',
  '4-239-196': 'GRUPO ALSARP-01',
  '1556811390-2-2019': 'GRUPO AUTO-01',
  '155683772-2-2019': 'GRUPO FURIA-01',
  '12552-2-124485': 'GRUPO ITAPAN-01',
  '155713158-2-2021': 'GRUPO LATTUGA-01',
  '2357-1-364165': 'GRUPO PRIMAVERA-01',
  '2014226-1-742306': 'GRUPO SALEHJI-01',
  '155684818-2-2019': 'GRUPO SIAMO,S.A.',
  '155628683-2-2016': 'GRUPO SKY-01',
  '52744-50-325686': 'GRUPO TURISTICO-01',
  '21955-112-196801': 'GRUPO ZUELLEN-01',
  '1575456-1-661583': 'GRUPO-01',
  '625499-1-455526': 'GRUPO-02',
  '8-904-2272': 'GRUPO-03',
  '41416-2-283564': 'GRUPO-04',
  '47028-19-305805': 'GRUPO-05',
  '3394-105-50823': 'GRUPO-06',
  '8-933-2026': 'GRUPO-07',
  '155652952-2-2017': 'GRUPO-08',
  '155652952-2-2017': 'GRUPO--08',
  '155664219-2-2018': 'GRUPO-09',
  '155722692-2-2022': 'GRUPO-10',
  '950014-1-525664': 'GRUPO-11',
  '2203561-1-773678': 'GRUPO-12',
  '2318976-1-792639': 'GRUPO-13',
  '1308142-1-607540': 'GRUPO-14',
  '155654520-2-2017': 'GRUPO-15',
  '1469541-1-641676': 'GSQ PANAMA-01',
  '155694458-2-2020': 'GTP SOLUTIONS-01',
  '8-929-1178': 'GUADALUPE-01',
  '4-186-402': 'GUILLERMO-01',
  '4-186-402': 'GUILLERMO-02',
  '8-NT-2-39303': 'GUILLERMO-03',
  '8-725-361': 'GUILLERMO-04',
  'E-8-49486': 'GUILLERMO-05',
  '2-729-929': 'GUILLERMO-06',
  '849508-1-505051': 'GWY SERVICES-01',
  '533-555-116945': 'H. TZANETATOS-01',
  '41978-47-285997': 'H.R-01',
  '8-972-1063': 'HAFEZ-01',
  '219435-1-398252': 'HAMBURG-01',
  '6-723-1833': 'HAROL-01',
  '8-469-232': 'HECTOR-01',
  '9-170-540': 'HERMANOS-01',
  '1970426-1-735258': 'HERMEC-01',
  '155709245-2-2021': 'HERO-01',
  '724675-1-473907': 'HIDROCA-01',
  '659-2-14637': 'HIELO-01',
  '25135-2-216719': 'HIELO-02',
  '8-729-1572': 'HILVE-01',
  '155672125-2-2018': 'HIPERACTIVO-01',
  '8444-1-365125': 'HLB CHENG-01',
  '2069791-1-751271': 'HOPSA-01',
  '29658-1-368019': 'HORMIGON-01',
  '8-136-125': 'HOSPEDAJE-01',
  '272750-1-406249': 'HOSPITAL-01',
  '5990-74-71492': 'HOSTELERIA-01',
  '45208-2-299108': 'HOTEL LOS MANDARINOS',
  '2685484-1-845782': 'HOTEL MAR-01',
  '34573-11-256767': 'HOTEL MIRAMAR-01',
  '1311073-1-608173': 'HOTEL MYKONOS-01',
  '386-153-85326': 'HOTEL PANAMONTE',
  '43231-0021-290939': 'HOTEL-01',
  '12129-38-120929': 'HOTEL-02',
  '590480-1-449940': 'HOTEL-03',
  '1345162-1-615951': 'HOTEL-05',
  '965983-1-528696': 'HOTEL-06',
  '1095969-1-558421': 'HOTEL-07',
  '155690063-2-2020': 'HOTEL-08',
  '9-122-853': 'HOTEL-10',
  '1983756-1-737461': 'HOTEL-11',
  '309480-1-411289': 'HOTEL-12',
  '956082-1-526870': 'HOTEL-13',
  '309480-1-41189': 'HOTEL-14',
  '24811-19-214777': 'HOTELERA-01',
  '2090046-1-754959': 'HOU-01',
  '155626689-2-2016': 'HRP PANAMA-01',
  '3-721-857': 'HUMBERTO-01',
  '8-393-611': 'HUMBERTO-02',
  '8-NT-1-10284': 'I.D-01',
  '155688340-2-2019': 'I.P CAPITAL',
  '8-909-1881': 'IAN-01',
  '8-1001-1621': 'IANNA-01',
  '155603245-2-2015': 'IGROW-01',
  '2268023-1-784648': 'IL-01',
  '1623976-1-670748': 'IL-02',
  '4-803-804': 'ILICH-01',
  '1386946-1-625430': 'IMC-01',
  '510-302-109858': 'IMPLOSA-01',
  '35226-2-259295': 'IMPORTADORA 01',
  '434-15-93796': 'IMPORTADORA-01',
  '510-302-109858': 'IMPORTADORA-02',
  '558-472-101708': 'IMPORTADORA-04',
  '8-222-2057': 'IMPRESIONES-01',
  '548718-1-442976': 'IMPRESIONES-02',
  '1681734-1-682377': 'IMPRESORA-01',
  '155725472-2-2022': 'IN AND OUT',
  '8-NT-2-755613': 'INDUSTRIAL-01',
  '923-390-106595': 'INDUSTRIAS-01',
  '589378-1-449730': 'INGENIERIA-01',
  '1737679-1-693704': 'INGENIERIA-02',
  '155695575-2-2020': 'INGENIERIA-03',
  '8-768-1311': 'INGRID-01',
  '2655303-1-841372': 'INMOBILIARIA-01',
  '30815-22-240563': 'INMOBILIARIA-02',
  '2655303-1-841372': 'INMOBILIARIA-03',
  '1068-32-114296': 'INMOBILIARIA-04',
  '273003-1-406331': 'INMOBILIARIO-01',
  '8-NT-1-13587': 'INSTITUTO-01',
  '43302-106-291094': 'INTER-AMERICAN-01',
  '47101-2-306017': 'INTERNATIONAL',
  '1556011-43-22015': 'INTERNATIONAL-01',
  '47101-2-306017': 'INTERNATIONAL-02',
  '155683520-2-2019': 'INTERWORLD-01',
  '2020788-1-743331': 'INVERSION Y DESA-01',
  '155592782-2-2015': 'INVERSIONES-01',
  '919615-1-519042': 'INVERSIONES-02',
  '905223-1-515718': 'INVERSIONES-03',
  '367422-1-25177': 'INVERSIONES-04',
  '886674-1-512095': 'INVERSIONES-05',
  '155663801-2-2018': 'INVERSIONES-06',
  '155650126-2-2017': 'INVERSIONES-07',
  '155669463-2-2018': 'INVERSIONES-08',
  '2417143-1-807383': 'INVERSIONES-09',
  '1311073-1-608173': 'INVERSIONES-10',
  '155672303-2-2018': 'INVERSIONES-11',
  '7698-106-83230': 'INVERSIONES-12',
  '155627028-2-2016': 'INVERSIONES-13',
  '573001-1-446946': 'INVERSIONES-15',
  '2020598-1-743303': 'INVERSIONES-16',
  '2404998-1-805475': 'INVERSIONES-17',
  '1927906-1-727524': 'INVEZA-01',
  '575-37-101777': 'IOVANE-01',
  '8-852-778': 'IRFAN-01',
  '8-956-1782': 'IRIS-01',
  '8-926-265': 'ISAAC-01',
  '155708848-2-2021': 'ISAFELU-01',
  '32318-11-247112': 'ISHOP-01',
  '596-270-122754': 'ISLA-01',
  '2-1022361': 'ISMAEL-01',
  '155685646-2-2019': 'ISTMO-01',
  '2331634-1-794553': 'IT GAP-01',
  '8-705-879': 'IT SOLUCIONES-01',
  '1293852-1-603822': 'ITS CONSULTANTS-01',
  '747157-1-479185': 'IVI',
  '2640248-1-839369': 'J & L MODELS',
  '8-497-156': 'J.B.CAPACITACION',
  '1758028-1-697729': 'J.G.M-01',
  '8-945-155': 'JACINTO',
  '2546245-1-825695': 'JAD GROUP-01',
  '5-700-1384': 'JADA-01',
  '25041469-3-2019': 'JAGUAR-01',
  '8-819-2405': 'JAHAT-01',
  '8-951-16': 'JAIME-01',
  '8-860-1600': 'JAIR-01',
  '1271700-1-598071': 'JANETH-01',
  '2558233-1-827310': 'JARDINES-01',
  '4-246-205': 'JAVIER-01',
  '4-246-205': 'JAVIER-02',
  '8-952-2466': 'JAVIER-03',
  '8-770-426': 'JAVIER-04',
  '8-950-608': 'JEAN-01',
  '8-771-753': 'JEAN-02',
  '8-723-804': 'JEANINI-01',
  '9857-0220-101047': 'JELLINI-01',
  '8-913-749': 'JEREMY-01',
  '8-873-1160': 'JESUS-01',
  '8-987-845': 'JHONATHAN-01',
  '8-424-967': 'JILMA EVELIA-01',
  '8-NT-2-30428': 'JIM-01',
  'PE-14-1576': 'JINGLOU CHEN -01',
  'N-21-1440': 'JINYI-01',
  '3-66-611': 'JOAQUIN-01',
  '2-736-2221': 'JOHANNA-01',
  '8-821-157': 'JONATHAN-01',
  '8-703-2327': 'JONATHAN-02',
  '8-1019-1282': 'JONATHAN-03',
  '8-987-845': 'JONATHAN-04',
  '4-701-1868': 'JORGE-001',
  '4-799-1985': 'JORGE-01',
  '8-493-987': 'JORGE-02',
  '4-145-966': 'JOSE A-01',
  '8-502-870': 'JOSE A-02',
  '8-226-2237': 'JOSE- anulado',
  '2-128-207': 'JOSE D-01',
  '4-744-989': 'JOSE L-01',
  '8-958-527': 'JOSE LUIS-01',
  '8-838-225': 'JOSE MADRID-01',
  '8-200-1265': 'JOSE-01',
  '4-268-196': 'JOSE-02',
  '4-287-868': 'JOSE-03',
  '8-226-2237': 'JOSE-04',
  '8-18-734': 'JOSE-05',
  '8-226-2237': 'JOSE-06',
  '4-733-1577': 'JOSE-07',
  '8-971-641': 'JOSE-08',
  '8-892-2447': 'JOSEPH-01',
  '8-220-2725': 'JUAN CARLOS-01',
  '8-440-140': 'JUAN CARLOS-02',
  '8-862-2365': 'JUAN CARLOS-03',
  '6-711-1608': 'JUAN E.-01',
  '8-836-2043': 'JUAN NAVARRO',
  '8-896-334': 'JUAN ORTIZ-01',
  '10285-101-105109': 'JUAN PABLO-01',
  '2-66-224': 'JUAN U-01',
  '4-281-861': 'JUAN-01',
  '8-559-216': 'JUAN-02',
  '8-703-2086': 'JUAN-03',
  '8-836-2043': 'JUAN-04',
  '8-766-1029': 'JUAN-05',
  '8-1007-109': 'JUAN-06',
  '8-766-1029': 'JUAN-07',
  '8-896-334': 'JUAN-08',
  '4-718-197': 'JUDITH-01',
  '8-885-1555': 'jul-02',
  '8-209-890': 'JULIO CESAR-01',
  '8-526-801': 'JULIO-01',
  '8-885-1555': 'JULIO-02',
  '8-NT-2-748102': 'JUNTA-01',
  '5044-11-64201': 'JURADO-01',
  '81655-1-375689': 'JUST-01',
  '8-1021-1778': 'JUSTIN-01',
  '5837-93-70420': 'KADIMA-01',
  '1661185-1-677737': 'KALO-01',
  '1095609-1-558328': 'KAREN-01',
  '8-950-1022': 'KARINA-01',
  '8-944-1101': 'KARINA-02',
  '8-100-258': 'KARL-01',
  '155723006-2-2022 ': 'KARMAN-01',
  '8-919-1629': 'KENNET-01',
  '8-919-1629': 'KENNETH-01',
  '8-885-2146': 'KEVIN-01',
  '8-NT-2-717884': 'KEVIN-02',
  '8-907-517': 'KEYMI-01',
  151144: 'KIMARY-O1',
  'N-20-606': 'KING-01',
  '6947-101-77831': 'KISEA-01',
  '155648575-2-2017': 'KITCHEN-01',
  '22330-1-367028': 'KOPY-01',
  '811194-1-498031': 'KOTOWA-01',
  '155607822-2-2015': 'KRISPY-01',
  '155624339-2-2016': 'KYOTO-01',
  '155688340-2-2019': 'L P CAPITAL-01',
  '1016854-1-539571': 'LA CASA-01',
  '35226-2-259295': 'LA CASA-02',
  '2015191-1-742488': 'LA CASA-03',
  '43946-81-293527': 'LA COCINA-01',
  '155677236-2-2019': 'LA DIVINA-01',
  '602700-1-451985': 'LA FACTORIA-01',
  '919-172-106058': 'LA PARRILLA-01',
  '8-737-1926': 'LA RUTA-01',
  '26268-0033-223109': 'LABORATORIO-01',
  '15419-167-148643': 'LABORATORIO-02',
  '8-788-1677': 'LABORATORIO-03',
  '155636345-2-2016': 'LABORATORIO-04',
  '155609904-2-2015': 'LABORATORIOS-01',
  '1144642-1-570367': 'LADRILLERA-01',
  '1153929-1-572298': 'LAGARES-01',
  '2074216-1-752049': 'LAPP-01',
  '8-406-481': 'LARISA-01',
  '8-406-481': 'LARISSA-01',
  '10189-2-104236': 'LAS HADAS-01',
  '8-258-761': 'LAVA AUTO-01',
  '2-713-2145': 'LAVA AUTO-02',
  '8-130-0687': 'LAVA AUTO-03',
  '3-720-1408': 'LAVA AUTO-04',
  'E-8-56546': 'LAVA AUTO-06',
  '62210-34-351522': 'LAVANDERIA-01',
  '47823-150-308580': 'LEADERSHIP-01',
  '25044376-3-2021': 'LEGALALLY',
  '1519533-1-651285': 'LEGITIMA-01',
  '729590-1-474973': 'LEONARDO-02',
  '171-358-4217': 'LETTFALAH-01',
  '8-509-985': 'LICDO. JORGE-01',
  '2180949-1-770371': 'LIDO-01',
  '2155801-1-766140': 'LIGA-01',
  '155588209-2-2014': 'LINVER-01',
  '8-876-506': 'LIONEL-01',
  '275-271-7018': 'LIOR-01',
  '8-457-761': 'LIZBETH-01',
  '4-740-474': 'LL',
  '8-849-152': 'LLANTERIA-01',
  '1556069-2-2015': 'LOPMAR',
  '529409-1-439947': 'LOPUS-01',
  'E-8-112858': 'LORENZA-01',
  '8-230-853': 'LORENZO-01',
  '8-1010-2118': 'LUCAS-01',
  '8-1004-2322': 'LUCCA-01',
  '155690754-2-2020 ': 'LUDOS-01',
  '8-700-1290': 'LUIS A-01',
  '8-252-873': 'LUIS A-02',
  '8-878-1259': 'LUIS E-01',
  '4-746-114': 'LUIS L-01',
  '7-109-905': 'LUIS M-01',
  '4-256-278': 'LUIS M-02',
  '8-920-963': 'LUIS MIGUEL-01',
  '596-137-122414': 'LUIS-01',
  '8-892-2459': 'LUIS-02',
  '553-231-100703': 'LUMICENTRO-01',
  '16415-2-155997': 'LUMMEN-01',
  '938-553-106007': 'LUNG-01',
  '352920-1-417766': 'LUZ-01',
  '8-893-1701': 'LUZ-02',
  '2317845-1-792481': 'M.R.O',
  '19-1523': 'M/S FRUTERIA-01',
  '26622-30-224801': 'MAAGOOS-01',
  '537091-1-441028': 'MAC-01',
  '2434796-1-809947': 'MACRO-01',
  '1059726-1-549472': 'MADDEN-01',
  '36828-69-264995': 'MAERKS -01',
  '2-715-2341': 'MAGBIS-01',
  '4-793-1469': 'MAIKEL-01',
  '8-952-494': 'MAIKER-01',
  '35141-164-259008': 'MAIL-01',
  '1-727-514': 'MAIRE-01',
  '3023-0002-011319': 'MAKE-01',
  '155656305-2-2017': 'MALE-01',
  '155635929-2-2016': 'MANA-01',
  '158080-1-387251': 'MANANTIAL-01',
  '4-239-196': 'MANUEL-01',
  'PE-9-1552': 'MANUEL-02',
  '8-812-2224': 'MANUEL-03',
  '155598608-2-2015': 'MAPFRE-01',
  '1261064-1-595513': 'MAQUINARIA-01',
  '36147-54-262470': 'MAQUINARIA-02',
  '2648875-1-840471': 'MAQUINARIAS Y EQ. PA',
  '11485-34-115332': 'MAQUINARIAS-01',
  '44789-97-297242': 'MARBELLA-01',
  '22006-268-197161': 'MARCA-01',
  '8-234-323': 'MARCIAL-01',
  '657230-1-460643': 'MARDI-01',
  '8-777-915': 'MARIA DEL CARMEN-01',
  '8-260-1024': 'MARIA-01',
  '8-958-526': 'MARIA-02',
  '8-1927-18': 'MARIANA-01',
  '8-712-2369': 'MARICRUZ-01',
  '4-761-941': 'MARIELIS-01',
  '8-922-621': 'MARIO-01',
  '16831-38-159113': 'MASTER DIRECT-01',
  '25116-68-216612': 'MASTER-01',
  '8-819-190': 'MATACASA-01',
  '155618411-2-2015': 'MATAOSCURA-01',
  '7-107-657': 'MATERIALES-01',
  '4-757-629': 'MATERIALES-02',
  '2604467-1-834384': 'MATERIALES-03',
  '26996-2-226712': 'MATERIALES-04',
  '155666359-2-2018': 'MATERIALES-05',
  '155704246-2-2021': 'MATERIALES-06',
  '1995855-1-739378': 'MATERIALES-07',
  '4-231-830': 'MAURICIO-01',
  '155666030-2-2018': 'MAX-02',
  '952-313-110427': 'MAXILLANTAS-01',
  '62619-58-352690': 'MAXIPISTA-01',
  '34347-8-255691': 'MAYHER-01',
  '43230-27-290896': 'MAYORISTAS-01',
  '1377855-1-623196': 'MC-01',
  '45090-108-298601': 'MEDICAL-01',
  '1405417-1-628761': 'MEJORES-01',
  '2-722-1086': 'MELISA-01',
  '25668-10-219826': 'MEMO-01',
  '155712422-2-2021': 'MERKAPP-01',
  '860.008.817-7': 'MERSEN-01',
  '916-586-106273': 'METALES-01',
  '1253900-1-593903': 'METRO-01',
  '57983-2-340436': 'METRO-OESTE-01',
  '155648819-2-2017': 'MF RETAIL-01',
  '8-338-681': 'MG CAR-01',
  '8-900-747': 'MICHAEL-01',
  '9-192-415': 'MIGUEL A-01',
  '8-962-155': 'MIGUEL ANGEL-01',
  '4-208-755': 'MIGUEL H-01',
  '8-271-47': 'MIGUEL M-01',
  '4-774-2470': 'MIGUEL-01',
  '8-939-475': 'MIGUEL-02',
  '8-925-1690': 'MILAGROS-01',
  'N-20-1582': 'MINI MARKET-01',
  '8-99-655': 'MINI SUPER-01',
  '8-904-502': 'MINI SUPER-02',
  'N-19-2127': 'MINI SUPER-03',
  'N-18-875': 'MINI SUPER-04',
  '8-847-1935': 'MINI SUPER-05',
  '6-76-837': 'MINI SUPER-06',
  '2-711-1276': 'MINI SUPER-07',
  'N-19-794': 'MINI SUPER-08',
  '8-797-2135': 'MINI SUPER-09',
  '8-908-1273': 'MINI SUPER-10',
  '8-939-1395': 'MINI SUPER-11',
  '8-858-1032': 'MINI SUPER-12',
  '1895740-1-721744': 'MINIMED-01',
  '8-NT-1-13653': 'MINISTERIO-01',
  '8-NT-2-747954': 'MINISTERIO-02',
  '8-NT-1-14274': 'MINISTERIO-03',
  '8-NT-2-5498': 'MINISTERIO-04',
  '8-NT-1-13654': 'MINISTERIO-05',
  'N-19-2447': 'MIQIAN-01',
  '256767-34573-11': 'MIRAMAR-01',
  '8-893-1483': 'MISAEL-01',
  '8-787-919': 'MITZI-01',
  '8-787-919': 'MITZY-01',
  '8-890-608': 'Mobile-01',
  '8-709-1750': 'MODESTO-01',
  '295570-1-409381': 'MOLINO-01',
  '9-890-609': 'MOMENTO',
  '155673736-2-2018': 'MONA-01',
  '8-952-747': 'MONICA-02',
  '8-922-239': 'MONICA-03',
  '128856-1-382436': 'MONTEMAR-01',
  '4-793-1469': 'MORALES-01',
  '8-514-789': 'MORENO-01',
  '8-531-2291': "MORENO'S-01",
  '155684450-2-2019': 'MOVICOM-01',
  '479149-1-19583': 'MOVIMIENTO-01',
  '141002-1-384331': 'MR-01',
  '155635437-2-2016': 'MR-02',
  '44410-76-295630': 'MR-03',
  '3-720-413': 'MR-04',
  '155629469-2-2016': 'MUH-01',
  '87102-1-376512': 'MULTI-01',
  '1597165-1-665672': 'MULTICENTRO-01',
  '1556966406-2-2020': 'MULTICOMPUTOS-01',
  '155690705-2-2020': 'MULTIMODAL-01',
  '155615168-2-2015': 'MULTISERVICIOS-01',
  '8-236-1544': 'MULTISERVICIOS-02',
  '2056493-1-748880': 'MULTISERVICIOS-03',
  '2016193-1-742634': 'MULTISERVICIOS-04',
  '155664752-2-2018': 'MULTISERVICIOS-05',
  '8-NT-2-725372': 'MULTISERVICIOS-06',
  '155697408-2-2020': 'MULTISERVICIOS-07',
  '36765-2-264774': 'MULTITEK-01',
  'N-20-586': 'MULTITORNILLOS-01',
  '748649-1-479568': 'MUNDO-01',
  '8-NT-1-21897': 'MUNICIPIO-01',
  '8-NT-2-8769': 'MUNICIPIO-02',
  '8-NT-1-12701': 'MUNICIPIO-03',
  '8-NT-1-12667': 'MUNICIPIO-04',
  '8-NT-1-21908': 'MUNICIPIO-05',
  '7-NT-2-8776': 'MUNICIPIO-06',
  '6-NT-2-8778': 'MUNICIPIO-07',
  '8-NT-1-13581': 'MUNICIPIO-08',
  '2-NT-1-570': 'MUNICIPIO-09',
  '2-NT-1-753': 'MUNICIPIO-10',
  '6-NT-1495': 'MUNICIPIO-11',
  '8-NT-1-14694': 'MUNICIPIO-12',
  '8-NT-1-15620': 'MUNICIPIO-13',
  '8-NT-2-1484': 'MUNICIPIO-14',
  '4-NT-1-8346': 'MUNICIPIO-15',
  '2-NT-2-1590': 'MUNICIPIO-16',
  '9-NT-2-8779': 'MUNICIPIO-17',
  '8-NT-2-5796': 'MUNICIPIO-18',
  '8-271-47': 'MURILLO-01',
  '1556374-2-2016': 'MUSA-01',
  '1NT-2-106344': 'MUTISERVICIOS-01',
  '2393264-1-803914': 'MY-01',
  '155599762-2-2015': 'MYBOX-01',
  '155706533-2-2021': 'NACION SUSHI COR',
  '155643534-2-2017': 'NACION SUSHI EC',
  '155602818-2-2015': 'NACION-01',
  '155658578-2-2017': 'NACION-02',
  '942-320-108352': 'NAQUEL-01',
  '57983-2-340436': 'NATURGY-01',
  '25046119-3-2021': 'NAVACRE-01',
  '25044653-3-2021': 'NAVARRO BROTHERS-01',
  '8-705-477': 'NELSON-01',
  '2306421-1-790619': 'NESTOR-01',
  '708771-1-470598': 'NETWORK-01',
  '8-494-926': 'NEW PRINTING-01',
  '2310851-1-791391': 'NEWTRON-01',
  '8-944-976': 'NICOLE-01',
  '2-80-049': 'NIDIA-01',
  '2058697-1-749331': 'NIEROSA-01',
  '155635893-2-2016': 'NIETO-01',
  '8-811-1578': 'NIEVES-01',
  '33209-109-250936': 'NIKOS-01',
  '8-513-961': 'NORA-01',
  '8-711-694': 'NOTARIA OCTAVA-01',
  '8-509-985': 'NOTARIA PRIMERA-01',
  '4-703-1164': 'NOTARIA PRIMERA-02',
  '4-201-226': 'NOTARIA SEGUNDA-01',
  '4-201-226': 'NOTARIA TERCERA-01',
  '4-157-725': 'NOTARIA-01',
  '8-250-338': 'NOTARIA-02',
  '8-260-1284': 'NOTARIA-03',
  '8-499-305': 'Notaria-04',
  '8-260-1284': 'NOTARIA-05',
  '8-811-1034': 'NOVEDADES-01',
  '155697784-2-2020': 'NOVERAMA-01',
  '10189-2-104236': 'NUEVOS-01',
  '1054246-1-548350': 'NUSRET-01',
  '8-963-1380': 'NYURKA-01',
  '656-413-132700': 'O.D-01',
  '155703889-2-2021': 'OCEAN PARKIG-01',
  '1621672-1-670343': 'OCEANIA-01',
  '8-751-2409': 'ODERAY-01',
  '656-413-132700': 'OFFICE-01',
  '2036276-1-35061': 'OFFICE-02',
  '155658841-2-2017': 'OFFICE-03',
  '155605985-2-2015': 'OFFICLIP-01',
  '7-92-464': 'OFI-01',
  '155675670-2-2019': 'OH-TORO-01',
  '2-144-985': 'OLDEMAR-01',
  '4-776-740': 'OMAR-01',
  '42238-41-287163': 'OMARCO-01',
  '155623139-2-2016': 'ONTARIO-01',
  '1675920-1-680848': 'OPERADORA-01',
  '155693476-2-2020': 'OPTICA-01',
  '29672-116-237038': 'ORGANICA-01',
  '1661800-1-677839': 'ORIGINAL-01',
  '630-483-123250': 'ORLYN-01',
  '1069588-1-551769': 'OSARAM-01',
  '8-795-2366': 'OSCAR-01',
  '4-773-2095': 'OSNAR-01',
  '8-NT-2-32413': 'P H CENTRO-01',
  '8-NT-2-1047': 'P.H. CONDOMINIO-01',
  '8-NT-1-23003': 'P.H.-01',
  '155653607-2-2017': 'P.R.ADVERTISING-01',
  '155673494-2-2018': 'PADDEL-01',
  '791655-1-491591': 'PALO ALTO RIVERSIDE',
  '2688473-1-846291': 'PAN-01',
  '678-216-130846': 'PAN-02',
  '155652952-2-2017': 'PANABLOCK-01',
  '36190-32-262590': 'PANADERIA-01',
  '26209-61-222826': 'PANADERIA-02',
  '66159-91-363381': 'PANAFOTO-01',
  '419758-1-427175': 'PANAMA-01',
  '4-136-1246': 'PANAMA-02',
  '2015969-1-742614': 'PANAMA-03',
  '155609607-2-2015': 'PANAMA-04',
  '12371-108-123024': 'PANAMA-05',
  '1906328-1-33672': 'PANAMA-06',
  '640063-1-457927': 'PANAPARKING-01',
  '52050-2-323221': 'PANAVIASA-01',
  '21420-90-192480': 'PANOS-01',
  '2318139-1-792536': 'PANRENTAL-01',
  '155702701-2-2021': 'PAO-01',
  '155719493-2-2022': 'PARK AND PADEL',
  '2329799-1-794327': 'PARKING-01',
  '2165450-1-767714': 'PARKING-02',
  '4-736-109': 'PARRILLADA ORIGENES',
  '682-67-187522': 'PARRILLADA-01',
  '8-118-418': 'PARRILLADA-02',
  '4852-8-62703': 'PARSA-01',
  '30688-33-239941': 'PARTES-01',
  '155588866-2-2014': 'PARTES-02',
  '48740-140-312112': 'PASTA-01',
  '155633907-2-2016': 'PASTA-02',
  '8-914-1950': 'PATRICIA-02',
  '8-705-1251': 'PATZY-01',
  '4-727-1054': 'PAULINO-01',
  '155704631-2-2021': 'PAVINF-01',
  '34503-66-256443': 'PC-01',
  '8-NT-2-710658': 'PEDIDOS-01',
  '8-227-303': 'PEDRO-01',
  '155663817-2-2018': 'PEIMAR-01',
  '143-95-36679': 'PENCO-01',
  '1540294-1-655531': 'PENSA-01',
  '1016-327-115007': 'PESQUEROS-01',
  '2157184-1-766323': 'PETRO-01',
  '528537-1-439790': 'PETRO-02',
  '29440-0104-236201': 'PETROAUTOS-01',
  '11524-2-115657': 'PETROLEOS-01',
  '2197853-1-772815': 'PETROLEOS-02',
  '155638908-2-2016': 'PETROLEOS-03',
  '155654997-2-2017': 'PETROLEOS-04',
  '324468-1-413694': 'PETROLERA-01',
  '1377855-1-623196': 'PETROLEUM-01',
  '8-NT-2-26371': 'PH BMW-01',
  '8-NT-2-6145': 'PH CAPITAL-01',
  '8-NT-2-10286': 'PH CENTRO-01',
  '8-NT-2-3409': 'PH PLAZA-01',
  '8-NT-2-20055': 'PH TORRE-01',
  '8-NT-2-3540': 'PH TORRE-02',
  '1284105-1-601253': 'PIER-01',
  '918469-1-518827': 'PINTURA-01',
  '1574237-1-661345': 'PINTURAS-01',
  '361958-1-419101': 'PIRUETAS-01',
  'E-8-113216': 'PIZZERIA-01',
  'E-8-186861': 'PIZZERIA-02',
  '963743-1-528477': 'PLANTA-01',
  '155627605-2-2016': 'PLATINIUM-01',
  '155679039-2-2019': 'PLOMERELECTRIC,S.A.',
  '521347-1-438253': 'PLUXEE-01',
  '155601143-2-2015': 'PMP-INTERNATIONAL-01',
  '2658790-1-841889': 'PONY PROPERTIES-01',
  '31922-23-245457': 'PORSI-01',
  '1738250-1-693794': 'PORTOBELO-01',
  '155597936-2-2015': 'POSTENCABLES-01',
  '1482157-1-643857': 'POSTNET',
  '59849-1-372370': 'POWER',
  '39293-52-274981': 'POWER-01',
  '1024902-1-541329': 'POYEQUIROZ-01',
  '155709220-2-2021': 'PPA-01',
  '155673765-2-2018': 'PRAIA-01',
  '2635032-1-838688': 'PREMIUM-01',
  '638045-1-457554': 'PREMIUM-02',
  '1925708-1-727160': 'PREMIUM-03',
  '1137997-1-568850': 'PREMIUN-01',
  '1989661-1-738454': 'PRETTYCAR SHOP-01',
  '898-241-102416': 'PRICESMART-01',
  '682977-1-1464809': 'PRIMERA-01',
  '764361-1-483448': 'PRINT',
  '764361-1-483448': 'PRINT-01',
  '1253761-1-593852': 'PRODELCA-01',
  '653-234-14529': 'PRODELSA-01',
  '186-505-46878': 'PRODUCTOS-01',
  '4-731-866': 'PROELECTRIC-01',
  '155587023-2-2014': 'PROGEINSA-01',
  '1484273-1-644387': 'PROMUEVETE-01',
  '14387-0101-139932': 'PRONTOPRINT-01',
  '1894538-1-721549': 'PROPERTY-01',
  '61131-53-348038': 'PROPIEDADES-01',
  '2651027-1-840766': 'PROSAFE',
  '57699-49-339574': 'PROSECAL-01',
  '1024902-1-541329': 'PROYEQUIROZ-01',
  '52065-1-371353': 'PSI-01',
  '2614079-1-835779': 'PTL DISTRIBUTION-01',
  '1157200-1-573083': 'PTY TEC-01',
  '2164777-1-767612': 'PUMA-01',
  '155702480-2-2021': 'PUMA-02',
  '1914582-1-725026': 'PUNTO-01',
  '2634836-1-838661': 'PUNTO-02',
  '36976-67-265614': 'QUALITY-01',
  '155651120-2-2017': 'QUALITY-02',
  '2364349-1-799753': 'QUEEN-01',
  '1710117-1-688248': 'QUESOS CHELA-01',
  '7266-167-80139': 'QUESOS-01',
  '630-483-123250': 'QUICK SHOP-01',
  '52887-52-326145': 'QUICK-01',
  '30170-22-237573': 'RAENCO-01',
  '8-797-2135': 'RAFAEL-01',
  '8-906-2388': 'RAFAEL-02',
  '1840337-1-711890': 'RAL RENT A LIFT',
  '4-720-35': 'RAMIRO-01',
  '155604944-2-2015': 'RAPID-01',
  '2395361-1-804189': 'RAPIRED-01',
  '8-897-1159': 'RAQUEL-02',
  'E-8-178793': 'REBECA-01',
  '952-313-110427': 'RECONSTRUCTORA-01',
  '42071-105-286474': 'RED VERDE-01',
  '25044376-3-2021': 'REGALALLY-01',
  '8-NT-1-22244': 'REGISTRO-01',
  '1901266-1-722499': 'REINO-01',
  '590480-1-449940': 'REITA-01',
  '155652353-2-2017': 'REMAX-01',
  '2511292-1-820369': 'RENESOLA-01',
  '952-313-110427': 'RENFRIO',
  '9685-125-99136': 'RENTA-01',
  '1129851-1-566686': 'RENTA-02',
  '8-385-625': 'REPARACIONES-01',
  '8-720-982': 'REPOSTERIA-01',
  '9033-92943-147': 'REPRESENTACIONES R-0',
  '8259-26-87072': 'REPRESENTACIONES-01',
  '2027408-1-744322': 'REPUBLIC-01',
  '2349064-1-797481': 'REPUESTOS-01',
  '10342-10-105577': 'REPUESTOS-02',
  '7-109-762': 'REST.  REF.-01',
  'E-8-62079': 'REST. DELICIAS',
  '8-163-2730': 'REST.05',
  '4-103-1243': 'REST-02',
  'E-8-60850': 'REST-03',
  'E-8-101719': 'REST-04',
  '2189932-1-771712': 'REST-05',
  '836454-1-502728': 'REST-06',
  '33678-1-415491': 'RESTAURANTE-01',
  'E-8-56283': 'RESTAURANTE-02',
  'E-8-56283': 'RESTAURANTE-03',
  '45087-1-370271': 'RESTAURANTE-04',
  '721-313-125925': 'RESTAURANTE-05',
  '8-294-984': 'RESTAURANTE-06',
  '1032577-1-543115': 'RESTAURANTE-07',
  '155644443-2-2017': 'RESTAURANTE-11',
  '836454-1-502728': 'RESTAURANTE-12',
  '155687637-2-2019': 'RESTAURANTE-13',
  '1312006-1-608425': 'RESTAURANTE-14',
  '155619207-2-2015': 'RESTAURANTE-15',
  '5-713-8': 'RESTAURANTE-16',
  '8-163-2730': 'RESTAURANTE-17',
  '2-703-100': 'RESTAURANTE-18',
  '6-62-59': 'RESTAURANTE-19',
  '1618930-1-669894': 'RESTAURANTE-20',
  '938-553-106007': 'RESTAURANTE-21',
  '2339153-1-795987': 'RESTAURANTE-22',
  'E-8-71563': 'RESTAURANTE-23',
  '2685484-1-845782': 'RESTAURANTE-24',
  '155673374-2-2018': 'RESTAURANTE-25',
  '155691783-2-2020': 'RESTAURANTE-26',
  '2617725-1-836211': 'RESTAURANTE-27',
  '7-103-823': 'RESTAURANTE-28',
  'E-8-107474': 'REYNA-01',
  '8-843-526': 'REYNALDO-01',
  '342-240-75526': 'RIBA-01',
  '305-414-68335': 'RICARDO-01',
  '8-475-965': 'RICARDO-02',
  '2-727-2014': 'RICARDO-03',
  '6-707-1076': 'RICARDO-04',
  '8-936-1371': 'RICARDO-05',
  '8-981-539': 'RICARDO-06',
  '9-755-2128': 'RICKY-01',
  '2454804-1-812754': 'RIMANIC-01',
  '1312006-1-608425': 'RINCON-01',
  '39274-16-274902': 'RIO PLATENSE-01',
  '715678-1-47820': 'RIO-01',
  '8-173-156': 'ROBERTO-01',
  'P-556429': 'ROBINSON-01',
  '696272-1-467757': 'ROCAYOL-01',
  '204-394-49730': 'RODELAG-01',
  '155703200-2-2021': 'RODMAN-01',
  '155702480-2-2021': 'RODRIGUEZ-01',
  '155622821-2-2016': 'ROG-01',
  '1661800-1-677839': 'ROK-01',
  '8-758-987': 'ROLANDO-01',
  '4-126-1994': 'ROLANDO-02',
  '155680753-2-2019': 'ROMCAR-01',
  '8-957-1287': 'ROSE FRAGO',
  '8-795-2187': 'ROSENDO-01',
  'P-138934217': 'ROSIMAR-01',
  '8-957-1287': 'ROSS-01',
  '8-885-2146': 'ROSTY POLLO-01',
  '9-720-1793': 'ROXANA-01',
  '8-442-924': 'ROY-01',
  '155645623-2-2017': 'RT SPORTS -01',
  '4-246-73': 'RUBEN-01',
  '4-772-123': 'RUBEN-02',
  '8-991-1706': 'RUBEN-03',
  '8-991-1706': 'RUBEN-04',
  '8-905-2388': 'Rubiel Medina',
  '8-905-2388': 'rubiel-01',
  '8-335-99': 'RUTH-01',
  '8-948-2492': 'RYAN-01',
  '2180949-1-770371': 'S & P-01',
  '16399-114-155895': 'S S-01',
  '155724557-2-2022': 'S4 LOGISTICS-01',
  '155728134-2-2022': 'S4 LOGISTICS-02',
  'TAX ID:84-1285716': 'S-5',
  '8-135-21': 'SABINO-01',
  '155586325-2-2014': 'SALSA-01',
  '155650250-2-2017': 'SALVA-01',
  '2613686-1-835711': 'SALYDULCE-01',
  '8-883-702': 'SAMUEL-01',
  '8-466-82': 'SAMUEL-02',
  '10-708-1663': 'SAMUEL-03',
  '155733905-2-2023': 'SAN & NAV',
  '155660845-2-2018': 'SAQUELLA-01',
  '8-1015-109': 'SARA-01',
  '8-1008-1668': 'SARA-02',
  '1545482-1-656462': 'SEA-01',
  '1564921-1-659717': 'SECHCARGO-01',
  'N-8-913-2013': 'SEDERIA-01',
  '1476937-1-643053': 'SEGRURIDAD-01',
  '30773-73-240340': 'SEMFYL-01',
  '7-241-714': 'SENEN-01',
  '4-166-704': 'SERAFIN-01',
  '8-782-1478': 'SERGIO-01',
  '2179268-1-770078': 'SERMESA-01',
  '9-118-2457': 'SERV. DE TRANSPORTE',
  '155746167-2-2024': 'SERVI-01',
  '904964-1-515656': 'SERVICENTRO-01',
  '61722-86 350108': 'SERVICENTRO-02',
  '1765597-1-699112': 'SERVICES-01',
  '36945-17-265492': 'SERVICIO-01',
  '73-561-21585': 'SERVICIO-02',
  '52196-38-323704': 'SERVICIO-03',
  '9-143-328': 'SERVICIOS DE-01',
  '1564921-1-659717': 'SERVICIOS-02',
  '8-284-369': 'SERVICIOS-03',
  '2357993-1-798831': 'SERVICIOS-04',
  '1798864-1-705036': 'SERVICIOS-05',
  '8-267-706': 'SERVICIOS-06',
  '8-57-287': 'SERVICIOS-07',
  '6-702-441': 'SERVICIOS-08',
  '8-473-593': 'SERVICIOS-09',
  '8-724-152': 'SERVICIOS-10',
  '8-441-626': 'SERVICIOS-11',
  '8-NT-2-755979': 'SERVITECNI-01',
  '48775-9-312287': 'SERVITIGER-01',
  '155609629-2-2015': 'SERVITRANSA-01',
  '1508-31-30247': 'SHALOM-01',
  '8-870-2252': 'SHERILL-01',
  '2658790-1-841889': 'SHOW PONY BEACH-01',
  '155702098-2-2021': 'SIGMAC',
  '1557022338-2-2021': 'SIGNATURE-01',
  '155667135-2-2018': 'SILOGSA',
  '266358-1-405335': 'SILVER-01',
  '144-152-7474': 'SINDICATO-01',
  '1578376-1-1313': 'SISTEMA-01',
  '521347-1-438253': 'SISTEMA-02',
  '557-538-101617': 'SISTEMAS-01',
  '155673501-2-2018': 'SKY-02',
  '2565016-1-828461': 'SOBE-01',
  '155732917-2-2023': 'SOLANT-01',
  '2652487-1-841025': 'SOLAR-01',
  '': 'SOLAR-02',
  '155747320-2-2024': 'SOLAR-07',
  '1631098-1-671872': 'SOLUCIONES-01',
  '2241456-1-779835': 'SOLUCIONES-02',
  '2212857-1-775245': 'SOLUCIONES-03',
  '155667135-2-2018': 'SOLUCIONES-04',
  '679926-1-464304': 'SOLUCIONES-05',
  '155703031-2-2021': 'SOPORTE-01',
  '155683036-2-2019': 'SOUVLAKI-01',
  '155596937-2-2015': 'SPIA-01',
  '2429941-1-809069': 'SPLASH-01',
  '21094-39-189991': 'SPORTLINE-01',
  '16399-0114-155895': 'SS-01',
  '155657539-2-2017': 'SSII-01',
  '150263-8716': 'ST GEORGES-01',
  '52573-29-324998': 'STARBUCKS-01',
  '155707729-2-2021': 'STARLINK-01',
  '8-260-1024': 'SUEGRA',
  '1542377-1-655898': 'SUGAVEL-01',
  '350751-1-20885': 'SUMARSE-01',
  '826-433-100292': 'SUMINISTROS-01',
  '8-704-108': 'SUMINISTROS-02',
  '1823691-1-709432': 'SUNLY-01',
  '12561-16-124551': 'SUPER BARU-01',
  '8-528-2184': 'SUPER CENTRO-01',
  '842775-1-503778': 'SUPER CENTRO-02',
  '8-820-2055': 'SUPER CENTRO-03',
  '155621758-2-2016': 'SUPER CENTRO-04',
  '47497-89-307311': 'SUPER-01',
  '9-746-324': 'SUPER-02',
  '155725977-2-2022': 'SUPER-03',
  '30815-22-240563': 'SUPERMERCADOS-01',
  '257643-1-404101': 'SUPREME-01',
  '227866-1-399456': 'SUSHI-01',
  '155670079-2-2018': 'SUVLAS S,A',
  '8-754-1465': 'SYS-01',
  '60992-1-372519': 'SYSTEX-01',
  '39871-29-277250': 'T.U.A.P-01',
  '60899-63-347952': 'TAKUJI-01',
  '9321-131-95539': 'TALLER-01',
  '2529479-1-823039': 'TALLER-02',
  '8-702-1686': 'TALLER-03',
  '15572-1-366220': 'TALLER-04',
  '8-872-2426': 'TALLER-05',
  '7-706-1353': 'TALLER-06',
  '337-297-72453': 'TAMBOR-01',
  '902679-1-515225': 'TAMEK-01',
  '8-712-451': 'TANISHA-01',
  '62137-36-351360': 'TARGET-01',
  '8-739-1707': 'TATIANA-01',
  '1735399-1-693298': 'TAVSA',
  '2072194-1-751715': 'TECBITE-01',
  '1058730-1-549317': 'TECNI-01',
  '2198621-1-772918': 'TECNIBAS-01',
  '8-876-506': 'TECNOBOX-01',
  '1654682-1-676378': 'TECNOLOGIA-01',
  '1020942-1-540436': 'TECNOLOGIA-02',
  '858922-1-506773': 'TECSAN-01',
  '8-500-385': 'TEJEDOR-01',
  '16399-114-155895': 'TELECOMUNICACIONES-0',
  '2309294-1-791085': 'TELEMETRIX-01',
  '30387-20-238537': 'TELERED-01',
  '155588866-2-2014': 'TENSACABLE-01',
  '1744155-1-694983': 'TERRA PILOS-01',
  '8-NT-1-13582': 'TESORERIA-01',
  '6947-101-77831': 'TESORO-03',
  '155694458-2-2020': 'TGP SOLUTIONS-01',
  '42-68-194': 'THE AMERICAN-01',
  '2593932-1-832766': 'THE CIRCLE-01',
  '1132-101-22991': 'THE LONDONER-01',
  '47708-87-308230': 'THE PAINT-01',
  '155620936-2-2016': 'THE PANAMA CLINIC',
  '1977598-1-736476': 'THE TWIST-01',
  '2349498-1-797505': 'THERMO-01',
  '1293639-1-603775': 'THRIFTY PANAMA',
  '2158079-1-766480': 'TIENDAS-01',
  '942869-1-524169': 'TITATIUM-01',
  '834-480-100956': 'TNT',
  '682073-1-464676': 'TODO A DOLAR-01',
  '2-731-749': 'TONY-01',
  '155713388-2-2021': 'TOOLS-01',
  '155696916-2-2020': 'TOOLS-02',
  '4-108-565': 'TORNERIA-01',
  'N-20-2214': 'TORNERIA-02',
  '64491-21-358118': 'TORNICENTRO-01',
  '155635781-2-2016': 'TORNILLERIA-01',
  '155635781-2-2016': 'TORNILLOS-01',
  '2138823-1-763352': 'TORNILLOS-02',
  '23024-191-205095': 'TORNILLOS-03',
  '1334036-1-613613': 'TORNISA-01',
  '1206864-1-583401': 'TORO-01',
  '155631871-2-2016': 'TORRE-01',
  '1659301-1-677326': 'TOTAL-01',
  '65219-63-360495': 'TOVA-01',
  '8-884-1822': 'TRAELOGISTIX-01',
  '2198203-1-772863': 'TRAMITES-01',
  '1000-331-112415C': 'TRANSPORTE-01',
  '9-701-122': 'TRANSPORTE-011',
  '775487-1-486563': 'TRANSPORTE-02',
  '792307-1-491849': 'TRANSPORTE-03',
  '792307-1-491849': 'TRANSPORTE-04',
  '2165861-1-767774': 'TRANSPORTE-05',
  '310365-1-411402': 'TRANSPORTE-06',
  '310365-1-411402': 'TRANSPORTE-07',
  '526032-1-439318': 'TRANSPORTE-08',
  '46065-93-302335': 'TRANSPORTE-09',
  '17123-12-161493': 'TRANSPORTE-10',
  '4-116-940': 'TRANSPORTE-11',
  '8-856-307': 'TRANSPORTE-12',
  '7-112-302': 'TRANSPORTE-13',
  '2361036-1-799305': 'TRANSPORTE-14',
  '802207-1-495322': 'TRANVIS-01',
  '1363515-1-620271': 'TRES-01',
  '1793765-1-704140': 'TRESCALINI-01',
  '1174419-1-576814': 'TRILLI-01',
  '155683124-2-2019': 'TROPICAL-01',
  '814345-1-498846': 'TROPIFUELS-01',
  '60949-1-372514': 'TROPIMAC-01',
  '56876-24-337263': 'TRULY-01',
  '2622258-1-836877': 'TSHIRT-01',
  '29355-52-234886': 'T-SHIRTS-01',
  '1570527-1-660612': 'TU ELECTRI-01',
  '39871-29-277250': 'TUAP-01',
  '679974-1-464306': 'TUCAN-01',
  'N-20-598': 'TUNGALAG-01',
  '2668003-1-843157': 'TWIST-01',
  '1151585-1-571890': 'UBER',
  '1091-157-111481': 'ULTRACOM',
  '25977-86-221692': 'UNION-01',
  '2260512-1-783203': 'UNIVERCELL-01',
  '1812163-1-707472': 'UNIVERSAL PACK-01',
  '155596491-2-2015': 'UNIVERSAL-01',
  '8-NT-1-12504': 'UNIVERSIDAD-01',
  '98407-1-378314': 'UNO-01',
  '37914-2-269514': 'UPS-01',
  '25977-86-221692': 'UTRAPEP-01',
  '155690286-2-2020': 'UXMAL-01',
  '630-483-123250': 'VA & VEN-01',
  '630-483-123250': 'VA & VEN-02',
  '278643-1-407080': 'VALIDREEF-01',
  '155689280-2-2019': 'VAPRO-01',
  '38682-54-272473': 'VENDELA-01',
  '1742685-1-694660': 'VENTANA-01',
  170655754: 'VERO-01',
  '1079368-1-554067': 'VIA ALERTA-01',
  '16480-267-156481': 'VIBOR-01',
  '8-771-1729': 'VICTOR J. PEREZ',
  '4-248-137': 'VICTOR V-01',
  'E-8-165732': 'VICTORIA-01',
  '2153805-1-765825': 'VINOTECA-01',
  '8-986-1846': 'VIVIANA-01',
  '1556128572-2015': 'W BOX-01',
  '2045129-1-747045': 'WARREN-01',
  '2045129-1-747045': 'WARREN-02',
  '7-702-573': 'WEB-01',
  '8-864-292': 'WEI KIN-01',
  'N-21-889': 'WEIQUN-01',
  '1717099-1-689550': 'WENDYS-01',
  '155705933-2-2021': 'WENDYS-02',
  '1736650-1-693508': 'WEST-01',
  '8-950-1350': 'WILSON-01',
  '2692871-1-847041': 'WYNN-01',
  '8-219-159': 'XIOMARA-01',
  '155616651-2-2015': 'XPERTISE-01',
  '155726585-2-2022': 'YACHT CLUB-01',
  '8-859-1313': 'YARELIS-01',
  '8-882-2060': 'YATZURY-01',
  '20096229-1-741594': 'YAYO-01',
  '8-876-1820': 'YEIMY-01',
  '8-527-1547': 'YISELL-01',
  '8-701-304': 'YOLANI -01',
  '963942-1-528502': 'YOYTEC-01',
  'N-20-188': 'YU-01',
  'N-20-2499': 'YUN-01',
  '8-97375': 'YUTING-01',
  '8-246-135': 'ZAIDA-01',
  '155668678-2-2018': 'ZARA-01',
  '8-486-659': 'ZELMAR-01',
  '155604600-2-2015': 'ZONA-01',
};
