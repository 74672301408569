import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, List, message } from 'antd';
import { LeftOutlined, RightOutlined, SendOutlined, WarningOutlined } from '@ant-design/icons';
import HeroAxios from '../../helpers/HeroAxios.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EmailInput from '../EmailComposer/EmailInput';

/**
 * Props:
 *  - visible: boolean (controls modal visibility)
 *  - onCancel: function (close modal callback)
 *  - allReminders: array of objects:
 *       [
 *         {
 *           client_name: "Bocao",
 *           emails: ["miguel@herofacturas.com"],
 *           obligations_by_source: {
 *             "CSS": [
 *               { description: "SIPE (pago) diciembre", due_date: "2025-01-31...", source: "CSS" }
 *               ...
 *             ],
 *             "MUPA": [...],
 *           }
 *         },
 *         ...
 *       ]
 *  - API_domain: string (e.g. "https://www.herofacturas.com/")
 */
const ObligationsReminderReviewModal = ({
    visible,
    onCancel,
    allReminders = [],
    API_domain = '',
}) => {
    // -- NEW State: let's keep track of each reminder's fields & status --
    const [remindersState, setRemindersState] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [sendingAll, setSendingAll] = useState(false);
    const [cancelRequested, setCancelRequested] = useState(false);

    // -- Initialize local state on mount (or on changes in allReminders) --
    useEffect(() => {
        // Map each item so we can store "to" (list), "subject", "body", and "sent"
        const initialized = allReminders.map((rem) => {
            const toAsList = Array.isArray(rem.emails) ? rem.emails : [];
            return {
                ...rem,
                to: toAsList,
                subject: `Recordatorio de Obligaciones: ${rem.client_name}`,
                body: buildDefaultBody(rem),
                sent: false,
            };
        });
        setRemindersState(initialized);
        setCurrentIndex(0);
    }, [allReminders]);

    // -- Whenever currentIndex changes, update the form fields --
    useEffect(() => {
        if (remindersState[currentIndex]) {
            form.setFieldsValue({
                to: remindersState[currentIndex].to,
                subject: remindersState[currentIndex].subject,
                body: remindersState[currentIndex].body,
            });
        }
    }, [currentIndex, remindersState, form]);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(amount);
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        });
    };

    function buildDefaultBody(reminderItem) {
        // Helper to format dates in "31 de Enero del 2025" style
        function formatDateSpanishLong(dateStr) {
            return new Date(dateStr).toLocaleDateString('es-ES', {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            }).replace(' de ', ' del '); // Replace last "de" with "del" for year
        }

        let summaryText = '<p style="font-family: Arial, sans-serif; font-size: 14px; margin: 12px 0;">Resumen:</p>';
        let detailedText = '';
        let totalAllSources = 0;

        // Build summary and detailed sections
        Object.entries(reminderItem.obligations_by_source || {}).forEach(([source, obs]) => {
            let sourceTotal = 0;
            const sortedObs = [...obs].sort(
                (a, b) => new Date(a.due_date) - new Date(b.due_date)
            );

            // Get earliest and latest dates for this source
            const dates = sortedObs.map(ob => new Date(ob.due_date));
            const earliestDate = formatDateSpanishLong(Math.min(...dates));
            const latestDate = formatDateSpanishLong(Math.max(...dates));

            // Calculate source total
            sourceTotal = sortedObs.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
            totalAllSources += sourceTotal;

            // Add to summary
            summaryText += `
              <div style="font-family: Arial, sans-serif; font-size: 14px; margin: 8px 0; padding-left: 20px;">
                ${source} - ${sortedObs.length} obligación(es)
                <br/>Monto Total: $${sourceTotal.toFixed(2)}
                <br/>Período: ${earliestDate}${earliestDate !== latestDate ? ` al ${latestDate}` : ''}
              </div>
            `;

            // Build detailed section
            detailedText += `
              <h4 style="font-family: Arial, sans-serif; font-size: 14px; margin: 12px 0 6px; text-transform: uppercase;">
                ${source}
              </h4>
              <div style="font-family: Arial, sans-serif; font-size: 14px; margin: 0 0 14px;">
            `;

            // Build each obligation line item
            sortedObs.forEach((item, index) => {
                const description = item.description || '';
                const amount = parseFloat(item.amount || 0);
                const formattedDate = formatDateSpanishLong(item.due_date);

                detailedText += `
                  <div style="margin-bottom: 12px; padding-left: 20px;">
                    ${index + 1}. ${description}<br/>
                    Monto: $${amount.toFixed(2)}<br/>
                    Fecha de vencimiento: ${formattedDate}
                  </div>
                `;
            });

            detailedText += `
              </div>
              <p style="font-family: Arial, sans-serif; font-size: 14px; margin: 4px 0 12px;">
                Total Pendiente en ${source}: $${sourceTotal.toFixed(2)}
              </p>
            `;
        });

        // Add grand total to summary if multiple sources
        const numSources = Object.keys(reminderItem.obligations_by_source || {}).length;
        if (numSources > 1) {
            summaryText += `
              <div style="font-family: Arial, sans-serif; font-size: 14px; margin: 8px 0; padding-left: 20px;">
                <strong>Total General: $${totalAllSources.toFixed(2)}</strong>
              </div>
            `;
        }

        // Count total obligations across all sources
        const totalObligations = Object.values(reminderItem.obligations_by_source || {})
            .reduce((sum, obs) => sum + obs.length, 0);

        // Construct the final email body
        return `
          <p style="font-family: Arial, sans-serif; font-size: 14px; margin-top: 0;">
            Estimados ${reminderItem.client_name},
            <br/><br/>
            Le presentamos a continuación ${totalObligations === 1 ? 'el detalle de su obligación pendiente' : 'un resumen y detalle de sus obligaciones pendientes'}:
          </p><br>

          ${totalObligations > 1 ? `
            <p style="font-family: Arial, sans-serif; font-size: 14px; margin: 24px 0 14px; color: #2c3e50; border-bottom: 2px solid #eee; padding-bottom: 8px;">
              Resumen:
            </p><br>

            ${Object.entries(reminderItem.obligations_by_source || {}).map(([source, obs]) => {
            const sourceTotal = obs.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
            const obligationCount = obs.length;
            const obligationText = obligationCount === 1 ? 'obligación' : 'obligaciones';

            return `
                <div style="font-family: Arial, sans-serif; font-size: 14px; margin: 14px 0; padding-left: 20px;">
                  ${source} - ${obligationCount} ${obligationText}
                  <br/>
                  Monto Total: $${sourceTotal.toFixed(2)}
                  <br/>
                  Período: ${formatDateSpanishLong(Math.max(...obs.map(ob => new Date(ob.due_date))))}
                </div>
              `;
        }).join('')}

          ` : ''}

          ${totalObligations > 1 ? `
            <br><p style="font-family: Arial, sans-serif; font-size: 14px; margin: 32px 0 14px; color: #2c3e50; border-bottom: 2px solid #eee; padding-bottom: 8px;">
              Detalle:
            </p><br>
          ` : ''}

          ${detailedText}

         <br> <p style="font-family: Arial, sans-serif; font-size: 14px; margin-top: 32px;">
            Cualquier consulta o asistencia que necesite, por favor contáctenos.
          </p>

          <p style="font-family: Arial, sans-serif; font-size: 14px; margin: 32px 0 24px;">
            Atentamente,<br/>
            Hero
          </p>
        `;
    }

    // -- Handle sending of email for the currently selected client --
    const handleSend = () => {
        form.validateFields().then((values) => {
            setLoading(true);
            const formData = new FormData();
            formData.append('to', values.to.join(','));
            formData.append('subject', values.subject);
            formData.append('body', values.body);
            formData.append('from_address', 'contabilidad@herofacturas.com');

            HeroAxios({
                method: 'post',
                url: `${API_domain}SendHeroEmail`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(() => {
                    message.success(`Correo enviado a: ${values.to.join(',')}`);
                    setRemindersState((prev) => {
                        const updated = [...prev];
                        updated[currentIndex].sent = true;
                        return updated;
                    });
                })
                .catch((err) => {
                    console.error('Error:', err);
                    message.error('No se pudo enviar el correo.');
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleSendAll = () => {
        Modal.confirm({
            title: '¿Enviar todos los recordatorios?',
            content: `Se enviarán ${remindersState.length} recordatorios. ¿Está seguro?`,
            okText: 'Sí, enviar todos',
            cancelText: 'Cancelar',
            onOk: async () => {
                setLoading(true);
                setSendingAll(true);

                // Create an informational modal
                const modal = Modal.info({
                    title: 'Enviando recordatorios...',
                    content: 'El proceso está en curso.',
                    okButtonProps: { style: { display: 'none' } }, // Hide the OK button
                    keyboard: false,
                    maskClosable: false,
                });

                try {
                    for (let i = 0; i < remindersState.length; i++) {
                        const reminder = remindersState[i];
                        const formData = new FormData();
                        formData.append('to', reminder.to.join(','));
                        formData.append('subject', reminder.subject);
                        formData.append('body', reminder.body);
                        formData.append('from_address', 'contabilidad@herofacturas.com');

                        await HeroAxios({
                            method: 'post',
                            url: `${API_domain}SendHeroEmail`,
                            data: formData,
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });

                        setRemindersState((prev) => {
                            const updated = [...prev];
                            updated[i].sent = true;
                            return updated;
                        });

                        // Update modal content with progress
                        modal.update({
                            content: `Enviando ${i + 1} de ${remindersState.length} recordatorios...`
                        });
                    }
                    message.success('Todos los recordatorios han sido enviados');
                } catch (error) {
                    console.error('Error:', error);
                    message.error('Hubo un error al enviar los recordatorios');
                } finally {
                    setLoading(false);
                    setSendingAll(false);
                    modal.destroy();
                }
            },
            onCancel() {
                setLoading(false);
                setSendingAll(false);
            },
        });
    };

    // -- For convenience, store the current item from state --
    const currentItem = remindersState[currentIndex] || {};

    // -- Render a side list of allReminders with send status. Clicking picks one. --
    const renderLeftNav = () => {
        return (
            <div
                style={{
                    width: 250,
                    borderRight: '1px solid #ddd',
                    padding: '8px',
                    overflowY: 'auto',
                }}
            >
                <h4 style={{ fontSize: '13px' }}>Clientes:</h4>
                <List
                    size="small"
                    dataSource={remindersState}
                    renderItem={(item, idx) => {
                        const isActive = idx === currentIndex;
                        return (
                            <List.Item
                                onClick={() => setCurrentIndex(idx)}
                                style={{
                                    cursor: 'pointer',
                                    background: isActive ? '#f0f0f0' : 'transparent',
                                    marginBottom: 4,
                                    padding: 4,
                                    fontSize: '12px'
                                }}
                            >
                                <span style={{ fontWeight: 500 }}>{item.client_name}</span>
                                {item.sent && (
                                    <span style={{ color: 'green', marginLeft: 6 }}>
                                        (Enviado)
                                    </span>
                                )}
                            </List.Item>
                        );
                    }}
                />
            </div>
        );
    };

    const renderEmailBody = () => (
        <Form.Item
            name="body"
            rules={[{ required: true, message: 'Ingrese cuerpo del correo' }]}
            style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
        >
            <ReactQuill
                theme="snow"
                style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                modules={{
                    toolbar: [
                        [{ header: [1, 2, 3, false] }],
                        ['bold', 'italic', 'underline'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['link'],
                        ['clean']
                    ],
                }}
            />
        </Form.Item>
    );

    return (
        <Modal
            title="Revisar Recordatorios"
            visible={visible}
            onCancel={onCancel}
            width={1200}
            style={{ top: 20 }}
            bodyStyle={{ maxHeight: '90vh', overflow: 'hidden' }}
            maskClosable={false}
            footer={null}
        >
            <div style={{ display: 'flex', height: '700px' }}>
                {renderLeftNav()}

                <div style={{ flex: 1, padding: '0 14px', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '16px' }}>
                        <p style={{ margin: '0' }}>Cliente: {currentItem.client_name}</p>
                    </div>

                    <Form
                        form={form}
                        layout="horizontal"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                        }}
                    >
                        <Form.Item
                            label="Para:"
                            name="to"
                            rules={[{ required: true, message: 'Ingrese al menos un destinatario' }]}
                            style={{ marginBottom: '8px' }}
                        >
                            <EmailInput />
                        </Form.Item>

                        <Form.Item
                            label="Asunto:"
                            name="subject"
                            rules={[{ required: true, message: 'Ingrese asunto' }]}
                            style={{ marginBottom: '8px' }}
                        >
                            <Input placeholder="Asunto del correo" />
                        </Form.Item>

                        <div style={{ flex: 1, overflow: 'auto', maxHeight: '450px' }}>
                            {renderEmailBody()}
                        </div>
                    </Form>

                    <div style={{
                        marginTop: '16px',
                        textAlign: 'right',
                        borderTop: '1px solid #f0f0f0',
                        paddingTop: '16px',
                        position: 'sticky',
                        bottom: 0,
                        background: 'white',
                        zIndex: 1
                    }}>
                        <Button
                            icon={<WarningOutlined />}
                            onClick={handleSendAll}
                            style={{ marginRight: 8 }}
                            loading={loading}
                        >
                            Enviar Todos
                        </Button>
                        <Button
                            icon={<LeftOutlined />}
                            disabled={currentIndex === 0}
                            onClick={() => setCurrentIndex(currentIndex - 1)}
                            style={{ marginRight: 8 }}
                        >
                            Anterior
                        </Button>
                        <Button
                            icon={<RightOutlined />}
                            disabled={currentIndex >= remindersState.length - 1}
                            onClick={() => setCurrentIndex(currentIndex + 1)}
                            style={{ marginRight: 8 }}
                        >
                            Siguiente
                        </Button>
                        <Button
                            key="send"
                            type="primary"
                            icon={<SendOutlined />}
                            loading={loading}
                            onClick={handleSend}
                        >
                            Enviar
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ObligationsReminderReviewModal; 