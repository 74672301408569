// CommitViewButton.js

import React from 'react';
import { Tooltip } from 'antd';
import { FormattedUSD } from './FormattedUSD';

// Helper function to decide background color
// (similar logic to getBaseBgColor in RelationshipButton)
function getCommitBgColor(auditUsersfacturasId) {
  // If it's an integer => user => greenish
  if (Number.isInteger(parseInt(auditUsersfacturasId, 10))) {
    return 'rgba(0, 128, 0, 0.16)';
  }
  // If it's 'AUTONOMOUS_AGENT' => purpleish
  if (auditUsersfacturasId === 'AUTONOMOUS_AGENT') {
    return 'rgba(128, 0, 128, 0.12)';
  }
  // Otherwise, no background
  return 'transparent';
}

// Helper function to format date (in the same style as RelationshipButton)
function formatDate(timestamp) {
  const date = new Date(timestamp);
  // Adjust time to your needs
  date.setHours(date.getHours() - 5);
  return date.toISOString().split('T')[0];
}

const CommitViewButton = ({ record, onClick, displayText = '✅' }) => {
  // 1) Identify the "entries" relationships—these represent the commit
  const commitRelations =
    record.relationship?.filter(
      (rel) => rel.movement_table_name === 'entries'
    ) || [];

  // 2) For demonstration, we can just look at the first entry-relationship:
  const { audit_usersfacturas_id, audit_timestamp } =
    commitRelations.length > 0 ? commitRelations[0] : {};

  // 3) Decide background color based on who committed
  const baseBgColor = getCommitBgColor(
    audit_usersfacturas_id
      ? audit_usersfacturas_id
      : record.audit_usersfacturas_id
  );

  // 4) Build tooltip string: who and when
  let tooltipText = 'Commit registrado';
  if (audit_usersfacturas_id) {
    if (audit_usersfacturas_id === 'AUTONOMOUS_AGENT') {
      tooltipText = `Commit creado por Agente Autónomo el ${formatDate(
        audit_timestamp
      )}`;
    } else {
      tooltipText = `Commit creado el ${formatDate(audit_timestamp)}`;
    }
  }

  return (
    <Tooltip title={tooltipText} mouseEnterDelay={1}>
      <span
        style={{
          color: 'var(--purple-dark)',
          cursor: 'pointer',
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: '4px',
          marginRight: '8px',
          transition: 'background-color 0.3s, color 0.3s',
          backgroundColor: baseBgColor,
          fontSize: '14px',
        }}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.(); // the parent can pass in openTransactionModal(record) or similar
        }}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = 'var(--purple-light)';
          e.target.style.color = 'white';
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = baseBgColor;
          e.target.style.color = 'var(--purple-dark)';
        }}
      >
        {displayText === '✅' ? (
          displayText
        ) : (
          <FormattedUSD total={displayText} />
        )}
      </span>
    </Tooltip>
  );
};

export { CommitViewButton };
