import { Button, DatePicker } from 'antd';
import styled from 'styled-components/macro';
import React, { useState } from 'react';
import moment from 'moment'; // Make sure you're importing moment here
import { getPreviousThreeMonths, getLastYear } from '../utils.js';

const { RangePicker } = DatePicker;

function DateFilterDropdown(props) {
  const { selectedKeys, setSelectedKeys, confirm, clearFilters } = props;
  const [dateRange, setDateRange] = useState(selectedKeys);
  const [selectedMonth, setSelectedMonth] = useState(null);

  return (
    <div style={{ padding: 8 }}>
      <div style={{ marginBottom: 4 }}>
        <span style={{ marginLeft: 10, marginRight: 3 }}>Filtros rápidos:</span>
        {getPreviousThreeMonths().map((monthName, index) => {
          // Calculate the date range for each "previous month"
          const thisMonthStart = moment().startOf('month');
          // For index = 0 → current month
          // For index = 1 → last month
          // For index = 2 → two months ago
          const targetMonthStart = thisMonthStart
            .clone()
            .subtract(index, 'months')
            .startOf('month');
          const targetMonthEnd = thisMonthStart
            .clone()
            .subtract(index, 'months')
            .endOf('month');
          const monthRange = [
            targetMonthStart.clone().startOf('day'),
            targetMonthEnd.clone().endOf('day'),
          ];

          return (
            <Button
              key={monthName}
              type={monthName === selectedMonth ? 'primary' : 'secondary'}
              onClick={() => {
                // Set this month’s date range
                setDateRange(monthRange);
                setSelectedKeys([monthRange]);
                setSelectedMonth(monthName);
                confirm();
              }}
              style={{ margin: 3 }}
            >
              {monthName}
            </Button>
          );
        })}
        <Button
          type={'lastYear' === selectedMonth ? 'primary' : 'secondary'}
          onClick={() => {
            const lastYearRange = getLastYear();
            const formattedRange = [
              lastYearRange[0].startOf('day'),
              lastYearRange[1].endOf('day'),
            ];
            setDateRange(formattedRange);
            setSelectedKeys([formattedRange]);
            setSelectedMonth('lastYear');
            confirm();
          }}
          style={{ margin: 3 }}
        >
          Último año
        </Button>
      </div>
      <RangePicker
        value={dateRange}
        onChange={(val) => {
          val[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
          val[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
          // val[1].set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
          setSelectedKeys([val]);
          setDateRange(val);
          setSelectedMonth(null);
        }}
      />
      <FilterButton
        type='primary'
        onClick={() => confirm()}
        size='small'
        style={{ width: 90 }}
      >
        Filtrar
      </FilterButton>
      <FilterButton
        onClick={() => {
          clearFilters();
          confirm();
          setSelectedKeys(null);
          setDateRange([]);
          setSelectedMonth(null);
        }}
        size='small'
        style={{ width: 90 }}
      >
        Reset
      </FilterButton>
    </div>
  );
}

const FilterButton = styled(Button)`
  margin-left: 7px;
`;

export { DateFilterDropdown };
