import { useContext, useState } from 'react';
import { AccountingClientSelect } from '../Accounting/AccountingClientSelect.js';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';

function AdminGetAccountingClientUserLoginLink(props) {
  const auth = useContext(authContext);

  function getUserLoginLink(value) {
    axios({
      method: 'post',
      url: props.API_domain + 'adminGetAccountingClientUserLoginLink',
      auth: {
        username: auth.adminEmail ? auth.adminEmail : auth.email,
        password: auth.adminToken ? auth.adminToken : auth.token,
      },
      data: {
        accounting_client_id: value,
      },
    })
      .then(function (response) {
        let loginLink = response.data;
  
        // 1) Pathname + search includes query params
        //    e.g. "/paperMovementsView?tabSelected=XXX"
        const fullPathAndSearch = window.location.pathname + window.location.search;

        // 2) Encode it so the query remains valid 
        loginLink += `&endpoint=${encodeURIComponent(fullPathAndSearch)}`;

        // Now redirect
        window.location.href = loginLink;
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        console.log(response);
      });
  }

  return (
    <AccountingClientSelect
      API_domain={props.API_domain}
      onAccountingClient={getUserLoginLink}
      noPadding={true}
      noWidth={true}
      placement='topLeft'
      selectedAccountingClient={auth.accountingClientsId}
    />
  );
}

export { AdminGetAccountingClientUserLoginLink };
