import React, { useContext, useState } from 'react';
import {
  Button,
  Layout,
  Dropdown,
  Row,
  Col,
  message,
  notification,
  Affix,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';
import { BankTable } from './BankTable.js';
import { SOTSelect } from './SOTSelect.js';
import { TableDataDownloader } from './TableDataDownloader.js';
import { SOTUploader } from './SOTUploader.js';
import { Refresh } from './Refresh.js';
import { MassEditButtonAndModalForSotTxns } from './MassEditButtonAndModalForSotTxns.js';
import { BankZohoDownloader } from './BankZohoDownloader.js';
import { BankMassAutoCategorizer } from './BankMassAutoCategorizer.js';
import { BankSimpleDownloader } from './BankSimpleDownloader.js';
import { authContext } from '../ProvideAuth.js';
import './styles.css';
import CommitSotTransactionsButton from './CommitSotTransactionsButton';
import EngineButton from './Engine/EngineButton.js';
import HeroAxios from '../helpers/HeroAxios.js';
import { ProgressOverview } from './ProgressOverview';

const { Content } = Layout;

function BankView(props) {
  const auth = useContext(authContext);
  const [currentData, setCurrentData] = useState([]);
  const [selectedSot, setSelectedSot] = useState(0);
  const [uploadChanged, setUploadChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedSotTxnsInMassEdit, setSelectedSotTxnsInMassEdit] = useState(
    []
  );
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [autoClassifyLoading, setAutoClassifyLoading] = useState(false);
  const [uncommittedBankRowsCount, setUncommittedBankRowsCount] = useState(0);
  const [categorizedBankRowsCount, setCategorizedBankRowsCount] = useState(0);
  const [highConfidenceBankRowsCount, setHighConfidenceBankRowsCount] =
    useState(0);
  const [relatedBankRowsCount, setRelatedBankRowsCount] = useState(0);
  // console.log(selectedSotTxnsInMassEdit);

  const handleAutoClassify = () => {
    // message.info('Pendiente desarrollo');

    setAutoClassifyLoading(true);

    HeroAxios({
      method: 'post',
      url: 'autoClassifySotTxns',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        client_id: props.clientId,
      },
    })
      .then((response) => {
        setAutoClassifyLoading(false);
        if (response.status === 200 && response.data.success) {
          notification.success({
            message: 'Autoclasificación completada',
            description: `Se clasificaron ${response.data.number_classified} / ${response.data.number_expenses_to_classify} movimientos.`,
            placement: 'topRight',
          });
          message.success('Movimientos clasificados correctamente.');
          // fetchExpenses(); todo! use uploadchanged state variable
          setUploadChanged(!uploadChanged);
        }
      })
      .catch((error) => {
        setAutoClassifyLoading(false);
        console.error('Error auto-classifying movements:', error);

        // Check if the error response exists
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          // Handle specific error messages from the backend
          if (
            error.response.data.error ===
            'Not enough movements with accounts to use the model'
          ) {
            message.error(
              'Necesitas al menos 10 movimientos clasificados manualmente para usar el modelo.'
            );
          } else {
            message.error(
              'Error al clasificar los movimientos: ' +
                error.response.data.error
            );
          }
        } else {
          // Handle generic errors
          message.error(
            'Error al clasificar los movimientos: ' + error.message
          );
        }
      });
  };

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        // borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <AffixDiv>
        {auth.adminEmail && (
          <ProgressOverview
            totalUncommitted={uncommittedBankRowsCount}
            categorizedCount={categorizedBankRowsCount}
            highConfidenceCount={highConfidenceBankRowsCount}
            relatedCount={relatedBankRowsCount}
          />
        )}
        <Affix offsetTop={48}>
          <div style={{ backgroundColor: 'white', padding: '14px' }}>
            <Row>
              <Col span={8} style={{ textAlign: 'left' }}>
                {/* <div
                  style={{
                    textAlign: 'left',
                    fontSize: 36,
                    fontWeight: 'bold',
                  }}
                >
                  Banco
                </div> */}
                <Refresh
                  onClick={() => setUploadChanged(!uploadChanged)}
                  spin={loading}
                />
                <SOTSelect
                  API_domain={props.API_domain}
                  selectedSot={selectedSot}
                  setSelectedSot={setSelectedSot}
                  type_of_mm={props.type_of_mm}
                />
              </Col>
              <Col span={16} style={{ textAlign: 'right' }}>
                <ButtonDiv>
                  <MassEditButtonAndModalForSotTxns
                    API_domain={props.API_domain}
                    selectedSotTxnsInMassEdit={selectedSotTxnsInMassEdit}
                    setSelectedSotTxnsInMassEdit={setSelectedSotTxnsInMassEdit}
                    submittedToggle={submittedToggle}
                    setSubmittedToggle={setSubmittedToggle}
                    accountOptions={accountOptions}
                  />
                </ButtonDiv>
                {/* <ButtonDiv>
                  <BankMassAutoCategorizer
                    API_domain={props.API_domain}
                    selectedSot={selectedSot}
                    selectedSotTxnsInMassEdit={selectedSotTxnsInMassEdit}
                    setSelectedSotTxnsInMassEdit={setSelectedSotTxnsInMassEdit}
                    submittedToggle={submittedToggle}
                    setSubmittedToggle={setSubmittedToggle}
                  />
                </ButtonDiv> */}
                {auth.adminEmail && (
                  <>
                    <ButtonDiv
                      style={{
                        visibility:
                          Object.keys(selectedSot).length === 0
                            ? 'hidden'
                            : 'visible',
                      }}
                    >
                      <SOTUploader
                        API_domain={props.API_domain}
                        selectedSot={selectedSot}
                        uploadChanged={uploadChanged}
                        setUploadChanged={setUploadChanged}
                      />
                    </ButtonDiv>
                    <ButtonDiv>
                      <EngineButton
                        text='Autoclasificar 🪄 🦸‍♂️'
                        disabled={autoClassifyLoading}
                        loading={autoClassifyLoading}
                        onClick={handleAutoClassify}
                        popOverContent={
                          <>
                            <div style={{ fontWeight: 500 }}>
                              Se autoclasificarán todos los GASTOS (débitos) de
                              BANCO y TARJETA que no tengan cuenta contable
                              asignada.
                            </div>
                            <br />
                            <div>
                              Se necesitan al menos 10 gastos con cuenta
                              contable asignada para que la magia funcione 🪄.
                            </div>
                            <br />
                            <div>
                              Se usará de referencia:
                              <ol>
                                <li>Todas las cuentas contables</li>
                                <li>
                                  Todos los gastos que ya tienen cuenta contable
                                </li>
                              </ol>
                            </div>
                            <div>⌛ Demorará unos segundos.</div>
                            <br />
                            <div>
                              Cuidado: por ahora se usan todos los gastos
                              categorizados, sin importar la confianza en la
                              clasificación.
                            </div>
                            <br />
                            <div style={{ fontWeight: 500 }}>
                              Si un débito está relacionado con una FE
                              clasificada, se usará la cuenta contable de la FE.
                              <br /> Si no, si el débito está relacionado a un
                              gasto de bot, se usará la cuenta contable del
                              gasto de bot.
                            </div>
                          </>
                        }
                      />
                    </ButtonDiv>
                    <ButtonDiv>
                      <CommitSotTransactionsButton
                        selectedSotTxnsInMassEdit={selectedSotTxnsInMassEdit}
                        API_domain={props.API_domain}
                        auth={auth}
                        setUploadChanged={setUploadChanged}
                        uploadChanged={uploadChanged}
                        setLoading={setLoading}
                        loading={loading}
                        clientId={props.clientId}
                        clientAccounts={props.clientAccounts}
                        setSelectedRowsForMassEdit={
                          setSelectedSotTxnsInMassEdit
                        }
                      />
                    </ButtonDiv>
                  </>
                )}
                {/* <ButtonDiv>
                  <BankSimpleDownloader data={currentData} />
                </ButtonDiv> */}
                {/* New "Descargar" dropdown using the latest menu syntax in Ant Design */}
                <ButtonDiv>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: 'zoho-downloader',
                          label: (
                            <BankZohoDownloader
                              data={currentData}
                              selectedSot={selectedSot}
                              accountOptions={accountOptions}
                              accountantOnly={true}
                            />
                          ),
                        },
                        {
                          key: 'table-downloader',
                          label: (
                            <TableDataDownloader
                              data={currentData}
                              accountantOnly={true}
                            />
                          ),
                        },
                        {
                          key: 'simple-downloader',
                          label: <BankSimpleDownloader data={currentData} />,
                        },
                      ],
                    }}
                    trigger={['hover']}
                  >
                    <Button>
                      Descargar <DownOutlined />
                    </Button>
                  </Dropdown>
                </ButtonDiv>
              </Col>
            </Row>
          </div>
        </Affix>
        <BankTable
          API_domain={props.API_domain}
          selectedSot={selectedSot}
          setCurrentData={setCurrentData}
          uploadChanged={uploadChanged}
          loading={loading}
          setLoading={setLoading}
          setSelectedSotTxnsInMassEdit={setSelectedSotTxnsInMassEdit}
          selectedSotTxnsInMassEdit={selectedSotTxnsInMassEdit}
          accountOptions={accountOptions}
          setAccountOptions={setAccountOptions}
          submittedToggle={submittedToggle}
          setUncommittedBankRowsCount={setUncommittedBankRowsCount}
          setCategorizedBankRowsCount={setCategorizedBankRowsCount}
          setHighConfidenceBankRowsCount={setHighConfidenceBankRowsCount}
          setRelatedBankRowsCount={setRelatedBankRowsCount}
        />
      </AffixDiv>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
  max-width: 1400px;
  margin: auto;
`;

const ButtonDiv = styled.div`
  text-align: right;
  display: inline-block;
  margin-left: 10px;
`;

export { BankView };
