import { Button, Form, Input, Modal, Select } from 'antd';
import axios from 'axios';
import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function AdminCreateSOT(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);

  useEffect(() => {
    if (visible) {
      getAccountingClientOptions();
    }
  }, [visible]);

  const getAccountingClientOptions = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: props.API_domain + 'getAccountingClients',
        auth: {
          username: auth.email,
          password: auth.token,
        },
      });
      const options = response.data.map((client) => (
        <Option key={client.id} value={client.id}>
          {client.name}
        </Option>
      ));
      setClientOptions(options);
    } catch (error) {
      console.error(
        'There was an error fetching the accounting clients',
        error
      );
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setSubmitting(true);

    const data = {
      name: values.name,
      zoho_name: values.zoho_name,
      client_id: values.client_id,
    };


    // Include config_id in settings if provided
    if (values.config_id) {
      data.settings = { config_id: values.config_id };
    }

    axios({
      method: 'post',
      url: props.API_domain + 'adminCreateSOT',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(() => {
        setVisible(false);
        setSubmitting(false);
        form.resetFields();
      })
      .catch((error) => {
        console.error('An error occurred while creating SOT', error);
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <Button type="secondary" onClick={showModal}>
        <ButtonSpan>Agregar SOT</ButtonSpan>
      </Button>
      <Modal
        title="Create Source of Truth"
        visible={visible}
        onCancel={handleCancel}
        onOk={form.submit}
        confirmLoading={isSubmitting}
        centered
      >
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please input the SOT name!' }]}
          >
            <Input placeholder="Enter SOT name" />
          </Form.Item>
          <Form.Item
            name="zoho_name"
            label="Zoho Name"
            rules={[{ required: true, message: 'Please input the Zoho SOT name!' }]}
          >
            <Input placeholder="Enter Zoho SOT name" />
          </Form.Item>
          <Form.Item
            name="client_id"
            label="Client"
            rules={[{ required: true, message: 'Please select the client!' }]}
          >
            <Select
              showSearch
              placeholder="Select a client"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {clientOptions}
            </Select>
          </Form.Item>

          {/* Optional fields below */}
          <Form.Item name="config_id" label="Config ID">
            <Input placeholder="Optional config ID" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

export { AdminCreateSOT };
