/********************************
 * AutoRelateEngineButton.jsx
 ********************************/
import React, { useState } from 'react';
import HeroAxios from '../../helpers/HeroAxios.js';
import { message } from 'antd';
import EngineButton from './EngineButton.js';

function AutoRelateEngineButton(props) {
  const [autoRelateLoading, setAutoRelateLoading] = useState(false);

  const handleAutoCreateRelationships = () => {
    setAutoRelateLoading(true);
    message.info('Se ha iniciado la creación de relaciones automática 🪄');
    HeroAxios.post('autoCreateRelationships', {
      // Note: ensure your endpoint expects "accounting_client_id"
      accounting_client_id: props.accountingClientsId,
    })
      .then(() => {
        setAutoRelateLoading(false);
        // message.success('Las relaciones se han creado correctamente.');
      })
      .catch((error) => {
        setAutoRelateLoading(false);
        message.error('Error creando relaciones automáticamente');
        console.error('Error creating relationships:', error);
      });
  };

  return (
    <EngineButton
      text='Auto relacionar 🪄🦸‍♂️'
      onClick={handleAutoCreateRelationships}
      loading={autoRelateLoading}
      // Popover content no longer relevant in this flow:
      popOverContent={null}
    />
  );
}

export { AutoRelateEngineButton };
