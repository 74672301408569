import React, { useState, useEffect } from 'react';
import {
  Button,
  message,
  Modal,
  Checkbox,
  Input,
  InputNumber,
  DatePicker,
  Table,
  Select,
  Divider,
  Spin
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import AccountingAccountSelect from './Accounting/AccountingAccountSelect'; // Ensure this component exists
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

function CommitSotTransactionsButton({
  selectedSotTxnsInMassEdit,
  API_domain,
  auth,
  setUploadChanged,
  uploadChanged,
  setLoading,
  loading,
  clientId,
  clientAccounts, // Ensure you pass clientAccounts as a prop
  setSelectedRowsForMassEdit,
}) {
  const [isCommitModalVisible, setIsCommitModalVisible] = useState(false);
  const [includeItbms, setIncludeItbms] = useState(false);
  const [selectedItbmsAccount, setSelectedItbmsAccount] = useState(null);
  const [transactionsData, setTransactionsData] = useState([]);

  // Fetch transaction entries when the modal is visible
  useEffect(() => {
    if (isCommitModalVisible) {
      fetchTransactionEntries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommitModalVisible, selectedItbmsAccount]);

  const fetchTransactionEntries = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${API_domain}/prepareSotTransactions`,
        {
          sot_transactions: selectedSotTxnsInMassEdit,
          include_itbms: includeItbms,
          itbms_account_id: includeItbms ? selectedItbmsAccount : null,
          client_id: clientId,
        },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );
      setTransactionsData(response.data.transactions);
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch transaction entries.');
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsCommitModalVisible(true);
  };

  const commitSotTransactions = async () => {
    setLoading(true);
    console.log(transactionsData);
    try {
      await axios.post(
        `${API_domain}/commitSotTransactions`,
        {
          transactions: transactionsData,
          client_id: clientId,
        },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );
      message.success('Transacciones subidas!');
      setUploadChanged(!uploadChanged);
      setLoading(false);
      setIsCommitModalVisible(false);
      setIncludeItbms(false);
      setSelectedItbmsAccount(null);
      setSelectedRowsForMassEdit([]);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to commit transactions. Please try again.';
      message.error(errorMessage);
      setLoading(false);
    }
  };

  const handleTransactionChange = (txnIndex, field, value) => {
    const updatedTransactions = [...transactionsData];
    updatedTransactions[txnIndex][field] = value;
    setTransactionsData(updatedTransactions);
  };

  const handleEntryChange = (txnIndex, entryKey, field, value) => {
    const updatedTransactions = [...transactionsData];
    const entryIndex = updatedTransactions[txnIndex].entries.findIndex(
      (entry) => entry.key === entryKey
    );
    if (entryIndex !== -1) {
      updatedTransactions[txnIndex].entries[entryIndex][field] = value;
      setTransactionsData(updatedTransactions);
    }
  };

  const addNewEntry = (txnIndex) => {
    const newEntry = {
      key: Date.now().toString(),
      account_id: null,
      amount: '',
      entry_type: 'D',
    };
    const updatedTransactions = [...transactionsData];
    const updatedEntries = [...updatedTransactions[txnIndex].entries, newEntry]; // Create new entries array
    updatedTransactions[txnIndex] = {
      ...updatedTransactions[txnIndex],
      entries: updatedEntries,
    };
    setTransactionsData(updatedTransactions);
  };

  const removeEntry = (txnIndex, entryKey) => {
    const updatedTransactions = [...transactionsData];
    updatedTransactions[txnIndex].entries = updatedTransactions[
      txnIndex
    ].entries.filter((entry) => entry.key !== entryKey);
    setTransactionsData(updatedTransactions);
  };

  const getEntryColumns = (txnIndex) => [
    {
      title: 'Cuenta',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => (
        <AccountingAccountSelect
          value={record.account_id}
          onChange={(value) =>
            handleEntryChange(txnIndex, record.key, 'account_id', value)
          }
          clientId={clientId}
          API_domain={API_domain}
          auth={auth}
          accounts={clientAccounts}
        />
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <InputNumber
          value={record.amount}
          onChange={(value) =>
            handleEntryChange(txnIndex, record.key, 'amount', value)
          }
          style={{ width: '100%' }}
        />
      ),
    },
    {
      title: 'Tipo de Entrada',
      dataIndex: 'entry_type',
      key: 'entry_type',
      render: (text, record) => (
        <Select
          value={record.entry_type}
          onChange={(value) =>
            handleEntryChange(txnIndex, record.key, 'entry_type', value)
          }
          style={{ width: 100 }}
        >
          <Select.Option value="D">Débito</Select.Option>
          <Select.Option value="C">Crédito</Select.Option>
        </Select>
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => removeEntry(txnIndex, record.key)}
        />
      ),
    },
  ];

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        disabled={loading || selectedSotTxnsInMassEdit.length === 0}
        style={{ fontWeight: 500 }}
      >
        Crear Diarios 🦸‍♂️
      </Button>
      <Modal
        title="Commit Transactions"
        visible={isCommitModalVisible}
        onOk={commitSotTransactions}
        confirmLoading={loading}
        onCancel={() => {
          setIsCommitModalVisible(false);
          setIncludeItbms(false);
          setSelectedItbmsAccount(null);
        }}
        width={800}
      >
        <div style={{ marginBottom: 16 }}>
          <Checkbox
            checked={includeItbms}
            onChange={(e) => setIncludeItbms(e.target.checked)}
          >
            Romper ITBMS
          </Checkbox>
        </div>
        {includeItbms && (
          <div style={{ marginBottom: 16 }}>
            <div>Cuenta ITBMS:</div>
            <AccountingAccountSelect
              value={selectedItbmsAccount}
              onChange={(value) => setSelectedItbmsAccount(value)}
              clientId={clientId}
              API_domain={API_domain}
              auth={auth}
              accounts={clientAccounts}
            />
          </div>
        )}
        {transactionsData.map((txnData, index) => (
          <div key={txnData.sot_transaction_id} style={{ marginBottom: 24 }}>
            <Divider style={{ 'border-color': 'var(--grey-dark)' }} />
            <Input
              placeholder="Description"
              value={txnData.description}
              onChange={(e) =>
                handleTransactionChange(index, 'description', e.target.value)
              }
              style={{ marginBottom: 8 }}
            />
            <DatePicker
              value={
                txnData.transaction_date
                  ? moment(txnData.transaction_date)
                  : null
              }
              onChange={(date) =>
                handleTransactionChange(
                  index,
                  'transaction_date',
                  date ? date.format('YYYY-MM-DD') : null
                )
              }
              style={{ marginBottom: 16 }}
            />
            <Table
              dataSource={txnData.entries}
              columns={getEntryColumns(index)}
              rowKey="key"
              pagination={false}
            />
            <Button
              type="dashed"
              onClick={() => addNewEntry(index)}
              style={{ marginTop: 16 }}
            >
              Agregar Entrada
            </Button>
          </div>
        ))}
      </Modal>
    </>
  );
}

export default CommitSotTransactionsButton;
