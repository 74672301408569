// PaperMovementsView.js

import React, { useContext, useEffect, useState } from 'react';
import { Affix, Layout, message, Button, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';

import { BankView } from './BankView.js';
import { ExpensesView } from './ExpensesView.js';
import { InvuTable } from './InvuTable.js';
import { FETable } from './FETable.js';
import { PedidosYaTable } from './PedidosYaTable.js';
import { BotExpensesTable } from './BotExpensesTable.js';
import { authContext } from '../ProvideAuth.js';

const { Content } = Layout;

function PaperMovementsView(props) {
  const auth = useContext(authContext);

  // React Router v5 hooks
  const location = useLocation();
  const history = useHistory();

  // Parse "tab" from the current URL query params
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || 'feRecibidas';

  const [activeComponent, setActiveComponent] = useState(initialTab);
  const [enabledSites, setEnabledSites] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [clientAccounts, setClientAccounts] = useState([]);

  // ============= Fetch Client Info (example) =============
  useEffect(() => {
    const fetchClientInfo = async () => {
      try {
        // First, get the client info
        const clientInfoResponse = await axios({
          method: 'post',
          url: `${props.API_domain}getUserAccountingClientCompanyInfo`,
          auth: {
            username: auth.email,
            password: auth.token,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (clientInfoResponse.status === 200) {
          const data = clientInfoResponse.data;
          const accountingClientId = data.accounting_client_id;
          setClientId(accountingClientId);

          // Now fetch the accounts
          const accountsResponse = await axios({
            method: 'post',
            url: `${props.API_domain}/getClientAccounts`,
            auth: {
              username: auth.email,
              password: auth.token,
            },
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              client_id: accountingClientId,
            },
          });

          if (accountsResponse.status === 200) {
            const accountsData = accountsResponse.data;
            setClientAccounts(accountsData.accounts);
          } else {
            console.error('Failed to fetch client accounts');
          }
        } else {
          console.error('Failed to fetch client info');
        }
      } catch (error) {
        console.error('Error fetching client info or accounts:', error);
      }
    };

    fetchClientInfo();
  }, [auth.email, auth.token, props.API_domain]);

  // ============= Fetch Enabled Sites (example) =============
  useEffect(() => {
    const getUserAccountingClientCompanyInfo = async () => {
      try {
        const response = await axios.post(
          `${props.API_domain}getEnabledClientConfigsForAccountingClient`,
          {},
          {
            auth: {
              username: auth.email,
              password: auth.token,
            },
          }
        );
        setEnabledSites(response.data);
      } catch (error) {
        message.error('Error consiguiendo las fuentes');
        console.error('Error fetching accounting client:', error);
      }
    };

    getUserAccountingClientCompanyInfo();
  }, [auth.email, auth.token, props.API_domain]);

  // ============= Update URL query param on tab change =============
  useEffect(() => {
    const currentParams = new URLSearchParams(location.search);
    const currentTab = currentParams.get('tab') || 'feRecibidas';
  
    if (currentTab !== activeComponent) {
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set('tab', activeComponent);
  
      history.replace({
        pathname: location.pathname,
        search: newSearchParams.toString(),
      });
    }
  }, [activeComponent, location, history]);

  // ============= Render the chosen component =============
  const renderComponent = () => {
    // Common props to pass to child components
    const commonProps = {
      API_domain: props.API_domain,
      clientId,
      clientAccounts,
    };

    switch (activeComponent) {
      case 'expenses':
        return (
          <ExpensesView
            {...commonProps}
            team={props.team}
            onTeamSelect={props.onTeamSelect}
            mobile={props.mobile}
          />
        );
      case 'invu':
        return (
          <InvuTable
            {...commonProps}
            team={props.team}
            mobile={props.mobile}
          />
        );
      case 'feRecibidas':
        return (
          <FETable
            {...commonProps}
            team={props.team}
            mobile={props.mobile}
            type="fe_recibidas"
          />
        );
      case 'feEmitidas':
        return (
          <FETable
            {...commonProps}
            team={props.team}
            mobile={props.mobile}
            type="fe_emitidas"
          />
        );
      case 'pedidosya':
        return (
          <PedidosYaTable
            API_domain={props.API_domain}
            team={props.team}
            mobile={props.mobile}
            type="fe_emitidas"
          />
        );
      case 'botexpenses':
        return (
          <BotExpensesTable
            {...commonProps}
            team={props.team}
            mobile={props.mobile}
          />
        );
      default:
        return <BankView {...commonProps} />;
    }
  };

  // ============= Define the Dropdown items for "more" =============
  const menuItems = [
    {
      key: 'bot-legacy',
      label: (
        <NavButton
          type="link"
          onClick={() => setActiveComponent('expenses')}
          isActive={activeComponent === 'expenses'}
        >
          Bot de WhatsApp <b>(legacy)</b>
        </NavButton>
      ),
    },
  ];

  const moreMenu = {
    items: menuItems,
  };

  // ============= Render JSX =============
  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderRight: 'solid rgb(235,235,235) 1px',
      }}
    >
      <Affix>
        <AffixDiv>
          <NavButton
            type="link"
            onClick={() => setActiveComponent('feRecibidas')}
            isActive={activeComponent === 'feRecibidas'}
          >
            🧾 FE Recibidas
          </NavButton>
          <NavButton
            type="link"
            onClick={() => setActiveComponent('feEmitidas')}
            isActive={activeComponent === 'feEmitidas'}
          >
            🧾 FE Emitidas
          </NavButton>
          {enabledSites.includes('invu') && (
            <NavButton
              type="link"
              onClick={() => setActiveComponent('invu')}
              isActive={activeComponent === 'invu'}
              disabled={!auth.adminEmail}
            >
              Invu
            </NavButton>
          )}
          {enabledSites.includes('pedidosya') && (
            <NavButton
              type="link"
              onClick={() => setActiveComponent('pedidosya')}
              isActive={activeComponent === 'pedidosya'}
              disabled={!auth.adminEmail}
            >
              Pedidos Ya
            </NavButton>
          )}
          <NavButton
            type="link"
            onClick={() => setActiveComponent('botexpenses')}
            isActive={activeComponent === 'botexpenses'}
          >
            📱 Bot de Gastos
          </NavButton>
          <Dropdown menu={moreMenu} trigger={['hover']}>
            <a onClick={(e) => e.preventDefault()} style={{ marginLeft: 32 }}>
              <Space>
                ☎️
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </AffixDiv>
      </Affix>

      <div>{renderComponent()}</div>
    </Content>
  );
}

// ============= Styled Components =============
const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
  text-align: left;
`;

const NavButton = styled(Button)`
  color: ${({ isActive }) => (isActive ? 'rgb(116, 116, 205)' : '#000')};
  font-weight: ${({ isActive }) => (isActive ? '600' : 'normal')};
  font-size: 16px;
  background: none;
  border: none;

  &:hover {
    color: ${({ isActive }) => (isActive ? 'rgb(116, 116, 205)' : '#000')};
    background: none;
    border: none;
    box-shadow: none;
  }

  &:focus,
  &:active {
    color: ${({ isActive }) => (isActive ? 'rgb(116, 116, 205)' : '#000')};
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

export { PaperMovementsView };
