import React, { useContext, useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Collapse, Typography, Tag } from 'antd';
import styled from 'styled-components';
import HeroAxios from '../../helpers/HeroAxios.js';
import { authContext } from '../../ProvideAuth.js';
import { AccountingObligationsTimeline } from './AccountingObligationsTimeline.js';
import AccountingObligationsSummary from './AccountingObligationsSummary';
import '../styles.css';

const { Text } = Typography;
const { Panel } = Collapse;

const AccountingObligationsViewer = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [clientNames, setClientNames] = useState({});
  const [defaultPaycaddyCards, setDefaultPaycaddyCards] = useState({});
  const [activeKeys, setActiveKeys] = useState([]);
  const [hasSummaryData, setHasSummaryData] = useState(false);
  const auth = useContext(authContext);

  const getAccountingObligations = async () => {
    setLoading(true);
    try {
      const response = await HeroAxios.post('getAccountingObligations', {
        group_by: 'source',
      });
      setData(response.data.obligations);
      setClientNames(response.data.names);
      setHasSummaryData(!!response.data.summary_table);
    } catch (error) {
      console.error('Error fetching accounting obligations:', error);
    } finally {
      setLoading(false);
    }
  };

  const getDefaultPaycaddyCards = async () => {
    try {
      const response = await HeroAxios.post('getDefaultPaycaddyCard');
      setDefaultPaycaddyCards(response.data.default_paycaddy_cards);
    } catch (error) {
      console.error('Error fetching default Paycaddy cards:', error);
    }
  };

  useEffect(() => {
    getAccountingObligations();
    getDefaultPaycaddyCards();
  }, []);

  const getClientCounts = (clientObligations) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    let pendingCount = 0;
    let dueThisMonthCount = 0;

    clientObligations.forEach(ob => {
      const dueDate = new Date(ob.due_date);

      if (ob.status === 'pendiente') {
        // Overdue
        if (dueDate < today) {
          pendingCount++;
        } else {
          // Due this month and not overdue
          if (dueDate.getMonth() === currentMonth && dueDate.getFullYear() === currentYear) {
            dueThisMonthCount++;
          }
        }
      }
    });

    return { pendingCount, dueThisMonthCount };
  };

  const renderCollapsibleData = () => {
    return (
      <Collapse
        activeKey={activeKeys}
        onChange={(keys) => setActiveKeys(keys)}
        style={{ marginTop: '16px' }}
      >
        {Object.keys(data).map((clientId) => {
          const clientObligations = data[clientId];
          const { pendingCount, dueThisMonthCount } = getClientCounts(clientObligations);

          // Show counts also in the panel header when summary data is present
          const panelHeader = (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Text style={{ fontWeight: 'bold' }}>{clientNames[clientId]} (ID: {clientId})</Text>
              <span>
                {pendingCount > 0 && (
                  <Tag color={pendingCount > 5 ? 'red' : 'orange'}>
                    {pendingCount} pendientes
                  </Tag>
                )}
                {dueThisMonthCount > 0 && (
                  <Tag color='blue'>{dueThisMonthCount} este mes</Tag>
                )}
              </span>
            </div>
          );

          return (
            <Panel header={panelHeader} key={clientId}>
              <AccountingObligationsTimeline
                API_domain={props.API_domain}
                loading={loading}
                data={{ obligations: { [clientId]: clientObligations }, names: { [clientId]: clientNames[clientId] } }}
                setRefresh={props.setRefresh}
                refresh={props.refresh}
                clientId={clientId}
                setShowCommentModal={props.setShowCommentModal}
                setCommentModalSotTxn={props.setCommentModalSotTxn}
                defaultPaycaddyCardId={defaultPaycaddyCards[clientId]}
                pendingCount={pendingCount}
                dueThisMonthCount={dueThisMonthCount}
              />
            </Panel>
          );
        })}
      </Collapse>
    );
  };

  const renderNonCollapsibleData = () => {
    return (
      <>
        {Object.keys(data).map((clientId) => {
          const clientObligations = data[clientId];
          const { pendingCount, dueThisMonthCount } = getClientCounts(clientObligations);
          return (
            <React.Fragment key={clientId}>
              <AccountingObligationsTimeline
                API_domain={props.API_domain}
                loading={loading}
                data={{ obligations: { [clientId]: clientObligations }, names: { [clientId]: clientNames[clientId] } }}
                setRefresh={props.setRefresh}
                refresh={props.refresh}
                clientId={clientId}
                setShowCommentModal={props.setShowCommentModal}
                setCommentModalSotTxn={props.setCommentModalSotTxn}
                defaultPaycaddyCardId={defaultPaycaddyCards[clientId]}
                pendingCount={pendingCount}
                dueThisMonthCount={dueThisMonthCount}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  };

  if (loading) {
    return <LoadingDiv><LoadingOutlined style={{ fontSize: 50 }} spin /></LoadingDiv>;
  }

  return (
    <div style={{ padding: '0 16px' }}>
      {hasSummaryData && (
        <>
          <Typography.Title level={3}>Summary Table</Typography.Title>
          <AccountingObligationsSummary
            onClientSelect={(clientId) => {
              setActiveKeys([...new Set([...activeKeys, clientId.toString()])]);
            }}
          />
          <Typography.Title level={3}>Timelines:</Typography.Title>
          {renderCollapsibleData()}
        </>
      )}

      {!hasSummaryData && (
        <>
          {renderNonCollapsibleData()}
        </>
      )}
    </div>
  );
};

const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { AccountingObligationsViewer };
