// RelationshipModalV2.js

import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';
import { Button, message, Modal, Spin, Descriptions } from 'antd';
import HeroAxios from '../helpers/HeroAxios.js';
import MovementSpecificDetails from './MovementSpecificDetails';

const RelationshipModalV2 = ({
  visible,
  onCancel,
  relationship,
  setRecords,
  selectedRecordForRelationship,
  setRelationshipModalVisible,
  API_domain,
  accounting_clients_id,
  tableOG,
}) => {
  const auth = useContext(authContext);
  const [relationshipData, setRelationshipData] = useState(
    relationship || null
  );
  const [relationshipLoading, setRelationshipLoading] = useState(false);

  useEffect(() => {
    setRelationshipData(relationship);
  }, [relationship]);

  const handleDeleteRelationship = async () => {
    if (!relationshipData || !relationshipData.relationship_id) return;
    setRelationshipLoading(true); // Optional: Show loading while deleting
    try {
      const response = await HeroAxios({
        method: 'post',
        url: 'deleteRelationship',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {
          relationship_id: relationshipData.relationship_id,
        },
      });

      if (response.status === 200) {
        message.success('Relación eliminada correctamente.');

        // Update parent records if setRecords and selectedRecordForRelationship are provided
        if (setRecords && selectedRecordForRelationship) {
          setRecords((prevRecords) =>
            prevRecords.map((record) => {
              if (record.id === selectedRecordForRelationship.id) {
                const updatedRelationship = record.relationship.filter(
                  (rel) =>
                    rel.relationship_id !== relationshipData.relationship_id
                );
                return {
                  ...record,
                  relationship: updatedRelationship,
                  related: updatedRelationship.length > 0,
                };
              }
              return record;
            })
          );
        }

        // Close the modal
        if (setRelationshipModalVisible) {
          setRelationshipModalVisible(false);
        } else if (onCancel) {
          onCancel();
        }
      } else {
        message.error('Error al eliminar la relación.');
      }
    } catch (error) {
      console.error('Error deleting relationship:', error);
      message.error('Error al eliminar la relación.');
    } finally {
      setRelationshipLoading(false); // Stop loading
    }
  };

  const modalFooter = [
    relationshipData && relationshipData.relationship_id && (
      <Button
        key='delete'
        type='danger'
        onClick={handleDeleteRelationship}
        loading={relationshipLoading} // Optional: Show loading on delete button
        disabled={!auth.adminEmail}
      >
        Eliminar Relación
      </Button>
    ),
    <Button key='close' onClick={onCancel}>
      Cerrar
    </Button>,
  ].filter(Boolean);

  return (
    <Modal
      visible={visible}
      title='Detalles de la Relación'
      onCancel={onCancel}
      footer={modalFooter}
      destroyOnClose
      width={1120}
    >
      {relationshipLoading ? (
        <div style={{ textAlign: 'center', padding: '2em' }}>
          <Spin size='large' />
        </div>
      ) : !relationshipData ? (
        <p>No hay relaciones para este movimiento.</p>
      ) : (
        <>
          {/* {renderRelationshipDetails()} */}
          <MovementSpecificDetails
            visible={visible}
            relationshipData={relationshipData}
            auth={auth}
            API_domain={API_domain}
            accounting_clients_id={accounting_clients_id}
            movementOG={selectedRecordForRelationship}
            tableOG={tableOG} // if tableOG is passed, it will be used to fetch the movement details and compare with the relationship movement
          />
        </>
      )}
    </Modal>
  );
};

export default RelationshipModalV2;
