// BotExpensesTable.js

import React, { useContext, useState, useEffect, useMemo } from 'react';
import {
  Button,
  Col,
  Input,
  Layout,
  Table,
  message,
  Row,
  Modal,
  notification,
  Space,
  Checkbox,
  Tooltip,
} from 'antd';
import {
  SearchOutlined,
  DownloadOutlined,
  CheckSquareOutlined,
} from '@ant-design/icons';
import { Refresh } from './Refresh.js';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
import moment from 'moment';
import { get_moments_from_month_name } from '../utils.js';
import { DateFilterDropdown } from './DateFilterDropdown.js';
import AccountingAccountSelect from './Accounting/AccountingAccountSelect';
import * as XLSX from 'xlsx'; // Import XLSX for CSV export
import { BotExpenseModal } from './BotExpenseModal'; // Import BotExpenseModal component
import HeroAxios from '../helpers/HeroAxios.js';
import RelationshipModalV2 from './RelationshipModalV2';
import { sourceMapping } from '../utils.js';
import TransactionCreatorModal from './Accounting/TransactionCreatorModal';
import EngineButton from './Engine/EngineButton.js';
import { ProgressOverview } from './ProgressOverview';
import { RelationshipButton } from './RelationshipButton.js';
import { CommitViewButton } from './CommitViewButton.js';
import { CreateBotExpenseButton } from './CreateBotExpenseButton.js';

// Import the DoubleFilterDropdown components
import { CommitDoubleFilterDropdown } from './CommitDoubleFilterDropdown.js';
import { AccountDoubleFilterDropdown } from './AccountDoubleFilterDropdown.js';
import { RelationshipDoubleFilterDropdown } from './RelationshipDoubleFilterDropdown.js';

const { Content } = Layout;

function BotExpensesTable(props) {
  const [expenses, setExpenses] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // State for filtered data
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedExpenses, setSelectedExpenses] = useState([]);
  const [isCommitModalVisible, setIsCommitModalVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [includeItbms, setIncludeItbms] = useState(false);
  const [selectedItbmsAccount, setSelectedItbmsAccount] = useState(null);
  const [autoClassifyLoading, setAutoClassifyLoading] = useState(false);

  const [selectedExpense, setSelectedExpense] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [relationshipModalVisible, setRelationshipModalVisible] =
    useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState(null);
  const [selectedExpenseForRelationship, setSelectedExpenseForRelationship] =
    useState(null);

  const [isTransactionModalVisible, setIsTransactionModalVisible] =
    useState(false);
  const [selectedMovement, setSelectedMovement] = useState(null);

  const auth = useContext(authContext);

  // NOTE: We keep the structure for simpler column filters (description, provider, etc.)
  // but for "Relación", "Cuenta", "🦸‍♂️ (Commit)" we will use the double-filter approach.
  const [currentFilters, setCurrentFilters] = useState({
    description: null,
    provider: null,
    receipt_date: null,
    receipt_number: null,
    committed: null, // Will hold the object from CommitDoubleFilterDropdown
    account_id: null, // Will hold the object from AccountDoubleFilterDropdown
    relationship: null, // Will hold the object from RelationshipDoubleFilterDropdown
  });

  const openRelationshipModal = (record, relationship) => {
    setSelectedExpenseForRelationship(record);
    setSelectedRelationship(relationship);
    setRelationshipModalVisible(true);
  };

  const fetchExpenses = () => {
    setLoading(true);
    HeroAxios({
      method: 'post',
      url: 'getBotExpenses',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.expenses
            .map((item) => ({
              ...item,
              committed: item.committed ? item.committed : false,
            }))
            .sort(
              (a, b) =>
                moment(b.receipt_date).unix() - moment(a.receipt_date).unix()
            );
          setExpenses(data);
          setFilteredData(data);
        } else {
          message.error('Error al traer los gastos.');
        }
      })
      .catch((error) => {
        console.error('Error fetching expenses:', error);
        message.error('Error consiguiendo los gastos');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchExpenses();
  }, [props.API_domain, auth.email, auth.token]);

  // Data for ProgressOverview
  const cutoffDate = moment('2024-10-31');
  const filteredDataAfterCutoff = useMemo(() => {
    return filteredData.filter((expense) =>
      moment(expense.receipt_date).isSameOrAfter(cutoffDate, 'day')
    );
  }, [filteredData, cutoffDate]);

  const totalExpenses = filteredDataAfterCutoff.length;

  const committedCount = filteredDataAfterCutoff.filter(
    (e) => e.committed
  ).length;

  const uncommittedCount = totalExpenses - committedCount;

  const categorizedCount = filteredDataAfterCutoff.filter(
    (e) => !e.committed && e.account_id !== null
  ).length;

  const highConfidenceCount = filteredDataAfterCutoff.filter(
    (e) => !e.committed && e.account_id_confidence >= 1
  ).length;

  const relatedCount = filteredDataAfterCutoff.filter(
    (e) => !e.committed && e.relationship && e.relationship.length > 0
  ).length;

  // Function to handle account change
  const handleAccountChange = (record, value = record.account_id) => {
    HeroAxios({
      method: 'post',
      url: 'updateBotExpenseAccount',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        expense_id: record.id, // record id
        account_id: value, // new account_id
      },
    })
      .then((response) => {
        if (response.status === 200) {
          message.success('Cuenta actualizada correctamente');
          const relatedMovements =
            response.data.amount_of_related_movements_reclassified;
          if (relatedMovements > 0) {
            message.info(
              `Se sincronizó${
                relatedMovements > 1 ? 'n' : ''
              } ${relatedMovements} movimiento${
                relatedMovements > 1 ? 's' : ''
              } relacionado${relatedMovements > 1 ? 's.' : '.'}`
            );
          }
          // Update the expenses state to reflect the change
          setExpenses((prevExpenses) =>
            prevExpenses.map((item) =>
              item.id === record.id
                ? { ...item, account_id: value, account_id_confidence: 1 }
                : item
            )
          );
        } else {
          message.error('Error al actualizar la cuenta');
        }
      })
      .catch((error) => {
        console.error('Error updating account:', error);
        message.error('Error al actualizar la cuenta');
      });
  };

  function getIndex(expenseObject) {
    return expenses.findIndex((obj) => expenseObject === obj);
  }

  function getExpenseObject(index) {
    return expenses[index];
  }

  function previousExpense() {
    const currentIndex = getIndex(selectedExpense);
    if (currentIndex > 0) {
      setSelectedExpense(getExpenseObject(currentIndex - 1));
    }
  }
  function nextExpense() {
    const currentIndex = getIndex(selectedExpense);
    if (currentIndex < expenses.length - 1) {
      setSelectedExpense(getExpenseObject(currentIndex + 1));
    } else {
    }
  }

  const handleAutoClassify = () => {
    setAutoClassifyLoading(true);

    HeroAxios({
      method: 'post',
      url: 'autoClassifyBotExpenses',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        accounting_clients_id: props.clientId,
      },
    })
      .then((response) => {
        setAutoClassifyLoading(false);
        if (response.status === 200) {
          message.info(
            'Autoclasificación en proceso. Puede demorar unos minutos.'
          );
          fetchExpenses();
        }
      })
      .catch((error) => {
        setAutoClassifyLoading(false);
        console.error('Error auto-classifying expenses:', error);

        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          if (
            error.response.data.error ===
            'Not enough expenses with accounts to use the model'
          ) {
            message.error(
              'Necesitas al menos 10 gastos clasificados manualmente para usar el modelo.'
            );
          } else {
            message.error(
              'Error al clasificar los gastos: ' + error.response.data.error
            );
          }
        } else {
          message.error('Error al clasificar los gastos: ' + error.message);
        }
      });
  };

  // ==========================
  // Double Filter Helpers
  // ==========================

  // Relationship double filter
  function relationshipDoubleFilter(value, record) {
    // value is an object: { relationshipType: [...], agentFilter: 'all'|'AUTONOMOUS_AGENT'|'human' }
    if (!value) return true; // no filter at all

    const { relationshipType = [], agentFilter = 'all' } = value;

    // 1) Relationship type filter
    let passType = false;
    // If no relationshipType is selected, pass
    if (!relationshipType.length) {
      passType = true;
    } else {
      // "no_relationship" means record.relationship is empty or doesn't exist
      if (relationshipType.includes('no_relationship')) {
        if (!record.relationship || record.relationship.length === 0) {
          // It's valid if we wanted "no_relationship"
          passType = true;
        }
      }
      // Check for the recognized relationships that are not 'entries'
      const relevantRels =
        record.relationship?.filter(
          (rel) =>
            rel.type_of_mm !== 'entries' &&
            rel.movement_table_name !== 'entries'
        ) || [];
      // See if at least one relationship matches any chosen type
      const matchedType = relevantRels.some((rel) =>
        relationshipType.includes(rel.type_of_mm || rel.movement_table_name)
      );
      // If we didn't pass yet with "no_relationship", see if we pass with a relevant relationship
      if (matchedType) passType = true;
    }

    if (!passType) {
      return false;
    }

    // 2) Agent filter
    if (agentFilter === 'all') return true;

    // If agentFilter is 'AUTONOMOUS_AGENT' or 'human',
    // we check if at least one relevant relationship has that agent
    const relevantRels =
      record.relationship?.filter(
        (rel) =>
          rel.type_of_mm !== 'entries' && rel.movement_table_name !== 'entries'
      ) || [];

    if (agentFilter === 'AUTONOMOUS_AGENT') {
      // pass if at least one relationship was created by AUTONOMOUS_AGENT
      return relevantRels.some(
        (rel) => rel.audit_usersfacturas_id === 'AUTONOMOUS_AGENT'
      );
    } else if (agentFilter === 'human') {
      // pass if at least one relationship was created by a non-bot user
      return relevantRels.some(
        (rel) => rel.audit_usersfacturas_id !== 'AUTONOMOUS_AGENT'
      );
    }

    return true;
  }

  // Commit double filter
  function commitDoubleFilter(value, record) {
    // value is { commitStatus: 'committed'|'not_committed'|null, agentFilter: 'all'|'AUTONOMOUS_AGENT'|'human' }
    if (!value) return true; // no filter

    const { commitStatus, agentFilter } = value;

    // 1) Commit status filter
    if (commitStatus === 'committed') {
      if (!record.committed) return false;
    } else if (commitStatus === 'not_committed') {
      if (record.committed) return false;
    }
    // if commitStatus===null => no filter

    // 2) Agent filter
    if (agentFilter === 'all') return true;

    // If the row is not committed, there's no "who committed it".
    // So if user wants 'AUTONOMOUS_AGENT' or 'human', that doesn't apply to a not_committed record => filter out.
    if (!record.committed) {
      return false;
    }

    // For committed items, we check the relationships that have movement_table_name='entries' or type_of_mm='entries'
    // Because that's typically how we know who committed it.
    const entriesRels =
      record.relationship?.filter(
        (rel) =>
          rel.movement_table_name === 'entries' || rel.type_of_mm === 'entries'
      ) || [];

    // if there are no 'entries' relationships but it's committed, not sure how to track the agent, let's fail
    if (entriesRels.length === 0) return false;

    if (agentFilter === 'AUTONOMOUS_AGENT') {
      return entriesRels.every(
        (rel) => rel.audit_usersfacturas_id === 'AUTONOMOUS_AGENT'
      );
    } else if (agentFilter === 'human') {
      return entriesRels.some(
        (rel) => rel.audit_usersfacturas_id !== 'AUTONOMOUS_AGENT'
      );
    }

    return true;
  }

  // Account double filter
  function accountDoubleFilter(value, record) {
    // value is { accountIds: [ ... ], agentFilter: 'all'|'AUTONOMOUS_AGENT'|'human' }
    if (!value) return true;

    const { accountIds = [], agentFilter = 'all' } = value;

    // 1) accountIds filter
    if (accountIds.length > 0) {
      // if record's account_id is not in the list, fail
      if (!accountIds.includes(record.account_id)) {
        return false;
      }
    }

    // 2) Agent filter => based on account_id_confidence
    // We'll treat account_id_confidence < 1 as "AUTONOMOUS_AGENT" assigned,
    // and >= 1 as "human" assigned
    if (agentFilter === 'all') return true;

    const conf = parseFloat(record.account_id_confidence || '0');
    if (agentFilter === 'AUTONOMOUS_AGENT') {
      return conf < 1 && conf > 0;
    } else if (agentFilter === 'human') {
      return conf >= 1;
    }

    return true;
  }

  // Build the dynamic list of relationship types (excluding 'entries')
  const relationshipTypeSet = new Set();
  expenses.forEach((exp) => {
    if (exp.relationship && exp.relationship.length > 0) {
      exp.relationship
        .filter(
          (rel) =>
            rel.type_of_mm !== 'entries' &&
            rel.movement_table_name !== 'entries'
        )
        .forEach((rel) =>
          relationshipTypeSet.add(rel.type_of_mm || rel.movement_table_name)
        );
    }
  });
  const relationshipTypeFilters = Array.from(relationshipTypeSet).map(
    (type) => {
      // "Relationship options should say 'Bot de Gastos' for bot_expenses,
      //  'Tarjeta' for card, 'Banco' for bank, 'FE recibida' for dgi_docs_recibidos,
      //  'FE emitida' for dgi_docs_emitidos"
      // We already have sourceMapping, e.g. sourceMapping[type].
      const beautifulName = sourceMapping[type] || type;
      return { text: beautifulName, value: type };
    }
  );
  // Also add a "no_relationship" option
  relationshipTypeFilters.push({
    text: 'Sin relación',
    value: 'no_relationship',
  });

  // Build the dynamic list of accounts that are actually present
  const accountFilterAccounts = props.clientAccounts.filter((acc) =>
    expenses.some((expense) => expense.account_id === acc.account_id)
  );

  // Now define the columns
  const columns = [
    {
      title: 'Fecha enviada',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 160,
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (text) => (
        <Tooltip
          title={moment(text).subtract(5, 'hours').format(' hh:mm A')}
          placement='top'
        >
          {moment(text).subtract(5, 'hours').format('YYYY-MM-DD')}
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        const createdDate = moment(record.created_at);

        if (Array.isArray(value) && value.length === 2) {
          const [start, end] = value;
          return createdDate.isBetween(start, end, 'day', '[]');
        } else if (typeof value === 'string') {
          const filterDates = get_moments_from_month_name(value);
          return createdDate.isBetween(
            filterDates[0],
            filterDates[1],
            'day',
            '[]'
          );
        }

        return false;
      },
    },
    {
      title: 'Fecha factura',
      dataIndex: 'receipt_date',
      key: 'receipt_date',
      width: 160,
      sorter: (a, b) =>
        moment(a.receipt_date).unix() - moment(b.receipt_date).unix(),
      render: (text) => moment(text).format('YYYY-MM-DD'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        const receiptDate = moment(record.receipt_date);

        if (Array.isArray(value) && value.length === 2) {
          const [start, end] = value;
          return receiptDate.isBetween(start, end, 'day', '[]');
        } else if (typeof value === 'string') {
          const filterDates = get_moments_from_month_name(value);
          return receiptDate.isBetween(
            filterDates[0],
            filterDates[1],
            'day',
            '[]'
          );
        }

        return false;
      },

      filteredValue: currentFilters.receipt_date || null, // Bind filteredValue
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      width: 300,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Buscar Descripción`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type='primary'
              onClick={confirm}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button onClick={clearFilters} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        const searchText = value.toLowerCase();
        return (
          record.description &&
          record.description.toLowerCase().includes(searchText)
        );
      },
      filteredValue: currentFilters.description || null,
    },
    {
      title: 'Proveedor',
      dataIndex: 'provider',
      key: 'provider',
      width: 240,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Buscar Proveedor`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type='primary'
              onClick={confirm}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button onClick={clearFilters} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        const searchText = value.toLowerCase();
        return (
          record.provider && record.provider.toLowerCase().includes(searchText)
        );
      },
      filteredValue: currentFilters.provider || null,
    },
    {
      title: 'Factura',
      dataIndex: 'receipt_number',
      key: 'receipt_number',
      width: 100,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Buscar Número de Recibo`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type='primary'
              onClick={confirm}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button onClick={clearFilters} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        const searchText = value.toLowerCase();
        return (
          record.receipt_number &&
          record.receipt_number.toLowerCase().includes(searchText)
        );
      },
      filteredValue: currentFilters.receipt_number || null,
    },
    {
      title: 'ITBMS',
      dataIndex: 'itbms',
      key: 'itbms',
      render: (value) =>
        value !== undefined ? `$${value.toFixed(2)}` : '$0.00',
      sorter: (a, b) => (a.itbms || 0) - (b.itbms || 0),
      align: 'right',
      // The old single filter approach (commenting out for reference):
      /*
      filters: Array.from(new Set(expenses.map((expense) => expense.itbms)))
        .sort((a, b) => a - b)
        .map((amount) => ({
          text: `$${amount.toFixed(2)}`,
          value: amount,
        })),
      onFilter: (value, record) => record.itbms === value,
      filteredValue: currentFilters.itbms || null,
      */
    },
    {
      title: 'Total',
      dataIndex: 'amount_total',
      key: 'amount_total',
      render: (value) => (
        <div style={{ textAlign: 'right' }}>
          {value !== undefined ? `$${value.toFixed(2)}` : '$0.00'}
        </div>
      ),
      sorter: (a, b) => (a.amount_total || 0) - (b.amount_total || 0),
      align: 'right',
      // The old single filter approach (commenting out for reference):
      /*
      filters: Array.from(
        new Set(expenses.map((expense) => expense.amount_total))
      )
        .sort((a, b) => a - b)
        .map((amount) => ({
          text: `$${amount?.toFixed(2)}`,
          value: amount,
        })),
      onFilter: (value, record) => record.amount_total === value,
      filteredValue: currentFilters.amount_total || null,
      */
    },
    {
      title: 'Cuenta',
      dataIndex: 'account_id',
      key: 'account_id',
      width: 320,
      // Replacing the old filter with our double filter dropdown:
      filterDropdown: (filterProps) => (
        <AccountDoubleFilterDropdown
          accounts={accountFilterAccounts}
          {...filterProps}
        />
      ),
      onFilter: (value, record) => accountDoubleFilter(value, record),
      filteredValue: currentFilters.account_id || null,

      render: (text, record) => (
        <div onClick={(e) => e.stopPropagation()}>
          <AccountingAccountSelect
            value={record.account_id}
            account_id_confidence={record.account_id_confidence}
            onChange={(value) => handleAccountChange(record, value)}
            clientId={props.clientId}
            API_domain={props.API_domain}
            accounts={props.clientAccounts}
            auth={auth}
            onClick={(e) => e.stopPropagation()} // Prevent modal trigger
            disabled={record.committed || !auth.adminEmail} // Disable if committed
            showThumbUp={
              record.account_id_confidence < 1 &&
              record.account_id_confidence > 0 &&
              !record.committed
            }
            audit_account_id_timestamp={record.audit_account_id_timestamp}
          />
        </div>
      ),
    },
    {
      title: 'Relación',
      dataIndex: 'relationship',
      key: 'relationship',
      width: 200,
      // We'll use the RelationshipDoubleFilterDropdown here
      filterDropdown: (filterProps) => (
        <RelationshipDoubleFilterDropdown
          existingFilters={relationshipTypeFilters}
          {...filterProps}
        />
      ),
      onFilter: (value, record) => relationshipDoubleFilter(value, record),
      filteredValue: currentFilters.relationship || null,

      render: (relationship, record) => {
        // The original rendering logic
        if (relationship && relationship.length > 0) {
          return relationship
            .filter(
              (rel) =>
                rel.type_of_mm !== 'entries' &&
                rel.movement_table_name !== 'entries'
            )
            .map((rel, index) => {
              const relatedMovementSource =
                rel.type_of_mm || rel.movement_table_name;
              const beautifulName =
                sourceMapping[relatedMovementSource] || relatedMovementSource;
              return (
                <RelationshipButton
                  key={index}
                  record={record}
                  rel={rel}
                  beautifulName={beautifulName}
                  openRelationshipModal={openRelationshipModal}
                  index={index}
                />
              );
            });
        } else {
          return '';
        }
      },
      // The old single filter code is commented out for reference:
      /*
      filters: [
        ...Array.from(
          new Set(
            expenses
              .filter(
                (expense) =>
                  expense.relationship &&
                  expense.relationship.length > 0 &&
                  expense.relationship.some(
                    (rel) =>
                      rel.type_of_mm !== 'entries' &&
                      rel.movement_table_name !== 'entries'
                  )
              )
              .flatMap((expense) =>
                expense.relationship
                  .filter(
                    (rel) =>
                      rel.type_of_mm !== 'entries' &&
                      rel.movement_table_name !== 'entries'
                  )
                  .map((rel) => rel.type_of_mm || rel.movement_table_name)
              )
          )
        ).map((type) => {
          const beautifulName = sourceMapping[type] || type;
          return { text: beautifulName, value: type };
        }),
        { text: ' ', value: 'no_relationship' },
      ],
      onFilter: (value, record) => {
        if (value === 'no_relationship') {
          return !record.relationship || record.relationship.length === 0;
        }
        const relationship = record.relationship;
        if (relationship && relationship.length > 0) {
          return relationship.some(
            (rel) =>
              rel.type_of_mm === value || rel.movement_table_name === value
          );
        } else {
          return false;
        }
      },
      filteredValue: currentFilters.relationship || null,
      */
    },
    ...(auth.adminEmail
      ? [
          {
            title: '🦸‍♂️',
            dataIndex: 'committed',
            key: 'committed',
            width: 80,
            // Replace with double filter for commit
            filterDropdown: (filterProps) => (
              <CommitDoubleFilterDropdown {...filterProps} />
            ),
            onFilter: (value, record) => commitDoubleFilter(value, record),
            filteredValue: currentFilters.committed || null,

            filterIcon: (filtered) => (
              <CheckSquareOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            render: (committed, record) => (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {committed ? (
                  <CommitViewButton
                    record={record}
                    onClick={() => openTransactionModal(record)}
                  />
                ) : (
                  ''
                )}
              </span>
            ),
            // The original single filter approach is commented out below:
            /*
            filters: [
              { text: 'Committed', value: true },
              { text: 'Not Committed', value: false },
            ],
            filterMultiple: false,
            onFilter: (value, record) => record.committed === value,
            */
          },
        ]
      : []),
  ];

  // Function to export table data to CSV
  const exportToCSV = () => {
    if (!filteredData || filteredData.length === 0) {
      message.warning('No hay datos para exportar.');
      return;
    }

    // Define headers based on columns
    const headers = [
      'Fecha enviada al bot',
      'Fecha de factura',
      'Descripción',
      'Proveedor',
      'Factura',
      'ITBMS',
      'Total',
      'Cuenta',
    ];

    // Map data to rows
    const data = filteredData.map((item) => {
      const row = [
        moment(item.created_at).format('YYYY-MM-DD'),
        moment(item.receipt_date).format('YYYY-MM-DD'),
        item.description,
        item.provider,
        item.receipt_number,
        item.itbms !== undefined ? `${item.itbms.toFixed(2)}` : '0.00',
        item.amount_total !== undefined
          ? `${item.amount_total.toFixed(2)}`
          : '0.00',
        (
          props.clientAccounts.find(
            (account) => account.account_id === item.account_id
          ) || {}
        ).zoho_account_name || '',
      ];

      return row;
    });

    // Combine headers and data
    const csvData = [headers, ...data];

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(csvData);

    // Create a workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Expenses');

    // Generate a binary string representation of the workbook
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'array' });

    // Create a Blob from the binary string
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });

    // Create a link to download the Blob
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Expenses.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedExpenses(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleCommit = () => {
    if (selectedExpenses.length === 0) {
      message.warning('No hay gastos seleccionados para confirmar.');
      return;
    }
    setIsCommitModalVisible(true);
  };

  const handleCommitConfirm = () => {
    if (!selectedAccount) {
      message.warning('Por favor seleccione una cuenta.');
      return;
    }

    if (includeItbms && !selectedItbmsAccount) {
      message.warning('Por favor seleccione una cuenta de ITBMS.');
      return;
    }

    HeroAxios({
      method: 'post',
      url: 'commitBotExpenses',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        expense_ids: selectedExpenses.map((expense) => expense.id),
        account_id: selectedAccount,
        itbms_account_id: includeItbms ? selectedItbmsAccount : null,
        include_itbms: includeItbms,
        client_id: props.clientId,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          message.success('Gastos confirmados correctamente.');
          setSelectedRowKeys([]);
          setSelectedExpenses([]);
          setIsCommitModalVisible(false);
          setSelectedAccount(null);
          setIncludeItbms(false);
          setSelectedItbmsAccount(null);
          const committedIds = response.data.committed_ids || [];
          setExpenses((prevExpenses) =>
            prevExpenses.map((expense) =>
              committedIds.includes(expense.id)
                ? { ...expense, committed: true }
                : expense
            )
          );
        } else {
          message.error('Error al confirmar los gastos.');
        }
      })
      .catch((error) => {
        console.error('Error committing expenses:', error);
        message.error('Error al confirmar los gastos.');
      });
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    // This gets the new filters from antd, store them in our currentFilters
    setCurrentFilters(filters);
    if (extra && extra.currentDataSource) {
      setFilteredData(extra.currentDataSource);
    } else {
      setFilteredData(expenses);
    }
  };

  const handleRowClick = (record) => {
    setSelectedExpense(record);
    setModalVisible(true);
    console.log(record);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedExpense(null);
  };

  const handleModalSubmit = () => {
    HeroAxios({
      method: 'post',
      url: 'getBotExpenses',
      data: {
        // any necessary data
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.expenses
            .map((item) => ({
              ...item,
              committed: item.committed !== undefined ? item.committed : false,
            }))
            .sort(
              (a, b) =>
                moment(b.receipt_date).unix() - moment(a.receipt_date).unix()
            );
          setExpenses(data);
          setFilteredData(data);
          closeModal();
        } else {
          message.error('Failed to fetch expenses');
        }
      })
      .catch((error) => {
        console.error('Error fetching expenses:', error);
        message.error('Error consiguiendo los gastos');
      });
  };

  const openTransactionModal = (record) => {
    setSelectedMovement({
      movement_table_name: 'bot_expenses',
      movement_id: record.id,
    });
    setIsTransactionModalVisible(true);
  };

  return (
    <Content
      style={{
        overflow: 'initial',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      {/* Progress Information now placed above the Refresh, EngineButton, etc. */}
      <AffixDiv style={{ maxWidth: '1600px', margin: 'auto' }}>
        {auth.adminEmail && (
          <ProgressOverview
            totalUncommitted={uncommittedCount}
            categorizedCount={categorizedCount}
            highConfidenceCount={highConfidenceCount}
            relatedCount={relatedCount}
            cutoffDate={cutoffDate}
          />
        )}
        <Row>
          <Col span={4} style={{ textAlign: 'left' }}>
            <div
              style={{
                color: 'var(--grey-dark)',
                marginLeft: 16,
                paddingTop: 24,
              }}
            >
              <Refresh
                onClick={() => {
                  fetchExpenses();
                }}
                spin={loading}
              />
            </div>
          </Col>
          <Col span={20} style={{ textAlign: 'right', paddingTop: 16 }}>
            {auth.adminEmail && (
              <Space>
                <EngineButton
                  text='Autoclasificar 🪄 🦸‍♂️'
                  disabled={autoClassifyLoading}
                  loading={autoClassifyLoading}
                  onClick={handleAutoClassify}
                  popOverContent={
                    <>
                      <div>
                        Se autoclasificarán todos los gastos que no tengan
                        cuenta contable asignada.
                      </div>
                      <br></br>
                      <div>
                        Se necesitan al menos 10 gastos con cuenta contable
                        asignada para que la magia funcione 🪄.
                      </div>
                      <br></br>
                      <div>
                        Se usará de referencia:
                        <ol>
                          <li>Todas las cuentas contables</li>
                          <li>
                            Todos los gastos que ya tienen cuenta contable
                            asignada
                          </li>
                        </ol>
                      </div>
                      <div>⌛ Demorará unos segundos.</div>
                      <br></br>
                      <div>
                        Cuidado: por ahora se usan todos los gastos
                        categorizados, sin importar la confianza.
                      </div>
                    </>
                  }
                />
                <Button
                  type='primary'
                  onClick={handleCommit}
                  disabled={selectedExpenses.length === 0}
                  style={{ marginRight: 8 }}
                >
                  Commit 🦸‍♂️
                </Button>
              </Space>
            )}
            <Space>
              <CreateBotExpenseButton
                API_domain={props.API_domain}
                API_endPoint={props.API_endPoint}
                clientId={props.clientId}
                fetchExpenses={fetchExpenses}
                onSubmit={fetchExpenses}
              />
              <Button
                type='secondary'
                icon={<DownloadOutlined />}
                onClick={exportToCSV}
              >
                Excel
              </Button>
            </Space>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={expenses}
          rowKey='id'
          loading={loading}
          pagination={{ pageSize: 100 }}
          rowSelection={auth.adminEmail ? rowSelection : null}
          style={{ margin: 'auto' }}
          onChange={onTableChange}
          onRow={(record) => ({
            onClick: () => {
              handleRowClick(record);
            },
            style: { cursor: 'pointer' },
          })}
        />
      </AffixDiv>

      {/* Expense Modal */}
      {modalVisible && (
        <BotExpenseModal
          botexpense={selectedExpense}
          visible={modalVisible}
          onCancel={closeModal}
          onSubmit={handleModalSubmit}
          API_domain={props.API_domain}
          API_endPoint={props.API_endPoint}
          accounts={props.clientAccounts}
          clientId={props.clientId}
          nextExpense={nextExpense}
        />
      )}
      {relationshipModalVisible && (
        <RelationshipModalV2
          visible={relationshipModalVisible}
          onCancel={() => setRelationshipModalVisible(false)}
          relationship={selectedRelationship}
          setRecords={setExpenses}
          selectedRecordForRelationship={selectedExpenseForRelationship}
          setRelationshipModalVisible={setRelationshipModalVisible}
          API_domain={props.API_domain}
          accounting_clients_id={props.clientId}
          tableOG='bot_expenses'
        />
      )}

      {/* Commit Modal */}
      <Modal
        title='Seleccione una cuenta para confirmar'
        visible={isCommitModalVisible}
        onOk={handleCommitConfirm}
        onCancel={() => {
          setIsCommitModalVisible(false);
          setSelectedAccount(null);
          setIncludeItbms(false);
          setSelectedItbmsAccount(null);
        }}
      >
        <div style={{ marginBottom: 16 }}>
          <div>Cuenta:</div>
          <AccountingAccountSelect
            value={selectedAccount}
            onChange={(value) => setSelectedAccount(value)}
            clientId={props.clientId}
            API_domain={props.API_domain}
            auth={auth}
            accounts={props.clientAccounts}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <div style={{ marginBottom: 16 }}>
          <Checkbox
            checked={includeItbms}
            onChange={(e) => setIncludeItbms(e.target.checked)}
          >
            ITBMS
          </Checkbox>
        </div>
        {includeItbms && (
          <div>
            <div>Cuenta de ITBMS:</div>
            <AccountingAccountSelect
              value={selectedItbmsAccount}
              onChange={(value) => setSelectedItbmsAccount(value)}
              clientId={props.clientId}
              API_domain={props.API_domain}
              auth={auth}
              accounts={props.clientAccounts}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        )}
      </Modal>

      <TransactionCreatorModal
        isVisible={isTransactionModalVisible}
        onCancel={() => setIsTransactionModalVisible(false)}
        prepareTransactionEndpoint={'getTransactionDataForMovement'}
        transactionCreationEndpoint={'editTransaction'}
        initialPayload={{
          movement_table_name: 'bot_expenses',
          movement_id: selectedMovement?.movement_id,
        }}
        API_domain={props.API_domain}
        auth={auth}
        clientId={props.clientId}
        onTransactionCreated={() => {
          message.success('Transacción editada exitosamente');
          fetchExpenses();
        }}
      />
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

export { BotExpensesTable };
