// SocketContext.js

import React, {
    createContext,
    useState,
    useCallback,
    useEffect,
    useRef,
  } from 'react';
  import { notification } from 'antd';
  import io from 'socket.io-client';
  
  const API_domain =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:5000/'
      : 'https://www.herofacturas.com/';
  
  export const SocketContext = createContext({
    socket: null,
    startSocket: () => {},
    stopSocket: () => {},
  });
  
  export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const closeTimeoutRef = useRef(null);
  
    // HELPER: CLEAR TIMER
    const clearCloseTimeout = () => {
      if (closeTimeoutRef.current) {
        console.log('[clearCloseTimeout] Clearing existing timer:', closeTimeoutRef.current);
        clearTimeout(closeTimeoutRef.current);
        closeTimeoutRef.current = null;
      }
    };
  
    // START SOCKET + 5-minute test timer
    const startSocket = useCallback(
      (userEmail) => {
        console.log('[startSocket] Called with userEmail:', userEmail);
  
        if (!userEmail) {
          console.log('[startSocket] No userEmail provided; aborting start.');
          return;
        }
  
        if (socket) {
          console.log('[startSocket] Socket already exists; skipping creation.');
          return;
        }
  
        console.log('[startSocket] Creating a new Socket IO instance...');
        const newSocket = io(API_domain, {
          path: '/socket.io',
          transports: ['polling'],
          reconnection: false, // don't automatically reconnect
        });
  
        newSocket.on('connect', () => {
          console.log('[socket] Connected with ID:', newSocket.id);
          newSocket.emit('join', { unique_user_identifier: userEmail });
        });
  
        newSocket.io.on('reconnect_attempt', (attempt) => {
          console.warn('[socket] Reconnect attempt #', attempt);
        });
  
        newSocket.on('disconnect', (reason) => {
          console.log('[socket] Disconnected. Reason:', reason);
        });
  
        newSocket.on('notification', (data) => {
          console.log('[socket] Received "notification" event:', data);
  
          const { title, description, type = 'info' } = data;
          notification[type]({
            message: title,
            description,
          });
  
          // Close upon first notification
          console.log('[socket] Stopping socket in response to notification...');
          stopSocket();
        });
  
        // 1) Create the socket
        setSocket(newSocket);
  
        // 2) Clear any old timer, then set a new 5-minute timer
        console.log('[startSocket] Setting a 5-minute timer to auto-stop if no notifications arrive...');
        clearCloseTimeout();
  
        closeTimeoutRef.current = setTimeout(() => {
          console.log('[startSocket] 5 minutes passed with no notification -> stopping socket...');
          stopSocket();
        }, 5 * 60 * 1000); // 5 minutes for testing
      },
      [socket]
    );
  
    // STOP SOCKET
    const stopSocket = useCallback(() => {
      console.log('[stopSocket] Called.');
      clearCloseTimeout();
      setSocket((prevSocket) => {
        if (!prevSocket) {
          console.log('[stopSocket] No existing socket to disconnect.');
          return null;
        }
        console.log('[stopSocket] Disconnecting socket with ID:', prevSocket.id);
        prevSocket.disconnect();
        prevSocket.removeAllListeners();
        return null;
      });
    }, []);
  
    // Cleanup on unmount
    // useEffect(() => {
    //   return () => {
    //     console.log('[SocketProvider] Unmounting => disconnecting socket if present...');
    //     clearCloseTimeout();
    //     if (socket) {
    //       socket.disconnect();
    //       socket.removeAllListeners();
    //     }
    //   };
    // }, [socket]);
  
    return (
      <SocketContext.Provider value={{ socket, startSocket, stopSocket }}>
        {children}
      </SocketContext.Provider>
    );
  };
  