import { Tooltip } from 'antd';
import React from 'react';

function getBaseBgColor(rel) {
  // If it's an integer => user => greenish
  if (Number.isInteger(parseInt(rel.audit_usersfacturas_id, 10))) {
    return 'rgba(0, 128, 0, 0.16)';
  }
  // If it's 'AUTONOMOUS_AGENT' => purpleish
  if (rel.audit_usersfacturas_id === 'AUTONOMOUS_AGENT') {
    return 'rgba(128, 0, 128, 0.12)';
  }
  // Otherwise, no background
  return 'transparent';
}

const RelationshipButton = ({
  record,
  rel,
  beautifulName,
  openRelationshipModal,
  index,
  onClick,
}) => {
  const baseBgColor = getBaseBgColor(rel);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    date.setHours(date.getHours() - 5);
    return date.toISOString().split('T')[0];
  };

  return (
    <Tooltip
      title={`${
        rel.audit_usersfacturas_id == 'AUTONOMOUS_AGENT'
          ? 'Relación creada por Agente Autónomo el ' +
            formatDate(rel.audit_timestamp)
          : 'Relación creada el ' + formatDate(rel.audit_timestamp)
      }`}
      mouseEnterDelay={1}
    >
      <span
        key={index}
        style={{
          color: 'var(--purple-dark)',
          cursor: 'pointer',
          padding: '4px 8px',
          borderRadius: '4px',
          marginRight: '8px',
          transition: 'background-color 0.3s, color 0.3s',
          backgroundColor: baseBgColor,
        }}
        onClick={(e) => {
          e.stopPropagation();
          onClick ? onClick() : openRelationshipModal(record, rel);
        }}
        onMouseEnter={(e) => {
          // Hover => purple highlight
          e.target.style.backgroundColor = 'var(--purple-light)';
          e.target.style.color = 'white';
        }}
        onMouseLeave={(e) => {
          // Reset to original color scheme
          e.target.style.backgroundColor = baseBgColor;
          e.target.style.color = 'var(--purple-dark)';
        }}
      >
        {beautifulName}
      </span>
    </Tooltip>
  );
};

export { RelationshipButton };
