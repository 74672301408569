// MovementSpecificDetails.js

import React, { useEffect, useState } from 'react';
import HeroAxios from '../helpers/HeroAxios.js';
import { message, Spin, Descriptions, Row, Col, Tag, Tooltip } from 'antd';
import FEbuttonAndModal from './FEbuttonAndModal';
import { ImgOrPdf } from './ImgOrPdf.js';
import { FormattedUSD } from './FormattedUSD.js';
import AccountingAccountSelect from './Accounting/AccountingAccountSelect.js';
import { sourceMapping } from '../utils.js';

const MovementSpecificDetails = ({
  visible,
  relationshipData,
  auth,
  API_domain,
  accounting_clients_id,
  movementOG,
  tableOG,
}) => {
  const [movementSpecificData, setMovementSpecificData] = useState(null);
  const [movementSpecificDataOGmovement, setMovementSpecificDataOGmovement] =
    useState(null);
  const [movementLoading, setMovementLoading] = useState(false);

  const fetchMovementSpecificData = async (
    movement_id,
    movement_table_name,
    which_movement
  ) => {
    if (!movement_id || !movement_table_name) return;

    setMovementLoading(true);

    try {
      const response = await HeroAxios({
        method: 'post',
        url: 'getMovementSpecificData',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {
          movement_id: movement_id,
          source_table_name: movement_table_name,
        },
      });

      if (response.status === 200) {
        // Assuming the backend returns an array of records
        const data = response.data[0] || null;
        if (which_movement === 'relationship') {
          setMovementSpecificData(data);
        } else {
          setMovementSpecificDataOGmovement(data);
        }
      } else {
        message.error('Error al obtener los detalles del movimiento.');
      }
    } catch (error) {
      console.error('Error fetching movement specific data:', error);
      message.error('Error al obtener los detalles del movimiento.');
    } finally {
      setMovementLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchMovementSpecificData(
        relationshipData.movement_id,
        relationshipData.movement_table_name,
        'relationship'
      );
      if (tableOG && movementOG) {
        fetchMovementSpecificData(movementOG.id, tableOG, 'movementOG');
      }
    } else {
      // Reset movement-specific data when modal is closed
      setMovementSpecificData(null);
      setMovementSpecificDataOGmovement(null);
    }
  }, [visible, relationshipData, auth.email, auth.token, movementOG, tableOG]);

  const renderDescriptions = (data, tableName, title) => {
    if (!data) {
      return (
        <Descriptions bordered column={1} size='small'>
          <Descriptions.Item label='Detalles Adicionales'>
            No se encontraron detalles adicionales.
          </Descriptions.Item>
        </Descriptions>
      );
    }

    switch (tableName) {
      case 'dgi_docs_recibidos_rows':
      case 'dgi_docs_emitidos_rows':
        return (
          <>
            <h3 style={{ marginBottom: '1em' }}>
              {title}
              <Tag style={{ marginLeft: 8 }}>{sourceMapping[tableName]}</Tag>
              {data.committed && (
                <Tooltip title='Incluido en contabilidad'>
                  <Tag>✅</Tag>
                </Tooltip>
              )}
            </h3>
            <Descriptions bordered column={1} size='small'>
              <Descriptions.Item label='Monto'>
                {data.amount ? (
                  <FormattedUSD total={data.amount} />
                ) : (
                  <FormattedUSD total={data.amount_total} />
                )}
              </Descriptions.Item>
              <Descriptions.Item label='Fecha'>
                {data.emission_date || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Cuenta contable'>
                <AccountingAccountSelect
                  value={data.account_id}
                  account_id_confidence={data.account_id_confidence}
                  onChange={(newValue) => {
                    console.log('handleChange', newValue);
                  }}
                  clientId={accounting_clients_id}
                  API_domain={API_domain}
                  auth={auth}
                  onClick={(e) => e.stopPropagation()} // Prevent modal trigger
                  // disabled={data.committed} // Disable if committed
                  disabled={true} // Disable always for now
                />
              </Descriptions.Item>
              <Descriptions.Item label='Emisor'>
                {data.emissor_name || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='CUFE'>
                {data.cufe || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Tipo de Documento'>
                {data.document_type || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Factura Electrónica'>
                {data.cufe_url ? (
                  <iframe
                    src={data.cufe_url}
                    width='100%'
                    height='600px'
                    title='Factura PDF'
                    frameBorder='0'
                  />
                ) : (
                  <FEbuttonAndModal
                    numero_factura={data.cufe}
                    API_domain={API_domain}
                    auth={auth}
                  />
                )}
              </Descriptions.Item>
            </Descriptions>
          </>
        );

      case 'bot_expenses':
      case 'cashflow':
        return (
          <>
            <h3 style={{ marginBottom: '1em' }}>
              {title}
              <Tag style={{ marginLeft: 8 }}>{sourceMapping[tableName]}</Tag>
              {data.committed && (
                <Tooltip title='Incluido en contabilidad'>
                  <Tag>✅</Tag>
                </Tooltip>
              )}
            </h3>
            <Descriptions bordered column={1} size='small'>
              <Descriptions.Item label='Monto'>
                <FormattedUSD total={data.amount_total || data.amount || 0} />
              </Descriptions.Item>
              <Descriptions.Item label='Fecha'>
                {/* fecha de emisión */}
                {data.receipt_date || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Cuenta contable'>
                <AccountingAccountSelect
                  value={data.account_id}
                  account_id_confidence={data.account_id_confidence}
                  onChange={(newValue) => {
                    console.log('handleChange', newValue);
                  }}
                  clientId={accounting_clients_id}
                  API_domain={API_domain}
                  auth={auth}
                  onClick={(e) => e.stopPropagation()} // Prevent modal trigger
                  // disabled={data.committed} // Disable if committed
                  disabled={true} // Disable always for now
                />
              </Descriptions.Item>
              <Descriptions.Item label='Proveedor'>
                {data.provider || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Descripción'>
                {data.description || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Documento'>
                <ImgOrPdf
                  src={{
                    imageSource:
                      data.imageurl ||
                      data.cashflow_url ||
                      (movementOG && movementOG.cashflow_url),
                  }}
                />
              </Descriptions.Item>
            </Descriptions>
          </>
        );

      case 'sot_transactions': // be more specific between bank and card in order to render the correct icon
        return (
          <>
            <h3 style={{ marginBottom: '1em' }}>
              {title}
              <Tag style={{ marginLeft: 8 }}>
                {relationshipData.type_of_mm == 'bank' ? '🏦' : '💳'}
              </Tag>
            </h3>
            <Descriptions bordered column={1} size='small'>
              <Descriptions.Item label='Monto'>
                <FormattedUSD
                  total={data.amount || (movementOG && movementOG.amount) || 0}
                />
              </Descriptions.Item>
              <Descriptions.Item label='Fecha'>
                {/* fecha de transacción */}
                {data.txn_date || (movementOG && movementOG.txn_date) || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Cuenta contable'>
                <AccountingAccountSelect
                  value={data.account_id}
                  account_id_confidence={data.account_id_confidence}
                  onChange={(newValue) => {
                    console.log('handleChange', newValue);
                  }}
                  clientId={accounting_clients_id}
                  API_domain={API_domain}
                  auth={auth}
                  onClick={(e) => e.stopPropagation()} // Prevent modal trigger
                  // disabled={data.committed} // Disable if committed
                  disabled={true} // Disable always for now
                />
              </Descriptions.Item>
              <Descriptions.Item label='Descripción'>
                {data.description || 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </>
        );

      default:
        return (
          <>
            <h3 style={{ marginBottom: '1em' }}>
              {title}
              <Tag style={{ marginLeft: 8 }}>{sourceMapping[tableName]}</Tag>
              {data.committed && (
                <Tooltip title='Incluido en contabilidad'>
                  <Tag>✅</Tag>
                </Tooltip>
              )}
            </h3>
            <Descriptions bordered column={1} size='small'>
              <Descriptions.Item label='ID de Movimiento'>
                {data.movement_id || (movementOG && movementOG.id) || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Descripción'>
                {data.description || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Monto'>
                <FormattedUSD total={data.amount || 0} />
              </Descriptions.Item>
            </Descriptions>
          </>
        );
    }
  };

  const renderMovementSpecificDetails = () => {
    if (movementLoading) {
      return (
        <div style={{ textAlign: 'center', padding: '2em' }}>
          <Spin size='large' />
        </div>
      );
    }

    if (!relationshipData || !relationshipData.movement_table_name) {
      return (
        <Descriptions bordered column={1} size='small'>
          <Descriptions.Item label='Detalles Adicionales'>
            No hay detalles adicionales.
          </Descriptions.Item>
        </Descriptions>
      );
    }

    const { movement_table_name } = relationshipData;

    if (!movementSpecificData && !movementSpecificDataOGmovement) {
      return (
        <Descriptions bordered column={1} size='small'>
          <Descriptions.Item label='Detalles Adicionales'>
            No se encontraron detalles adicionales.
          </Descriptions.Item>
        </Descriptions>
      );
    }

    if (movementSpecificDataOGmovement) {
      return (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            {renderDescriptions(
              movementSpecificDataOGmovement,
              tableOG,
              'Movimiento Original'
            )}
          </Col>
          <Col xs={24} md={12}>
            {renderDescriptions(
              movementSpecificData,
              movement_table_name,
              'Movimiento Relacionado'
            )}
          </Col>
        </Row>
      );
    }

    // If movementSpecificDataOGmovement does not exist, render normally
    return renderDescriptions(
      movementSpecificData,
      movement_table_name,
      'Movimiento'
    );
  };

  return <>{renderMovementSpecificDetails()}</>;
};

export default MovementSpecificDetails;
