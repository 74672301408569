// RelationshipDoubleFilterDropdown.js
import React, { useState } from 'react';
import { Button, Checkbox, Radio, Space } from 'antd';

/**
 * props.existingFilters: Array of objects like [{ text: 'Facturas Bot', value: 'bot_facturas' }, ...]
 * props.selectedKeys: The current [selectedValue]. We'll store an object here, e.g.:
 *    selectedKeys = [{
 *      relationshipType: [], // e.g. ["bot_facturas", "some_other_type"]
 *      agentFilter: "all"    // or "AUTONOMOUS_AGENT" / "human"
 *    }]
 * props.confirm: function to confirm filter
 * props.clearFilters: function to clear filter
 *
 * Because antd expects selectedKeys to be an array, we store our entire "double filter" object
 * as the **first element** in selectedKeys.
 */
export function RelationshipDoubleFilterDropdown({
  existingFilters,
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
}) {
  // If nothing is in selectedKeys, initialize a default object:
  const initialFilterValue = selectedKeys[0] || {
    relationshipType: [],
    agentFilter: 'all',
  };

  const [localFilter, setLocalFilter] = useState(initialFilterValue);

  const onChangeRelationshipTypes = (checkedValues) => {
    setLocalFilter((prev) => ({
      ...prev,
      relationshipType: checkedValues,
    }));
  };

  const onChangeAgentFilter = (e) => {
    setLocalFilter((prev) => ({
      ...prev,
      agentFilter: e.target.value,
    }));
  };

  const handleConfirm = () => {
    setSelectedKeys([localFilter]);
    confirm();
  };

  const handleClear = () => {
    setLocalFilter({ relationshipType: [], agentFilter: 'all' });
    setSelectedKeys([]);
    clearFilters();
    confirm({ closeDropdown: true });
  };

  return (
    <div style={{ padding: 12 }}>
      <div style={{ marginBottom: 8, fontWeight: 500 }}>Relación:</div>
      <Checkbox.Group
        options={existingFilters.map((f) => ({
          label: f.text,
          value: f.value,
        }))}
        value={localFilter.relationshipType}
        onChange={onChangeRelationshipTypes}
        style={{ display: 'flex', flexDirection: 'column', marginBottom: 12 }}
      />

      <div style={{ marginBottom: 8, fontWeight: 500 }}>Creado por:</div>
      <Radio.Group
        onChange={onChangeAgentFilter}
        value={localFilter.agentFilter}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Radio value='all'>Todas</Radio>
        <Radio value='AUTONOMOUS_AGENT'>Autónomas</Radio>
        <Radio value='human'>Humanos</Radio>
      </Radio.Group>

      <Space style={{ marginTop: 12 }}>
        <Button type='primary' onClick={handleConfirm}>
          Filtrar
        </Button>
        <Button onClick={handleClear}>Reset</Button>
      </Space>
    </div>
  );
}
