import React, { useContext, useEffect, useState } from 'react';
import { Affix, Col, Layout, Row, Button } from 'antd';
import styled from 'styled-components/macro';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';

import { BankView } from './BankView.js';
import { authContext } from '../ProvideAuth.js';

const { Content } = Layout;

function MoneyMovementsView(props) {
  const auth = useContext(authContext);

  // -- React Router v5 hooks --
  const location = useLocation();
  const history = useHistory();

  // -- Read "tab" from the URL query param, default to 'bank' --
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || 'bank';

  const [activeComponent, setActiveComponent] = useState(initialTab);
  const [clientId, setClientId] = useState(null);
  const [clientAccounts, setClientAccounts] = useState([]);

  // ===== Fetch client info (example) =====
  useEffect(() => {
    const fetchClientInfo = async () => {
      try {
        // First, get the client info
        const clientInfoResponse = await axios({
          method: 'post',
          url: `${props.API_domain}getUserAccountingClientCompanyInfo`,
          auth: {
            username: auth.email,
            password: auth.token,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (clientInfoResponse.status === 200) {
          const data = clientInfoResponse.data;
          const accountingClientId = data.accounting_client_id;
          setClientId(accountingClientId);

          // Now fetch the accounts
          const accountsResponse = await axios({
            method: 'post',
            url: `${props.API_domain}getClientAccounts`,
            auth: {
              username: auth.email,
              password: auth.token,
            },
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              client_id: accountingClientId,
            },
          });

          if (accountsResponse.status === 200) {
            const accountsData = accountsResponse.data;
            setClientAccounts(accountsData.accounts);
          } else {
            console.error('Failed to fetch client accounts');
          }
        } else {
          console.error('Failed to fetch client info');
        }
      } catch (error) {
        console.error('Error fetching client info or accounts:', error);
      }
    };

    fetchClientInfo();
  }, [auth.email, auth.token, props.API_domain]);

  // ===== Keep the URL's ?tab in sync with activeComponent, but avoid loops =====
  useEffect(() => {
    const currentParams = new URLSearchParams(location.search);
    const currentTab = currentParams.get('tab') || 'bank';

    if (currentTab !== activeComponent) {
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set('tab', activeComponent);

      history.replace({
        pathname: location.pathname,
        search: newSearchParams.toString(),
      });
    }
  }, [activeComponent, location, history]);

  // ===== Render the chosen component =====
  const commonProps = {
    API_domain: props.API_domain,
    clientId: clientId,
    clientAccounts: clientAccounts,
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'bank':
        return <BankView {...commonProps} type_of_mm="bank" />;
      case 'card':
        return <BankView {...commonProps} type_of_mm="card" />;
      default:
        // Fallback to 'bank'
        return <BankView {...commonProps} type_of_mm="bank" />;
    }
  };

  // ===== JSX =====
  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderRight: 'solid rgb(235,235,235) 1px',
      }}
    >
      <Affix>
        <AffixDiv>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <NavButton
                type="link"
                onClick={() => setActiveComponent('bank')}
                isActive={activeComponent === 'bank'}
              >
                🏦 Banco
              </NavButton>
              <NavButton
                type="link"
                onClick={() => setActiveComponent('card')}
                isActive={activeComponent === 'card'}
              >
                💳 Tarjetas
              </NavButton>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {/* You can add other elements or controls here */}
            </Col>
          </Row>
        </AffixDiv>
      </Affix>
      <div>{renderComponent()}</div>
    </Content>
  );
}

// ===== Styled Components =====
const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

const NavButton = styled(Button)`
  color: ${({ isActive }) => (isActive ? 'rgb(116, 116, 205)' : '#000')};
  font-weight: ${({ isActive }) => (isActive ? '600' : 'normal')};
  font-size: 16px;
`;

export { MoneyMovementsView };
